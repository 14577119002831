import React from 'react';

import Page from 'components/Page';
import AuthForm, { STATE_LOGIN } from 'components/AuthForm';
import { MdArrowBack, MdKeyboardArrowDown, MdArrowDropDown, MdCancel, MdDelete } from 'react-icons/md';
import {
    Row,
    Col,
    Button,
    ButtonGroup,
    Card,
    CardHeader,
    CardSubtitle,
    CardBody,
    CardText,
    Modal,
    ModalBody,
    Nav, Navbar, NavItem,
} from 'reactstrap';
import LotteryListHeader from '../components/Layout/LotteryListHeader';

class LotteryListPage extends React.Component {

    render() {

        return (
            <div >
                <LotteryListHeader {...this.props} />

                <div style={{ marginTop: 10 }}>
                    <span style={{ fontSize: "14px", margin: 75, }}>
                        20200205-0722期投注截止
                <p style={{ color: '#ff5722', marginLeft: 255, marginTop: -20 }}>
                            00:00:30
                </p>
                    </span>
                </div>

                <div style={{ marginTop: -30 }}>
                    <Button style={{
                        backgroundColor: 'white', background: 'white',
                        color: '#ff5722', borderColor: 'white', marginLeft: 17
                    }}>+ 手动选号</Button>

                    <Button style={{
                        backgroundColor: 'white', background: 'white',
                        color: '#ff5722', borderColor: 'white', marginLeft: 17
                    }}>+ 机选选号</Button>

                    <Button style={{
                        backgroundColor: 'white', background: 'white',
                        color: '#ff5722', borderColor: 'white', marginLeft: 17
                    }}><MdDelete color={'#ff5722'} size={18} /> 清空列表</Button>
                </div>

                <div style={{ boxSizing: "border-box", background: 'white', marginTop: 10, marginLeft: -45, padding: 1, }}>
                    <ul>
                        <li>
                            <div style={{ marginTop: 15 }}>
                                <font style={{ color: '#f44336', marginLeft: 30 }}>,,27,,</font> <span>[定位胆——定位胆]</span>
                                <button style={{ marginLeft: 130, marginTop: -5, backgroundColor: 'white', border: 'none' }} >
                                    <MdCancel color={'gray'} size={20} />
                                </button>
                            </div>
                        </li>
                        <li>
                            <div style={{ marginTop: 20 }}>
                                <font style={{ color: '#f44336', marginLeft: 30 }}>,,,8,3</font> <span>[定位胆——定位胆]</span>
                                <button style={{ marginLeft: 129, marginTop: -5, backgroundColor: 'white', border: 'none' }} >
                                    <MdCancel color={'gray'} size={20} />
                                </button>
                            </div>
                        </li>
                        <li>
                            <div style={{ marginTop: 20 }}>
                                <font style={{ color: '#f44336', marginLeft: 30 }}>,6,1,,</font> <span>[定位胆——定位胆]</span>
                                <button style={{ marginLeft: 134, marginTop: -5, backgroundColor: 'white', border: 'none' }} >
                                    <MdCancel color={'gray'} size={20} />
                                </button>
                            </div>
                        </li>
                    </ul>
                </div>

                <div className="foot-bar">
                    <div style={{ position: "absolute", bottom: 0, zIndex: 499, background: 'white', display: 'flex' }}>
                        <div className="bsBox" style={{ padding: 13, marginLeft: 350 }}>
                            <span style={{ color: 'black', margin: 12, marginLeft: -355, marginTop: -14 }}> 倍投</span>
                            <input type="text" placeholder="1" style={{ width: 80, textAlign: "right", height: 35, marginTop: -5, border: 'none' }}>
                            </input>
                            <div style={{
                                height: '35px', lineHeight: '35px', borderRadius: '2px', display: 'flex', width: 110, marginLeft: -76,
                                marginTop: '-4px', boxShadow: '0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24)'
                            }}>
                                <div style={{marginLeft:83, marginTop:-5}}>
                                <MdArrowDropDown size={25} />
                                </div>
                            </div>
                            <div>
                                <Button style={{
                                    background: 'white', color: '#F74F0A', borderColor: 'white', margin: -130, padding: 5,
                                    marginTop: -155, marginLeft: 124, boxShadow: '0 1px 3px rgba(0,0,0,.12), 0 1px 2px rgba(0,0,0,.24)'
                                }}> 智能追号
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="toolbar" style={{ padding: 13, marginLeft: 0, paddingLeft: 355 }}>
                    <div>
                        <a>
                            <font style={{ color: '#f44336', marginLeft: -340 }}>3</font>单
                        </a>
                        <a>
                            <font style={{ color: '#f44336', marginLeft: 45 }}>6</font>注
                        </a>
                        <a>
                            <font style={{ color: '#f44336', marginLeft: 50 }}>12.00</font>元
                        </a>
                        <button className="toolbar-button">
                            <p style={{ fontSize: 14, width: 88, marginLeft: -8, color:'white' }}>提交购彩</p>
                        </button>
                    </div>


                </div>

            </div>
        );

    };
}


export default LotteryListPage;
