import React from 'react';
import { Button, Modal } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import { MdArrowBack} from 'react-icons/md';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { ShowLoginModal } from '../../actions/HomePageAction';
import { closeForgotPasswordModal } from '../../services/HomeService';
import i18next from 'i18next';
import {alert} from '../../services/AlertService';

const tran = (input) => {
  return i18next.t(input);
}

const mapStateToProps = (state) => {
  return {
    modal: state.modal,
    indexInit: state.indexInit,
  };
};

const mapDispatchToProps = dispatch => (
  bindActionCreators({
    ShowLoginModal,
  }, dispatch)
);

class ForgotPasswordPage extends React.Component {

  state = {
    username: '',
    password: '',
    validCode: '',
    generateValidCode:''
  };

  constructor(props) {
    super(props);
  }

  componentDidMount() {

  }

  
  forgetPasswordPost = function() {
    let loginName = this.state.username;
    let password = this.state.password;
    let validCode = this.state.validCode;

    if(!loginName){
      alert(tran("账号不能为空！"),"")
      return;
    }
    if(!password){
      alert(tran("取款密码不能为空！"),"")
      return;
    }
    if(!validCode){
      alert(tran("验证码不能为空！"),"")
      return;
    }

    const data = new URLSearchParams();
    data.set('account', loginName);
    data.set('password', password);
    data.set('rmNum', validCode);
    data.set('generateValidCode',this.state.generateValidCode)

    fetch(window.$wgtDomain + '/member-center/my-account/forget-psd', {
      method: 'POST',
      headers: new Headers({
        'Accept': 'application/json, text/plain, */*',
        'Content-Type': 'application/x-www-form-urlencoded',
      }),
      // credentials: 'include',
      body: data,
      referrer: 'h5'
    })
      .then((response) => response.json())
      .then((response) => {
        console.log(response);

        if (response.success) {
          alert(response.msg,"");
          closeForgotPasswordModal();
        }
        else {
          this.setState({validCode:""});
          alert(response.msg,"");
        }

      })
      .catch((error) => {
        console.log("error forgotpassword")
        console.error(error);
        alert(tran("网络问题, 请稍后重试"),"");
      });
  };

  closeModal(){
    closeForgotPasswordModal();
  }

  inputOnFocus(event) {
    let a = event.currentTarget.parentElement;
    a.classList.add('focus-state');
  }

  inputOnBlur(event) {
    let a = event.currentTarget.parentElement;
    a.classList.remove('focus-state');
  }

  handleUsername(event) {
    this.setState({ username: event.target.value });
  }

  handlePassword(event) {
    this.setState({ password: event.target.value });
  }

  handleValidCode(event) {
    this.setState({ validCode: event.target.value });
  }

  showValidCode = function(event){
      let randomCode = new Date().getTime();
      this.setState({
        generateValidCode:randomCode
      })
      let validCodeImg = document.getElementById('forgotPasswordValidCode');
      validCodeImg.setAttribute("src" , window.$wgtDomain+'/valid-code?_t=' + randomCode);
      validCodeImg.style.display = "block";
  }
  
  
  render() {
    const { t } = this.props;

    return (
      <Modal isOpen={this.props.modal.forgotPasswordModal.show} className="cart-modal">
          <div className="page-content">
          <div style={{
            backgroundColor: 'var(--theme-color)',
            color: '#fff',
            flexDirection: 'row',
            padding: '10px',
            lineHeight: '24px',
            display: 'block',
            textAlign: 'center',
          }}>
            <div style={{ float: 'left', position: 'absolute', left: 15, top: 0, lineHeight: '44px' }}>
              <Button outline style={{ outline: 'none', border: 0, alignSelf: 'flex-start', padding: 0 }}>
                <MdArrowBack size={24} onClick={this.closeModal}/>
              </Button>
            </div>
            <div className="center" style={{ fontSize: 18 }}>{t('loginpage.NameLoginContent.forgetpassword')}</div>
          </div>
          <form>
            <div className="list-block inputs-list">
              <ul>
                <li className="item-content">
                  <div className="item-inner"> 
                    <div className="item-title label">{t('会员账号')}</div>
                    <div className="item-input">
                      <input style={{borderBottom:'1px solid rgba(0, 0, 0, 0.2)'}} type="text" name="forgotpasswordusername" placeholder="" maxLength="12" onChange={this.handleUsername.bind(this)}/>
                    </div>
                  </div>
                </li>
                {/* <li className="item-content">
                  <div className="item-inner"> 
                    <div className="item-title label">{t('psdprotectedpage.safepwd')}</div>
                    <div className="item-input">
                      <input style={{borderBottom:'1px solid rgba(0, 0, 0, 0.2)'}} type="password" name="safepassword" placeholder="" maxLength="6" onChange={this.handlePassword.bind(this)} />
                    </div>
                  </div>
                </li> */}
                <li className="item-content">
                  <div className="item-inner"> 
                    <div className="item-title label">{t('loginpage.NameLoginContent.verifycode')}</div>
                    <div className="item-input item-code">
                      <input style={{borderBottom:'1px solid rgba(0, 0, 0, 0.2)'}} type="number" name="validCode" value={this.state.validCode} placeholder="" maxLength="4" onFocus={this.inputOnFocus} onBlur={this.inputOnBlur} onClick={this.showValidCode} onChange={this.handleValidCode.bind(this)}/>
                      <img src="" style={{display: 'none'}} id="forgotPasswordValidCode"/>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
        <div className="content-block"><div href="#" className="f7-button button-fill register-button" onClick={this.forgetPasswordPost.bind(this)}>{t('Confirm')}</div></div>
            {/* <div className="tip" style={{backgroundColor:'white',padding:'10px 15px 0',fontSize:12}}>{t('温馨提醒：会员账号及安全密码核对正确后,系统即自动发送邮件至会员资料内所设置E-MAIL信箱，请注意查收！')}</div> */}
            <div className="tip" style={{backgroundColor:'white',padding:'10px 15px 0',fontSize:12}}>{t('温馨提醒：会员账号核对正确后,系统即自动发送邮件至会员资料内所设置E-MAIL信箱，请注意查收！')}</div>
          </form>
        </div>
      </Modal>
    );
  };
}

const ConnectedForgotPasswordPage = connect(mapStateToProps, mapDispatchToProps)(ForgotPasswordPage);
export default withTranslation('common')(ConnectedForgotPasswordPage);