import React from 'react';
import { withTranslation } from "react-i18next";
import '../styles/components/GameMenuList.css';
import '../styles/tab.css';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { saveHomePageGameList, UpdateAlert } from '../actions/HomePageAction';
import SwipeableViews from 'react-swipeable-views-react-18-fix';
import { selectGameService, updateBetRecord } from '../actions/GameAction';
import { selectGameFromJson } from '../services/GameService';
import { PullToRefresh } from "react-js-pull-to-refresh";
import ReactLoading from 'react-loading';
import {getMemberBalance, checkLogin} from '../services/HomeService';
import i18next from 'i18next';

const images = require.context('../assets/image/game/gameui', true);
const t = (input) => {
	return i18next.t(input);
  }
const xiushiSrc = require('../assets/image/xiushi.png');

const mapStateToProps = (state) => {
	return {
		home: state.home,
		game: state.game,
		utils: state.utils,
		alert: state.alert,
	};
};

const mapDispatchToProps = dispatch => (
	bindActionCreators({
		saveHomePageGameList,
		selectGameService,
		updateBetRecord,
		initLoadingAction,
		closeLoadingAction,
		UpdateAlert,
	}, dispatch)
);

const initLoadingAction = () => {
	return { type: 'RESET_LOAD' }
}

const closeLoadingAction = () => {
	return { type: 'SUCCESS_LOAD' }
}

class GameLogoCard extends React.Component {
	constructor(props) {
		super(props);
	}

	selectGame = async (event) => {
		this.props.initLoadingAction();

		let selectedGameObj = {
			playId: event.currentTarget.getAttribute('playid'),
			splayId: event.currentTarget.getAttribute('splayid'),
			playType: event.currentTarget.getAttribute('playtype'),
			splayType: event.currentTarget.getAttribute('splaytype'),
			id: event.currentTarget.getAttribute('id'),
			gameName: event.currentTarget.getAttribute('gameName'),
			isCatch: JSON.parse(event.currentTarget.getAttribute('isCatch')),
			isZongHe: event.currentTarget.getAttribute('isZongHe'),
			zongHeType: event.currentTarget.getAttribute('zongHeType'),
			isShengXiaoLoaded: (this.props.game.mainGameObj?.shengxiao !== undefined && this.props.game.mainGameObj?.shengxiao.length !== 0),
		};

		try {
			const shellGameObj = {"brnn":1,"hhdz":2,"jsbjl":3,"qqcg":5};
			if (selectedGameObj.id && shellGameObj[selectedGameObj.id.toLowerCase()]) {
				let selectedGame = this.props.home.gameList[0].gameList.find(item => item.id == selectedGameObj.id);
				this.props.history.push({ pathname: '/gameHall', state: { selectedGame: selectedGame, hallGameId: shellGameObj[selectedGameObj.id.toLowerCase()] } });
				this.props.closeLoadingAction();
			} else {
				let gameObj = await selectGameFromJson(selectedGameObj);
				gameObj.gameMap = this.props.home.gameList[0].gameList;
				this.props.selectGameService(gameObj);
				getMemberBalance();
				this.props.history.push('/games');
			}
		} catch (e) {
			this.props.closeLoadingAction();
			this.props.alert.content = t('无法进入游戏!');
			this.props.alert.title = t('提示');
			this.props.alert.alertType = 'normalAlert';
			this.props.alert.showAlert = true;
			this.props.UpdateAlert(this.props.alert);
		}
	}

	getGameImage(imagewithtype){
		try{
		  return images('./'+imagewithtype);
		} catch (err) {
		  console.error(err);
		  return window.$gameUrl+"/imagenotfound.png"
		}
	}

	render() {
		const { t, isOpen } = this.props;
		if (isOpen == null) { // if unable to retrieve isOpen default to true
			isOpen = true;
		}
		let imageSrc;
		try {
			if(this.props.gamePictureMod != null){
				imageSrc = this.props.gamePictureMod;
			}else if(this.props.memberInfo.MemberInfo.gamecategory === null || this.props.memberInfo.MemberInfo.gamecategory === 1){
				imageSrc = images(`./safe_${this.props.id}.png`);
			  }else{
				imageSrc = images(`./en_safe_${this.props.id}.png`);
			}
		} catch (err) {
      console.error(err);
			imageSrc = null;
		}

		let dataAttribute = {
			splayid: this.props.splayId,
			playid: this.props.playId,
			playtype: this.props.playType,
			splaytype: this.props.splayType,
			id: this.props.id,
			gamename: this.props.name,
			iscatch: this.props.isCatch,
			iszonghe: this.props.isZongHe?'true':'false',
			zonghetype: (this.props.zongHeType!='' && this.props.zongHeType!=null)?this.props.zongHeType:''
		}

		const grayout = !isOpen ? { filter: 'grayscale(1)', opacity: '0.65' } : {};
		return (
			<div  style={{float:i18next.language!='zh'?'':'left',width:i18next.language!='zh'?'calc(50% - 10px)':'33%',textAlign:i18next.language!='zh'?'':'center',marginBottom:i18next.language!='zh'?'':10, position: 'relative',
						padding:i18next.language!='zh'?'10px 0 10px 5px':'',border:i18next.language!='zh'?'2px solid #f3f3f3':'',margin:i18next.language!='zh'?'5px':'',height:i18next.language!='zh'?'70px':''}} {...dataAttribute} onClick={!isOpen ? null : this.selectGame}>
				<img style={{...grayout, width: i18next.language!='zh'?'50px':'64px', height: i18next.language!='zh'?'50px':'64px', borderRadius: '10px', marginBottom: '5px', marginTop: i18next.language!='zh'?'-3px':'',float: i18next.language!='zh'?'left':''}} src={imageSrc}/>
				{!isOpen ?
					<img style={{ width: i18next.language!='zh'?'60px':'64px', height: i18next.language!='zh'?'60px':'64px', borderRadius: '10px', marginBottom: '5px',
						position: 'absolute', top: '-1px', left:i18next.language!='zh'?'100%':'50%', marginLeft: i18next.language!='zh'?'-60px':'-31px', marginTop: i18next.language!='zh'?'1px':''
					}} src={xiushiSrc}/> : null}
				<div style={{ textAlign: i18next.language!='zh'?'left':'center', fontSize: "14px", marginLeft:i18next.language!='zh'?'55px':''}}>{i18next.language !='zh' ? t(this.props.name) : this.props.name}</div>
				<div style={{ display:i18next.language!='zh'?'none':'block', textAlign: "center", color: '#999', fontSize: '13px', margin: '5px 0', padding: '0 10px' }}>{this.props.categoryName}</div>
			</div>
		);
	}
}

class GameList extends React.Component {
	constructor(props) {
		super(props);
	}

	render() {
		return (
			<div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', backgroundColor: 'white' }}>
				{this.props.gameList.map((game, index) =>
					<GameLogoCard key={index} id={game.id} name={game.gameName} categoryName={game.description} splayId={game.splayId} playId={game.playId} splayType={game.splayType} playType={game.playType} isOpen={game.isOpen} gamePictureMod={game.gamePictureMod} isZongHe={game.isZongHe} zongHeType={game.zongHeType} isCatch={game.isCatch?'true':'false'} {...this.props} />
				)}
			</div>
		)
	}
}

class GameMenuList extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			activeIndex: 0,
		}
	}

	jumpGame = async (gameId) => {
		this.props.initLoadingAction();
		let selectedGame = this.props.home.gameList[0].gameList.find(game => game.id == gameId);
		if (selectedGame) {
			let selectedGameObj = {
				playId: selectedGame.playId,
				splayId: selectedGame.splayId,
				playType: selectedGame.playType,
				splayType: selectedGame.splayType,
				id: selectedGame.id,
				isCatch: selectedGame.isCatch,
				isZongHe: selectedGame.isZongHe,
				zongHeType: selectedGame.zongHeType,
				gameName: selectedGame.gameName,
				isShengXiaoLoaded: (this.props.game.mainGameObj?.shengxiao !== undefined && this.props.game.mainGameObj?.shengxiao.length !== 0),
			}
			try {
				const shellGameObj = {"brnn":1,"hhdz":2,"jsbjl":3,"qqcg":5};
				if (selectedGameObj.id && shellGameObj[selectedGameObj.id.toLowerCase()]) {
					let selectedGame = this.props.home.gameList[0].gameList.find(item => item.id == selectedGameObj.id);
					this.props.history.push({ pathname: '/gameHall', state: { selectedGame: selectedGame, hallGameId: shellGameObj[selectedGameObj.id.toLowerCase()] } });
					this.props.closeLoadingAction();
				}
				else{
				let gameObj = await selectGameFromJson(selectedGameObj);
				gameObj.gameMap = this.props.home.gameList[0].gameList;
				this.props.selectGameService(gameObj);
				this.props.history.push('/games');
				}
			} catch (e) {
				this.props.closeLoadingAction();
				this.props.alert.content = t('无法进入游戏!');
				this.props.alert.title = t('提示');
				this.props.alert.alertType = 'normalAlert';
				this.props.alert.showAlert = true;
				this.props.UpdateAlert(this.props.alert);
			}
		} else {
			this.props.closeLoadingAction();
			this.props.alert.content = t('game_not_exist');
			this.props.alert.title = t('提示');
			this.props.alert.alertType = 'normalAlert';
			this.props.alert.showAlert = true;
			this.props.UpdateAlert(this.props.alert);
		}
	}

	componentDidMount() {
		const search = new URLSearchParams(window.location.search);
		let id = window.setTimeout(function() {}, 0);
		while (id--) {
			window.clearTimeout(id);
		}
		if (this.props.home.gameList.length === 1) {
			fetch(window.$gameUrl + "/rest/game/lottery/loadgame?mobile=true")
				.then(res => res.json())
				.then(
					(result) => {
						this.props.saveHomePageGameList(result.result.games);
						this.setState({
							activeIndex: 0,
						});
						// console.log('loadgame jump:'+this.props.utils.jumpGame+':'+search.get("game")+':');
						if (this.props.utils.jumpGame) {
							this.jumpGame(this.props.utils.jumpGame);
						//} else {
						//	this.jumpGame(search.get("game"));
						}
					},
					(error) => {
						this.setState({
							error: error
						});
					}
				)
		} else {
			if (this.props.utils.jumpGame) {
				this.jumpGame(this.props.utils.jumpGame);
			}
		}

		let intervalId = setInterval(() => {
      checkLogin();
		}, 60000);
	}

    handleActiveIndexUpdate(e) {
        let input = e.currentTarget.getAttribute('ivalue');
        this.setState({ activeIndex: parseInt(input) });
    };

	handleChangeIndex = input => {
		this.setState({ activeIndex: input });
	};

	render() {
		const { t } = this.props;
		const { activeIndex } = this.state;
		let tabBarItems;
		let windowHeight = window.innerHeight;
		let gameListHeight = windowHeight - 196;
		if(window.$mode == 2){
			gameListHeight = gameListHeight + 44;
		}
		const PullDownContent =
				<div style={{ background: 'rgb(213, 233, 247)', color: 'rgb(42, 144, 215)', textAlign: 'center', fontSize: 14, lineHeight: '32px' }}>{t('pull_down_to_refresh')}</div>
			;
		const ReleaseContent =
				<div style={{ background: 'rgb(213, 233, 247)', color: 'rgb(42, 144, 215)', textAlign: 'center', fontSize: 14, lineHeight: '32px' }}>{t('release_to_refresh')}</div>
			;
		const RefreshContent =
			<ReactLoading type={"spin"} style={{width:"50px",height:"50px",margin:'auto'}}/>
		;

		if (this.props.home.gameList && this.props.home.gameList.length > 1) {
			tabBarItems = this.props.home.gameList.map((item, index) => {
				let imageSrc;
				try {
					if(this.props.memberInfo.MemberInfo.gamecategory === null || this.props.memberInfo.MemberInfo.gamecategory === 1){
						imageSrc = images(`./safe_${item.id}.png`)
					}else{
						imageSrc = images(`./en_safe_${item.id}.png`);
					}
				} catch (err) {
          //console.error(err);
					imageSrc = null;
				}
				return (
					<div key={index} className="rules-method-header" style={{paddingTop:0}}>
						<div key={index} className={'rules-method-header-button ' + (activeIndex === index ? 'active' : "")}
						ivalue={index} onClick={this.handleActiveIndexUpdate.bind(this)}
						style={{color: 'black'}}>
							<img style={{ width: '40px', height: '40px', borderRadius: '50%' }} src={imageSrc} />
							<div style={{ textAlign: "center", fontSize: "14px" }}>{i18next.language != 'zh' ? t(item.id) : item.gameName}</div>
						</div>
					</div>
				)
			})

			return (
				<div className='menu-page'>
					<div className="rules-method-container" 
					style={{height:85, paddingTop:5, paddingBottom:5, backgroundColor: '#f4f4f4'}}>
						{tabBarItems}
					</div>
					<div style={{ overflow: 'auto', height: `calc(${gameListHeight}px - var(--safe-bottom))`,width:window.innerWidth }}>
						<PullToRefresh
							pullDownContent={PullDownContent}
							releaseContent={ReleaseContent}
							refreshContent={RefreshContent}
							pullDownThreshold={50}
							onRefresh={this.props.refresh.bind(this)}
							triggerHeight={"auto"}
							startInvisible={true}
						>
							<SwipeableViews index={activeIndex} onChangeIndex={this.handleChangeIndex} animateHeight={true} disableLazyLoading={true}
								children={this.props.home.gameList.map((game, index) => <GameList key={index} gameList={game.gameList} {...this.props} />)}
								style={{ paddingTop: '10px', background: 'white' }} />
						</PullToRefresh>
					</div>
				</div>
			);
		} else {
			return null;
		}
	}
}

const ConnectedGameMenuList = connect(mapStateToProps, mapDispatchToProps)(GameMenuList);

export default withTranslation('common')(ConnectedGameMenuList);
