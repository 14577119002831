import React from 'react';
import PropTypes from 'utils/propTypes';
import { Card } from 'reactstrap';
import Typography from 'components/Typography';
import { Trans } from 'react-i18next';

const AwardZhongHePage = ({ title, issue, grand, first, second, third, four, five, six, seven, eight, zongheType, live, ...restProps }) => {

  return (
    <Card body {...restProps}
          style={{ width: '100%', padding: '14px 16px', borderRadius: 0, borderTop: 0, borderBottomWidth: '0.4px'
          ,backgroundColor:live?'transparent':'white', color:live?'white':'black' }}>
      <div style={{ display: 'flex',marginBottom:'5px' }}>
        <Typography className="mb-0" style={{ lineHeight: '24px' }}>
          {title}
        </Typography>
        <div style={{ marginLeft: 'auto', fontSize: 14, lineHeight: '24px' }}>{issue}</div>
      </div>
      { zongheType == 'VN' ?
        <>
          <table className="awardzhonghe" style={{ width: '100%' }}>
            <tbody>
            <tr>
              <th style={{ background: '#f44336', color: 'white',padding:'5px' }}><Trans className="text-white" i18nKey="首奖"></Trans></th>
              <th colSpan={12} style={{ background: '#FFFFC2', color: 'black' }}>{grand}</th>
            </tr>
            <tr>
              <th style={{padding:'5px'}}><Trans className="text-white" i18nKey="一等奖"></Trans></th>
              <td colSpan={12}>{first}</td>
            </tr>
            <tr>
              <th style={{padding:'5px'}}><Trans className="text-white" i18nKey="二等奖"></Trans></th>
              <td colSpan={12}>{second}</td>
            </tr>
            <tr>
              <th style={{padding:'5px'}}><Trans className="text-white" i18nKey="三等奖"></Trans></th>
              <td colSpan={6}>{third.split(',')[0]}</td>
              <td colSpan={6}>{third.split(',')[1]}</td>
            </tr>
            <tr>
              <th rowSpan={2} style={{padding:'5px'}}><Trans className="text-white" i18nKey="四等奖"></Trans></th>
              <td colSpan={3}>{four.split(',')[0]}</td>
              <td colSpan={3}>{four.split(',')[1]}</td>
              <td colSpan={3}>{four.split(',')[2]}</td>
              <td colSpan={3}>{four.split(',')[3]}</td>
            </tr>
            <tr>
              <td colSpan={4}>{four.split(',')[4]}</td>
              <td colSpan={4}>{four.split(',')[5]}</td>
              <td colSpan={4}>{four.split(',')[6]}</td>
            </tr>
            <tr>
              <th style={{padding:'5px'}}><Trans className="text-white" i18nKey="五等奖"></Trans></th>
              <td colSpan={12}>{five}</td>
            </tr>
            <tr>
              <th style={{padding:'5px'}}><Trans className="text-white" i18nKey="六等奖"></Trans></th>
              <td colSpan={4}>{six.split(',')[0]}</td>
              <td colSpan={4}>{six.split(',')[1]}</td>
              <td colSpan={4}>{six.split(',')[2]}</td>
            </tr>
            <tr>
              <th style={{padding:'5px'}}><Trans className="text-white" i18nKey="七等奖"></Trans></th>
              <td colSpan={12}>{seven}</td>
            </tr>
            <tr>
              <th style={{padding:'5px'}}><Trans className="text-white" i18nKey="八等奖"></Trans></th>
              <td colSpan={12}>{eight}</td>
            </tr>
            </tbody>
          </table>
        </>
        :
        <>
          <table className="awardzhonghe" style={{ width: '100%' }}>
            <tbody>
            <tr>
              <th style={{ background: '#f44336', color: 'white',padding:'5px' }}><Trans className="text-white" i18nKey="首奖"></Trans></th>
              <th colSpan={12} style={{ background: '#FFFFC2', color: 'black' }}>{grand}</th>
            </tr>
            <tr>
              <th style={{padding:'5px'}}><Trans className="text-white" i18nKey="一等奖"></Trans></th>
              <td colSpan={12}>{first}</td>
            </tr>
            <tr>
              <th style={{padding:'5px'}}><Trans className="text-white" i18nKey="二等奖"></Trans></th>
              <td colSpan={6}>{second.split(',')[0]}</td>
              <td colSpan={6}>{second.split(',')[1]}</td>
            </tr>
            <tr>
              <th rowSpan={2} style={{padding:'5px'}}><Trans className="text-white" i18nKey="三等奖"></Trans></th>
              <td colSpan={4}>{third.split(',')[0]}</td>
              <td colSpan={4}>{third.split(',')[1]}</td>
              <td colSpan={4}>{third.split(',')[2]}</td>
            </tr>
            <tr>
              <td colSpan={4}>{third.split(',')[3]}</td>
              <td colSpan={4}>{third.split(',')[4]}</td>
              <td colSpan={4}>{third.split(',')[5]}</td>
            </tr>
            <tr>
              <th style={{padding:'5px'}}><Trans className="text-white" i18nKey="四等奖"></Trans></th>
              <td colSpan={3}>{four.split(',')[0]}</td>
              <td colSpan={3}>{four.split(',')[1]}</td>
              <td colSpan={3}>{four.split(',')[2]}</td>
              <td colSpan={3}>{four.split(',')[3]}</td>
            </tr>
            <tr>
              <th rowSpan={2} style={{padding:'5px'}}><Trans className="text-white" i18nKey="五等奖"></Trans></th>
              <td colSpan={4}>{five.split(',')[0]}</td>
              <td colSpan={4}>{five.split(',')[1]}</td>
              <td colSpan={4}>{five.split(',')[2]}</td>
            </tr>
            <tr>
              <td colSpan={4}>{five.split(',')[3]}</td>
              <td colSpan={4}>{five.split(',')[4]}</td>
              <td colSpan={4}>{five.split(',')[5]}</td>
            </tr>
            <tr>
              <th style={{padding:'5px'}}><Trans className="text-white" i18nKey="六等奖"></Trans></th>
              <td colSpan={4}>{six.split(',')[0]}</td>
              <td colSpan={4}>{six.split(',')[1]}</td>
              <td colSpan={4}>{six.split(',')[2]}</td>
            </tr>
            <tr>
              <th rowSpan={2} style={{padding:'5px'}}><Trans className="text-white" i18nKey="七等奖"></Trans></th>
              <td colSpan={3}>{seven.split(',')[0]}</td>
              <td colSpan={3}>{seven.split(',')[1]}</td>
              <td colSpan={3}>{seven.split(',')[2]}</td>
              <td colSpan={3}>{seven.split(',')[3]}</td>
            </tr>
            <tr>
            </tr>
            </tbody>
          </table>
        </>
      }
    </Card>
  );
};

AwardZhongHePage.propTypes = {
  title: PropTypes.string.isRequired,
  right: PropTypes.node,
};

export default AwardZhongHePage;
