import AwardPage from 'components/AwardLivePage';
import React from 'react';
import 'cross-fetch/polyfill';
import { connect } from 'react-redux';
import {
  Button,
  Col,
  Row,
} from 'reactstrap';
import { withTranslation} from 'react-i18next';
import { withLastLocation } from 'react-router-last-location';
import i18next from 'i18next';
import { PullToRefresh } from 'react-js-pull-to-refresh';
import "react-datepicker/dist/react-datepicker.css";
import { MdSearch,MdPhotoFilter } from 'react-icons/md';
import AwardZhongHePage from '../components/AwardZhongHePage';
import Select from "react-select";
import ReactLoading from 'react-loading';

let try2 = [];
let awardList = [];
const game_images = require.context('../assets/image/game/', true);
let isLiveResultDisplay = window.location.hash?(window.location.hash == '#/liveresult'):false; 
const mapStateToProps = (state) => {
  return {
    game: state.game,
    home: state.home,
    memberInfo: state.memberInfo,
  };
};

const customStyles = {
  control: (provided, state) => ({
    ...provided,
    background: '#fff',
    borderColor: '#9e9e9e',
    minHeight: '26px',
    boxShadow: state.isFocused ? null : null,
    textAlign: 'left'
  }),

  valueContainer: (provided, state) => ({
    ...provided,
    height: '26px',
    padding: '0 6px'
  }),
  menu: (provided, state) => ({
    ...provided,
    textAlign: 'left'
  }),
  input: (provided, state) => ({
    ...provided,
    margin: '0px',
    paddingTop: '0px'
  }),
  indicatorSeparator: state => ({
    display: 'none',
  }),
  indicatorsContainer: (provided, state) => ({
    ...provided,
    height: '26px',
  }),
};

class LiveAwardPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      tabLine: "left",
      awardetail: true,
      awardModal: false,
      gameName: '',
      gameId: '',
      top: 20,
      activeIndex: 0,
      issueDate: '',
      values: [],
      selectedValue: '',
      localizeAwards: [],
      zhType: 'VN',
      isClearable: false,
      isDisabled: false,
      isLoading: false,
      isRtl: false,
      isSearchable: false,
      loading: false,
      isVN6DOpen: false,
      isVN5DOpen: false,
      lang: 'zh'
    };
    this.changeTab = this.changeTab.bind(this);
    this.onPress = this.onPress.bind(this);
    this.handleSearch = this.handleSearch.bind(this);
    this.handleIssueDateChange = this.handleIssueDateChange.bind(this);
    this.handleSelectChange = this.handleSelectChange.bind(this);
  }

  refresh = async () => {
    let promise = new Promise((resolve, reject) => {
      this.componentDidMount();
      setTimeout(() => resolve('done!'), 1000);
    });
  };

  componentDidMount() {
    this.setState({
      loading: true
    });
    const windowURL = window.location.href; 
    // console.log('lastlocation at LiveAwardPage: '+this.props.lastLocation);
    // if (this.props.lastLocation === null) {
    //   if (windowURL.includes('livemode=true')) {
    //     if (windowURL.includes('gameId=')) {
    //       this.props.history.push('/liveresult');
    //     } else {
    //       this.props.history.push('/live');
    //     }
    //   }       
    // }
    this.loadData();
  }

  async loadData(){
    let requests = [];
    awardList = [];
    let live = (window.location.href.includes("/live")?true:false);
    console.log('loadData: '+live);
    if (live) {
      // console.log('storage:'+storage.getItem('key'));
      let urlParameter = window.location.href.split('#')[0];
      console.log('LiveAwardPage url: '+urlParameter);
      let vars = {};
      urlParameter.replace(/[?&]+([^=&]+)=([^&]*)/gi, function (m, key, value) {
          vars[key] = value;
          console.log('key: '+key+'='+value);
      });
      if (typeof vars['lang'] !== 'undefined') {
        this.setState({lang:vars['lang']});
        i18next.changeLanguage(vars['lang']);
      }
      // if (typeof vars['key'] !== 'undefined') {
      //   var encryptedBase64Key = 'bXVzdGJlMTZieXRlc2tleQ==';//<encoded base64 key from Java>;
      //   var parsedBase64Key = CryptoJS.enc.Base64.parse(encryptedBase64Key);
      //   var encryptedCipherText = vars['key'];
      //   var decryptedData = CryptoJS.AES.decrypt(encryptedCipherText, parsedBase64Key, {
      //     mode: CryptoJS.mode.ECB,
      //     padding: CryptoJS.pad.Pkcs7
      //   });
      //   var decryptedText = decryptedData.toString(CryptoJS.enc.Utf8);
      //   let inputKey = decryptedText.split('|');
      // }
      let gameId = vars['gameId'];
      if (gameId !== undefined) {
        // for (let i = 0; i < gameId.length; i++) {
          if(gameId === 'VN6D'){
            this.setState({isVN6DOpen:true});
            this.setState({zhType:'VN'});
          }
          if(gameId === 'VN5D'){
            this.setState({isVN5DOpen:true});
            this.setState({zhType:'VN_5D'});
          }
          requests.push( 
          fetch(window.$gameUrl + '/rest/game/lottery/loadaward/' + gameId+ '?top=1', {
            method: 'GET',
            headers: new Headers({
              'Accept': 'application/json, text/plain, */*',
              'Content-Type': 'application/x-www-form-urlencoded',
            }),
            // credentials: 'include',
          }).then((response) => response.json()) 
            .then((response) => awardList.push({
              'result': (response.result.award !== 'undefined' ? response.result.award[0] : ''),
              'gameName': response.result.award[0].gameName,
              'gameId': gameId,
              'playId': response.result.award[0].playId,
			        // 'gamePictureMod': gameId[i].gamePictureMod
            }))
            .then((response) => {
              return Promise.resolve(true);

            })
            .catch((error) => {
              console.error(error);
              return Promise.resolve(false);
            })
          )
        }
      // }      
    }
    // if(this.props.home.gameList.length > 0) {
    //   let gameId = this.props.home.gameList[0].gameList;

    // }
    await Promise.all(requests).then((results)=>{
      // here we have all the results
      this.setState({
        loading: false
      });
    try2 = awardList;
    // this.getLocalizeGame(this.state.zhType);
    //const formattedIssueDate = Moment(this.state.issueDate).format("YYYYMMDD");
    this.getLocalizeResult(this.state.selectedValue, this.state.zhType, this.state.issueDate, i18next.language, 10);
  })
    
  }

  onPress(item, top) {
    fetch(window.$gameUrl + '/rest/game/lottery/loadaward/' + item.gameId + '?top=1', {
      method: 'POST',
      headers: new Headers({
        'Accept': 'application/json, text/plain, */*',
        'Content-Type': 'application/x-www-form-urlencoded',
      }),
      // credentials: 'include',
    }).then((response) => response.json())
      .then((response) => {
        this.setState({
          awardetails: response.result,
          awardetail: false,
          awardModal: true,
          gameName: item.gameName,
          playId: item.playId,
          gameId: item.gameId,
          top: this.state.top,
        }, function() {
        });
      })
      .catch((error) => {
        console.error(error);
      });
  }

  AwardDetailToggle = () => {
    this.setState({
      awardModal: !this.state.awardModal,
      awardetail: !this.state.awardetail,
    });
  };

  getGameImage(imagewithtype) {
    try {
      return game_images('./' + imagewithtype);
    } catch (err) {
      console.error(err);
      return window.$wgtDomain + '/imagenotfound.png';
    }
  }

  getLocalizeGame(zongheType) {
    if(this.props.home.gameList.length > 0) {
      let gameVOList = this.props.home.gameList[0].gameList;
      let games = gameVOList.filter(g => g.isCatch && g.isZongHe && g.zongHeType.toUpperCase() === zongheType.toUpperCase()).map(game => {
          return { value: game.id, name: game.gameName };
      });
      this.setState({
        values: games
      });
    }
  };

  getLocalizeResult(gameId, zongheType, issue, lang, top) {
    this.setState({
      loading: true
    });
    let initialAwads = [];
    fetch(window.$gameUrl + '/page/chart/localizeresult?gameId=' + gameId + '&zongheType=' + zongheType + '&issue=' + issue + '&lang=' + lang + '&last=' + top, {
      method: 'POST',
      headers: new Headers({
        'Accept': 'application/json, text/plain, */*',
        'Content-Type': 'application/x-www-form-urlencoded',
      }),
      // credentials: 'include',
    }).then(response => {
      return response.json();
    }).then(data => {
      initialAwads = data.result.list.map(award => {
        return {
          name: award.gameName,
          issue: award.gameIssue,
          grand: award.resultAward[0].value,
          first: award.resultAward[1].value,
          second: award.resultAward[2].value,
          third: award.resultAward[3].value,
          four: award.resultAward[4].value,
          five: award.resultAward[5].value,
          six: award.resultAward[6].value,
          seven: award.resultAward[7].value,
          eight: award.resultAward[8] === undefined ? '' : award.resultAward[8].value,
          zongheType: zongheType
        };
      });
      this.setState({
        localizeAwards: initialAwads,
        loading: false
      });
    }).catch(error => {
      console.log(error);
      this.setState({
        loading: false
      });
    });
   // this.props.closeLoadingAction();
  }

  changeTab() {
    let selectedTab = this.state.activeIndex;
    let selectedZhType = '';
    let selectedTabLine = 'left';
    const selectedValues = '';
    const { t } = this.props;
    if (selectedTab === 1) {
      selectedZhType = 'VN';
      selectedTabLine = 'right';
      if (this.state.isVN6DOpen && this.state.isVN5DOpen) {
        selectedZhType = 'VN_5D';
      }
    } else if(selectedTab === 2) {
      selectedZhType = 'VN_5D';
      selectedTabLine = 'center';
      if (this.state.isVN6DOpen && this.state.isVN5DOpen) {
        selectedZhType = 'VN';
      }
    } else {
      selectedZhType = '';
      selectedTabLine = 'left';
    }
    this.setState({ awardList: [] });
    this.getLocalizeGame(selectedZhType);
    this.getLocalizeResult('', selectedZhType, '', i18next.language, 10);
    this.setState({ tabLine: selectedTabLine, issueDate: '' });
    this.setState({ zhType: selectedZhType });
    var s_value = document.getElementsByClassName("select__single-value");
    if (s_value.length > 0) {
      //console.log('selectedTab:'+selectedTab+':'+s_value[0].textContent);
      s_value[0].textContent = t('全部');
    }
  }

  handleChangeIndex = input => {
    this.state.activeIndex = input;
    this.setState({ activeIndex: input }, this.changeTab());
  };

  handleActiveIndexUpdate = (input) => {
    this.state.activeIndex = input;
    this.setState({ activeIndex: input }, this.changeTab());
  };

  handleSearch() {
    this.getLocalizeResult(this.state.selectedValue, this.state.zhType, this.state.issueDate, i18next.language,10);
  };

  handleIssueDateChange(d) {
    this.setState({ issueDate: d.target.value });
  };

  handleSelectChange(e) {
    this.setState({ selectedValue: e.value });
  };

  render() {
    const { t } = this.props;
    const { activeIndex } = this.state;
    const winHeight = window.innerHeight;
    const modalHeight = winHeight - 44;
    const modalHeight6D = winHeight - 136;

    const PullDownContent =
      <div style={{
        background: 'rgb(213, 233, 247)',
        color: 'rgb(42, 144, 215)',
        textAlign: 'center',
        fontSize: 14,
        lineHeight: '32px',
      }}>{t('pull_down_to_refresh')}</div>
    ;
    const ReleaseContent =
      <div style={{
        background: 'rgb(213, 233, 247)',
        color: 'rgb(42, 144, 215)',
        textAlign: 'center',
        fontSize: 14,
        lineHeight: '32px',
      }}>{t('pull_down_to_refresh')}</div>
    ;
    const RefreshContent = ''
    ;

    const getResult = (item) => {
      let aw = item.result.result;
      let res;

      if (item.playId === 'QQC') {
        aw = aw.substring(aw.length - 3, aw.length);
        res = aw.split('');
      } else {
        res = aw.split(',');
      }
      return res;
    };

    let optionTemplate = [{ value: '', label: t('全部') }];
    this.state.values.map(v => (
      optionTemplate.push({
        value:v.value, 
        label:t(v.name)})
      )
    );

    return (
      <>
        {/* <div className="awardcenterpage" style={{backgroundImage: 'url(' + titleBg + ')', width:'250px', height:'100px', backgroundSize:'cover'}}> */}
        <div style={{background: 'transparent'}}>
          {this.state.loading === true ?
            <ReactLoading type={'spin'} className={'loadingSpinner'} style={{ width: '49px', height: '49px' }}/>
            : false}
          <Row>
          <Col lg={12} md={12} sm={12} xs={12}>
          {this.state.isVN6DOpen && !this.state.isVN5DOpen?
          <div style={{width:'100%',flexDirection:'row',display:'flex',backgroundColor:'white'}}>
              <div className={this.state.tabLine === "left" ? "award-result-tab active" : "award-result-tab"}>
                <Button color="link" outline onClick={()=>this.handleChangeIndex(0)} value={"0"} style={{color:this.state.tabLine === "left" ?'var(--theme-color)':'#000'}}>{t('全部')}</Button>
              </div>
              <div className={this.state.tabLine === "right" ? "award-result-tab active" : "award-result-tab"}>
                <Button color="link" outline onClick={()=>this.handleChangeIndex(1)} value={"1"} style={{color:this.state.tabLine === "right" ?'var(--theme-color)':'#000'}}>{t('越南')}6D</Button>
              </div>
          </div>
          :null}
          {!this.state.isVN6DOpen && this.state.isVN5DOpen?
          <div style={{width:'100%',flexDirection:'row',display:'flex',backgroundColor:'white'}}>
              <div className={this.state.tabLine === "left" ? "award-result-tab active" : "award-result-tab"}>
                <Button color="link" outline onClick={()=>this.handleChangeIndex(0)} value={"0"} style={{color:this.state.tabLine === "left" ?'var(--theme-color)':'#000'}}>{t('全部')}</Button>
              </div>
              <div className={this.state.tabLine === "center" ? "award-result-tab active" : "award-result-tab"}>
                <Button color="link" outline onClick={()=>this.handleChangeIndex(2)} value={"2"} style={{color:this.state.tabLine === "center" ?'var(--theme-color)':'#000'}}>{t('越南')}5D</Button>
              </div>
          </div>
          :null}
          {this.state.isVN6DOpen && this.state.isVN5DOpen?
          <div style={{width:'100%',flexDirection:'row',display:'flex',backgroundColor:'white'}}>
              <div className={this.state.tabLine === "left" ? "award-result-tab active" : "award-result-tab"}>
                <Button color="link" outline onClick={()=>this.handleChangeIndex(0)} value={"0"} style={{color:this.state.tabLine === "left" ?'var(--theme-color)':'#000'}}>{t('全部')}</Button>
              </div>
              <div className={this.state.tabLine === "right" ? "award-result-tab active" : "award-result-tab"}>
                <Button color="link" outline onClick={()=>this.handleChangeIndex(1)} value={"1"} style={{color:this.state.tabLine === "right" ?'var(--theme-color)':'#000'}}>{t('越南')}5D</Button>
              </div>
              <div className={this.state.tabLine === "center" ? "award-result-tab active" : "award-result-tab"}>
                <Button color="link" outline onClick={()=>this.handleChangeIndex(2)} value={"2"} style={{color:this.state.tabLine === "center" ?'var(--theme-color)':'#000'}}>{t('越南')}6D</Button>
              </div>
          </div>
          :null}                    
          {this.state.tabLine === "left"?
            <div id="allAward" style={{ overflow: 'auto' }}>
              <PullToRefresh
                pullDownContent={PullDownContent}
                releaseContent={ReleaseContent}
                refreshContent={RefreshContent}
                pullDownThreshold={0}
                onRefresh={this.refresh}
                triggerHeight={this.state.loading ? 0 : 'auto'}
                startInvisible={true}
              >
              <Row style={{ overflow: 'auto', height: isLiveResultDisplay?'':`calc(${modalHeight}px - var(--safe-bottom))`, paddingBottom:'80px' }}>
                {try2.map((item, index) => {
                  if (!item.result) {
                    return null;
                  } else {
                    return (
                      <Col lg="4" md="12" sm="12" xs="12" key={index}>
                        <Button disabled={this.state.loading} outline color="link" onClick={() => this.onPress(item, this.state.top)}
                                style={{ padding: 0, border: 0, width: '100%' }}>
                          <React.Fragment>
                            <AwardPage
                              title={t(item.gameName)}
                              description={getResult(item)}
                              issue={i18next.language !== 'zh' ? t('drawno') + item.result.issue : '第' + item.result.issue + '期'}
                              playid={item.playId}
                              gameid={item.gameId}
                              gamename={t(item.gameName)}
                              total={getResult(item).reduce((a, b) => parseInt(a) + parseInt(b))}
                              getGameImage={this.getGameImage.bind(this)}
							                gamePictureMod={item.gamePictureMod}
                              gamecategory={this.props.memberInfo.MemberInfo.gamecategory}
                              gamemode={null}
                              lng={this.state.lang}
                            />
                          </React.Fragment>
                        </Button>
                      </Col>
                    );
                  }
                })}
              </Row>
              </PullToRefresh>
            </div>
            :
            <div id="zhongHe" style={{ overflow: 'auto', height:'100%'}}>
              <div style={{
                height: '48px',
                background: 'white',
                width: '100%',
                padding: '0 15px',
                marginBottom: '1px',
                textAlign: 'center'
              }}>
                <div style={{
                  borderRadius: '4px',
                  display: 'inline-block',
                  marginTop: '12px',
                  border: '1px solid #D7D7D7',
                  marginRight: 3,
                }}>
                  <input type="text" className="input-field" style={{width:'100px'}} placeholder={t('drawno')} value={this.state.issueDate} onChange={this.handleIssueDateChange.bind(this)}/>
                </div>
                <div style={{
                  borderRadius: '4px',
                  display: 'inline-block',
                  marginRight: 3,
                  width: 'calc(90% / 3)'
                }}>
                 <Select className="basic-single" classNamePrefix="select"
                  defaultValue={optionTemplate[0]}
                  selected={this.state.selectedValue}
                  isDisabled={this.state.isDisabled}
                  isLoading={this.state.isLoading}
                  isClearable={this.state.isClearable}
                  isRtl={this.state.isRtl}
                  isSearchable={this.state.isSearchable}
                  name="color"
                  options={optionTemplate}
                  styles={customStyles}
                  onChange={this.handleSelectChange}
                />
                </div>
                <div style={{ display: 'inline-flex', padding: '12px 5px 5px 0', paddingLeft: 0 }}>
                  <a style={{
                    padding: '0 15px',
                    color: 'white',
                    background: 'var(--theme-color)',
                    lineHeight: '26px',
                    borderRadius: '4px',
                  }}>
                    <div style={{ fontSize: '12px'}}>
                      <button style={{color:'#fff'}} onClick={this.handleSearch}>
                        <MdSearch size={20}/>{t('lowerreportdetaillistpage.search')}
                      </button>
                    </div>
                  </a>
                </div>
              </div>
              {this.state.localizeAwards.length > 0 ?
              <Row style={{ overflow: 'auto', height: `calc(${modalHeight6D}px - var(--safe-bottom))`, paddingBottom:'40px' }}>
                  {this.state.localizeAwards.map((item, index) => {
                    return (
                      <>
                        <Col lg="4" md="12" sm="12" xs="12">
                          <Button disabled={this.state.loading} outline color="link"
                                  style={{ padding: 0, border: 0, width: '100%' }}>
                            <React.Fragment>
                              <AwardZhongHePage
                                title={i18next.language !== 'zh' ? t(item.name) : item.name}
                                issue={i18next.language !== 'zh' ? t('drawno') + item.issue : '第' + item.issue + '期'}
                                grand={item.grand}
                                first={item.first}
                                second={item.second}
                                third={item.third}
                                four={item.four}
                                five={item.five}
                                six={item.six}
                                seven={item.seven}
                                eight={item.eight}
                                zongheType={item.zongheType}
                              />
                            </React.Fragment>
                          </Button>
                        </Col>
                      </>
                    );
                  })}
              </Row>
              : this.state.loading === false ? (
                <div style={{textAlign:'center',paddingTop: 50,paddingBottom: 50,lineHeight:1}}>
                  <MdPhotoFilter size={110} style={{color:'#ccc'}}/>
                  <div style={{lineHeight:1,color:'#ccc',fontSize:20}}>{t('no_record_found')}</div>
                </div>
              ):''}
            </div>
          }
          </Col>
          </Row>
        </div>                          
      </>
    );
  }

}

const LiveAwardCenterPage = connect(mapStateToProps)(LiveAwardPage);
export default withLastLocation(withTranslation('common')(LiveAwardCenterPage));
