import {store} from '../index';
import { SaveMemberInfo, UpdateAlert, ShowLoginModal, ShowRegisterModal, ShowForgotPasswordModal, ShowSafePasswordModal, ShowIdentityConfirmation ,
  ShowSecurityResetModal, saveIndexInit, SetAccountConfigs, SetUserInfo, updatePromotion } from '../actions/HomePageAction';
import { testPlayAlert, safePasswordAlert } from '../services/AlertService';
import i18next from 'i18next';
import Fetcher from '../components/Fetcher';
import GameFetcher from '../components/GameFetcher';
import moment from 'moment';

const tran = (input) => {
  return i18next.t(input);
}

const getMemberInfo = function() {
  let memberObj = store.getState().memberInfo.MemberInfo;
  GameFetcher(window.$gameUrl + "/rest/member/myinfo", {
    method: "Get",
    headers: {
      'Accept':'application/json, text/plain, */*',
      'Content-Type': 'application/x-www-form-urlencoded',
    },
    credentials: 'include'
  })
    .then((info) => info.json())
    .then((info) =>{
      if (info.status) {
        if (info.result.useStatus !== 0) {
          let alertObj = store.getState().alert;
          alertObj.alert.content = tran('app.account.is.terminated');
          alertObj.alert.title = tran('提示');
          alertObj.alert.showAlert = false;
          store.dispatch(UpdateAlert(alertObj));
        } else {
          memberObj.loginName = info.result.loginName;
          memberObj.language = info.result.lang;
          memberObj.systemLanguage = info.result.avaiLanguage;
          memberObj.currency = info.result.defaultCurr;
          memberObj.currAbbr = info.result.currAbbr;
          memberObj.gamecategory = info.result.gameCategoryType;
          memberObj.pauseWarning = info.result.pauseWarning;
          memberObj.profitWarning = info.result.profitWarning;
          memberObj.restWarning = info.result.restWarning;
          memberObj.sleepWarning = info.result.sleepWarning;
          memberObj.allWarning = info.result.allWarning;
          memberObj.memberWarningSetting = info.result.memberWarningSetting;
          memberObj.loginTime = Date.parse(new Date());
          memberObj.gameTimeZone = info.result.gameTimeZone;
          memberObj.idVer = info.result.idVer;
          // console.log(memberObj.memberWarningSetting);
          i18next.changeLanguage(memberObj.language, (err) => {
            // console.log("myinfo lang:" + memberObj.language);
            if (err) console.log(err);
          })
          if(info.result.icon){
            memberObj.userImgExist = true;
            memberObj.userImg = info.result.icon;
          }
          store.dispatch(SaveMemberInfo(memberObj));
        }
      }
    })
};

const logOut = function() {
  Fetcher(window.$wgtDomain + '/wap/login-out', {
    method: 'GET',
    headers: {
        'Accept': '*/*',
    },
    // credentials: 'include',
    referrer: 'h5'
})
    .then((response) => { return response.text(); })
    .then(
        (response) => {
            if (response === "ok" || response==='NoLogin') {
                let indexInit = store.getState().indexInit.indexInit;
                indexInit.loginUser = null;
                store.dispatch(saveIndexInit(indexInit));
                let memberInfo = store.getState().memberInfo;
                memberInfo.token = null;
                memberInfo.MemberInfo.langid = '';
                store.dispatch(SaveMemberInfo(memberInfo));
            }
        }).finally(
          (response) => {
            window.location.href = window.location.href.split('#')[0].split('?')[0];
          }
        )
    .catch((error) => {
        console.error(error);
    });
}

const getPing = function(){
  let milliseconds = 0;
  let started = new Date().getTime();
  let memberObj = store.getState().memberInfo.MemberInfo;
  var xhr = new XMLHttpRequest()

  // get a callback when the server responds
  xhr.addEventListener('load', () => {
    // update the state of the component with the result here
    if(xhr.responseText === 'ok'){
      let ended = new Date().getTime();
      milliseconds = ended - started;
      if(milliseconds >= 100 && milliseconds < 400){
        memberObj.networkPing = 3;
      }else if(milliseconds >= 400 && milliseconds < 600){
        memberObj.networkPing = 2;
      }else if(milliseconds >= 600 && milliseconds < 1000){
        memberObj.networkPing = 1;
      }else if(milliseconds >= 1000){
        memberObj.networkPing = 0;
      }else{
        memberObj.networkPing = 4;
      }
      memberObj.ms = milliseconds+"ms";
      store.dispatch(SaveMemberInfo(memberObj));
    }
  })
  // open the request with the verb and the url
  xhr.open('GET', window.$gameUrl + "/health")
  // send the request
  xhr.send()
};

const getMemberBalance = function(){
  let memberObj = store.getState().memberInfo.MemberInfo;
  GameFetcher(window.$gameUrl + "/rest/member/getmoney", {
    method: "Get",
    headers: {
      'Accept':'application/json, text/plain, */*',
      'Content-Type': 'application/x-www-form-urlencoded',
    },
    credentials: 'include'
  })
    .then((response) => response.json())
    .then((response) => {
      let alertObj = store.getState().alert;
      if(response.code === 401){
        alertObj.content = tran(response.message);
        alertObj.title = tran('提示');
        alertObj.alertType = 'goBackAlert';
        alertObj.showAlert = true;
        store.dispatch(UpdateAlert(alertObj));
      }else{
        // alertObj.content = '';
        // alertObj.title = '';
        // alertObj.alertType = '';
        // alertObj.showAlert = false;
        // store.dispatch(UpdateAlert(alertObj));
      }
      memberObj.balanceMoney = response.result.money;
      memberObj.currency = response.result.defaultcurr;
      memberObj.gamecategory = response.result.gamecategory;
      store.dispatch(SaveMemberInfo(memberObj));
    })
    .catch((error) => {
      console.error(error);
    });
};

const transferPoint = function(){
  Fetcher(window.$wgtDomain + "/wap/third-login/safe/transfer_point", {
    headers: {
      'Accept': 'application/json, text/plain, */*',
      'Content-Type': 'application/x-www-form-urlencoded',
    },
    // credentials: 'include',
    referrer: 'h5'
  }).then((response) => response.json())
  .then((response) => {
    console.log("转点成功");
  })
  .catch((error) => {
    console.error(error);
  });
};

const checkLogin = function(){
  let indexInit = store.getState().indexInit.indexInit;
  let gameUserName = indexInit.loginUser === undefined ?store.getState().memberInfo.MemberInfo.loginName:indexInit.loginUser?.apiLoginName;
  GameFetcher(window.$gameUrl + "/rest/user/checklogin?loginName="+gameUserName, {
    method: "Get",
    headers: {
      'Accept':'application/json, text/plain, */*',
      'Content-Type': 'application/x-www-form-urlencoded',
    },
    credentials: 'include'
  })
    .then((response) => response.json())
    .then((response) => {
      if(response.status){
        let memberInfo = store.getState().memberInfo;
        memberInfo.gametoken = response.result.gametoken;
        store.dispatch(SaveMemberInfo(memberInfo));
      }
      let alertObj = store.getState().alert;
      if(response.code === 401){
        alertObj.alert.content = tran(response.message);
        alertObj.alert.title = tran('提示');
        alertObj.alert.showAlert = true;
        store.dispatch(UpdateAlert(alertObj));
      }
    })
}

const showLoginModal = function(){
  let modalObj = store.getState().modal;
  fetch(window.$wgtDomain + "/wap/loginData", {
    method: 'get',
    headers: new Headers({
      'Accept': 'text/html,application/xhtml+xml,application/xml;q=0.9,image/webp,image/apng,*/*;q=0.8,application/signed-exchange;v=b3;q=0.9'
    }),
    responseType:'json',
    // credentials: 'include',
    referrer: 'h5'
  }).then((response) => response.json())
    .then((response) => {
      modalObj.loginModal.result = response;
      modalObj.loginModal.show = true;
      store.dispatch(ShowLoginModal(modalObj));
    })
    .catch((error) => {
      console.error(error);
    });
}

const closeLoginModal = function(){
  let modalObj = store.getState().modal;
  modalObj.loginModal.show = false;
  store.dispatch(ShowLoginModal(modalObj));
}

const showForgotPasswordModal = function(){
  let modalObj = store.getState().modal;
  modalObj.forgotPasswordModal.show = true;
  store.dispatch(ShowForgotPasswordModal(modalObj));
}

const showSafePasswordModal = function(){
  let modalObj = store.getState().modal;
  // modalObj.safePasswordModal.show = true;
  modalObj.safePasswordModal.show = false;
  store.dispatch(ShowSafePasswordModal(modalObj));
}

const closeSafePasswordModal = function(){
  let modalObj = store.getState().modal;
  modalObj.safePasswordModal.show = false;
  store.dispatch(ShowSafePasswordModal(modalObj));
}

const closeForgotPasswordModal = function(){
  let modalObj = store.getState().modal;
  modalObj.forgotPasswordModal.show = false;
  store.dispatch(ShowForgotPasswordModal(modalObj));
}

const showSecurityResetModal = function(){
  let modalObj = store.getState().modal;
  modalObj.securityResetModal.show = true;
  store.dispatch(ShowSecurityResetModal(modalObj));
}

const closeSecurityResetModal = function(){
  let modalObj = store.getState().modal;
  modalObj.securityResetModal.show = false;
  store.dispatch(ShowSecurityResetModal(modalObj));
}

const showRegisterModal = function(){
  let modalObj = store.getState().modal;
  fetch(window.$wgtDomain + "/wap/register-valid", {
    method: 'Post',
    headers: new Headers({
      'Accept': 'text/html,application/xhtml+xml,application/xml;q=0.9,image/webp,image/apng,*/*;q=0.8,application/signed-exchange;v=b3;q=0.9'
      // 'Accept': 'application/json, text/plain, */*',

    }),
    responseType:'json',
    referrer: 'h5'
  }).then((response) => response.json())
    .then((response) => {
      modalObj.registerModal.result = response;
      modalObj.registerModal.show = true;
      store.dispatch(ShowRegisterModal(modalObj));
    })
    .catch((error) => {
      console.error(error);
    });
};

const closeRegisterModal = function(){
  let modalObj = store.getState().modal;
  modalObj.registerModal.show = false;
  store.dispatch(ShowRegisterModal(modalObj));
}

const saveNewAccConfigsService = function(loginName,siteagent){
  let accountConfigsDefault = store.getState().memberInfo.AccountConfigsDefault;
  const defaultAuthSetting = {isShakeBet: false, isBackMusic: false, isColorTheme:false, colorThemeValue:accountConfigsDefault.defaultColor, isBetMultiple:false, betMultipleValue:[1,5,10,15,20,50,100], betCountdownValue: 5,isCountDownSound: false, hideJHCPopUp: false};
  let userData = "-"+loginName+"-"+siteagent;
  let param = {userInfo:userData};
  store.dispatch(SetUserInfo(param));
  let oriAccountConfigs = store.getState().memberInfo.AccountConfigs;
  //console.log(oriAccountConfigs["accountConfigs"+userData]);
  if(!oriAccountConfigs["accountConfigs"+userData]){
    let param = {...oriAccountConfigs,["accountConfigs"+userData]:defaultAuthSetting};
    store.dispatch(SetAccountConfigs(param));
  }
}

const showTestPlayAlert = function(){
  testPlayAlert();
}

const showSafePasswordAlert = function(){
  // safePasswordAlert();
}

const showIdentityConfirmation = function(){
  let modalObj = store.getState().modal;
  modalObj.safePasswordModal.show = true;
  store.dispatch(ShowIdentityConfirmation(modalObj));
}

const getLang = async function () {
  let memberInfo = store.getState().memberInfo;
  let langid = memberInfo.MemberInfo.langid?memberInfo.MemberInfo.langid:'';

  let res = await fetch(window.$wgtDomain + "/getlang?langid="+langid, {
    method: 'get',
    headers: new Headers({
      'Accept': 'text/html,application/xhtml+xml,application/xml;q=0.9,image/webp,image/apng,*/*;q=0.8,application/signed-exchange;v=b3;q=0.9'
    }),
    responseType:'json',
    // credentials: 'include',
    referrer: 'h5'
  })
  let result = await res.text();
  if (result && !['en', 'zh', 'vi', 'th', 'in', 'id', 'pt', 'es'].includes(result)) {
    i18next.changeLanguage('zh', (err) => {
      if (err) console.log(err);
    })
    return 'zh';
  } else {
    if(memberInfo?.UserInfo?.userInfo && memberInfo.UserInfo.userInfo.includes("NoLogin") && memberInfo?.MemberInfo?.language){
      // let lang = memberInfo.MemberInfo.language;
      //console.log("getLang No Login:" + lang);
      i18next.changeLanguage(result, (err) => {
        if (err) console.log(err);
      })
    }else{
      //console.log("getLang result:" + result);
     i18next.changeLanguage(result, (err) => {
       if (err) console.log(err);
     })
    }
    return result;
  }
}

const checkThridLogin = async (fromLogin) => {
  const postConfig = {
    method: 'POST',
    headers: {
        'Accept': 'application/json, text/plain, */*',
        'Content-Type': 'application/x-www-form-urlencoded'
    },
    // credentials: 'include',
    referrer: 'h5'
  }
  let checkLoginResult;
  if (!fromLogin) {
    let indexInit = store.getState().indexInit.indexInit;
    let gameUserName = indexInit.loginUser.apiLoginName === undefined ?"":indexInit.loginUser.apiLoginName;
    checkLoginResult = await GameFetcher(window.$gameUrl + "/rest/user/checklogin?loginName="+gameUserName, postConfig);
    checkLoginResult = await checkLoginResult.json();
  }
  if (fromLogin || (checkLoginResult && !checkLoginResult.status)) {
    //TEMPORARY SET TO FALSE TO TRANSFER POINT TO SAFETWO
    //NEED TO CHANGE BACK TO TRUE AFTER SETUP FOR ONE SERVER DONE
    let param = "?onlyLogin=true";
    // let param = "?onlyLogin=false";

    let directJsonResult = await Fetcher(window.$wgtDomain + "/wap/third-login/safe_/direct-json" + param, postConfig);
    // let directJsonResult = await fetch(window.$wgtDomain + "/wap/third-login/safetwo_/direct-json" + param, postConfig);
    directJsonResult = await directJsonResult.json();
    if (directJsonResult && directJsonResult.tourl) {
      let gameLoginUrl = window.$gameUrl + directJsonResult.tourl.substring(directJsonResult.tourl.indexOf('/login')).replace('/login', '/rest/user/login');
      gameLoginUrl = gameLoginUrl + "&lang=" + await getLang();
      let gameLoginResult = await fetch(gameLoginUrl, postConfig);
      // if (gameLoginResult.redirected) {
      //   let newUrl = gameLoginResult.url.split("/");
      //   newUrl = newUrl[0] + '//' + newUrl[2];
      //   window.$gameUrl = newUrl;
      // } else {
      //   let newUrl = gameLoginUrl.split("/");
      //   newUrl = newUrl[0] + '//' + newUrl[2];
      //   window.$gameUrl = newUrl;
      // }
      gameLoginResult = await gameLoginResult.json();
      if (gameLoginResult.status) {
        let indexInit = store.getState().indexInit.indexInit;
        indexInit.api_login = true;
        store.dispatch(saveIndexInit(indexInit));
        let memberInfo = store.getState().memberInfo;
        memberInfo.gametoken = gameLoginResult.result.gametoken;
        store.dispatch(SaveMemberInfo(memberInfo));
        return true;
      }
      if (!gameLoginResult.status) {
        throw new Error(tran('app.account.unable.login'));
      }
    }
  } else if (checkLoginResult.status) {
    let indexInit = store.getState().indexInit.indexInit;
    indexInit.api_login = true;
    store.dispatch(saveIndexInit(indexInit));
    let memberInfo = store.getState().memberInfo;
    memberInfo.gametoken = checkLoginResult.result.gametoken;
    store.dispatch(SaveMemberInfo(memberInfo));
    return true;
  }
}
const convertMoney = function(money){
  if ( money.toString().length > 6 ) {
    var abs = Math.abs( money );
    if ( abs >= Math.pow(10, 15) ) {
      // quadrillion
      money = parseFloat(( money / Math.pow( 10, 15 ) ).toFixed( 2 )) + "Q";
    } else if ( abs < Math.pow( 10, 15 ) && abs >= Math.pow( 10, 12 ) ) {
      // trillion
      money = parseFloat(( money / Math.pow( 10, 12 ) ).toFixed( 2 )) + "T";
    } else if ( abs < Math.pow( 10, 12 ) && abs >= Math.pow( 10, 9 ) ) {
      // billion
      money = parseFloat(( money / Math.pow( 10, 9 ) ).toFixed( 2 )) + "B";
    } else if ( abs < Math.pow( 10, 9 ) && abs >= Math.pow( 10, 6 ) ) {
      // million
      money = parseFloat(( money / Math.pow( 10, 6 ) ).toFixed( 2 )) + "M";
    } else if ( abs < Math.pow( 10, 6 ) && abs >= Math.pow( 10, 3 ) ) {
      // thousand
      money = parseFloat(( money / Math.pow( 10, 3 ) ).toFixed( 2 )) + "K";
    }
  }
  return money;
}

const getSiteCurrency = async function(){
  let memberObj = store.getState().memberInfo.MemberInfo;
  fetch(window.$gameUrl + "/rest/member/get-site-currency", {
    method: "Get",
    headers: new Headers({
      'Accept':'application/json, text/plain, */*',
      'Content-Type': 'application/x-www-form-urlencoded',
    }),
    credentials: 'include'
  })
    .then((response) => response.json())
    .then((response) => {
      if (response.result.currency) {
        var resList = response.result.currency.split("-");
        var currencySymbol = resList[0];
        var defaultShortForm = resList[1];
        if (defaultShortForm) {
          memberObj.currAbbr = parseInt(defaultShortForm);
        } else {
          memberObj.currAbbr = 0;
        }
        memberObj.currency = currencySymbol;
        store.dispatch(SaveMemberInfo(memberObj));
      }

      // 显示货币符号
      // var currencyElement = document.getElementsByClassName("currencySymbol");
      // for (var i = 0; i < currencyElement.length; i++){
      //     currencyElement[i].textContent = currencySymbol;
      // }

      //显示货币缩写
      // if (defaultShortForm === 1){
      //   var moneyValueElement = document.getElementsByClassName("moneyValue");

      //   for (var i = 0; i < moneyValueElement.length; i++){
      //       var currValue = moneyValueElement[i].textContent;
      //       if (currValue >= 1000){
      //           moneyValueElement[i].textContent = intToString(Math.trunc(currValue),defaultShortForm);
      //       }
      //   }
      // }
    })
    .catch((error) => {
      console.error(error);
    });
};

const getGaiaSiteCurrency = function(){
  let memberObj = store.getState().memberInfo.MemberInfo;
  fetch(window.$wgtDomain + '/get-site-currency', {
      method: 'POST',
      headers: new Headers({
          'Accept': 'application/json, text/plain, */*',
          'Content-Type': 'application/x-www-form-urlencoded',
      }),
      // credentials: 'include',
      referrer: 'h5'
  })
  .then((response) => response.text())
  .then((response) => {
    if (response) {
      var resList = response.split("-");
      var currencySymbol = resList[0];
      var defaultShortForm = resList[1];
      if (defaultShortForm) {
        memberObj.currAbbr = parseInt(defaultShortForm);
      } else {
        memberObj.currAbbr = 0;
      }
      memberObj.currency = currencySymbol;
      store.dispatch(SaveMemberInfo(memberObj));
    }

      // 显示货币符号
      // var currencyGaiaElement = document.getElementsByClassName("currencySymbol");
      // for (var i = 0; i < currencyGaiaElement.length; i++){
      //   currencyGaiaElement[i].textContent = currencySymbol;
      // }

      //显示货币缩写
      // if (defaultShortForm === 1){
      //   var moneyValueGaiaElement = document.getElementsByClassName("moneyValue");
      //   for (var i = 0; i < moneyValueGaiaElement.length; i++){
      //     var currValue = moneyValueGaiaElement[i].textContent;
      //     if(!currValue.includes('K') && !currValue.includes('M') && !currValue.includes('B') && !currValue.includes('T') && !currValue.includes('Q')){
      //       moneyValueGaiaElement[i].textContent = intToString(Math.trunc(currValue),defaultShortForm);
      //     }
      //   }
      // }

  })
  .catch((error) => {
     console.error(error);
  });
};

const intToString = function(number,shortform,currency) {
  number = parseFloat((Math.round(number * 100) / 100).toFixed( 2 ));
  if (shortform === 2 || (number.toString().length > 3 && (shortform === 1))) {
    var abs = Math.abs( number );
    if ( abs >= Math.pow( 10, 15) ) {
      // quadrillion
      number = parseFloat(( number / Math.pow( 10, 15 ) ).toFixed( 2 )) + "Q";
    } else if ( abs < Math.pow( 10, 15 ) && abs >= Math.pow( 10, 12 ) ) {
      // trillion
      number = parseFloat(( number / Math.pow( 10, 12 ) ).toFixed( 2 )) + "T";
    } else if ( abs < Math.pow( 10, 12 ) && abs >= Math.pow( 10, 9 ) ) {
      // billion
      number = parseFloat(( number / Math.pow( 10, 9 ) ).toFixed( 2 )) + "B";
    } else if ( abs < Math.pow( 10, 9 ) && abs >= Math.pow( 10, 6 ) ) {
      // million
      number = parseFloat(( number / Math.pow( 10, 6 ) ).toFixed( 2 )) + "M";
    } else if ( abs < Math.pow( 10, 6 ) && abs >= Math.pow( 10, 3 ) ) {
      // thousand
      number = parseFloat(( number / Math.pow( 10, 3 ) ).toFixed( 2 )) + "K";
    }
    return number;
  } else if(currency == "R$"){ // handle real currrency format
    number = (Math.round(number * 100) / 100).toFixed( 2 );
    var parts = number.toString().split(".");
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    return parts.join(",");
  } else {
    number = (Math.round(number * 100) / 100).toFixed( 2 );
    let memberInfo = store.getState().memberInfo;
    if(memberInfo.MemberInfo.currency == "R$"){
      number = (Math.round(number * 100) / 100).toFixed( 2 );
      var parts = number.toString().split(".");
      parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ".");
      return parts.join(",");
    }
    var parts = number.toString().split(".");
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    return parts.join(".");
  }
};

const intToStringNoDecimal = function(number,shortform) {
  number = parseFloat((Math.round(number * 100) / 100));
  if ( number.toString().length > 3 && shortform === 1) {
    var abs = Math.abs( number );
    if ( abs >= Math.pow( 10, 15) ) {
      // quadrillion
      number = parseFloat(( number / Math.pow( 10, 15 ) )) + "Q";
    } else if ( abs < Math.pow( 10, 15 ) && abs >= Math.pow( 10, 12 ) ) {
      // trillion
      number = parseFloat(( number / Math.pow( 10, 12 ) )) + "T";
    } else if ( abs < Math.pow( 10, 12 ) && abs >= Math.pow( 10, 9 ) ) {
      // billion
      number = parseFloat(( number / Math.pow( 10, 9 ) )) + "B";
    } else if ( abs < Math.pow( 10, 9 ) && abs >= Math.pow( 10, 6 ) ) {
      // million
      number = parseFloat(( number / Math.pow( 10, 6 ) )) + "M";
    } else if ( abs < Math.pow( 10, 6 ) && abs >= Math.pow( 10, 3 ) ) {
      // thousand
      number = parseFloat(( number / Math.pow( 10, 3 ) )) + "K";
    }
    return number;
  } else {
    number = (Math.round(number * 100) / 100);
    var parts = number.toString().split(".");
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    return parts;
  }
};
//查询是否有设置停用账号提醒
const getMemberPauseWarning = function(){
  let memberObj = store.getState().memberInfo.MemberInfo;
  let pausebetween = [];
  let pause = '';
  if(memberObj.memberWarningSetting !== null && memberObj.memberWarningSetting !== '' && memberObj.memberWarningSetting !== undefined){
  JSON.parse(memberObj.memberWarningSetting, (key, value) => {
    if (key === 'pause') {
      pause = value;
    }
  });
  if(memberObj.pauseWarning === 1 && (pause !== null && pause !== '')){
    pausebetween = pause.split('&');
    if(pausebetween.length>1){
    let DateStart =new Date(pausebetween[0]);
    let DateEnd =new Date(pausebetween[1]);

    // verify time zone
    let date = moment(convertByTimeZone(new Date(), "YYYY-MM-DD HH:mm"));
    // console.log(date > DateStart && date < DateEnd);
     if(date > DateStart && date < DateEnd){
        return true;}
    }
    else{
      return false;
    }}}
  return false;
};
//查询是否有设置睡眠提醒
const getMemberSleepWarning = function(){
  let memberObj = store.getState().memberInfo.MemberInfo;
  let sleepbetween = [];
  let sleep = '';
  if(memberObj.memberWarningSetting !== null && memberObj.memberWarningSetting !== '' && memberObj.memberWarningSetting !== undefined){
  JSON.parse(memberObj.memberWarningSetting, (key, value) => {
    if (key === 'sleep') {
      sleep = value;
    }
  });
  if(memberObj.sleepWarning === 1 && (sleep !== null && sleep !== '')){
    sleepbetween = sleep.split('&');
    if(sleepbetween.length>1){
    let DateStart = sleepbetween[0].split(':');
    let DateEnd = sleepbetween[1].split(':');

    // verify time zone
    let today = moment(convertByTimeZone(new Date(), "YYYY-MM-DD HH:mm"));
    let hourStart = parseInt(DateStart[0]) * 60 + parseInt(DateStart[1]);
    let hourEnd = parseInt(DateEnd[0]) * 60 + parseInt(DateEnd[1]);
    let hourNow = today.hour() * 60 + today.minutes();
    if(hourStart>hourEnd){
      if(hourEnd >hourNow && hourNow < hourStart){
        return true;
      }
    }else if(hourNow > hourStart && hourNow < hourEnd){
        return true;
    }
  }else{
    return false;
  }}}
  return false;
};
//查询是否有设置睡眠提醒
const getMemberRestWarning = function(){
  let memberObj = store.getState().memberInfo.MemberInfo;
  let rest = '';
  let restWarning = [];
  if(memberObj.memberWarningSetting !== null && memberObj.memberWarningSetting !== '' && memberObj.memberWarningSetting !== undefined){
  JSON.parse(memberObj.memberWarningSetting, (key, value) => {
    if (key === 'rest') {
      rest = value;
    }
  });
  if(memberObj.restWarning === 1 && (rest !== null && rest !== '')){
    let restmin = rest.split(':');
    let restStart = restmin[0]*3600000;
    let restEnd = restmin[1]*60000;
    let restNow = memberObj.loginTime + restStart +restEnd;
    let NowTimer = new Date();
     if(Date.parse(NowTimer)>restNow){
      memberObj.loginTime = restNow;
      store.dispatch(SaveMemberInfo(memberObj));
      restWarning.push({'status': true, 'restHour': restmin[0],'restMin': restmin[1] });
      return restWarning;
    }else{
      restWarning.push({'status': false, 'restHour': "",'restMin': "" });
      return restWarning;
    }
  }else{
    restWarning.push({'status': false, 'restHour': "",'restMin': "" });
  return restWarning;}
}else{
  restWarning.push({'status': false, 'restHour': "",'restMin': "" });
  return restWarning;}
};

const updatePromoDetails = function(obj){
  store.dispatch(updatePromotion(obj));
}

const convertByTimeZone = function(input, format){
  let tz = 1 === window.$mode ? store.getState().memberInfo.MemberInfo.gameTimeZone : store.getState().indexInit.indexInit.timeZone;
  let d = new Date(input);
  let len = d.getTime();
  let offset = d.getTimezoneOffset() * 60000;
  let utcTime = len + offset;
  let gmt = 8 + parseInt(tz);
  let res = new Date(utcTime + 3600000 * gmt);
  return moment(res).format(format);
}

const fbAPI = async (response) => {
  console.log('fbAPI, Welcome! Fetching your information.... ', response);

  const data = new URLSearchParams();
  if (response.email == undefined) {
    data.set('id', response.id);
    data.set('loginName', response.id);
  }else data.set('email', response.email);
  data.set('h5', "h5");
  data.set('isSocialLogin', true);
  console.log("data:",data);
  if(response.status != "unknown"){
  /*
  ===Validate Login API===
  */
  let socialLoginValid = await fetch(window.$wgtDomain + '/auth-social/login-valid', {
    method: 'POST',
    headers: new Headers({
      'Accept': 'application/json, text/plain, */*',
      'Content-Type': 'application/x-www-form-urlencoded',
    }),
    // credentials: 'include',
    body: data,
    referrer: 'h5'
  })
  let res = await socialLoginValid.json();
  console.log("response from /auth-social/login-valid", res);
  if (res.status != null) {
    if (res.status == 0) {//got validation error
      console.log("got validation error");

    } else if (res.status == 1) {//acc found, proceed to login
      console.log("acc found, proceed to login");
      // location.href = "${ctx}/?gaiatoken="+res.gaiatoken;
      if (res.gaiatoken !== null) {
        let memberInfo = store.getState().memberInfo;
        memberInfo.token = response.gaiatoken;
        store.dispatch(SaveMemberInfo(memberInfo));
      }
      // try {
      //   await checkThridLogin(true);
      // } catch (e) {}
      const windowURL = window.location.href;
      console.log("windowURL2::", windowURL);
      const windowURLHash = window.location.hash;
      console.log("windowURLHash2::", windowURLHash);

      let gaiaTokenStr = "gaiatoken="+res.gaiatoken;
      let gaiaTokenIndex = windowURL.indexOf(gaiaTokenStr);
      console.log("gaiaTokenIndex", gaiaTokenIndex);
      if (gaiaTokenIndex > 0) {
        //   let finall = window.location.href.substring(0,bbbs);
        //   window.location.href = finall+"gaiatoken="+res.gaiatoken;
        window.location.href = window.location.href;
      } else {
        const appendSymbol = window.$isApp ? "" : "?";
        window.location.href = window.location.href.split('#')[0] + appendSymbol + "gaiatoken=" + res.gaiatoken;// no need '?' is because facebook response aldy incl '?'
      }
    } else if (res.status == 2) {//acc not found, need proceed register
      console.log("acc not found, need proceed register");

      /*
      ===Register API===
      */
      (async () => {
        let memberInfo = store.getState().memberInfo;
        let langid = memberInfo.MemberInfo.langid?memberInfo.MemberInfo.langid:'';

        await fetch(window.$wgtDomain + '/memreg/save?langid='+langid, {
          method: 'POST',
          headers: new Headers({
            'Accept': 'application/json, text/plain, */*',
            'Content-Type': 'application/x-www-form-urlencoded',
          }),
          // credentials: 'include',
          body: data,
          referrer: 'h5'
        })
          .then((resReg) => resReg.json())
          .then(async (resReg) => {
            console.log("response from /memreg/save", resReg);
            if (resReg.status != null && resReg.status == 1) {//success
              if (resReg.gaiatoken !== null) {
                let memberInfo = store.getState().memberInfo;
                memberInfo.token = response.gaiatoken;
                store.dispatch(SaveMemberInfo(memberInfo));
              }
              window.location.href = window.location.href.split('#')[0] + "?gaiatoken=" + resReg.gaiatoken;
              //here need to pop up the 完善资料?
            }
          })
          .catch((error) => {
            console.error(error);
          });
      })()
    }
  }
  }
}

const sha256 = function(ascii){
  function rightRotate(value, amount) {
      return (value>>>amount) | (value<<(32 - amount));
  };

  var mathPow = Math.pow;
  var maxWord = mathPow(2, 32);
  var lengthProperty = 'length'
  var i, j; // Used as a counter across the whole file
  var result = ''

  var words = [];
  var asciiBitLength = ascii[lengthProperty]*8;

  //* caching results is optional - remove/add slash from front of this line to toggle
  // Initial hash value: first 32 bits of the fractional parts of the square roots of the first 8 primes
  // (we actually calculate the first 64, but extra values are just ignored)
  var hash = sha256.h = sha256.h || [];
  // Round constants: first 32 bits of the fractional parts of the cube roots of the first 64 primes
  var k = sha256.k = sha256.k || [];
  var primeCounter = k[lengthProperty];
  /*/
  var hash = [], k = [];
  var primeCounter = 0;
  //*/

  var isComposite = {};
  for (var candidate = 2; primeCounter < 64; candidate++) {
      if (!isComposite[candidate]) {
          for (i = 0; i < 313; i += candidate) {
              isComposite[i] = candidate;
          }
          hash[primeCounter] = (mathPow(candidate, .5)*maxWord)|0;
          k[primeCounter++] = (mathPow(candidate, 1/3)*maxWord)|0;
      }
  }

  ascii += '\x80' // Append Ƈ' bit (plus zero padding)
  while (ascii[lengthProperty]%64 - 56) ascii += '\x00' // More zero padding
  for (i = 0; i < ascii[lengthProperty]; i++) {
      j = ascii.charCodeAt(i);
      if (j>>8) return; // ASCII check: only accept characters in range 0-255
      words[i>>2] |= j << ((3 - i)%4)*8;
  }
  words[words[lengthProperty]] = ((asciiBitLength/maxWord)|0);
  words[words[lengthProperty]] = (asciiBitLength)

  // process each chunk
  for (j = 0; j < words[lengthProperty];) {
      var w = words.slice(j, j += 16); // The message is expanded into 64 words as part of the iteration
      var oldHash = hash;
      // This is now the undefinedworking hash", often labelled as variables a...g
      // (we have to truncate as well, otherwise extra entries at the end accumulate
      hash = hash.slice(0, 8);

      for (i = 0; i < 64; i++) {
          var i2 = i + j;
          // Expand the message into 64 words
          // Used below if
          var w15 = w[i - 15], w2 = w[i - 2];

          // Iterate
          var a = hash[0], e = hash[4];
          var temp1 = hash[7]
              + (rightRotate(e, 6) ^ rightRotate(e, 11) ^ rightRotate(e, 25)) // S1
              + ((e&hash[5])^((~e)&hash[6])) // ch
              + k[i]
              // Expand the message schedule if needed
              + (w[i] = (i < 16) ? w[i] : (
                      w[i - 16]
                      + (rightRotate(w15, 7) ^ rightRotate(w15, 18) ^ (w15>>>3)) // s0
                      + w[i - 7]
                      + (rightRotate(w2, 17) ^ rightRotate(w2, 19) ^ (w2>>>10)) // s1
                  )|0
              );
          // This is only used once, so *could* be moved below, but it only saves 4 bytes and makes things unreadble
          var temp2 = (rightRotate(a, 2) ^ rightRotate(a, 13) ^ rightRotate(a, 22)) // S0
              + ((a&hash[1])^(a&hash[2])^(hash[1]&hash[2])); // maj

          hash = [(temp1 + temp2)|0].concat(hash); // We don't bother trimming off the extra ones, they're harmless as long as we're truncating when we do the slice()
          hash[4] = (hash[4] + temp1)|0;
      }

      for (i = 0; i < 8; i++) {
          hash[i] = (hash[i] + oldHash[i])|0;
      }
  }

  for (i = 0; i < 8; i++) {
      for (j = 3; j + 1; j--) {
          var b = (hash[i]>>(j*8))&255;
          result += ((b < 16) ? 0 : '') + b.toString(16);
      }
  }
  return result;
};


const checkWithdrawalBlacklist = function(){
  return Fetcher(window.$wgtDomain + '/withdrawal/blacklist/checkIsWithdrawalBlacklist', {
      method: 'POST',
      headers: new Headers({
          'Accept': 'application/json, text/plain, */*',
          'Content-Type': 'application/x-www-form-urlencoded',
      }),
      // credentials: 'include',
      referrer: 'h5'
  })
  .then((response) => response.json())
  .then((response) => {
    if (response) {
      return response;
    }
  })
  .catch((error) => {
     console.error(error);
  });
}

export { getMemberInfo, logOut, getPing, getMemberBalance,transferPoint, checkLogin,
  showLoginModal, showRegisterModal, closeLoginModal, closeRegisterModal, showForgotPasswordModal,
  closeForgotPasswordModal, showSafePasswordModal, closeSafePasswordModal,
  showSecurityResetModal, closeSecurityResetModal, saveNewAccConfigsService, showTestPlayAlert, showSafePasswordAlert,
  checkThridLogin,convertMoney, getLang, getSiteCurrency, intToString, intToStringNoDecimal, getGaiaSiteCurrency,getMemberPauseWarning,
  getMemberSleepWarning,getMemberRestWarning, updatePromoDetails, convertByTimeZone, showIdentityConfirmation
  , fbAPI, sha256, checkWithdrawalBlacklist
}

