import cloneDeep from 'lodash.clonedeep';
import * as betcommon from './bet.common';
import qs from 'qs';
import { store } from '../index';
import { updateBetRecord, updatePopupObj, saveRecommendGames } from '../actions/GameAction';
import {UpdateAlert} from '../actions/HomePageAction';
// import MSG from '../utils/enums';
import {getMemberBalance} from './HomeService';
import i18next from 'i18next';
import Lunar from '../utils/lunar';
import moment from 'moment';
import GameFetcher from '../components/GameFetcher';

const tran = (input) => {
  return i18next.t(input);
}

const selectGameFromJson = async (selectedGameObj) => {
  let gameJSONObj = {};
  // let live = (window.location.href.split('#')[1] == '/live'?true:false);
  let live = (window.location.href.includes("/live")?true:false);
  let str = "";
  switch (selectedGameObj.playId) {
    case 'BSC':
      gameJSONObj.official = await import('../gameData/BSC.json');
      break;
    case 'DDC':
      gameJSONObj.official = await import('../gameData/DDC'+(live?'-live':'')+'.json');
      break;
    case 'F3D':
      gameJSONObj.official = await import('../gameData/F3D.json');
      break;
    case 'FTW':
      gameJSONObj.official = await import('../gameData/FTW.json');
      break;
    case 'KL8':
      gameJSONObj.official = await import('../gameData/KL8.json');
      break;
    case 'LHC':
      gameJSONObj.official = await import('../gameData/LHC'+(live?'-live':'')+'.json');
      break;
    case 'LOT':
        gameJSONObj.official = await import('../gameData/LOT'+(live?'-live':'')+'.json');
        break;
    case 'UKL':
        gameJSONObj.official = await import('../gameData/UKL'+(live?'-live':'')+'.json');
        break;
    case 'VKL':
        gameJSONObj.official = await import('../gameData/VKL'+(live?'-live':'')+'.json');
        break;
    case 'TDB':
        gameJSONObj.official = await import('../gameData/TDB'+(live?'-live':'')+'.json');
        break;
    case 'SFL':
        gameJSONObj.official = await import('../gameData/SFL'+(live?'-live':'')+'.json');
        break;
    case 'PWB':
        gameJSONObj.official = await import('../gameData/PWB'+(live?'-live':'')+'.json');
        break;
    case 'MGM':
        gameJSONObj.official = await import('../gameData/MGM'+(live?'-live':'')+'.json');
        break;
    case 'VMG':
        gameJSONObj.official = await import('../gameData/VMG'+(live?'-live':'')+'.json');
        break;
    case 'VPW':
        gameJSONObj.official = await import('../gameData/VPW'+(live?'-live':'')+'.json');
        break;
    case 'PL3':
      gameJSONObj.official = await import('../gameData/PL3.json');
      break;
    case 'SSC':
      gameJSONObj.official = await import('../gameData/SSC.json');
      break;
    case 'SSL':
      gameJSONObj.official = await import('../gameData/SSL.json');
      break;
    case 'XX5':
      gameJSONObj.official = await import('../gameData/XX5.json');
      break;
    case 'KW3':
      gameJSONObj.official = await import('../gameData/KW3.json');
      break;
    case 'STM':
      gameJSONObj.official = await import('../gameData/STM.json');
      break;
    case 'M4D':
      gameJSONObj.official = await import('../gameData/M4D'+(live?'-live':'')+'.json');
      break;
    case 'V6D':
      gameJSONObj.official = await import('../gameData/V6D'+(live?'-live':'')+'.json');
      break;
    case 'LCT':
      gameJSONObj.official = await import('../gameData/LCT'+(live?'-live':'')+'.json');
      break;
    case 'V6Z':
      gameJSONObj.official = await import('../gameData/V6Z'+(live?'-live':'')+'.json');
      break;
    case 'V5Z':
      gameJSONObj.official = await import('../gameData/V5Z'+(live?'-live':'')+'.json');
      break;
    case 'YSC':
      gameJSONObj.official = await import('../gameData/YSC'+(live?'-live':'')+'.json');
      break;
    case 'YXX':
      gameJSONObj.official = await import('../gameData/YXX'+(live?'-live':'')+'.json');
      break;
    case 'KLS':
      gameJSONObj.official = await import('../gameData/KLS'+(live?'-live':'')+'.json');
      break;
    case 'OSD':
      gameJSONObj.official = await import('../gameData/OSD'+(live?'-live':'')+'.json');
      break;
    case 'JHC':
      gameJSONObj.official = await import('../gameData/JHC'+(live?'-live':'')+'.json');
      break;
      // case 'O3D':
      //   gameJSONObj.official = await import('../gameData/O3D'+(live?'-live':null)+'.json');
      //   break;
      // case 'OK3':
      //   gameJSONObj.official = await import('../gameData/OK3'+(live?'-live':null)+'.json');
      //   break;
      // case 'OP3':
      //   gameJSONObj.official = await import('../gameData/OP3'+(live?'-live':null)+'.json');
      //   break;
      // case 'OSC':
      //   gameJSONObj.official = await import('../gameData/OSC'+(live?'-live':null)+'.json');
      //   break;
      // case 'OSL':
      //   gameJSONObj.official = await import('../gameData/OSL'+(live?'-live':null)+'.json');
      //   break;
      // case 'OX5':
      //   gameJSONObj.official = await import('../gameData/OX5'+(live?'-live':null)+'.json');
      //   break;
      // case 'PKS':
      //   gameJSONObj.official = await import('../gameData/PKS'+(live?'-live':null)+'.json');
      //   break;
    default:
  }

  switch (selectedGameObj.splayId) {
    case 'O3D':
      gameJSONObj.classic = await import('../gameData/O3D'+(live?'-live':'')+'.json');
      break;
    case 'OK3':
      gameJSONObj.classic = await import('../gameData/OK3'+(live?'-live':'')+'.json');
      break;
    case 'OK8':
      gameJSONObj.classic = await import('../gameData/OK8'+(live?'-live':'')+'.json');
      break;
    case 'OP3':
      gameJSONObj.classic = await import('../gameData/OP3'+(live?'-live':'')+'.json');
      break;
    case 'OSC':
      gameJSONObj.classic = await import('../gameData/OSC'+(live?'-live':'')+'.json');
      break;
    case 'OSL':
      gameJSONObj.classic = await import('../gameData/OSL'+(live?'-live':'')+'.json');
      break;
    case 'OX5':
      gameJSONObj.classic = await import('../gameData/OX5'+(live?'-live':'')+'.json');
      break;
    case 'PKS':
      gameJSONObj.classic = await import('../gameData/PKS'+(live?'-live':'')+'.json');
      break;
    default:
  }
  gameJSONObj.selectId = selectedGameObj.id;
  gameJSONObj.gameName = selectedGameObj.gameName;
  gameJSONObj.playId = selectedGameObj.playId;
  gameJSONObj.splayId = selectedGameObj.splayId;
  gameJSONObj.isOnlyClassic = ((selectedGameObj.splayId ==null || selectedGameObj.splayId =="" )  && (selectedGameObj.playType==0 || selectedGameObj.playType==2))? true : false;
  gameJSONObj.isOnlyOfficial = ((selectedGameObj.splayId ==null || selectedGameObj.splayId =="" || (selectedGameObj.splayType ==2 && selectedGameObj.splayId!="")) && selectedGameObj.playType==1)? true : false;
  gameJSONObj.isAllPlayType = (!gameJSONObj.isOnlyClassic && !gameJSONObj.isOnlyOfficial);
  gameJSONObj.isZongHe = selectedGameObj.isZongHe;
  gameJSONObj.isCatch = selectedGameObj.isCatch;
  gameJSONObj.zongHeType = selectedGameObj.zongHeType;
  if (gameJSONObj.isOnlyClassic) {
    gameJSONObj.splayId = gameJSONObj.playId;
    gameJSONObj.classic = gameJSONObj.official;
    gameJSONObj.official = null;
  }

  if (gameJSONObj.isAllPlayType || gameJSONObj.isOnlyOfficial) {

    let tempObj = Object.assign({}, gameJSONObj.official);
    let postionText = ['万位', '千位', '百位', '十位', '个位'];

    if(gameJSONObj.playId=='M4D'){
        postionText = ['千位', '百位', '十位', '个位'];
    }

    if(gameJSONObj.playId=='V6D'){
      postionText = ['第一位', '第二位', '第三位', '第四位','第五位','第六位'];
    }
    if(gameJSONObj.playId=='M4D'){
      postionText = ['千位','百位','十位','个位'];
    }
    gameJSONObj.official.list.map((item, index) => {
      item.label.map((subItem, subIndex) => {
        subItem.label.map((subSubItem, subSubIndex) => {
          if (!subSubItem.selectarea.hasOwnProperty('layout')) {
            tempObj.list[index].label[subIndex].label = tempObj.list[index].label[subIndex].label.filter((element) => element.methodid != subSubItem.methodid);
          }
        });
        let tempObjList = tempObj.list[index].label.find((item) => item.playId == subItem.playId);
        if (tempObjList && tempObjList.label.length < 1) {
          tempObj.list[index].label = tempObj.list[index].label.filter((element) => element.playId != subItem.playId);
        }
      });
      if (tempObj.list[index].label.length < 1) {
        tempObj.list = tempObj.list.filter((element) => element.playId != item.playId);
      }
    });
    tempObj.list.map((item, index) => {
      item.label.map((subItem, subIndex) => {
        subItem.label.map((subSubItem, subSubIndex) => {
          if (tempObj.list[index].label[subIndex].label[subSubIndex].selectarea.selPosition) {
            let dps = gameJSONObj.official.list[index].label[subIndex].label[subSubIndex].selectarea.defaultposition.split('');
            tempObj.list[index].label[subIndex].label[subSubIndex].selectarea.selPositionArray = [];
            dps.map((v, i) => {
              let d = {
                index: i,
                text: postionText[i],
                sel: v == '1',
              };
              tempObj.list[index].label[subIndex].label[subSubIndex].selectarea.selPositionArray.push(d);
            });
          }
        });
      });
    });

    gameJSONObj.official = tempObj;
  }

  let officialBetRateurl, classicBetRateurl;

  if (gameJSONObj.isAllPlayType || gameJSONObj.isOnlyOfficial) {
    officialBetRateurl = window.$gameUrl + '/rest/game/lottery/loadamode/';
    officialBetRateurl += gameJSONObj.selectId;
    officialBetRateurl += '?play=' + gameJSONObj.playId + '&track=false';
    if(store.getState().memberInfo.gametoken!==undefined && store.getState().memberInfo.gametoken!==null) {
      officialBetRateurl += '&gametoken='+store.getState().memberInfo.gametoken;
    }
  }
  if (gameJSONObj.isAllPlayType || gameJSONObj.isOnlyClassic) {
    classicBetRateurl = window.$gameUrl + '/rest/game/lottery/loadamode/';
    classicBetRateurl += gameJSONObj.selectId;
    classicBetRateurl += '?play=' + gameJSONObj.splayId + '&track=false';
    if(store.getState().memberInfo.gametoken!==undefined && store.getState().memberInfo.gametoken!==null) {
      classicBetRateurl += '&gametoken='+store.getState().memberInfo.gametoken;
    }
  }
  gameJSONObj.gameBetObject = {};

  if (gameJSONObj.isAllPlayType) {
    let [officialBetRateResultJson, classicBetRateResultJson] = await fetchAll(officialBetRateurl, classicBetRateurl);
    let [officialBetRateResult, classicBetRateResult] = await jsonAll(officialBetRateResultJson, classicBetRateResultJson);
    if (officialBetRateResult.status && officialBetRateResult.code == 200) {
      gameJSONObj.gameBetObject.official = officialBetRateResult.result;
    }
    if (classicBetRateResult.status && classicBetRateResult.code == 200) {
      gameJSONObj.gameBetObject.classic = classicBetRateResult.result;
    }
  } else if (gameJSONObj.isOnlyOfficial) {
    let jsonResult = await fetchSync(officialBetRateurl, fetchConfig);
    let betResult = await jsonSync(jsonResult);
    if (betResult.status && betResult.code == 200) {
      gameJSONObj.gameBetObject.official = betResult.result;
    }
  }else if (gameJSONObj.isOnlyClassic) {
    let jsonResult = await fetchSync(classicBetRateurl, fetchConfig);
    let betResult = await jsonSync(jsonResult);
    if (betResult.status && betResult.code == 200) {
      gameJSONObj.gameBetObject.classic = betResult.result;
    }
  }

  if (!selectedGameObj.shengxiao) {
    gameJSONObj.shengxiao = {};

    let jsonResult = await fetchSync(
        window.$gameUrl + '/rest/game/lottery/loadshengxiao',
      {
        method: 'Get',
        headers: {
          'Accept': 'application/json, text/plain, */*',
          'Content-Type': 'application/x-www-form-urlencoded',
          'gametoken': (store.getState().memberInfo.gametoken!==undefined && store.getState().memberInfo.gametoken!==null)?store.getState().memberInfo.gametoken:''
        },
        // credentials: 'include',
      },
    );
    let result = await jsonSync(jsonResult);
    gameJSONObj.shengxiao = result.result.shengxiao;
  } else {
    gameJSONObj.shengxiao = selectedGameObj.shengxiao;
  }
  return gameJSONObj;
};

const fetchConfig = {
  method: 'get',
  headers: new Headers({
    'Accept': 'application/json, text/plain, */*',
    'Content-Type': 'application/x-www-form-urlencoded',
  }),
  // credentials: 'include',
};

const fetchAll = async (officialBetRateurl, classicBetRateurl) => {
  return await Promise.all([fetch(officialBetRateurl, fetchConfig), fetch(classicBetRateurl, fetchConfig)]);
};

const jsonAll = async (officialBetRateResultJson, classicBetRateResultJson) => {
  return await Promise.all([officialBetRateResultJson.json(), classicBetRateResultJson.json()]);
};

const fetchSync = async (url, config) => {
  return await fetch(url, config);
};

const jsonSync = async (jsonInput) => {
  return await jsonInput.json();
};

const initGamePlay = (input) => {
  let game = cloneDeep(input);
  let gamePlayObject = {};
  let postionText = ['万位', '千位', '百位', '十位', '个位'];

  if (game.isChangeSelectType) {
    gamePlayObject.game = game.prevGameBetObject.game;
  } else {
    gamePlayObject.game = game.mainGameObj.gameMap.find(item => item.id == game.mainGameObj.selectId);
  }
  gamePlayObject.betTimes = game.mainGameObj.selectedType == 'OFFICIAL' ? game.mainGameObj.official.betTimes : game.mainGameObj.classic.betTimes;
  if (game.gameBetContent && !game.isChangeGame) {
    gamePlayObject.betContent = game.gameBetContent;
  } else {
    clearBetContent(false, gamePlayObject);
  }
  gamePlayObject.amode = game.mainGameObj.selectedType == 'OFFICIAL' ? game.mainGameObj.gameBetObject.official.amode : game.mainGameObj.gameBetObject.classic.amode;
  gamePlayObject.selamode = gamePlayObject.amode[0];
  gamePlayObject.currplay = game.mainGameObj.selectedType == 'OFFICIAL' ? game.gameOfficialDetails.gameListLabelLabelDetails : game.gameClassicDetails.currentGameList;
  gamePlayObject.betPlayLabel = game.mainGameObj.selectedType == 'OFFICIAL' ? game.mainGameObj.gameBetObject.official.betPlayLabel : game.mainGameObj.gameBetObject.classic.betPlayLabel;
  gamePlayObject.layout = game.mainGameObj.selectedType == 'OFFICIAL' ? gamePlayObject.currplay.selectarea.layout : gamePlayObject.currplay.area.layout;
  gamePlayObject.maxBetAmt = game.mainGameObj.selectedType == 'OFFICIAL' ? game.mainGameObj.gameBetObject.official.maxBetAmt:game.mainGameObj.gameBetObject.classic.maxBetAmt;
  gamePlayObject.minBetAmt = game.mainGameObj.selectedType == 'OFFICIAL' ? game.mainGameObj.gameBetObject.official.minBetAmt:game.mainGameObj.gameBetObject.classic.minBetAmt;
  gamePlayObject.officialBetModeStatus = game.mainGameObj.selectedType == 'OFFICIAL' ? game.mainGameObj.gameBetObject.official.officialBetModeStatus : null;
  gamePlayObject.betContent.times = gamePlayObject.minBetAmt;
  if (gamePlayObject.betPlayLabel && gamePlayObject.betPlayLabel[gamePlayObject.currplay.playId]) {
    let playlabel = gamePlayObject.betPlayLabel[gamePlayObject.currplay.playId];
    if (playlabel.layout) {
      gamePlayObject.currplay.selectarea.layout.map((layout, layoutIndex) => {
        layout.no.map((no, noIndex) => {
          no.tip = playlabel.layout[layoutIndex].no[noIndex].tip;
        });
      });
    }
  }

  if (game.mainGameObj.isOnlyClassic) {
    gamePlayObject.game.playtype = gamePlayObject.game.playType;
    gamePlayObject.game.playid = gamePlayObject.game.playId;
  } else if (game.mainGameObj.selectedType == 'OFFICIAL') {
    gamePlayObject.game.playtype = gamePlayObject.game.playType;
    gamePlayObject.game.playid = gamePlayObject.game.playId;
  } else {
    gamePlayObject.game.playtype = gamePlayObject.game.splayType;
    gamePlayObject.game.playid = gamePlayObject.game.splayId;
  }

  gamePlayObject.selBetPosition = [];
  gamePlayObject.curRebate = '0'; // TODO: curRebate where to get
  gamePlayObject.selectItem = {
    content: [],
    money: 0,
    count: 0,
    curRebate: gamePlayObject.curRebate,
    times: 1,
    position: '',
    odds: [],
  };
  gamePlayObject.isSelNumber = false;//重置选择
  gamePlayObject.curtab = game.mainGameObj.selectedType == 'OFFICIAL' ? game.gameOfficialDetails.gameListDetails : gamePlayObject.currplay;
  gamePlayObject.preAmount = gamePlayObject.minBetAmt;
  if (game.isChangeSelectType) {
    gamePlayObject.award = game.prevGameBetObject.award;
    gamePlayObject.isloadaward = game.prevGameBetObject.isloadaward;
    gamePlayObject.openAwardIntervalNum = game.prevGameBetObject.openAwardIntervalNum;
    gamePlayObject.AWARD_NUM = game.prevGameBetObject.AWARD_NUM;
  } else {
    gamePlayObject.award = { period: {}, nums: [], list: [], lastissue: [], times: 3 };
    gamePlayObject.isloadaward = false;
    gamePlayObject.openAwardIntervalNum = {};
    gamePlayObject.AWARD_NUM = resultNum(gamePlayObject.game.playId);

    gamePlayObject.game.awardStyle = 0;
    gamePlayObject.game.numStyle = 0;
    if (gamePlayObject.game.playId == 'LHC') {
      gamePlayObject.game.awardStyle = 1;
      gamePlayObject.game.numStyle = 1;
    } else if (gamePlayObject.game.playId == 'LOT') {
      gamePlayObject.game.awardStyle = 1;
      gamePlayObject.game.numStyle = 1;
    } else if (gamePlayObject.game.playId == 'KL8') {
      gamePlayObject.game.awardStyle = 1;
      gamePlayObject.game.numStyle = 2;
    } else if (gamePlayObject.game.playId == 'DDC') {
      gamePlayObject.game.numStyle = 3;
    } else if (gamePlayObject.game.playId == 'BSC') {
      gamePlayObject.game.awardStyle = 1;
      gamePlayObject.game.numStyle = 4;
    } else if (gamePlayObject.game.playId == 'KW3') {
      gamePlayObject.game.numStyle = 5;
    } else if (gamePlayObject.game.playId == 'FTW') {
      gamePlayObject.game.awardStyle = 1;
      gamePlayObject.game.numStyle = 4;
    } else if (gamePlayObject.game.playId == 'STM') {
      gamePlayObject.game.numStyle = 6;
    } else if (gamePlayObject.game.playId == 'LCT') {
      gamePlayObject.game.awardStyle = 8;
    } else if (gamePlayObject.game.playId == 'YSC') {
      gamePlayObject.game.awardStyle = 9;
    } else if (gamePlayObject.game.playId == 'YXX') {
      gamePlayObject.game.awardStyle = 10;
    }
  }


  if (gamePlayObject.betPlayLabel && gamePlayObject.betPlayLabel[gamePlayObject.currplay.playId]) {
    for (let k in gamePlayObject.betPlayLabel[gamePlayObject.currplay.playId].prize) {
      gamePlayObject.selectItem.curRebate = k;
      break;
    }
    gamePlayObject.selrebate = gamePlayObject.betPlayLabel[gamePlayObject.currplay.playId].prize[gamePlayObject.selectItem.curRebate];
  }

  if (game.mainGameObj.selectedType == 'OFFICIAL') {
    gamePlayObject.otype = gamePlayObject.currplay.selectarea.type;
    if (gamePlayObject.currplay.selectarea.type != 'input') {//选择 过滤掉input 玩法
      gamePlayObject.currplay.selectarea.hastitle = false;
      for (let i = 0; i < gamePlayObject.layout.length; i++) {
        let l = gamePlayObject.layout[i];
        if (l.cols > 0)
          gamePlayObject.currplay.selectarea.hastitle = true;

        let mod = l.no.length % (l.maxcol);
        if (mod != 0) {
          let fillno = l.maxcol - mod;
          for (let k = 0; k < fillno; k++) {
            l.no.push({ no: '', tip: '' });
          }
        }
        l.selectNums = {};
        l.selectOdds = {};
        l.selectClick = {};
        l.targete = '';
        l.showFastBtn=false;  //是否显示当前的快捷选项条
        l.btnOne = false;
        l.btnTwo = false;
        l.btnThree = false;
        l.btnFour = false;
        l.btnFive = false;
        l.btnSix = false;
      }
    }

    if (gamePlayObject.currplay.selectarea.selPosition) {//任选位置
      if(gamePlayObject.game.playid=='V6D'){
        postionText = ['第一位', '第二位', '第三位', '第四位','第五位','第六位'];
      }
      if(gamePlayObject.game.playid=='M4D'){
        postionText = ['千位','百位','十位','个位'];
      }
      let dps = gamePlayObject.currplay.selectarea.defaultposition.split('');
      let positionArr = gamePlayObject.currplay.selectarea.selPositionArray = [];
      dps.map((v, i) => {
        let d = {
          index: i,
          text: postionText[i],
          sel: v == '1',
        };
        positionArr.push(d);
      });
    }
  } else { // game.mainGameObj.selectedType == "CLASSIC"
    // logic from handlePlay --start
    /* start hide for req 1208 */
    if(gamePlayObject.game.playid=='V6Z' || gamePlayObject.game.playid=='V5Z'){
      for (let i = 0; i < gamePlayObject.currplay.selectarea.layout.length; i++) {
        let l = gamePlayObject.currplay.selectarea.layout[i];
        if (l.cols > 0)
          gamePlayObject.currplay.selectarea.hastitle = true;

        l.selectNums = {};
        l.selectOdds = {};
        l.selectClick = {};
        l.targete = '';
        l.showFastBtn=false;  //是否显示当前的快捷选项条
        l.btnOne = false;
        l.btnTwo = false;
        l.btnThree = false;
        l.btnFour = false;
        l.btnFive = false;
        l.btnSix = false;
      }
    }
    /* end hide for req 1208 */
    gamePlayObject.betPlay = game.gameClassicDetails.gameList;
    gamePlayObject.betPlay.map((e, i) => {
      // let reg = new RegExp("^" + e.playId);
      // if (reg.test($scope.paramplayid)) {
      //     e.selected = true;
      //     BettingDataService.setplay($scope, e);
      //     sel = true;
      // } else
      e.selected = false;
    });
    // logic from handlePlay --end
    setplay(game, gamePlayObject);

    // set default selected by YM
    gamePlayObject.currplay.area.layout.map((e, i) => {
      e.items.map((se, si) => {
        se.map((sse, ssi) => {
          sse.selected = false;
        });
      });
    });
  }
  return gamePlayObject;
};

// only call if classic
const setplay = (game, gamePlayObject) => {
  gamePlayObject.orders = {
    issue: '',
    playId: game.mainGameObj.playId,
    gameId: game.mainGameObj.selectId,
    items: [],
    betType: 0, // if isChat == true ? 1 : 0
    totalMoney: 0,
    pursePlanName: '方案1',
  };
  /* start hide for req 1208 */
  if(gamePlayObject.game.playid=='V6Z' || gamePlayObject.game.playid=='V5Z'){
    for (let i = 0; i < gamePlayObject.currplay.selectarea.layout.length; i++) {
      let l = gamePlayObject.currplay.selectarea.layout[i];
      if (l.cols > 0)
        gamePlayObject.currplay.selectarea.hastitle = true;

      l.selectNums = {};
      l.selectOdds = {};
      l.selectClick = {};
      l.targete = '';
      l.showFastBtn=false;  //是否显示当前的快捷选项条
      l.btnOne = false;
      l.btnTwo = false;
      l.btnThree = false;
      l.btnFour = false;
      l.btnFive = false;
      l.btnSix = false;
    }
  }
  /* end hide for req 1208 */
  gamePlayObject.currplay.curtabidx = gamePlayObject.currplay.curtabidx || 0;
  if (gamePlayObject.currplay.area.type == 'select') {
    if (!gamePlayObject.currplay.resetplay) {
      if (gamePlayObject.betPlayLabel) {
        gamePlayObject.currplay.area.oddsLabel.map((item, index) => {
          if (gamePlayObject.betPlayLabel[item.playId])
            item.odds = gamePlayObject.betPlayLabel[item.playId].odds;
        });
      }

      let hl = gamePlayObject.currplay.area.head.length;
      let l = gamePlayObject.currplay.area.list.length / hl;
      let s = 0;
      gamePlayObject.selectMap = {};
      gamePlayObject.currplay.area.oddsLabel.map((o, i) => {
        gamePlayObject.selectMap[o.playId] = o;
        o.layout = [];
        o.label = [];
        o.nums.map((b, a) => {
          o.label[a] = [];
          for (let k = 0; k < l; k++) {
            let p = gamePlayObject.currplay.area.list[s + a + (k * hl)];
            p.row = a;
            p.col = k;
            o.label[a].push(p);
          }
          let im = 5;
          let ilen = Math.ceil(o.label[a].length / im);
          let items = [];
          for (let j = 0; j < ilen; j++) {
            let p = o.label[a].slice(j * im, (j + 1) * im);
            items.push(p);
          }
          o.layout.push({ caption: gamePlayObject.currplay.area.head[s + a], items: items });
        });
        s += o.nums.length;
      });
      gamePlayObject.currplay.resetplay = true;
    }
  } else {
    gamePlayObject.currplay.area.layout.map((e, i) => {
      if (e.items && e.items.length > 0) return;
      if (gamePlayObject.betPlayLabel) {
        e.list.map((m, j) => {
          if (gamePlayObject.betPlayLabel[m.playId])
            m.odds = gamePlayObject.betPlayLabel[m.playId].odds;
            m.odds1 = gamePlayObject.betPlayLabel[m.playId]?.highOdds;
        });
      }
      e.items = [];
      let s = e.mlen;
      let len = Math.ceil(e.list.length / s);

      for (let j = 0; j < len; j++) {
        let p = e.list.slice(j * s, (j + 1) * s);
        e.items.push(p);
      }
    });
    if (gamePlayObject.currplay.area.checkItems) {
      gamePlayObject.currplay.area.checkItems.map((e, i) => {
        if (e.items && e.items.length > 0) return;
        let s = e.mlen;
        e.items = [];
        let len = Math.ceil(e.list.length / s);
        for (let j = 0; j < len; j++) {
          let p = e.list.slice(j * s, (j + 1) * s);
          e.items.push(p);
        }
      });
    }
  }
  if (!gamePlayObject.currplay.area.tabsettting && gamePlayObject.currplay.area.tabtitle && gamePlayObject.currplay.area.tabtitle.length > 0) {
    gamePlayObject.currplay.area.tabtitle.map((e, i) => {
      gamePlayObject.currplay.area.tabtitle[i] = { name: e, selected: false };
    });
    gamePlayObject.currplay.area.tabsettting = true;
  }
  // $scope.curplay = play;
  // if (gamePlayObject.paramitem) {
  //     if (gamePlayObject.currplay.area.type == 'select') {
  //         let bets = gamePlayObject.selectMap[gamePlayObject.paramplayid];
  //         let arr = gamePlayObject.paramitem.split('&');
  //         arr.map((a, i) => {
  //             bets.label[i].map((col, w) => {
  //                 if (col.project == a) {
  //                     selectxx(col, col.row, col.col, gamePlayObject);
  //                 }
  //             })
  //         })
  //     } else {
  //         gamePlayObject.currplay.area.layout.map((e, i) => {
  //             e.list.map((col, w) => {
  //                 if (col.playId == gamePlayObject.paramplayid) {
  //                     if (gamePlayObject.currplay.area.type == 'odds')
  //                         selectedItem(col, gamePlayObject);
  //                     else if (gamePlayObject.currplay.area.type == 'check')
  //                         checkBetPlay(col, gamePlayObject);
  //                     else if (gamePlayObject.currplay.area.type == 'check_tab')
  //                         $scope.selectBetPlay(col);
  //                 }
  //             })
  //         })
  //         if (gamePlayObject.currplay.area.checkItems) {
  //             gamePlayObject.currplay.area.checkItems.map((e, i) => {
  //                 let arr = gamePlayObject.paramitem.split("&");
  //                 arr.map((a, k) => {
  //                     e.list.map(col, w) {
  //                         if (a == col.project) {
  //                             $scope.checkBetItem(col);
  //                         }
  //                     }
  //                 })
  //             })
  //         }
  //     }
  // }
};

// 经典直选
const selectxx = (p, outidx, idx, gamePlayObject) => {
  if (p.disabled) return;
  p.selected = !p.selected;
  let s = gamePlayObject.selectMap[p.playId];
  if (p.selected) {
    s.nums[p.idx] = p.num;
  } else
    s.nums[p.idx] = null;

  for (let i = 0; i < s.label.length; i++) {
    if (i != outidx)
      s.label[i][idx].disabled = p.selected;
  }
  s.label[outidx].map((e, i) => {
    if (e && e.playId == p.playId && i != idx)
      e.disabled = p.selected;
  });
  let l = 0;
  gamePlayObject.currplay.area.oddsLabel.map((s, i) => {
    let o = true;
    s.nums.map((n, k) => {
      o = o && n;
    });
    if (o)
      l++;
  });
  gamePlayObject.currplay.selectNums = l;
  totalNums(gamePlayObject);
  //console.log('selectxx:'+JSON.stringify(gamePlayObject));
};

const totalNums = (gamePlayObject) => {
  let total = 0;
  gamePlayObject.betPlay.map((x, i) => {
    if (x.selectNums)
      if(x.selectNums>0){
        total += x.selectNums;
      }
  });
  gamePlayObject.totalItem = total;
};

//经典-切换二级玩法
const changeTab = ($idx, gamePlayObject) => {
  if (gamePlayObject.currplay.curtabidx == $idx) return;
  gamePlayObject.currplay.curtabidx = $idx;
  gamePlayObject.currplay.area.layout.map((e, i) => {
    if (i == $idx) {
      e.show = true;
    } else
      e.show = false;
  });
};

// 经典-选中项
const selectedItem = (p, gamePlayObject) => {
  if (gamePlayObject.preAmount == null || gamePlayObject.preAmount === 0 || gamePlayObject.preAmount === '') {
    gamePlayObject.preAmount = gamePlayObject.minBetAmt;
  }
  p.selected = !p.selected;
  let len = 0;
  gamePlayObject.currplay.area.layout.map((n, a) => {
    n.list.map((e, b) => {
      if (e.selected) {
        len++;
      }
    });
  });
  if (gamePlayObject.currplay.area.tabtitle && gamePlayObject.currplay.area.tabtitle.length)
    gamePlayObject.currplay.area.tabtitle[gamePlayObject.currplay.curtabidx].selected = len;
  gamePlayObject.currplay.selectNums = len;

  let total = 0;
  gamePlayObject.betPlay.map((x, i) => {
    if (x.selectNums)
      total += x.selectNums;
  });
  gamePlayObject.totalItem = total;
  if (gamePlayObject.totalItem <= 0) {
    gamePlayObject.isClassicSelNumber = false;
  } else {
    gamePlayObject.isClassicSelNumber = true;
  }
  totalNums(gamePlayObject);
  //console.log('selectedItem:'+JSON.stringify(gamePlayObject));
};

// 经典-选择check项
const checkBetPlay = (p, gamePlayObject) => {
  if (gamePlayObject.preAmount == null  || gamePlayObject.preAmount === 0 || gamePlayObject.preAmount === '') {
    gamePlayObject.preAmount = '1';
  }
  gamePlayObject.currplay.area.checkplay = p;
  let len = 0;
  gamePlayObject.currplay.area.checkItems.map((e, n) => {
    e.list.map((c, m) => {
      if (c.selected) c.selected = false;
      c.disabled = false;
    });
    e.items.map((item, index) => {
      item.map((subitem, index) => {
        subitem.selected = false;
      });
    });
  });
  gamePlayObject.currplay.selectNums = 0;
  totalNums(gamePlayObject);
};

// 经典-根据check项 选择对应 的一组号码
const checkBetItem = (p, gamePlayObject) => {
  if (gamePlayObject.preAmount == null  || gamePlayObject.preAmount === 0 || gamePlayObject.preAmount === '') {
    gamePlayObject.preAmount = '1';
  }

  if (!gamePlayObject.currplay.area.checkplay || p.disabled) return;
  p.selected = !p.selected;
  let nums = [];
  gamePlayObject.currplay.area.checkItems.map((e, n) => {
    e.list.map((c, m) => {
      if (c.selected) nums.push(c.project);
    });
  });

  gamePlayObject.currplay.area.checkItems.map((e, n) => {
    e.list.map((c, m) => {
      if (c && !c.selected)
        c.disabled = nums.length >= gamePlayObject.currplay.area.checkplay.max;
    });
  });
  let resultnums = betcommon.ApiAll.getRank(nums.sort(), gamePlayObject.currplay.area.checkplay.min);
  if (resultnums && resultnums.length)
    gamePlayObject.currplay.selectNums = resultnums.length || 0;
  totalNums(gamePlayObject);
};

// 经典-根据项选一组项投注
const selectBetPlay = (p, gamePlayObject) => {
  if (gamePlayObject.preAmount == null  || gamePlayObject.preAmount === 0 || gamePlayObject.preAmount === '') {
    gamePlayObject.preAmount = '1';
  }
  if (p.disabled) return;
  p.selected = !p.selected;

  let len = 0;
  gamePlayObject.currplay.area.layout[gamePlayObject.currplay.curtabidx].list.map((e, i) => {
    if (/^check/.test(gamePlayObject.currplay.area.type) && e.selected)
      len++;
  });
  gamePlayObject.currplay.area.layout[gamePlayObject.currplay.curtabidx].list.map((v, k) => {
    if (v && !v.selected)
      v.disabled = len >= gamePlayObject.currplay.area.layout[gamePlayObject.currplay.curtabidx].max;
  });
  let s = 0;
  gamePlayObject.currplay.area.layout.map((x, i) => {
    let nums = [];
    x.list.map((v, k) => {
      if (v.selected)
        nums.push(v.project);
    });
    let resultnums = betcommon.ApiAll.getRank(nums, x.min);
    gamePlayObject.currplay.area.tabtitle[i].selected = resultnums && resultnums.length;
    if (resultnums && resultnums.length) {
      s += resultnums.length;
    }
  });
  gamePlayObject.currplay.selectNums = s;
  totalNums(gamePlayObject);
};

// 选择号码
const selectedNums = (area, n, random, gamePlayObject) => {
  area.btnOne = false;
  area.btnTwo = false;
  area.btnThree = false;
  area.btnFour = false;
  area.btnFive = false;
  area.btnSix = false;
  gamePlayObject.randomTime = 0;
  let alertObj = store.getState().alert;
  if (!n.no) return;
  if (gamePlayObject.selectItem.content[area.place] && gamePlayObject.currplay.maxcodecount) {
    if (gamePlayObject.selectItem.content[area.place].length == gamePlayObject.currplay.maxcodecount && !area.selectNums[n.no]) {
      // chaos.fw7.alert(MSG.T_15.replace('%s', $scope.curplay.maxcodecount),MSG.T_00);
      alertObj.content = tran('MSG.T_15').replace('%s', gamePlayObject.currplay.maxcodecount);
      alertObj.title = tran('MSG.T_00');
      alertObj.alertType = 'normalAlert';
      alertObj.showAlert = true;
      store.dispatch(UpdateAlert(alertObj));
      return;
    }
  }
  area.selectNums[n.no] = !area.selectNums[n.no];
  resetodds(area, n);
  if (!random) {
    area.selectClick[n.no] = area.selectNums[n.no];
    if (area.selectClick[n.no])
    // $timeout(function(){
      area.selectClick[n.no] = false;
    // },50);
  }
  refreshBet(gamePlayObject);
};

const selectedConvenient = (methodid,nowPlace,gamePlayObject) => {
    console.log(methodid);
    console.log(nowPlace);
    //处理当前当前选项条
    gamePlayObject.currplay.selectarea.layout.map((area,index) => {
      if(area.place==nowPlace){
        area.showFastBtn = !area.showFastBtn;
      }else{
        //area.showFastBtn = false;
      }
    })
    store.dispatch(updateBetRecord(gamePlayObject));
};

const selAll = (gamePlayObject,index) => {
  let area = gamePlayObject.currplay.selectarea.layout[index];
  area.targete = 'all';
  area.btnOne = true;
  area.btnTwo = false;
  area.btnThree = false;
  area.btnFour = false;
  area.btnFive = false;
  area.btnSix = false;
  area.no.map((e, index) => {
    if(e.no){
      area.selectNums[e.no] = true;
      resetodds(area, e);
    }
  });
  refreshBet(gamePlayObject);
  store.dispatch(updateBetRecord(gamePlayObject));
};

const selBig = (gamePlayObject,index) =>{
  let area = gamePlayObject.currplay.selectarea.layout[index];
  area.targete='big';
  area.btnOne = false;
  area.btnTwo = true;
  area.btnThree = false;
  area.btnFour = false;
  area.btnFive = false;
  area.btnSix = false;
  area.no.map((e, i) => {
    if(e.no){
      if(gamePlayObject.currplay.playId === "XX5005001002"){
        area.selectNums[e.no] = i+3 >= gamePlayObject.currplay.selectarea.noBigIndex;
      }else{
        area.selectNums[e.no] = i >= gamePlayObject.currplay.selectarea.noBigIndex;
      }
      resetodds(area,e);
    }
  });
  refreshBet(gamePlayObject);
  store.dispatch(updateBetRecord(gamePlayObject));
};

const selSmall = (gamePlayObject,index) =>{
  let area = gamePlayObject.currplay.selectarea.layout[index];
  area.targete='small';
  area.btnOne = false;
  area.btnTwo = false;
  area.btnThree = true;
  area.btnFour = false;
  area.btnFive = false;
  area.btnSix = false;
  area.no.map((e, i) => {
    if(e.no){
      if(gamePlayObject.currplay.playId === "XX5005001002"){
        area.selectNums[e.no] = i+3 < gamePlayObject.currplay.selectarea.noBigIndex;
      }else{
        area.selectNums[e.no] = i < gamePlayObject.currplay.selectarea.noBigIndex;
      }
      resetodds(area,e);
    }
  });
  refreshBet(gamePlayObject);
  store.dispatch(updateBetRecord(gamePlayObject));
};

const selDouble = (gamePlayObject,index) =>{
  let area = gamePlayObject.currplay.selectarea.layout[index];
  area.targete='double';
  area.btnOne = false;
  area.btnTwo = false;
  area.btnThree = false;
  area.btnFour = false;
  area.btnFive = true;
  area.btnSix = false;
  area.no.map((e, i) => {
    if(e.no){
      area.selectNums[e.no] = (e.no) % 2==0;
      resetodds(area,e);
    }
  });
  refreshBet(gamePlayObject);
  store.dispatch(updateBetRecord(gamePlayObject));
};

const selSingle = (gamePlayObject,index) =>{
  let area = gamePlayObject.currplay.selectarea.layout[index];
  area.targete='single';
  area.btnOne = false;
  area.btnTwo = false;
  area.btnThree = false;
  area.btnFour = true;
  area.btnFive = false;
  area.btnSix = false;
  area.no.map((e, i) => {
    if(e.no){
      area.selectNums[e.no] = (e.no) % 2==1;
      resetodds(area,e);
    }
  });
  refreshBet(gamePlayObject);
  store.dispatch(updateBetRecord(gamePlayObject));
};

const selectClear = (gamePlayObject,index) =>{
  let area = gamePlayObject.currplay.selectarea.layout[index];
  area.targete='clear';
  area.btnOne = false;
  area.btnTwo = false;
  area.btnThree = false;
  area.btnFour = false;
  area.btnFive = false;
  area.btnSix = false;
  area.no.map((e, i) => {
    area.selectNums[e.no] = false;
    resetodds(area,e);
  });
  refreshBet(gamePlayObject);
  store.dispatch(updateBetRecord(gamePlayObject));
};

// 金额模式
const changeAmode = function(modeid, gamePlayObject) {
  gamePlayObject.selamode = gamePlayObject.amode.find((element) => element.modeid == modeid);
  refreshBetMoney(gamePlayObject);
};

// 倍数
const changeTimes = (input, gamePlayObject) => {
  if (input && !/^[0-9]+$/.test(input)) {
    gamePlayObject.betContent.times = 1;
  } else {
    gamePlayObject.betContent.times = input;
  }
  let totalmoney = 0;
  gamePlayObject.betContent.items.map((item, i) => {
    item.times = gamePlayObject.betContent.times * parseInt(gamePlayObject.betContent.multipleAmountValue);
    item.money = item.basemoney * item.times;
    totalmoney += item.money;
  });
  gamePlayObject.betContent.money = totalmoney;
  gamePlayObject.selectItem.money = gamePlayObject.selectItem.count * gamePlayObject.betTimes * gamePlayObject.selamode.rate * gamePlayObject.betContent.times * gamePlayObject.betContent.multipleAmountValue;
};

const changeMultiple = (input, gamePlayObject) => {
  if(!input){
    gamePlayObject.betContent.multipleAmountValue = "";
  }
  let maxDigitForMultiAmtVal = 9999999999;
  if(input >0 && input <= maxDigitForMultiAmtVal){
    gamePlayObject.betContent.multipleAmountValue = input;
  }else if (input > maxDigitForMultiAmtVal){
    gamePlayObject.betContent.multipleAmountValue = maxDigitForMultiAmtVal;
  }
  let totalmoney = 0;
  gamePlayObject.betContent.items.map((item, i) => {
    item.times = gamePlayObject.betContent.times * parseInt(gamePlayObject.betContent.multipleAmountValue);
    item.money = item.basemoney * item.times;
    totalmoney += item.money;
  });
  gamePlayObject.betContent.money = totalmoney;
  gamePlayObject.selectItem.money = gamePlayObject.selectItem.count * gamePlayObject.betTimes * gamePlayObject.selamode.rate * gamePlayObject.betContent.times * gamePlayObject.betContent.multipleAmountValue;
};

// 刷新注数和金额
const refreshBet = (gamePlayObject) => {
  let op = checkBetNumbers(gamePlayObject.currplay);
  gamePlayObject.selectItem.count = op.count || 0;

  if (gamePlayObject.selectItem.count > 0) {
    gamePlayObject.isSelNumber = true;
  } else {
    gamePlayObject.isSelNumber = false;
  }
  gamePlayObject.selectItem.content = op.numbers;
  gamePlayObject.selectItem.position = op.position;
  gamePlayObject.selectItem.odds = op.odds;
  refreshBetMoney(gamePlayObject);
};

// 刷新投注金额
const refreshBetMoney = (gamePlayObject) => {
  if (gamePlayObject.selectItem) {
    gamePlayObject.selectItem.money = gamePlayObject.selectItem.count * gamePlayObject.betTimes * gamePlayObject.selamode.rate * gamePlayObject.betContent.times * gamePlayObject.betContent.multipleAmountValue;
    gamePlayObject.selectItem.basemoney = gamePlayObject.selectItem.count * gamePlayObject.betTimes * gamePlayObject.selamode.rate;
  }
};

const resetodds = (area, n) => {
  if (area.selectNums[n])
    area.selectOdds[n] = n.tip;
  else
    delete area.selectOdds[n.no];
};

//清除投注确认区
const clearBetContent = (isbet, gamePlayObject) => {
  gamePlayObject.betContent = {
    items: [],
    issue: '',
    // times: isbet ? gamePlayObject.betContent.times : 1,
    times: 1,
    multipleAmountValue: isbet ? gamePlayObject.betContent.multipleAmountValue : 1,
    exitems: [],
    profixs: [],
    count: 0,
    money: 0,
    rate: 1,
    tracetype: 1,
    tracenums: 0,
    tracemoney: 0.0,
    basemoney: 0,
    traces: [],
    isTrace: false,
    isStop: false,   // 追号中奖追停,
    betType: 0,//$rootScope.ischat?1:0
  };

  // if(isbet){
  //     $rootScope.getUserInfo();
  //     $scope.betContent.issue = $scope.game.period.fullIssue;
  //     chaos.fw7.closeModal('.betitem-menu');
  //     chaos.fw7.closeModal(".purlist-popup");
  //     $scope.betMult.setValue('1');
  // }
};

const checkBetNumbers = (play) => {
  // let tmp_nums = 1;
  let nums = 0;
  let minchosen = [];
  let odds = [];
  let data_sel = [];
  let mname = play.methodname;
  let max_place = 0;
  let positionSel = [];
  if (play.selectarea.selPositionArray) {
    play.selectarea.selPositionArray.map((v, s) => {
      v.sel && positionSel.push(s + 1);
    });
  }
  play.selectarea.layout.map((n, s) => {
    data_sel[n.place] = (data_sel[n.place] || []).concat(betcommon.getObjKeys(n.selectNums, function(k, v) {
      return v;
    }));
    odds[n.place] = (odds[n.place] || []).concat(betcommon.getObjValue(n.selectOdds, function(k, v) {
      return v;
    }));
    minchosen[n.place] = n.minchosen || 0;
    max_place = Math.max(max_place, n.place);
  });

  let selobj = betcommon.getSelectBetNums(mname, max_place, minchosen, positionSel, data_sel);
  nums = selobj.nums;
  positionSel = selobj.position;
  return {
    numbers: data_sel,
    count: nums,
    position: positionSel,
    odds: odds,
  };
};

const selNum = (gamePlayObject) => {
  if (gamePlayObject.selectItem.count > 0) {
    addNumber(gamePlayObject);

  } else {
    // if(gamePlayObject.game.playType==0){
    //     // $scope.addOddsBetItem();
    // }else{
    //     $scope.betBasket();
    // }
  }
};

// 加入彩票栏
const addNumber = (gamePlayObject) => {
  let alertObj = store.getState().alert;
  if (!gamePlayObject.selectItem.count) {
    // chaos.fw7.alert(MSG.W_10,MSG.T_00);
    alertObj.content = tran('MSG.W_10');
    alertObj.title = tran('MSG.T_00');
    alertObj.alertType = 'normalAlert';
    alertObj.showAlert = true;
    store.dispatch(UpdateAlert(alertObj));
    return;
  }
  let cur_position = 0;
  if (gamePlayObject.selectItem.position && gamePlayObject.selectItem.position.length > 0) {
    gamePlayObject.selectItem.position.map((n, i) => {
      cur_position += Math.pow(2, 5 - parseInt(n, 10));
    });
  }
  let nos = gamePlayObject.currplay.show_str;
  let title = '[ ' + tran(gamePlayObject.curtab.playId) + ' ' + tran(gamePlayObject.currplay.methodid) + ' ]';
  let temp = [];
  gamePlayObject.selectItem.content.map((e, i) => {
    nos = nos.replace('X', e.sort(betcommon._SortNum(gamePlayObject)).join(gamePlayObject.currplay.code_sp));
    temp.push(e.sort(betcommon._SortNum(gamePlayObject)).join('&'));
  });
  if (!gamePlayObject.betContent.exitems[gamePlayObject.currplay.methodid]) {
    gamePlayObject.betContent.exitems[gamePlayObject.currplay.methodid] = [];
  }
  if (!gamePlayObject.betContent.exitems[gamePlayObject.currplay.methodid][gamePlayObject.selamode.modeid]) {
    gamePlayObject.betContent.exitems[gamePlayObject.currplay.methodid][gamePlayObject.selamode.modeid] = [];
  }
  if (!gamePlayObject.betContent.exitems[gamePlayObject.currplay.methodid][gamePlayObject.selamode.modeid][cur_position]) {
    gamePlayObject.betContent.exitems[gamePlayObject.currplay.methodid][gamePlayObject.selamode.modeid][cur_position] = [];
  }
  if (gamePlayObject.betContent.exitems[gamePlayObject.currplay.methodid][gamePlayObject.selamode.modeid][cur_position].indexOf(temp.join('|')) > -1) {
    // chaos.fw7.alert(MSG.E_08, MSG.T_00);
    alertObj.content = tran('MSG.E_08');
    alertObj.title = tran('MSG.T_00');
    alertObj.alertType = 'normalAlert';
    alertObj.showAlert = true;
    store.dispatch(UpdateAlert(alertObj));
    selclear(gamePlayObject);
    return;
  }
  gamePlayObject.betContent.exitems[gamePlayObject.currplay.methodid][gamePlayObject.selamode.modeid][cur_position].push(temp.join('|'));
  gamePlayObject.betContent.items.push({
    playName: title,
    numno: nos,
    nums: gamePlayObject.selectItem.count,
    times: gamePlayObject.betContent.times * parseInt(gamePlayObject.betContent.multipleAmountValue),
    modename: gamePlayObject.selamode.name,
    rate: gamePlayObject.selamode.rate,
    mode: gamePlayObject.selamode.modeid,
    money: gamePlayObject.selectItem.money,
    //prize: gamePlayObject.currplay.showprize ? gamePlayObject.betPlayLabel[gamePlayObject.currplay.playId].prize[gamePlayObject.selectItem.curRebate] : undefined,
    poschoose: gamePlayObject.selectItem.position.join(','),
    posidx: cur_position,
    codes: temp.join('|'),
    type: gamePlayObject.currplay.selectarea.type,
    methodId: gamePlayObject.currplay.methodid,
    modeid: gamePlayObject.selamode.modeid,
    description: title + ' ' + nos,
    rebate: gamePlayObject.selectItem.curRebate,
    methodname: gamePlayObject.currplay.methodname,
    basemoney: gamePlayObject.selectItem.basemoney,
  });
  gamePlayObject.betContent.count += gamePlayObject.selectItem.count;
  gamePlayObject.betContent.money += gamePlayObject.selectItem.money;
  gamePlayObject.betContent.basemoney += gamePlayObject.selectItem.basemoney;
  setselplay(gamePlayObject);
  //console.log('addNumber:'+JSON.stringify(gamePlayObject.betContent));
};

//清
const selclear = (gamePlayObject) => {
  gamePlayObject.currplay.selectarea.layout.map((area, i) => {
    area.no.map((e, i) => {
      if (e.no)
        area.selectNums[e.no] = false;
    });
  });
  refreshBet(gamePlayObject);
};

const resultNum = function(playId) {
  let nums = [];
  switch (playId) {
    case 'XX5':
    case 'OX5':
      for (let i = 1; i < 12; i++)
        nums.push((i > 9 ? '' : '0') + i);
      break;
    case 'KW3':
    case 'OK3':
      for (let i = 1; i < 6; i++)
        nums.push(i + '');
      break;
    case 'BSC':
    case 'FTW':
    case 'BRNN':
    case 'PKS':
      for (let i = 1; i < 11; i++)
        nums.push(i + '');
      break;
    case 'LHC':
    case 'LOT':
    case 'UKL':
    case 'VKL':
    case 'TDB':
    case 'SFL':
    case 'PWB':
    case 'MGM':
    case 'VMG':
    case 'VPW':
    case 'JHC':
      for (let i = 1; i < 50; i++)
        nums.push((i > 9 ? '' : '0') + i);
      break;
    case 'YXX':
      for (let i = 1; i < 7; i++)
        nums.push(i + '');
      break;
    default:
      for (let i = 0; i < 10; i++)
        nums.push(i + '');
      break;
  }
  return nums;
};

//初始化玩法
const setselplay = (gamePlayObject) => {
  let postionText = ['万位', '千位', '百位', '十位', '个位'];
  let item = gamePlayObject.currplay;
  gamePlayObject.currplay = item;

  if (gamePlayObject.betPlayLabel && gamePlayObject.betPlayLabel[gamePlayObject.currplay.playId]) {
    let playlabel = gamePlayObject.betPlayLabel[gamePlayObject.currplay.playId];
    if (playlabel.layout) {
      gamePlayObject.currplay.selectarea.layout.map((layout, layoutIndex) => {
        layout.no.map((no, noIndex) => {
          no.tip = playlabel.layout[layoutIndex].no[noIndex].tip;
        });
      });
    }
  }

  gamePlayObject.selBetPosition = [];
  gamePlayObject.curRebate = '0'; // TODO: curRebate where to get
  gamePlayObject.selectItem = {
    content: [],
    money: 0,
    count: 0,
    curRebate: gamePlayObject.curRebate,
    times: 1,
    position: '',
    odds: [],
  };
  gamePlayObject.isSelNumber = false;//重置选择

  if (gamePlayObject.betPlayLabel && gamePlayObject.betPlayLabel[gamePlayObject.currplay.playId]) {
    for (let k in gamePlayObject.betPlayLabel[gamePlayObject.currplay.playId].prize) {
      gamePlayObject.selectItem.curRebate = k;
      break;
    }
    gamePlayObject.selrebate = gamePlayObject.betPlayLabel[gamePlayObject.currplay.playId].prize[gamePlayObject.selectItem.curRebate];
  }
  gamePlayObject.otype = item.selectarea.type;
  if (item.selectarea.type != 'input') {//选择 过滤掉input 玩法
    item.selectarea.hastitle = false;
    for (let i = 0; i < item.selectarea.layout.length; i++) {
      let l = item.selectarea.layout[i];
      if (l.cols > 0)
        item.selectarea.hastitle = true;

      let mod = l.no.length % (l.maxcol);
      if (mod != 0) {
        let fillno = l.maxcol - mod;
        for (let k = 0; k < fillno; k++) {
          l.no.push({ no: '', tip: '' });
        }
      }
      l.selectNums = {};
      l.selectOdds = {};
      l.selectClick = {};
      l.targete = '';
      l.showFastBtn=false;
      l.btnOne = false;
      l.btnTwo = false;
      l.btnThree = false;
      l.btnFour = false;
      l.btnFive = false;
      l.btnSix = false;
    }
  }
  if (item.selectarea.selPosition) {//任选位置
    if(gamePlayObject.game.playId=='V6D'){
      postionText = ['第一位', '第二位', '第三位', '第四位','第五位','第六位'];
    }
    if(gamePlayObject.game.playId=='M4D'){
      postionText = ['千位','百位','十位','个位'];
    }
    let dps = item.selectarea.defaultposition.split('');
    let positionArr = item.selectarea.selPositionArray = [];

    dps.map((v, i) => {
      let d = {
        index: i,
        text: postionText[i],
        sel: v == '1',
      };
      positionArr.push(d);
    });
  }
  // 设置位置
};

const addItem = (gamePlayObject) => {
  let t = [];
  let alertObj = store.getState().alert;
  gamePlayObject.betPlay.map((b, i) => {
    if (b.selectNums) {
      if (b.area.type == 'check' && b.area.checkplay) {
        let snums = [];

        b.area.checkItems.map((c, k) => {
          c.list.map((v, j) => {
            if (v.selected)
              snums.push(v.project);
          });
        });

        let nums = betcommon.ApiAll.getRank(snums.sort(), b.area.checkplay.min);

        nums.map((e, i) => {
          t.push({
            project: e,
            codes: e.replace(/,/g, '&'),
            odds: b.area.checkplay.odds,
            methodId: b.area.checkplay.playId,
            money: gamePlayObject.preAmount*gamePlayObject.betContent.multipleAmountValue,
            methodname: b.area.checkplay.methodName,
            type: b.area.type,
            playName: b.area.checkplay.playName,
            description: b.area.checkplay.playName + ' ' + e,
          });
        });

      } else if (b.area.type == 'check_tab') {
        b.area.layout.map((l, j) => {
          let nums = [];
          let odds = [];
          let methodname = '';
          let playName = '';
          let playId = [];
          l.list.map((e, k) => {
            if (e.selected) {
              nums.push(e.project);
              odds.push(e.odds);
              methodname = e.methodName;
              playName = e.playName;
              playId.push(e.playId);
            }
          });
          let t1 = betcommon.ApiAll.getRank(nums, l.min);
          let t2 = betcommon.ApiAll.getRank(playId, l.min);
          let t3 = betcommon.ApiAll.getRank(odds, l.min);
          t1.map((e, i) => {
            let odds = t3[i].split(',');
            let playId = t2[i].split(',');
            let methodId = playId[0];
            let modds = odds[0];
            for (let k = 1; k < odds.length; k++) {
              if (parseFloat(modds) > parseFloat(odds[k])) {
                modds = odds[k];
                methodId = playId[k];
              }
            }
            t.push({
              project: e,
              codes: e.replace(/,/g, '&'),
              odds: modds,
              methodId: methodId,
              money: gamePlayObject.preAmount*gamePlayObject.betContent.multipleAmountValue,
              methodname: methodname,
              type: b.area.type,
              playName: playName,
              description: playName + ' ' + e,
            });
          });
        });
      } else if (b.area.type == 'odds') {
        b.area.layout.map((e, i) => {
          e.list.map((m, k) => {
            if (m.selected) {
              t.push({
                project: m.project,
                codes: m.project,
                odds: m.odds,
                methodId: m.playId,
                money: gamePlayObject.preAmount*gamePlayObject.betContent.multipleAmountValue,
                methodname: m.methodName,
                type: b.area.type,
                playName: m.playName,
                description: m.playName + ' ' + m.project,
              });
            }
          });
        });
      } else if (b.area.type == 'select') {
        Object.entries(gamePlayObject.selectMap).map((item) => {
          const v = item[0];
          let s = v.nums.join('');
          if (s.length > 0 && v.nums.indexOf(null) == -1) {
            t.push({
              project: v.nums.join(','),
              codes: v.nums.join('&'),
              odds: v.odds,
              methodId: v.playId,
              money: gamePlayObject.preAmount*gamePlayObject.betContent.multipleAmountValue,
              methodname: v.methodName,
              type: b.area.type,
              playName: v.caption,
              description: v.caption + ' ' + v.nums.join(','),
            });
          }
        });
      }
    }
  });

  if (!t.length) {
    // chaos.fw7.alert(MSG.E_21, MSG.T_00);
    alertObj.content = tran('MSG.E_21');
    alertObj.title = tran('MSG.T_00');
    alertObj.alertType = 'normalAlert';
    alertObj.showAlert = true;
    store.dispatch(UpdateAlert(alertObj));
  } else {
    if (!gamePlayObject.preAmount || gamePlayObject.preAmount <= 0) {
      // chaos.fw7.alert(MSG.E_17, MSG.T_00);
      alertObj.content = tran('MSG.E_17');
      alertObj.title = tran('MSG.T_00');
      alertObj.alertType = 'normalAlert';
      alertObj.showAlert = true;
      store.dispatch(UpdateAlert(alertObj));
      return;
    }
    gamePlayObject.orders.items = t;
    let totalMoney = 0;

    t.map((e, i) => {
      let money = parseInt(e.money);
      totalMoney += money;
    });
    gamePlayObject.orders.totalMoney = totalMoney;
    // chaos.fw7.popup('.betitem-menu');
  }
  // console.log('addItem:'+JSON.stringify(gamePlayObject.betPlay));
  // console.log('addItem:'+JSON.stringify(gamePlayObject.betContent));
  // console.log('addItem:'+JSON.stringify(gamePlayObject.orders));
};

// 下注
const betNowClick = (gamePlayObject) => {
  let params = {
    items: [],
    issue: gamePlayObject.game.period.fullIssue,
    times: parseInt(gamePlayObject.betContent.times) * parseInt(gamePlayObject.betContent.multipleAmountValue),
    exitems: [],
    profixs: [],
    count: 0,
    money: 0,
    rate: 1,
    tracetype: 1,
    tracenums: 0,
    tracemoney: 0.0,
    basemoney: 0,
    traces: [],
    isTrace: false,
    isStop: false,   // 追号中奖追停,
    betType: 0, // isChat ? 1 : 0
  };
  let alertObj = store.getState().alert;
  if (!gamePlayObject.selectItem.count) {
    // chaos.fw7.alert(MSG.W_10, MSG.T_00);
    alertObj.content = tran('MSG.W_10');
    alertObj.title = tran('MSG.T_00');
    alertObj.alertType = 'normalAlert';
    alertObj.showAlert = true;
    store.dispatch(UpdateAlert(alertObj));
    return;
  }
  let cur_position = 0;
  if (gamePlayObject.selectItem.position && gamePlayObject.selectItem.position.length > 0) {
    gamePlayObject.selectItem.position.map((n, i) => {
      cur_position += Math.pow(2, 5 - parseInt(n, 10));
    });
  }
  let nos = gamePlayObject.currplay.show_str;
  let title = '[' + tran(gamePlayObject.curtab.playId) + '_' + tran(gamePlayObject.currplay.methodid) + ']';
  let temp = [];
  gamePlayObject.selectItem.content.map((e, i) => {
    nos = nos.replace('X', e.sort(betcommon._SortNum(gamePlayObject)).join(gamePlayObject.currplay.code_sp));
    temp.push(e.sort(betcommon._SortNum(gamePlayObject)).join('&'));
  });
  if (!params.exitems[gamePlayObject.currplay.methodid]) {
    params.exitems[gamePlayObject.currplay.methodid] = [];
  }
  if (!params.exitems[gamePlayObject.currplay.methodid][gamePlayObject.selamode.modeid]) {
    params.exitems[gamePlayObject.currplay.methodid][gamePlayObject.selamode.modeid] = [];
  }
  if (!params.exitems[gamePlayObject.currplay.methodid][gamePlayObject.selamode.modeid][cur_position]) {
    params.exitems[gamePlayObject.currplay.methodid][gamePlayObject.selamode.modeid][cur_position] = [];
  }

  if (params.exitems[gamePlayObject.currplay.methodid][gamePlayObject.selamode.modeid][cur_position].indexOf(temp.join('|')) > -1) {
    // chaos.fw7.alert(MSG.E_08, MSG.T_00);
    alertObj.content = tran('MSG.E_08');
    alertObj.title = tran('MSG.T_00');
    alertObj.alertType = 'normalAlert';
    alertObj.showAlert = true;
    store.dispatch(UpdateAlert(alertObj));
    selclear(gamePlayObject);
    return;
  }
  params.exitems[gamePlayObject.currplay.methodid][gamePlayObject.selamode.modeid][cur_position].push(temp.join('|'));
  params.items.push({
    playName: title,
    numno: nos,
    nums: gamePlayObject.selectItem.count,
    times: params.times,
    modename: gamePlayObject.selamode.name,
    rate: gamePlayObject.selamode.rate,
    mode: gamePlayObject.selamode.modeid,
    money: gamePlayObject.selectItem.money,
   // prize: gamePlayObject.currplay.showprize ? gamePlayObject.betPlayLabel[gamePlayObject.currplay.playId].prize[gamePlayObject.selectItem.curRebate] : undefined,
    poschoose: gamePlayObject.selectItem.position.join(','),
    posidx: cur_position,
    codes: temp.join('|'),
    type: gamePlayObject.currplay.selectarea.type,
    methodId: gamePlayObject.currplay.methodid,
    modeid: gamePlayObject.selamode.modeid,
    description: title + ' ' + nos,
    rebate: gamePlayObject.selectItem.curRebate,
    methodname: gamePlayObject.currplay.methodname,
    basemoney: gamePlayObject.selectItem.basemoney,
  });
  params.count += gamePlayObject.selectItem.count;
  params.money += gamePlayObject.selectItem.money;
  params.basemoney += gamePlayObject.selectItem.basemoney;
  setselplay(gamePlayObject);
  addBetNowPost(params, gamePlayObject);
};

// 机选一注
const randomBetSelect = (isAdd, gamePlayObject) => {
  let alertObj = store.getState().alert;
  if (gamePlayObject.currplay.selectarea.selPosition) {
    let plen = gamePlayObject.currplay.selectarea.defaultposition.replace(/0/g, '').length;
    let sellen = 0;
    gamePlayObject.currplay.selectarea.selPositionArray.map((e, i) => {
      if (e.sel)
        sellen++;
    });
    if (sellen < plen) {
      // alert("最少选择" + plen + "个位置");
      alertObj.content = '最少选择' + plen + '个位置';
      alertObj.title = tran('MSG.T_00');
      alertObj.alertType = 'normalAlert';
      alertObj.showAlert = true;
      store.dispatch(UpdateAlert(alertObj));
      // chaos.fw7.alert("最少选择"+plen+"个位置",MSG.T_00);
      return;
    }
  }
  let layouts = gamePlayObject.currplay.selectarea.layout;
  let rows = gamePlayObject.currplay.show_str.split(',').length;
  let randrow = layouts.length;
  let mapcur = {};
  let exrow = 0;
  // 开始遍历所有按钮, 模拟点击
  let selectnum = [];
  while (gamePlayObject.selectItem.count < 1) {
    let cur = Math.floor(Math.random() * randrow);
    if (mapcur[cur] && exrow < rows) {
      cur = Math.floor(Math.random() * randrow);
      continue;
    }
    mapcur[cur] = [];
    exrow++;
    let layout = layouts[cur];

    for (let i = 0; i < layout.minchosen; i++) {
      let n = null;

      n = getNoSelectNumber(layout, true);
      while (n && selectnum.indexOf(n.no) > -1) {
        n = getNoSelectNumber(layout, true);
      }
      if (n == null) return;
      selectnum.push(n.no);
      selectedNums(layout, n, true, gamePlayObject);
    }

  }
  if (isAdd)//添加到确认投注区
    addBetItem(isAdd, gamePlayObject);
};

//添加号码
const addBetItem = (isrand, gamePlayObject) => {
  let alertObj = store.getState().alert;
  if (!gamePlayObject.selectItem.count) {
    // chaos.fw7.alert(MSG.W_10,MSG.T_00);
    alertObj.content = tran('MSG.W_10');
    alertObj.title = tran('MSG.T_00');
    alertObj.alertType = 'normalAlert';
    alertObj.showAlert = true;
    store.dispatch(UpdateAlert(alertObj));
    return;
  }
  let cur_position = 0;
  if (gamePlayObject.selectItem.position && gamePlayObject.selectItem.position.length > 0) {
    gamePlayObject.selectItem.position.map((n, i) => {
      cur_position += Math.pow(2, 5 - parseInt(n, 10));
    });
  }
  let nos = gamePlayObject.currplay.show_str;
  let title = '[' + tran(gamePlayObject.curtab.playId) + '_' + tran(gamePlayObject.currplay.methodid) + ']';
  let temp = [];
  gamePlayObject.selectItem.content.map((e, i) => {
    nos = nos.replace('X', e.sort(betcommon._SortNum(gamePlayObject)).join(gamePlayObject.currplay.code_sp));
    temp.push(e.sort(betcommon._SortNum(gamePlayObject)).join('&'));
  });
  if (!gamePlayObject.betContent.exitems[gamePlayObject.currplay.methodid]) {
    gamePlayObject.betContent.exitems[gamePlayObject.currplay.methodid] = [];
  }
  if (!gamePlayObject.betContent.exitems[gamePlayObject.currplay.methodid][gamePlayObject.selamode.modeid]) {
    gamePlayObject.betContent.exitems[gamePlayObject.currplay.methodid][gamePlayObject.selamode.modeid] = [];
  }
  if (!gamePlayObject.betContent.exitems[gamePlayObject.currplay.methodid][gamePlayObject.selamode.modeid][cur_position]) {
    gamePlayObject.betContent.exitems[gamePlayObject.currplay.methodid][gamePlayObject.selamode.modeid][cur_position] = [];
  }

  if (gamePlayObject.betContent.exitems[gamePlayObject.currplay.methodid][gamePlayObject.selamode.modeid][cur_position].indexOf(temp.join('|')) > -1) {
    //  chaos.fw7.alert(MSG.E_08,MSG.T_00);
    alertObj.content = tran('MSG.E_08');
    alertObj.title = tran('MSG.T_00');
    alertObj.alertType = 'normalAlert';
    alertObj.showAlert = true;
    store.dispatch(UpdateAlert(alertObj));
    selclear(gamePlayObject);
    return;
  }
  gamePlayObject.betContent.exitems[gamePlayObject.currplay.methodid][gamePlayObject.selamode.modeid][cur_position].push(temp.join('|'));
  gamePlayObject.betContent.items.push({
    playName: title,
    numno: nos,
    nums: gamePlayObject.selectItem.count,
    times: gamePlayObject.betContent.times,
    modename: gamePlayObject.selamode.name,
    rate: gamePlayObject.selamode.rate,
    mode: gamePlayObject.selamode.modeid,
    money: gamePlayObject.selectItem.money,
    //prize: gamePlayObject.currplay.showprize ? gamePlayObject.betPlayLabel[gamePlayObject.currplay.playId].prize[gamePlayObject.selectItem.curRebate] : undefined,
    poschoose: gamePlayObject.selectItem.position.join(','),
    posidx: cur_position,
    codes: temp.join('|'),
    type: gamePlayObject.currplay.selectarea.type,
    methodId: gamePlayObject.currplay.methodid,
    modeid: gamePlayObject.selamode.modeid,
    description: title + ' ' + nos,
    rebate: gamePlayObject.selectItem.curRebate,
    methodname: gamePlayObject.currplay.methodname,
    basemoney: gamePlayObject.selectItem.basemoney,
  });
  gamePlayObject.betContent.count += gamePlayObject.selectItem.count;
  gamePlayObject.betContent.money += gamePlayObject.selectItem.money;
  gamePlayObject.betContent.basemoney += gamePlayObject.selectItem.basemoney;
  setselplay(gamePlayObject);
  //console.log('addBetItem:'+JSON.stringify(gamePlayObject.betContent));
};

const getNoSelectNumber = function(layout) {
  let nums = cloneDeep(layout.no);
  for (let i = nums.length; i--;) {
    let n = nums[i];
    if (!nums[i].no || (nums[i].no && layout.selectNums[n.no])) {
      nums.splice(i, 1);
    }
  }
  if (nums.length == 0) {
    return null;
  } else if (nums.length == 1) {
    return nums[0];
  } else return nums[Math.floor(Math.random() * nums.length)];
};

const addBetNowPost = (param, gamePlayObject) => {
  GameFetcher(window.$gameUrl + '/rest/game/lottery/savebet/' + gamePlayObject.game.playid + '/' + gamePlayObject.game.id, {
    method: 'post',
    headers: {
      'Accept': 'application/json, text/plain, */*',
      'Content-Type': 'application/x-www-form-urlencoded',
    },
    // credentials: 'include',
    body: qs.stringify(param, { allowDots: true }),
  }).then(res => res.json()).then(
    (data) => {
      gamePlayObject.issub = false;
      let popupObj = store.getState().game.popupObj;
      let alertObj = store.getState().alert;
      if (data.status) {
        popupObj.popupType = 'betNowSuccess';
        popupObj.containerType = 'pd-20';
        popupObj.showPopup = true;
        popupObj.betContent = param;
        store.dispatch(updatePopupObj(popupObj));
        getMemberBalance();
        gamePlayObject.game.playtype ? clearBetContent(true, gamePlayObject) : resetForm(true, gamePlayObject);

      } else if (data && data.result && data.result.order && data.result.order.orders && data.result.order.orders.length) {
        let msg = tran('MSG.W_05').replace('[success]', data.result.order.successNum).replace('[fail]', data.result.order.failNum);
        msg += '<div class="floatarea" style="height:100px;overflow-y:auto;margin: 15px 0;">';
        data.result.order.orders.map((item,index)=>{
          msg += '<span style="font-size:14px;margin-bottom:5px;padding-bottom: 10px;display:block;border-bottom: 1px solid #ececec;">'+item +'</span>';
        });
        msg += '</div>';
        let content = tran('MSG.W_07').replace('[content]', msg);
        alertObj.content = content;
        alertObj.alertType = 'confirmAlert';
        alertObj.showAlert = true;
        alertObj.betContent = gamePlayObject;
        store.dispatch(UpdateAlert(alertObj));
        getMemberBalance();
      } else {
        alertObj.content = data && data.message ? (data.message == '用户余额不知' ? tran('MSG.E_11') : data.message ): tran('MSG.E_02');
        alertObj.title = tran('MSG.T_00');
        alertObj.alertType = 'normalAlert';
        alertObj.showAlert = true;
        store.dispatch(UpdateAlert(alertObj));
        getMemberBalance();
      }

      store.dispatch(updateBetRecord(gamePlayObject));
    },
    (error) => {
      gamePlayObject.issub = false;
      store.dispatch(updateBetRecord(gamePlayObject));
    },
  );
};

const resetForm = (close, gamePlayObject) => {
  try{
    gamePlayObject.betPlay.map((e, i) => {
      e.selectNums = 0;
      if (e.area.tabsettting)
        e.area.tabtitle.map((b, t) => {
          b.selected = false;
        });
      if (e.area.type == 'check') {
        e.area.checkplay = null;
        e.area.checkItems.map((c, k) => {
          c.selected = false;
          c.disabled = false;
        });
      } else if (e.area.type == 'select') {
        e.area.oddsLabel.map((v, k) => {
          if (v.label)
            v.label.map((c, x) => {
              c.map((y, z) => {
                y.selected = false;
                y.disabled = false;
              });
            });
          v.nums.map((n, i) => {
            n = null;
          });
        });
      } else {
        e.area.layout.map((l, k) => {
          l.list.map((v, j) => {
            v.selected = false;
            v.disabled = false;
          });
        });
      }
    });
    totalNums(gamePlayObject);
    gamePlayObject.preAmount = '';
    gamePlayObject.betContent.multipleAmountValue = 1;
    if (close) {
      // $rootScope.getUserInfo();
    }
  }catch(error){
    console.error(error);
  }

};

//投注区
const delItem = (item, idx, gamePlayObject) => {
  if (gamePlayObject) {
    if (gamePlayObject.betContent.items.length) {
      if (gamePlayObject.game.playtype == 0) {
        if (gamePlayObject.betContent.exitems[item.methodId][item.codes])
          delete gamePlayObject.betContent.exitems[item.methodId][item.codes];
      } else {
        if (gamePlayObject.betContent.exitems[item.methodId]) {
          if (gamePlayObject.betContent.exitems[item.methodId][item.modeid]) {
            if (gamePlayObject.betContent.exitems[item.methodId][item.modeid][item.posidx]) {
              gamePlayObject.betContent.exitems[item.methodId][item.modeid][item.posidx].splice(gamePlayObject.betContent.exitems[item.methodId][item.modeid][item.posidx].indexOf(item.codes), 1);
            }
          }
        }
      }
      gamePlayObject.betContent.items.splice(idx, 1);
      gamePlayObject.betContent.count -= item.nums;
      gamePlayObject.betContent.money -= item.money;
      gamePlayObject.betContent.basemoney -= item.basemoney;
      if (gamePlayObject.betContent.items.length == 0) {
        gamePlayObject.betContent.isTrace = false;
      }
    }
  }

};

const delOrder = (item, idx, gamePlayObject) => {
  if (gamePlayObject.orders.items.length) {
    gamePlayObject.orders.items.splice(idx, 1);
    gamePlayObject.orders.totalMoney -= item.money;
    gamePlayObject.totalItem -= 1;
  }
};
const clearOrder = (gamePlayObject) => {
  gamePlayObject.orders.items = [];
  gamePlayObject.orders.totalMoney = 0;
};

const addBet = (gamePlayObject) => {
  if (gamePlayObject.issub) return;
  gamePlayObject.issub = true;
  let alertObj = store.getState().alert;

  let thisIssue = gamePlayObject.game.period.fullIssue;
  let nextIssue = null;
  for(let i=0; i<gamePlayObject.issues.length; i++){
     if(thisIssue == gamePlayObject.issues[i].fullIssue){
       nextIssue = gamePlayObject.issues[i+1];
     }
  }
  if (!gamePlayObject.game.period.open || gamePlayObject.game.stop) {
    if (i18next.language == 'th' || i18next.language == 'vi'){
    alertObj.content = tran('game.startDate') + ' ' + tran(gamePlayObject.game.gameName) + ' ' + moment(nextIssue!=null && gamePlayObject.game.closeFlag === -1?nextIssue.startTime:gamePlayObject.game.startTime).format('YYYY-MM-DD HH:mm:ss') ;
    alertObj.isContentHtml = true;
    alertObj.title = tran('MSG.T_00');
    alertObj.alertType = 'normalAlert';
    alertObj.showAlert = true;
    store.dispatch(UpdateAlert(alertObj));
    gamePlayObject.issub = false;
    return;
    } else {
    alertObj.content = tran(gamePlayObject.game.gameName) + ' ' + tran('game.startDate') + ' ' + moment(nextIssue!=null && gamePlayObject.game.closeFlag === -1?nextIssue.startTime:gamePlayObject.game.startTime).format('YYYY-MM-DD HH:mm:ss') ;
    alertObj.isContentHtml = true;
    alertObj.title = tran('MSG.T_00');
    alertObj.alertType = 'normalAlert';
    alertObj.showAlert = true;
    store.dispatch(UpdateAlert(alertObj));
    gamePlayObject.issub = false;
    return;
  }}
  if (gamePlayObject.game.playtype) {//官
    if (!gamePlayObject.betContent.items.length) {
      alertObj.content = tran('MSG.E_09');
      alertObj.title = tran('MSG.T_00');
      alertObj.alertType = 'normalAlert';
      alertObj.showAlert = true;
      store.dispatch(UpdateAlert(alertObj));
      gamePlayObject.issub = false;
      return;
    }

    gamePlayObject.betContent.issue = gamePlayObject.game.period.fullIssue;
    gamePlayObject.betContent.times = gamePlayObject.betContent.times * parseInt(gamePlayObject.betContent.multipleAmountValue);

  } else {
    if (!gamePlayObject.orders || !gamePlayObject.orders.items || !gamePlayObject.orders.items.length) {
      alertObj.content = tran('MSG.E_09');
      alertObj.title = tran('MSG.T_00');
      alertObj.alertType = 'normalAlert';
      alertObj.showAlert = true;
      store.dispatch(UpdateAlert(alertObj));
      gamePlayObject.issub = false;
      return;
    }
    gamePlayObject.orders.issue = gamePlayObject.game.period.fullIssue;
  }

  let inputData = gamePlayObject.game.playtype ? gamePlayObject.betContent : gamePlayObject.orders;
  if (!inputData.pursePlanName) {
    if (inputData.defaultPursePlan) {
      inputData.pursePlanName = inputData.defaultPursePlan;
    } else {
      inputData.pursePlanName = '方案1';
    }
  }

  GameFetcher(window.$gameUrl + '/rest/game/lottery/savebet/' + gamePlayObject.game.playid + '/' + gamePlayObject.game.id, {
    method: 'post',
    headers: {
      'Accept': 'application/json, text/plain, */*',
      'Content-Type': 'application/x-www-form-urlencoded',
    },
    // credentials: 'include',
    body: qs.stringify(inputData, { allowDots: true }),
  }).then(res => res.json()).then(
    (data) => {
      if (gamePlayObject.game.playtype) gamePlayObject.betContent.times = gamePlayObject.betContent.times / parseInt(gamePlayObject.betContent.multipleAmountValue);
      gamePlayObject.issub = false;
      let popupObj = store.getState().game.popupObj;
      let alertObj = store.getState().alert;
      if (data.status) {
        popupObj.popupType = gamePlayObject.isClassicSelBet ? 'showSuccessModal' : 'showSuccess';
        popupObj.showPopup = true;
        popupObj.containerType = gamePlayObject.isClassicSelBet ? 'pd-20' : '';
        popupObj.modalPadding = gamePlayObject.isClassicSelBet ? 'pd-0' : '';
        popupObj.betContent = gamePlayObject.orders;
        store.dispatch(updatePopupObj(popupObj));
        getMemberBalance();
        gamePlayObject.game.playtype ? clearBetContent(true, gamePlayObject) : resetForm(true, gamePlayObject);
        gamePlayObject.isClassicSelBet = false;
      } else if (data && data.result && data.result.order && data.result.order.orders && data.result.order.orders.length) {
        let msg = tran('MSG.W_05').replace('[success]', data.result.order.successNum).replace('[fail]', data.result.order.failNum);
        msg += '<div class="floatarea" style="height:100px;overflow-y:auto;margin: 15px 0;">';
        data.result.order.orders.map((item,index)=>{
          msg += '<span style="font-size:14px;margin-bottom:5px;padding-bottom: 10px;display:block;border-bottom: 1px solid #ececec;">'+item +'</span>';
        });
        msg += '</div>';
        let content = tran('MSG.W_07').replace('[content]', msg);
        alertObj.content = content;
        alertObj.alertType = 'confirmAlert';
        alertObj.showAlert = true;
        alertObj.betContent = gamePlayObject;
        store.dispatch(UpdateAlert(alertObj));
        getMemberBalance();
      } else {
        alertObj.content = data && data.message ? data.message : tran('MSG.E_02');
        alertObj.title = tran('MSG.T_00');
        alertObj.alertType = 'normalAlert';
        alertObj.showAlert = true;
        store.dispatch(UpdateAlert(alertObj));
        getMemberBalance();
      }
      store.dispatch(updateBetRecord(gamePlayObject));
    },
    (error) => {
      store.dispatch(updateBetRecord(gamePlayObject));
    },
  );
};


// 经典点击下注
const classicSelBetClick = function(gamePlayObject) {
  let alertObj = store.getState().alert;
  gamePlayObject.isClassicSelBet = true;
  let t = [];
  gamePlayObject.betPlay.map((b, i) => {
    if (b.selectNums) {
      if (b.area.type == 'check' && b.area.checkplay) {
        let snums = [];

        b.area.checkItems.map((c, k) => {
          c.list.map((v, j) => {
            if (v.selected)
              snums.push(v.project);
          });
        });
        let nums = betcommon.ApiAll.getRank(snums.sort(), b.area.checkplay.min);
        nums.map((e, y) => {
          var bet_items = e.split(",");
          var bet_item = '';
            for (var x=0;x<bet_items.length;x++){
              bet_item += tran(bet_items[x])
            if (y != (bet_items.length-1)) {
              bet_item += ",";
            }
          }
          t.push({
            project: bet_item,
            codes: e.replace(/,/g, '&'),
            odds: b.area.checkplay.odds,
            methodId: b.area.checkplay.playId,
            money: gamePlayObject.preAmount*gamePlayObject.betContent.multipleAmountValue,
            methodname: b.area.checkplay.methodName,
            type: b.area.type,
            playName: b.area.checkplay.playName,
            description: b.area.checkplay.playName + ' ' + e,
          });
        });

      } else if (b.area.type == 'check_tab') {
        b.area.layout.map((l, j) => {
          let nums = [];
          let odds = [];
          let methodname = '';
          let playName = '';
          let playId = [];
          l.list.map((e, k) => {
            if (e.selected) {
              nums.push(e.project);
              odds.push(e.odds);
              methodname = e.methodName;
              playName = e.playName;
              playId.push(e.playId);

            }
          });
          let t1 = betcommon.ApiAll.getRank(nums, l.min);
          let t2 = betcommon.ApiAll.getRank(playId, l.min);
          let t3 = betcommon.ApiAll.getRank(odds, l.min);
          t1.map((e, i) => {
            let odds = t3[i].split(',');
            let playId = t2[i].split(',');
            let methodId = playId[0];
            let modds = odds[0];
            for (let k = 1; k < odds.length; k++) {
              if (parseFloat(modds) > parseFloat(odds[k])) {
                modds = odds[k];
                methodId = playId[k];
              }
            }
            t.push({
              project: e,
              codes: e.replace(/,/g, '&'),
              odds: modds,
              methodId: methodId,
              money: gamePlayObject.preAmount*gamePlayObject.betContent.multipleAmountValue,
              methodname: methodname,
              type: b.area.type,
              playName: playName,
              description: playName + ' ' + e,
            });
          });

        });
      } else if (b.area.type == 'odds') {
        b.area.layout.map((e, i) => {
          e.list.map((m, k) => {
            if (m.selected) {
              t.push({
                project: m.project,
                codes: m.project,
                odds: m.odds,
                methodId: m.playId,
                money: gamePlayObject.preAmount*gamePlayObject.betContent.multipleAmountValue,
                methodname: m.methodName,
                type: b.area.type,
                playName: m.playName,
                description: m.playName + ' ' + m.project,
              });
            }
          });
        });
      } else if (b.area.type == 'select') {
        Object.entries(gamePlayObject.selectMap).map((item) => {
          const v = item[1];
          let s = v.nums.join('');
          if (s.length > 0 && v.nums.indexOf(null) == -1) {
            t.push({
              project: v.nums.join(','),
              codes: v.nums.join('&'),
              odds: v.odds,
              methodId: v.playId,
              money: gamePlayObject.preAmount*gamePlayObject.betContent.multipleAmountValue,
              methodname: v.methodName,
              type: b.area.type,
              playName: v.caption,
              description: v.caption + ' ' + v.nums.join(','),
            });
          }
        });
      }
    }
  });

  if (!t.length) {
    alertObj.content = tran('MSG.E_21');
    alertObj.title = tran('MSG.T_00');
    alertObj.alertType = 'normalAlert';
    alertObj.showAlert = true;
    store.dispatch(UpdateAlert(alertObj));
  } else {
    if (!gamePlayObject.preAmount || gamePlayObject.preAmount <= 0) {
      alertObj.content = tran('MSG.E_17');
      alertObj.title = tran('MSG.T_00');
      alertObj.alertType = 'normalAlert';
      alertObj.showAlert = true;
      store.dispatch(UpdateAlert(alertObj));
      return;
    }
    gamePlayObject.orders.items = t;
    gamePlayObject.orders.totalMoney = 0;
    t.map((e, i) => {
      gamePlayObject.orders.totalMoney += e.money;
    });
    addBet(gamePlayObject);
    // chaos.fw7.popup('.betitem-menu');
  }
};

const preventOverride = (gameBetObject, gameAwardObj) => {
  gameAwardObj.game.playtype = gameBetObject.game.playtype;
  gameAwardObj.game.playid = gameBetObject.game.playid;
};

const updatePosition = (selectedPosition, gamePlayObject) => {
  gamePlayObject.currplay.selectarea.selPositionArray[selectedPosition].sel = !gamePlayObject.currplay.selectarea.selPositionArray[selectedPosition].sel;
};

const changeDays = (input, gamePlayObject) => {
  if (input && !/^[0-9]+$/.test(input)) {
    gamePlayObject.betContent.times = 0;
  } else {
    gamePlayObject.betContent.times = input;
  }
};

const currentSXNumList = () => {
  let numList = ["01","02","03","04","05","06","07","08","09",
		"10","11","12","13","14","15","16","17","18","19",
		"20","21","22","23","24","25","26","27","28","29",
		"30","31","32","33","34","35","36","37","38","39",
		"40","41","42","43","44","45","46","47","48","49"];
  let shengxiaoList = {
			'鼠': 1,
			'牛': 2,
			'虎': 3,
			'兔': 4,
			'龙': 5,
			'蛇': 6,
			'马': 7,
			'羊': 8,
			'猴': 9,
			'鸡': 10,
			'狗': 11,
			'猪': 12,
			1:'鼠',
			2:'牛',
			3:'虎',
			4:'兔',
			5:'龙',
			6:'蛇',
			7:'马',
			8:'羊',
			9:'猴',
			10:'鸡',
			11:'狗',
			12:'猪'
  };
  let todayDate = new Date();
  if(store.getState().memberInfo.serverTime!==undefined && store.getState().memberInfo.serverTime!==null) {
    todayDate = new Date(store.getState().memberInfo.serverTime);
  }
  //let solarDate = Lunar.toSolar(todayDate.getFullYear(), 1,1);
  let currentZodiac=Lunar.toLunar(todayDate.getFullYear(), todayDate.getMonth()+1, todayDate.getDate())[4];
	let zodiacYear=shengxiaoList[currentZodiac];
	let zodiacList=[];
	let currentSXNumList=[];
	zodiacList.push(currentZodiac);
	for(let i=1;i<12;i++){
		zodiacList.push(shengxiaoList[(zodiacYear-i<=0?(12+zodiacYear-i):Math.abs(zodiacYear-i)%12)]);
	}
	for(let i=0;i<numList.length;i++){
		currentSXNumList[numList[i]] = zodiacList[Math.abs(i)%12];
  }
  return currentSXNumList;
};

const preSXNumList = () => {
  let numList = ["01","02","03","04","05","06","07","08","09",
		"10","11","12","13","14","15","16","17","18","19",
		"20","21","22","23","24","25","26","27","28","29",
		"30","31","32","33","34","35","36","37","38","39",
		"40","41","42","43","44","45","46","47","48","49"];
  let shengxiaoList = {
			'鼠': 1,
			'牛': 2,
			'虎': 3,
			'兔': 4,
			'龙': 5,
			'蛇': 6,
			'马': 7,
			'羊': 8,
			'猴': 9,
			'鸡': 10,
			'狗': 11,
			'猪': 12,
			1:'鼠',
			2:'牛',
			3:'虎',
			4:'兔',
			5:'龙',
			6:'蛇',
			7:'马',
			8:'羊',
			9:'猴',
			10:'鸡',
			11:'狗',
			12:'猪'
  };
  let todayDate = new Date();
  if(store.getState().memberInfo.serverTime!==undefined && store.getState().memberInfo.serverTime!==null) {
    todayDate = new Date(store.getState().memberInfo.serverTime);
  }
  let currentZodiac=Lunar.toLunar(todayDate.getFullYear(), todayDate.getMonth()+1, todayDate.getDate())[4];
  let zodiacYear=shengxiaoList[currentZodiac];
	let preZodiacYear=(zodiacYear-1)==0?12:(zodiacYear-1);
	let previousZodiac=shengxiaoList[(zodiacYear-1)==0?12:(zodiacYear-1)];
	let preZodiacList=[];
	let preSXNumList=[];
	preZodiacList.push(previousZodiac);
	for(let i=1;i<12;i++){
		preZodiacList.push(shengxiaoList[(preZodiacYear-i<=0?(12+preZodiacYear-i):Math.abs(preZodiacYear-i)%12)]);
	}
	for(let i=0;i<numList.length;i++){
		preSXNumList[numList[i]] = preZodiacList[Math.abs(i)%12];
	}

  return preSXNumList;
};

const shengxiaoNum = (num,opentime,preSXNum,currentSXNum) => {
  let openTime = opentime.split(" ")[0].split("/");
  let todayDate = new Date();
  if(store.getState().memberInfo.serverTime!==undefined && store.getState().memberInfo.serverTime!==null) {
    todayDate = new Date(store.getState().memberInfo.serverTime);
  }
  let solar = Lunar.toLunar(todayDate.getFullYear(), todayDate.getMonth()+1, todayDate.getDate())[4];
  let openDate = Lunar.toLunar(openTime[0],openTime[1],openTime[2])[4];

  if (solar != openDate) {
      return preSXNum[num];
  }
  else{
      return currentSXNum[num];
  }
};

const getRecommendGames = () => {
  GameFetcher(window.$gameUrl + "/rest/game/lottery/loadRecommendGame", fetchConfig)
    .then(res => res.json())
    .then(
      (response) => {

        if(response.status){
          if (typeof response.result.allRecommendGame !== 'undefined' && response.result.allRecommendGame.length !== 0) {
            store.dispatch(saveRecommendGames(response.result.allRecommendGame));

          }
        }
      }
    )
    .catch((error) => {
      console.error(error);
    });
}

const calOtherAwardResult=(nums,playId) =>{
  let otherAwardResult = [];
  let SDBS = "";
  let Total = 0;
  if(playId === 'BSC'){
    let resultLT = "";
    if(nums.length>1){
      Total = parseInt(nums[0]) + parseInt(nums[1]);
      otherAwardResult.push(Total);
      SDBS = (Total%2)===0?"双":"单";
      otherAwardResult.push(SDBS);
      SDBS = Total >= 12?"大":"小";
      otherAwardResult.push(SDBS);
      SDBS = parseInt(nums[0]) > parseInt(nums[nums.length-1])?"龙":"虎";
      otherAwardResult.push(SDBS);
      SDBS = parseInt(nums[1]) > parseInt(nums[nums.length-2])?"龙":"虎";
      otherAwardResult.push(SDBS);
      SDBS = parseInt(nums[2]) > parseInt(nums[nums.length-3])?"龙":"虎";
      otherAwardResult.push(SDBS);
      SDBS = parseInt(nums[3]) > parseInt(nums[nums.length-4])?"龙":"虎";
      otherAwardResult.push(SDBS);
      SDBS = parseInt(nums[4]) > parseInt(nums[nums.length-5])?"龙":"虎";
      otherAwardResult.push(SDBS);
    }
  }else if(playId === 'SSC'|| playId === 'XX5' || playId === 'KLS'){
    if(nums.length > 4){
      for(let a = 0;a<nums.length;a++){
        Total = Total + parseInt(nums[a]);
      }
      otherAwardResult.push(Total);
      if(playId === 'SSC'|| playId === 'XX5'){
        SDBS = Total===30?"和":(Total%2)===0?"双":"单";
      }else{
        SDBS = Total===84?"和":(Total%2)===0?"双":"单";
      }
        otherAwardResult.push(SDBS);
      if(playId == 'KLS'){
        SDBS = Total===84?"和":Total >= 85?"大":"小";
      }else if(playId === 'XX5'){
        if(Total === 30){
          SDBS = "和";
        }else{
          SDBS = Total > 30?"大":"小";
        }
      }else{
        SDBS = Total >= 23?"大":"小";
      }
        otherAwardResult.push(SDBS);
      if(playId === 'XX5'){
        if(Total === 30){
          SDBS = "和";
        }else{
          SDBS = (Total%10)>=5?"尾大":"尾小";
        }
        otherAwardResult.push(SDBS);
      }
      if(playId === 'SSC'){
        SDBS = parseInt(nums[0]) === parseInt(nums[nums.length-1])?"和":parseInt(nums[0]) > parseInt(nums[nums.length-1])?"龙":"虎";
          otherAwardResult.push(SDBS);
        SDBS = calPlayResult(parseInt(nums[0]),parseInt(nums[1]),parseInt(nums[2]))
          otherAwardResult.push(SDBS);
        SDBS = calPlayResult(parseInt(nums[1]),parseInt(nums[2]),parseInt(nums[3]))
          otherAwardResult.push(SDBS);
        SDBS = calPlayResult(parseInt(nums[2]),parseInt(nums[3]),parseInt(nums[4]))
          otherAwardResult.push(SDBS);
        SDBS = niuCal(nums);
          otherAwardResult.push(SDBS);
      }else{
        SDBS = parseInt(nums[0]) > parseInt(nums[nums.length-1])?"龙":"虎";
          otherAwardResult.push(SDBS);
      }
    }
  }else if(playId === 'KW3'){
    if(nums.length > 2){
      for(let a = 0;a<nums.length;a++){
        Total = Total + parseInt(nums[a]);
      }
      otherAwardResult.push(Total);
      let A = nums[0];
      let B = nums[1];
      let C = nums[2];
      let E = 0;
      E = C - B ==0?1:0;
      E = B - A ==0? ++E : E;
      if(E === 2){
        SDBS = "豹子";
      }else{
        if(Total >= 11){
          SDBS = "大";
        }else{
          SDBS = "小";
        }
      }
      otherAwardResult.push(SDBS);
    }

  }
  return otherAwardResult;
}

const calPlayResult = (a,b,c) =>{
      let E = 0;
      let F = 0;
      let array = [a,b,c];
      for(let i =0;i<array.length;i++){
        for(let x =0;x<array.length;x++){
          if(array[i]>array[x]){
            let temp = array[x];
            array[x] = array[i];
            array[i] = temp;
          }
        }
      }
      let A = array[0];
      let B = array[1];
      let C = array[2];
      let result = "";
      E = C - B ==0?1:0;
      E = B - A ==0? ++E : E;
      E = C - A ==0? E>=1?E:++E:E;
      F = (A - B ==1 || A - B == -1)||(A - B ==9 || A - B == -9)?1:0;
      F = (B - C ==1 || B - C == -1)||(B - C ==9 || B - C == -9)? ++F :F;
      F = (C - A ==1 || C - A == -1)||(C - A ==9 || C - A == -9)? ++F :F;
      if(E === 1) {
          result ="对子";
        }else if(E === 2){
          result ="豹子";
        }else if(F === 1){
          result ="半顺";
        }else if(F === 2){
          result ="顺子";
        }else{
          result ="杂六";
        }
    return result;
}

const niuCal =(num)=>{
    let nums = [];
    for(let a = 0;a<num.length;a++){
    nums[a] = parseInt(num[a]);
    }
    let end = false;
    let niuniu = [];
    let result = "";
    for(let ab = niuniu.length;ab<nums.length;ab++){
    niuniu[ab] = nums[ab];
    }
    for (let i = 0, len1 = nums.length; i < len1; i++) {
    let a2 = nums.concat();
    a2.splice(0, i + 1);
    for (let j = 0, len2 = a2.length; j < len2; j++) {
      let a3 = a2.concat();
      a3.splice(0, j + 1);
      for (let k = 0, len3 = a3.length; k < len3; k++) {
        if((nums[i] + a2[j] + a3[k])%10 == 0){
          for(let l = 0; l < niuniu.length; l++){ 
            if (niuniu[l] === nums[i]) { 
                niuniu.splice(l, 1);
              for(let m = 0; m < niuniu.length; m++){ 
                if (niuniu[m] == a2[j]) { 
                  niuniu.splice(m, 1);
                  for(let n = 0; n < niuniu.length; n++){ 
                    if (niuniu[n] == a3[k]) { 
                      niuniu.splice(n, 1);
                      end = true;
                      break;
                    }
                  }
                }
                if(end){
                  break;
                }
              }
            }
            if(end){
              break;
            }
          }
          let niuniutotal =0;
          for(let cd = 0;cd<niuniu.length;cd++){
            niuniutotal = niuniutotal + niuniu[cd];
          }
          if(niuniutotal%10==0){
            result = "牛牛";
            return result;
          }else{
            result = "牛"+niuniutotal%10;
          }
          end = true;
          return result;
        }
      }
      if(end){
        break;
      }
    }
    if(end){
      break;
    }
    }
    if(!end){
    result = "没牛";
    return result;
    }
}

export {
  selectGameFromJson, initGamePlay, selectedNums,
  selectedItem, setplay, changeTab, selNum,
  addItem, checkBetPlay, checkBetItem, selectBetPlay,
  changeAmode, changeTimes, randomBetSelect,
  betNowClick, selclear, resetForm, delItem,
  delOrder, clearOrder, addBet, clearBetContent,
  classicSelBetClick, preventOverride, selectedConvenient, selAll, selBig, selSmall,
  selDouble, selSingle, selectClear, selectxx, updatePosition,changeDays,shengxiaoNum,currentSXNumList,preSXNumList, changeMultiple,
  getRecommendGames,calOtherAwardResult,calPlayResult,niuCal
};

