import React from 'react';
import { withTranslation } from 'react-i18next';
// import Picker from 'react-mobile-picker';
import Picker from '../MobilePicker';
import { Modal } from 'reactstrap';

class PickerModal extends React.Component {

  state = {
    // numberPickerValue: [1, 2, 3, 4, 5, 6, 7, 8, 9],
    // pickInputValue: '',
  };

  constructor(props) {
    super(props);
  }

  componentDidMount() {

  }

  render() {
    const { t } = this.props;
    return (
      // isOpen={this.props.show}
      //onClick={this.props.complete}
      <Modal isOpen={this.props.pickerShow} toggle={this.props.selectComplete} style={{ margin: '0', top: window.innerHeight - 264,position:'fixed', width:'100%' }} className="borderZero">
        {/* <ModalHeader style={{ fontSize: '20px' }}>  */}
        <div className="" style={{ textAlign: 'right' }}>
          <div className="left"></div>
          <div className="right theme-background-color" style={{ paddingRight: '20px' }}>
            <a style={{ fontSize: 14, lineHeight: '48px', height: '48px', color: 'white' }} onClick={this.props.selectComplete}>{t('complete')}</a>
          </div>
        </div>
        {/* </ModalHeader> */}
        {/* <ModalBody
          style={{ fontSize: '16px', color: '#757575' }}> */}
        <Picker
          optionGroups={this.props.optionGroups}
          valueGroups={this.props.valueGroups}
          onChange={this.props.pickerChange}/>
        {/* </ModalBody> */}
        {/* <ModalFooter>
          <button style={{
              color: '#ff5722',
              outline: '0',
              backgroundColor: 'white',
              fontWeight: 'bold',
              fontSize: '14px',
              border: 0,
          }} onClick={this.selectComplete.bind(this)}>确定
                </button>
      </ModalFooter> */}
      </Modal>
    );
  };
}
export default withTranslation('common')(PickerModal);