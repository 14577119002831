import { initGamePlay, setplay as initClassicChangePlay } from '../services/GameService';

const storeGameObject = (state = { mainGameObj: {}, gameClassicDetails: {}, gameOfficialDetails: {}, gameBetObject: {}, popupObj: {} }, action) => {
    let live = (window.location.href.includes("/live")?true:false);
    if (action.type === 'CHANGE_GAME') {
        let mainGameObj = action.input;
        // default selected game
        let newGameClassicDetails = {};
        let newGameOfficialDetails = {};

        if (mainGameObj.isAllPlayType || mainGameObj.isOnlyOfficial) {
            newGameOfficialDetails.selectedPlayId = mainGameObj.official.list[0].playId; //PL3001
            newGameOfficialDetails.selectedMethodId =mainGameObj.official.list[0].label[0].label[0].methodId //PL3001001001
            newGameOfficialDetails.gameListDetails = mainGameObj.official.list[0]
            newGameOfficialDetails.gameListLabels = [];
            mainGameObj.official.list[0].label.map((item) => {
                newGameOfficialDetails.gameListLabels = newGameOfficialDetails.gameListLabels.concat(item.label);
            })

            newGameOfficialDetails.gameListLabelLabelDetails = mainGameObj.official.list[0].label[0].label[0];
            if (Object.keys(mainGameObj.gameBetObject).length !== 0 && mainGameObj.gameBetObject.constructor === Object) {
                newGameOfficialDetails.selectedGameBetRate = mainGameObj.gameBetObject.official.betPlayLabel[newGameOfficialDetails.gameListLabelLabelDetails.methodid];
            }
        }
        if (mainGameObj.isAllPlayType || mainGameObj.isOnlyClassic) {
            newGameClassicDetails.selectedPlayId = mainGameObj.classic.list[0].playId; //OSC001
            newGameClassicDetails.gameList = mainGameObj.classic.list;
            newGameClassicDetails.currentGameList = mainGameObj.classic.list[0];
            newGameClassicDetails.currentGameListLabel = mainGameObj.classic.list[0].area.layout[0].list[0];
            // newGameClassicDetails.gameListLabels = [];
            // mainGameObj.classic.list[0].area.layout.list.map((item) => {
            //     newGameClassicDetails.gameListLabels = newGameClassicDetails.gameListLabels.concat(item.label);
            // })
        }
        if (window.$mode == 2) {
            if(mainGameObj.isOnlyClassic){
                mainGameObj.selectedType = 'CLASSIC';
            }
            else if(mainGameObj.isOnlyOfficial){
                mainGameObj.selectedType = 'OFFICIAL';
            }else{
                if(mainGameObj.gameBetObject.official?.sitegame.gameMode===0 || mainGameObj.gameBetObject.classic?.sitegame.gameMode ==0){
                    mainGameObj.selectedType = 'OFFICIAL';
                }
                else{
                    mainGameObj.selectedType = 'CLASSIC';
                }
            }
            
        } else {
            if(mainGameObj.isOnlyOfficial){
                mainGameObj.selectedType = 'OFFICIAL';
            } else if (mainGameObj.isOnlyClassic) {
                mainGameObj.selectedType = 'CLASSIC';
            }
            else{
                if(mainGameObj.gameBetObject.official?.sitegame.gameMode===0 || mainGameObj.gameBetObject.classic?.sitegame.gameMode ==0){
                    mainGameObj.selectedType = 'OFFICIAL';
                }
                else{
                    mainGameObj.selectedType = 'CLASSIC';
                }
                if (live) {
                    switch (newGameClassicDetails.selectedPlayId.substring(0,3)) { // splayId
                      case 'O3D':
                      case 'OK3':
                      case 'OK8':
                      case 'OP3':
                      case 'OSC':
                      case 'OSL':
                      case 'OX5':
                      case 'PKS':
                        mainGameObj.selectedType = 'CLASSIC';
                        break;
                      default:
                    }                    
                }
            }
        }

        let game = {
            mainGameObj: mainGameObj,
            gameClassicDetails: newGameClassicDetails,
            gameOfficialDetails: newGameOfficialDetails,
            isChangeGame: true
        }
        return { ...state, mainGameObj: mainGameObj, gameClassicDetails: newGameClassicDetails, gameOfficialDetails: newGameOfficialDetails, gameBetObject: initGamePlay(game) };
    } else if (action.type === 'CHANGE_PLAYID') {
        let newPlayId = action.input;
        let newGameClassicDetails = state.gameClassicDetails;
        let newGameOfficialDetails = state.gameOfficialDetails;
        let newGameBetObject = state.gameBetObject;
        let game = {
            mainGameObj: state.mainGameObj,
            gameClassicDetails: newGameClassicDetails, gameOfficialDetails: newGameOfficialDetails,
            gameBetContent: state.gameBetObject.betContent,
            isChangeSelectType: true,
            prevGameBetObject: state.gameBetObject
        }
        if (state.mainGameObj.selectedType === 'OFFICIAL') {
            newGameOfficialDetails.selectedPlayId = newPlayId;
            newGameOfficialDetails.gameListDetails = state.mainGameObj.official.list.find(item => item.playId == newPlayId )
            newGameOfficialDetails.gameListLabels = [];
            newGameOfficialDetails.gameListDetails.label.map((item) => {
                newGameOfficialDetails.gameListLabels = newGameOfficialDetails.gameListLabels.concat(item.label);
            })
            newGameOfficialDetails.gameListLabelLabelDetails = newGameOfficialDetails.gameListLabels[0]
            newGameOfficialDetails.selectedMethodId = newGameOfficialDetails.gameListLabelLabelDetails.methodId;
            if (Object.keys(state.mainGameObj.gameBetObject).length !== 0 && state.mainGameObj.gameBetObject.constructor === Object) {
                newGameOfficialDetails.selectedGameBetRate = state.mainGameObj.gameBetObject.official.betPlayLabel[newGameOfficialDetails.gameListLabelLabelDetails.methodid];
            }
            newGameBetObject = initGamePlay(game)
        } else if (state.mainGameObj.selectedType === 'CLASSIC') {
            // console.log('classic')
            newGameClassicDetails.selectedPlayId = newPlayId
            newGameClassicDetails.currentGameList = newGameClassicDetails.gameList.find(item => item.playId == newPlayId)
            newGameClassicDetails.gameListLabels = [];
            // newGameClassicDetails.currentGameList.area.layout.map((item) => {
            //     newGameClassicDetails.gameListLabels = newGameClassicDetails.gameListLabels.concat(item.list);
            // })
            // newGameClassicDetails.currentGameListLabel = newGameClassicDetails.gameListLabels[0];
            newGameBetObject.currplay = newGameBetObject.betPlay.find(item => item.playId == newPlayId);
            newGameBetObject.layout = newGameBetObject.currplay.area.layout;
            initClassicChangePlay(game, newGameBetObject);
        }
        // console.log(newGameBetObject, ' newGameBetObject')
        
        
        return { ...state, gameClassicDetails: newGameClassicDetails, gameOfficialDetails: newGameOfficialDetails, gameBetObject: newGameBetObject }
    } else if (action.type === 'CHANGE_METHODID') {
        let newMethodId = action.input;
        let newPlayId = action.input;
        let newGameClassicDetails = state.gameClassicDetails;
        let newGameOfficialDetails = state.gameOfficialDetails;
        if (state.mainGameObj.selectedType === 'OFFICIAL') {
            newGameOfficialDetails.gameListLabelLabelDetails = newGameOfficialDetails.gameListLabels.find(item => item.methodid === newMethodId)
            newGameOfficialDetails.selectedMethodId = newGameOfficialDetails.gameListLabelLabelDetails.methodId;
            if (Object.keys(state.mainGameObj.gameBetObject).length !== 0 && state.mainGameObj.gameBetObject.constructor === Object) {
                newGameOfficialDetails.selectedGameBetRate = state.mainGameObj.gameBetObject.official.betPlayLabel[newGameOfficialDetails.gameListLabelLabelDetails.methodid];
            }

        } else if (state.mainGameObj.selectedType === 'CLASSIC') {
            newGameClassicDetails.gameListLabels = [];
            newGameClassicDetails.currentGameList.area.layout.map((item) => {
                newGameClassicDetails.gameListLabels = newGameClassicDetails.gameListLabels.concat(item.list);
            })
            newGameClassicDetails.currentGameListLabel = newGameClassicDetails.gameListLabels.find(item => item.playId === newPlayId)
        }

        let game = {
            mainGameObj: state.mainGameObj,
            gameOfficialDetails: newGameOfficialDetails, gameClassicDetails: newGameClassicDetails,
            gameBetContent: state.gameBetObject.betContent,
            isChangeSelectType: true,
            prevGameBetObject: state.gameBetObject
        }
        return { ...state, gameOfficialDetails: newGameOfficialDetails, gameClassicDetails: newGameClassicDetails, gameBetObject: initGamePlay(game) }
    } else if (action.type === 'CHANGE_SELECTEDTYPE') {
        let newMainGameObj = state.mainGameObj;
        if (newMainGameObj.selectedType === 'OFFICIAL') {
            newMainGameObj.selectedType = 'CLASSIC';
        } else {
            newMainGameObj.selectedType = 'OFFICIAL';
        }

        let game = {
            mainGameObj: newMainGameObj,
            gameOfficialDetails: state.gameOfficialDetails, 
            gameClassicDetails: state.gameClassicDetails,
            isChangeSelectType: true,
            prevGameBetObject: state.gameBetObject
        }
        return { ...state, mainGameObj: newMainGameObj, gameBetObject: initGamePlay(game) }
    }
    else if (action.type === 'CHANGE_TRACETYPE') {
        let newMainGameObj = state.gameBetObject;
        let newGame = state.mainGameObj;
        if(newGame.selectedType === 'OFFICIAL') {
            if (newMainGameObj.betContent.tracetype === 1) {
                newMainGameObj.betContent.tracetype = 3;
            } else {
                newMainGameObj.betContent.tracetype = 1;
            }
        }else{
            if (newMainGameObj.orders.tracetype === 1) {
                newMainGameObj.orders.tracetype = 3;
            } else {
                newMainGameObj.orders.tracetype = 1;
            }
        }


        return { ...state, gameBetObject: newMainGameObj }
    }else if (action.type === 'CHANGE_ISSTOP'){
        let newMainGameObj = state.gameBetObject;
        let newGame = state.mainGameObj;
        if(newGame.selectedType === 'OFFICIAL'){
            if (newMainGameObj.betContent.isStop === false){
                newMainGameObj.betContent.isStop = true;
            }else{
                newMainGameObj.betContent.isStop = false;
            }
        }else{
            if (newMainGameObj.orders.isStop === false){
                newMainGameObj.orders.isStop = true;
            }else{
                newMainGameObj.orders.isStop = false;
            }
        }

        return { ...state, gameBetObject: newMainGameObj }
    }
    else if (action.type == 'UPDATE_BETRECORD') {
        return { ...state, gameBetObject: action.input}
    } else if (action.type == 'UPDATE_POPUP') {
        return { ...state, popupObj: action.input}
    } else {
        return state
    }
}

const storeLuzituInfo = (state = { luzituInfo: {}}, action) => {
    if (action.type == 'UPDATE_LUZITU') {
        let result = action.input;
        return { ...state, luzituInfo: result };
    }else {
        return state
    }
}

function removeProperty(obj, propertyName) {
  let { [propertyName]: _, ...result } = obj
  return result
}

export { storeGameObject, storeLuzituInfo  }