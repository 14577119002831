import React from 'react';
import { Button, Card, CardBody, Col, Nav, Navbar, NavItem, Row } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import bn from 'utils/bemnames';
import { MdArrowBack, MdKeyboardArrowDown, MdKeyboardArrowUp } from 'react-icons/md';

const bem = bn.create('header');

class Header extends React.Component {

  constructor(props){
    super(props);
    this.state = {
      dataSource: this.props.dataSource,
      result:this.props.result,
      tdb:this.props.tdb,
      tdw:this.props.tdw,
      ydb:this.props.ydb,
      ydw:this.props.ydw,
      twb:this.props.twb,
      tww:this.props.tww,
      lwb:this.props.lwb,
      lww:this.props.lww,
      tmb:this.props.tmb,
      tmw:this.props.tmw,
      lmb:this.props.lmb,
      lmw:this.props.lmw
    };
  }

  goBack = () => {
    this.props.history.goBack();
  };

  render() {
    const { t } = this.props;
    let icon;

    if(this.props.condition) {
      icon = <MdKeyboardArrowUp size={20}/>;
    } else {
      icon = <MdKeyboardArrowDown size={20}/>;
    }

    return (
      <div>
      <Navbar light expand style={{padding:0,height:44}}>
        <Nav navbar className={bem.e('nav-left')} style={{position:'absolute',lineHeight:'44px',left:0,zIndex:9}}>
          <NavItem className="mr-2" style={{flexDirection:'row'}}>
            <Button outline style={{outline:'none',border:0}} onClick={this.goBack}>
              <MdArrowBack size={24} />
            </Button>
          </NavItem>
        </Nav>

        <Nav navbar className={bem.e('nav-left')} style={{margin:'auto',position:'absolute',width:'100%',top:0,textAlign:'center'}}>
          <NavItem>
            <p className="p text-white" style={{marginBottom:0,position:'absolute',top:0,lineHeight:'44px',width:'100%'}}>{t('cashflowrecord')}</p>
          </NavItem>
        </Nav>

        <Nav navbar className={bem.e('nav-left')} style={{position:'absolute',right:10,lineHeight:'44px'}}>
          <NavItem>
            <a style={{outline:'none',border:0,marginBottom:0,color:'#fff',marginRight:5}} className="slide-toggle__toggle" onClick={this.props.onToggle2.bind(this)}>
              <span style={{ paddingLeft: '8px', lineHeight: '20px', fontSize: '12px',color:'#fff' }}>{t(this.props.title)}</span>{icon}
            </a>
          </NavItem>
        </Nav>
      </Navbar>

        <Row style={{zIndex:5,position:'absolute',width:'100%',margin:0, display:this.props.condition?'block':'none'}}>
          <Col lg={12} md={12} sm={12} xs={12} style={{padding:0}}>
                <div className="my-collapsible" style={{backgroundColor:'white'}}>
                    <div className="my-collapsible__content-inner">
                      <Card>
                        <CardBody className="cb-btn">
                          <hr className="hr-text" data-content={t('date')} />

                          <div className="div-btn">
                            <Button className="btn-brh" outline color="danger" onClick={(e)=>{this.props.onClickDateChange(e,"value")}}
                                    value={"0"} style={{color:this.props.tdb,borderColor:this.props.tdw}}>{t('today')}
                            </Button>
                          </div>

                          <div className="div-btn">
                            <Button className="btn-brh" outline color="danger" onClick={(e)=>{this.props.onClickDateChange(e,"value")}}
                                    value={"1"} style={{color:this.props.ydb,borderColor:this.props.ydw}}>{t('yesterday')}
                            </Button>
                          </div>

                          <div className="div-btn">
                            <Button className="btn-brh" outline color="danger" onClick={(e)=>{this.props.onClickDateChange(e,"value")}}
                                    value={"2"} style={{color:this.props.twb,borderColor:this.props.tww}}>{t('thisweek')}
                            </Button>
                          </div>

                          <div className="div-btn">
                          <Button className="btn-brh" outline color="danger" onClick={(e)=>{this.props.onClickDateChange(e,"value")}}
                                  value={"3"} style={{color:this.props.lwb,borderColor:this.props.lww}}>{t('lastweek')}
                          </Button>
                          </div>

                          <div className="div-btn">
                          <Button className="btn-brh" outline color="danger" onClick={(e)=>{this.props.onClickDateChange(e,"value")}}
                                  value={"4"} style={{color:this.props.tmb,borderColor:this.props.tmw}}>{t('thismonth')}
                          </Button>
                          </div>

                          <div className="div-btn">
                          <Button className="btn-brh" outline color="danger" onClick={(e)=>{this.props.onClickDateChange(e,"value")}}
                                  value={"5"} style={{color:this.props.lmb,borderColor:this.props.lmw}}>{t('lastmonth')}
                          </Button>
                          </div>
                        </CardBody>
                      </Card>
                    </div>
                </div>
          </Col>
        </Row>
      </div>
    );
  }
}

export default withTranslation('common')(Header);
