import React from 'react';
import PropTypes from 'utils/propTypes';
import { MdKeyboardArrowRight } from 'react-icons/md';
import BettingRecordModal from './Modal/BettingRecordModal';
import { withTranslation } from 'react-i18next';

const images = require.context('../assets/image/game/gameui', true);

class BettingRecord extends React.Component {

  state = {
    show: false,
    statusStr: this.props.statusStr,
  };

  constructor(props) {
    super(props);
    this.open_popup_toggle = this.open_popup_toggle.bind(this);
  }

  open_popup_toggle = () => () => {
    if (!this.state.show) {
      this.setState({ show: true });
    } else {
      return this.setState({ show: false });
    }
  };

  showAwardResult = (rc) => {
    this.props.showAwardResult(rc);
  }

  getImage(imagewithtype) {
    try {
      return images(imagewithtype);
    } catch (err) {
      // console.error(err);
      return images('./imagenotfound.png');
      //return window.$wgtDomain + '/imagenotfound.png';
    }
  }

  render() {
    const { t } = this.props;
    let live = (window.location.href.includes("/live")?true:false);
    let imageSrc;
		try {
			if(this.props.gamecategory === null || this.props.gamecategory ===1){
        // console.log('live:'+live+':'+this.props.imageSrc+':'+this.props.gameid+':');
        if (live) {
          imageSrc = this.props.imageSrc!=null?this.props.imageSrc:this.getImage(`./live/safe_${this.props.gameid}.png`);
        } else {
          imageSrc = this.props.imageSrc!=null?this.props.imageSrc:this.getImage(`./safe_${this.props.gameid}.png`);
        }
			}else{
        if (live) {
          imageSrc = this.props.imageSrc!=null?this.props.imageSrc:this.getImage(`./live-en/safe_${this.props.gameid}.png`);
        } else {
          imageSrc = this.props.imageSrc!=null?this.props.imageSrc:this.getImage(`./en_safe_${this.props.gameid}.png`);
        }
			}
		} catch (err) {
      console.error('err:'+err+':GameId:'+this.props.gameid);
			imageSrc = this.props.imageSrc!=null?this.props.imageSrc:this.getImage(`./safe_${this.props.gameid}.png`);
		}    
    return (
      this.state.statusDemo === '已派彩' ?
        <>
          <div onClick={this.open_popup_toggle()} className="d-flex justify-content-between"
               style={{ flexDirection: 'row' }}>
            <div style={{ fontSize: '16px', color: 'red', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>{this.props.title}-{this.props.subtitle}</div>
            <div style={{ fontSize: '14px', whiteSpace: 'nowrap', color: live?'white':'#757575' }}>{this.props.date}
              <a style={{ color: live?'white':'#757575' }}>
                <MdKeyboardArrowRight size={22}/>
              </a>
              {this.state.show ?
                <div style={{
                  position: 'fixed',
                  width: '100%',
                  left: 0,
                  top: 0,
                  height: '100vh',
                  background: 'rgba(0,0,0,0.4)',
                  zIndex: 9
                }}/>
                :
                null
              }
              <BettingRecordModal show={this.state.show} open_popup_toggle={this.open_popup_toggle}
                                  item={this.props.item} showAwardResult={this.showAwardResult}/>
            </div>
          </div>

          <div className="d-flex justify-content-between" style={{ flexDirection: 'row' }}>
            <p style={{ fontSize: '14px', marginRight: 5, color: 'red' }}>{t('betting_amount_title')} : {this.props.betamount}</p>
            <p style={{ fontSize: '14px', flex: 1, color: 'red' }}>{t('payout')} : {this.props.betaward}</p>
          </div>
        </>
        : (
          <>
            <div style={{float:'left'}}>
            {this.props.gamecategory === null || this.props.gamecategory ===1 ?
              <img className="rules-img" src={imageSrc} />
                :
              <img className="rules-img" src={imageSrc} />

            }
            </div>
            <div style={{marginLeft:'60px'}}>
            <div onClick={this.open_popup_toggle()} className="d-flex justify-content-between"
                 style={{ flexDirection: 'row' }}>
              <div style={{ fontSize: '16px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>{this.props.title}-{this.props.subtitle}</div>
              <div style={{ fontSize: '14px', whiteSpace: 'nowrap', color: live?'white':'#757575' }}>{this.props.date}
                <a style={{ color: live?'white':'#757575' }}>
                  <MdKeyboardArrowRight size={22}/>
                </a>
                {this.state.show ?
                  <div style={{
                    position: 'fixed',
                    width: '100%',
                    left: 0,
                    top: 0,
                    height: '100vh',
                    background: 'rgba(0,0,0,0.4)',
                    zIndex: 9
                  }}/>
                  :
                  null
                }
                <BettingRecordModal show={this.state.show} open_popup_toggle={this.open_popup_toggle} item={this.props.item} showAwardResult={this.showAwardResult}/>
              </div>
            </div>
            {/* bug 1039 */}
            {((this.props.item.winStatus == 0 && this.props.item.statusName == '个人取消') || this.props.item.billStatus == 0) ?
              <div className="d-flex justify-content-between" style={{ flexDirection: 'row' }}>
                <a style={{ fontSize: '14px'}}>{t('status')} : {t('owncancelled')}</a>
              </div>
              :((this.props.item.remark == '正常' || this.props.item.remark == '重新结算' || this.props.item.remark == '取消结算')  && this.props.item.billStatus !== -1) ?
              this.props.item.winStatus == '0' ?
              <div className="d-flex justify-content-between" style={{ flexDirection: 'row' }}>
                <a style={{ fontSize: '14px'}}>{t('status')} : {t('pending')}</a>
              </div>
              : this.props.item.winStatus == '5' ?
              <div className="d-flex justify-content-between" style={{ flexDirection: 'row' }}>
                <a style={{ fontSize: '14px'}}>{t('status')} : {t('won')}</a>
              </div>
              : this.props.item.winStatus == '1' ?
              <div className="d-flex justify-content-between" style={{ flexDirection: 'row' }}>
              <a style={{ fontSize: '14px'}}>{t('status')} : {t('lost')}</a>
              </div>
              : this.props.item.winStatus == '3' ?
              <div className="d-flex justify-content-between" style={{ flexDirection: 'row' }}>
              <a style={{ fontSize: '14px'}}>{t('status')} : {t('平')}</a>
              </div>
              :
                <div className="d-flex justify-content-between" style={{ flexDirection: 'row' }}>
                  <a style={{ fontSize: '14px'}}>{t('status')} : {t('syscancelled')}</a>
                </div>
                :
                <div className="d-flex justify-content-between" style={{ flexDirection: 'row' }}>
                  <a style={{ fontSize: '14px'}}>{t('status')} : {t('syscancelled')}</a>
                </div>
              }

              {this.props.item.remark == '个人取消' ?
              <div className="d-flex justify-content-between" style={{ flexDirection: 'row' }}>
                <p style={{ fontSize: '14px', marginRight: '5px' }}>{t('betting_amount_title')} : {this.props.betamount}</p>
                <p style={{ fontSize: '14px', flex: 1 }}>{t('payout')} : {this.props.betaward}</p>
              </div>
              : this.props.item.remark != '个人取消' && this.props.item.remark != '正常' && this.props.item.remark != '' ?
                <div className="d-flex justify-content-between" style={{ flexDirection: 'row' }}>
                  <p style={{ fontSize: '14px', marginRight: '5px' }}>{t('betting_amount_title')} : {this.props.betamount}</p>
                  <p style={{ fontSize: '14px', flex: 1 }}>{t('payout')} : {this.props.betaward}</p>
                </div>
                : (
                  <div className="d-flex justify-content-between" style={{ flexDirection: 'row' }}>
                    <p style={{ fontSize: '14px', marginRight: 5 }}>{t('betting_amount_title')} : {this.props.betamount}</p>
                    <p style={{ fontSize: '14px', flex: 1 }}>{t('payout')} : {this.props.betaward}</p>
                  </div>
                )}
            </div>
          </>
        )
    );
  }
}

BettingRecord.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
  subtitle2: PropTypes.string,
  betamount: PropTypes.string,
  betaward: PropTypes.string,
  date: PropTypes.oneOfType([
    PropTypes.string.isRequired,
    PropTypes.date.isRequired,
  ]),
  color: PropTypes.oneOf([
    'dark',
    'dark',
    'dark',
    'dark',
    'dark',
    'dark',
  ]),
};

BettingRecord.defaultProps = {
  title: '',
  subtitle: '',
  subtitle2: '',
  betamount: '',
  betaward: '',
  date: 0,
  color: 'dark',
};

export default withTranslation('common')(BettingRecord);
