import React from 'react';
import { Button, Nav, Navbar, NavItem,} from 'reactstrap';
import { withTranslation } from 'react-i18next';
import bn from 'utils/bemnames';
import { MdArrowBack, } from 'react-icons/md';

const bem = bn.create('header');

class Header extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      toggleEvent: Date.now(),
      condition: false
    };
  }

  onToggle = () => {
    this.setState({
      toggleEvent: Date.now(),
      condition: !this.state.condition
    });
  };

  goBack = () => {
    this.props.history.goBack();
  };

  render() {
    const { t } = this.props;
    return (
      <div>
        <Navbar light expand style={{ padding:0 }}>
          <Nav navbar className={bem.e('nav-left')} style={{ position: 'absolute' }}>
            <NavItem className="mr-2" style={{ flexDirection: 'row' }}>
              <Button outline style={{ outline: 'none', border: 0 }} onClick={this.goBack}>
                <MdArrowBack size={24} />
              </Button>
            </NavItem>
          </Nav>

          <Nav navbar className={bem.e('nav-left')} style={{ margin: 'auto' }}>
            <NavItem>
              <p className="p text-white" style={{ marginBottom: 0,fontSize: 18, lineHeight: '44px' }}>{t('awardCenter')}</p>
            </NavItem>
          </Nav>
        </Navbar>
      </div>
    );
  }
}

export default withTranslation('common')(Header);
