import React, { Component } from 'react'
import {
    Nav,
    Navbar,
    NavItem,
  } from 'reactstrap';
  import { withTranslation } from "react-i18next";
  import bn from 'utils/bemnames';
  
import {MdSignalCellular0Bar, MdSignalCellular1Bar, MdSignalCellular2Bar, MdSignalCellular3Bar, MdSignalCellular4Bar} from 'react-icons/md';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { SaveMemberInfo } from '../../actions/HomePageAction';
import { intToString } from '../../services/HomeService';

const bem = bn.create('header');

const mapDispatchToProps = dispatch => (
	bindActionCreators({
		SaveMemberInfo,
	}, dispatch)
  );

  const mapStateToProps = (state) => {
    return { 
      usercentre: state.usercentre,
      memberInfo: state.memberInfo,
    };
  };

  export const currencyFormatDE = (input) => {
    return (
          ' €' + input
            .toFixed(2) // always two decimal digits
            .replace('.', ',') // replace decimal point character with ,
            .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.')
        )
  };

class HeaderNav extends Component {

  formatNum = input => {
    if (input !== undefined) {
        //return intToString(input, this.props.memberInfo?.MemberInfo?.currAbbr);
        return intToString(input, 0);
    }
  };

  render() {
        const { t, usercentre } = this.props;
        const userDefaultPic = require('assets/image/user-default-pic.png');
        const accLogo = usercentre && usercentre.indexdata && usercentre.indexdata.loginUser && usercentre.indexdata.loginUser.accountLogo;
        let finalAccLogo;
        if (accLogo && accLogo != null) {
          finalAccLogo = window.$gameUrl + window.$uidomain + accLogo;
        } else {
          finalAccLogo = userDefaultPic;
        }
        return (
            <div>
            <Navbar light expand>
                <Nav navbar className="mr-2">
                    <div style ={{display:'flex'}}>
                      {this.props.userImgExist ?
                        <img style={{width:45,borderRadius:'100%',height:45}}src={finalAccLogo} />
                      :
                        <img style={{width:45,borderRadius:'100%',height:45}}src={userDefaultPic} />
                      }

                            <div style={{flexDirection:'row-reverse',color:'#FFFFFF',fontSize:13,paddingLeft:5,alignItems:'center',fontFamily:'Roboto,Noto,Helvetica,Arial,sans-serif'}}>
                                    <span style={{lineHeight:'22.5px'}}>{this.props.loginName}</span>
                                <div>
                                    <i></i>
                                    {/* <NumberFormat value={this.props.balanceMoney} displayType={'text'} thousandSeparator={true} prefix={'￥'}
                                                  renderText={value => <span style={{lineHeight:'22.5px'}}>{value}</span>} fixedDecimalScale={true} decimalScale={2}/> */}
                                    {this.props.memberInfo.MemberInfo.currency === undefined? '￥':this.props.memberInfo.MemberInfo.currency} {this.formatNum(this.props.balanceMoney === undefined ? '0.00':this.props.balanceMoney)}                                                 
                                </div>
                            </div>
                    </div>
                </Nav>

                <Nav navbar className={bem.e('nav-right')}>
                    <NavItem>
                        <div style={{display:'flex',alignItems:'center',flexDirection:"column",marginRight:20,color:'#FFFFFF',width:50}}>
                            <div>
                                {
                                  this.props.networkPing == 0 ?
                                    <MdSignalCellular0Bar size={25}/>
                                    :
                                    this.props.networkPing == 1 ?
                                      <MdSignalCellular1Bar size={25}/>
                                      :
                                      this.props.networkPing == 2 ?
                                        <MdSignalCellular2Bar size={25}/>
                                        :
                                        this.props.networkPing == 3 ?
                                          <MdSignalCellular3Bar size={25}/>
                                          :
                                          this.props.networkPing == 4 ?
                                            <MdSignalCellular4Bar size={25}/>
                                            :
                                            null
                                }

                            </div>
                            <span className="ping">{this.props.ms}</span>
                        </div>
                    </NavItem>
                </Nav>
            </Navbar>
            </div>
        )
    }
}

const ConnectedBalanceMoney = connect(mapStateToProps, mapDispatchToProps)(HeaderNav);
export default withTranslation('common')(ConnectedBalanceMoney)
