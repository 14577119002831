import AwardPage from 'components/AwardPage';
import React from 'react';
import 'cross-fetch/polyfill';
import { connect } from 'react-redux';
import {
  Button,
  Col, DropdownItem, DropdownMenu, DropdownToggle, ListGroup, ListGroupItem, Nav, Navbar, NavItem,
  Row, UncontrolledButtonDropdown,
} from 'reactstrap';
import { withTranslation} from 'react-i18next';
import AwardRecordHeader from '../components/Layout/AwardRecordHeader';
import { withLastLocation } from 'react-router-last-location';
import AwardDetailModal from '../components/Modal/AwardDetailModal';
import i18next from 'i18next';
import { PullToRefresh } from 'react-js-pull-to-refresh';
import "react-datepicker/dist/react-datepicker.css";
import { MdSearch, MdPhotoFilter, MdKeyboardArrowDown, MdArrowBack, MdMoreVert } from 'react-icons/md';
import AwardZhongHePage from '../components/AwardZhongHePage';
import Select from "react-select";
import ReactLoading from 'react-loading';
import bn from '../utils/bemnames';
import AwardDetailPage from '../components/AwardDetailPage';
import { convertByTimeZone } from '../services/HomeService';

let try2 = [];
let awardList = [];
const game_images = require.context('../assets/image/game/', true);
const images = require.context('../assets/image/game/gameui', true);
const bem = bn.create('header');

const mapStateToProps = (state) => {
  return {
    game: state.game,
    home: state.home,
    memberInfo: state.memberInfo,
  };
};

const customStyles = {
  control: (provided, state) => ({
    ...provided,
    background: '#fff',
    borderColor: '#9e9e9e',
    minHeight: '26px',
    boxShadow: state.isFocused ? null : null,
    textAlign: 'left'
  }),

  valueContainer: (provided, state) => ({
    ...provided,
    height: '26px',
    padding: '0 6px'
  }),
  menu: (provided, state) => ({
    ...provided,
    textAlign: 'left'
  }),
  input: (provided, state) => ({
    ...provided,
    margin: '0px',
    paddingTop: '0px'
  }),
  indicatorSeparator: state => ({
    display: 'none',
  }),
  indicatorsContainer: (provided, state) => ({
    ...provided,
    height: '26px',
  }),
};

class DashboardPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      tabLine: "left",
      awardetail: true,
      awardModal: false,
      gameName: '',
      gameId: '',
      top: 20,
      activeIndex: 0,
      issueDate: '',
      values: [],
      selectedValue: '',
      localizeAwards: [],
      zhType: 'VN',
      isClearable: false,
      isDisabled: false,
      isLoading: false,
      isRtl: false,
      isSearchable: false,
      loading: false,
      isVN6DOpen: false,
      isVN5DOpen: false,
      playId: this.props.game.mainGameObj.playId,
      onToggle: false,
      awardList2: false,
      activeCat: [this.props.home.gameList[1].id],
      awardModal2: false,
    };
    this.changeTab = this.changeTab.bind(this);
    this.onPress = this.onPress.bind(this);
    this.handleSearch = this.handleSearch.bind(this);
    this.handleIssueDateChange = this.handleIssueDateChange.bind(this);
    this.handleSelectChange = this.handleSelectChange.bind(this);
  }

  refresh = async () => {
    let promise = new Promise((resolve, reject) => {
      this.componentDidMount();
      setTimeout(() => resolve('done!'), 1000);
    });
  };

  componentDidMount() {
    this.setState({
      loading: true
    });
    if (this.props.lastLocation === null) {
      this.props.history.push('/');
    }
    this.loadData();
  }

  async loadData(){
    let requests = [];
    awardList = [];
    if(this.props.home.gameList.length > 0) {
      let gameId = this.props.home.gameList[0].gameList;
      if (gameId !== undefined) {
        for (let i = 0; i < gameId.length; i++) {
          if(gameId[i].id === 'VN6D'){
            this.setState({isVN6DOpen:true});
            this.setState({zhType:'VN'});
          }
          if(gameId[i].id === 'VN5D'){
            this.setState({isVN5DOpen:true});
            this.setState({zhType:'VN_5D'});
          }
          requests.push(
          fetch(window.$gameUrl + '/rest/game/lottery/loadaward/' + gameId[i].id, {
            method: 'GET',
            headers: new Headers({
              'Accept': 'application/json, text/plain, */*',
              'Content-Type': 'application/x-www-form-urlencoded',
            }),
            // credentials: 'include',
          }).then((response) => response.json())
            .then((response) => awardList.push({
              'result': (response.result.award !== 'undefined' ? response.result.award[0] : ''),
              'gameName': gameId[i].gameName,
              'gameId': gameId[i].id,
              'playId': gameId[i].playId,
			        'gamePictureMod': gameId[i].gamePictureMod
            }))
            .then((response) => {
              return Promise.resolve(true);

            })
            .catch((error) => {
              console.error(error);
              return Promise.resolve(false);
            })
          )
        }
      }
    }
    await Promise.all(requests).then((results)=>{
      // here we have all the results
      this.setState({
        loading: false
      });
    try2 = awardList;
    this.getLocalizeGame(this.state.zhType);
    //const formattedIssueDate = Moment(this.state.issueDate).format("YYYYMMDD");
    this.getLocalizeResult(this.state.selectedValue, this.state.zhType, this.state.issueDate, i18next.language, 10);
  })

  }

  onPress(item, top) {
    fetch(window.$gameUrl + '/rest/game/lottery/loadaward/' + item.gameId + '?top=' + top, {
      method: 'POST',
      headers: new Headers({
        'Accept': 'application/json, text/plain, */*',
        'Content-Type': 'application/x-www-form-urlencoded',
      }),
      // credentials: 'include',
    }).then((response) => response.json())
      .then((response) => {
        this.setState({
          awardetails: response.result,
          awardetail: false,
          awardModal: true,
          gameName: item.gameName,
          playId: item.playId,
          gameId: item.gameId,
          top: this.state.top,
        }, function() {
        });
      })
      .catch((error) => {
        console.error(error);
      });
  }

  onPress2(item, top) {
    if(item.playId === 'V5Z' ||item.playId === 'V6Z'){
      let selectedZhType = '';
      const { t } = this.props;
      if (item.playId === 'V5Z') {
        selectedZhType = 'VN_5D';
      } else if(item.playId === 'V6Z') {
        selectedZhType = 'VN';
      } else {
        selectedZhType = '';
      }
      this.setState({ awardList: [] });
      this.getLocalizeGame(selectedZhType);
      this.getLocalizeResult('', selectedZhType, '', i18next.language, 10);
      this.setState({ zhType: selectedZhType,onToggle: !this.state.onToggle,awardModal2: true});
    }else{
      fetch(window.$gameUrl + '/rest/game/lottery/loadaward/' + item.id + '?top=' + top, {
        method: 'POST',
        headers: new Headers({
          'Accept': 'application/json, text/plain, */*',
          'Content-Type': 'application/x-www-form-urlencoded',
        }),
        // credentials: 'include',
      }).then((response) => response.json())
        .then((response) => {
          this.setState({
            awardetails: response.result,
            awardModal2: true,
            gameName: item.gameName,
            playId: item.playId,
            gameId: item.id,
            top: this.state.top,
            onToggle: !this.state.onToggle,
            zhType:''
          }, function() {
          });
        })
        .catch((error) => {
          console.error(error);
        });
    }

  }

  onPress3(gameId,playId,gameName, top) {
    fetch(window.$gameUrl + '/rest/game/lottery/loadaward/' + gameId + '?top=' + top, {
      method: 'POST',
      headers: new Headers({
        'Accept': 'application/json, text/plain, */*',
        'Content-Type': 'application/x-www-form-urlencoded',
      }),
      // credentials: 'include',
    }).then((response) => response.json())
      .then((response) => {
        this.setState({
          awardetails: response.result,
          awardModal2: true,
          gameName: gameName,
          playId: playId,
          gameId: gameId,
          top: this.state.top
        }, function() {
        });
      })
      .catch((error) => {
        console.error(error);
      });

  }

  AwardDetailToggle = () => {
    this.setState({
      awardModal: !this.state.awardModal,
      awardetail: !this.state.awardetail,
    });
  };

  AwardDetailToggle2 = () => {
    this.setState({
      awardModal2: !this.state.awardModal2,
      onToggle: true
    });
    this.handleChangeIndex(0);
  };

  getGameImage(imagewithtype) {
    try {
      return game_images('./' + imagewithtype);
    } catch (err) {
      console.error(err);
      return window.$wgtDomain + '/imagenotfound.png';
    }
  }

  getLocalizeGame(zongheType) {
    if(this.props.home.gameList.length > 0) {
      let gameVOList = this.props.home.gameList[0].gameList;
      let games = gameVOList.filter(g => g.isCatch && g.isZongHe && g.zongHeType.toUpperCase() === zongheType.toUpperCase()).map(game => {
          return { value: game.id, name: game.gameName };
      });
      this.setState({
        values: games
      });
    }
  };

  selectGameCategory(event){
    let catId = event.currentTarget.getAttribute('catid');
    let tempActiveCat = this.state.activeCat;
    if(tempActiveCat.includes(catId)){
      tempActiveCat = tempActiveCat.filter(item => item !== catId);
    }else{
      tempActiveCat.push(catId);
    }
    this.setState({activeCat : tempActiveCat});
  }

  AwardDetailToggle2 = () => {
    this.setState({
      awardModal2: !this.state.awardModal2,
      onToggle: true
    });
    this.handleChangeIndex(0);
  };

  getLocalizeResult(gameId, zongheType, issue, lang, top) {
    this.setState({
      loading: true
    });
    let initialAwads = [];
    fetch(window.$gameUrl + '/page/chart/localizeresult?gameId=' + gameId + '&zongheType=' + zongheType + '&issue=' + issue + '&lang=' + lang + '&last=' + top, {
      method: 'POST',
      headers: new Headers({
        'Accept': 'application/json, text/plain, */*',
        'Content-Type': 'application/x-www-form-urlencoded',
      }),
      // credentials: 'include',
    }).then(response => {
      return response.json();
    }).then(data => {
      initialAwads = data.result.list.map(award => {
        return {
          name: award.gameName,
          issue: award.gameIssue,
          grand: award.resultAward[0].value,
          first: award.resultAward[1].value,
          second: award.resultAward[2].value,
          third: award.resultAward[3].value,
          four: award.resultAward[4].value,
          five: award.resultAward[5].value,
          six: award.resultAward[6].value,
          seven: award.resultAward[7].value,
          eight: award.resultAward[8] === undefined ? '' : award.resultAward[8].value,
          zongheType: zongheType
        };
      });
      this.setState({
        localizeAwards: initialAwads,
        loading: false
      });
    }).catch(error => {
      console.log(error);
      this.setState({
        loading: false
      });
    });
   // this.props.closeLoadingAction();
  }

  changeTab() {
    let selectedTab = this.state.activeIndex;
    let selectedZhType = '';
    let selectedTabLine = 'left';
    const selectedValues = '';
    const { t } = this.props;
    if (selectedTab === 1) {
      selectedTabLine = 'right';
    } else {
      selectedTabLine = 'left';
    }
    this.setState({ awardList: [] });
    this.getLocalizeGame(selectedZhType);
    //this.getLocalizeResult('', selectedZhType, '', i18next.language, 10);
    this.setState({ tabLine: selectedTabLine, issueDate: '' });
    this.setState({ zhType: selectedZhType });
    var s_value = document.getElementsByClassName("select__single-value");
    if (s_value.length > 0) {
      //console.log('selectedTab:'+selectedTab+':'+s_value[0].textContent);
      s_value[0].textContent = t('全部');
    }
  }

  handleChangeIndex = input => {
    this.state.activeIndex = input;
    if(input === 1){
      this.setState({
        onToggle: true
      });
    }else{
      this.setState({
        onToggle: false
      });
    }
    this.setState({ activeIndex: input }, this.changeTab());
  };

  handleActiveIndexUpdate = (input) => {
    this.state.activeIndex = input;
    this.setState({ activeIndex: input }, this.changeTab());
  };

  handleSearch() {
    //const formattedIssueDate = Moment(this.state.issueDate).format("YYYYMMDD");
    this.getLocalizeResult(this.state.selectedValue, this.state.zhType, this.state.issueDate, i18next.language,10);
  };

  handleIssueDateChange(d) {
    this.setState({ issueDate: d.target.value });
  };

  handleSelectChange(e) {
    this.setState({ selectedValue: e.value });
  };

  render() {
    const { t } = this.props;
    const { activeIndex } = this.state;
    const winHeight = window.innerHeight;
    const modalHeight = winHeight - 44;
    const modalHeight6D = winHeight - 136;

    const modalHeight2 = winHeight - 138;

    let changeGameHeight = window.innerHeight;
    changeGameHeight = changeGameHeight - 93;

    const PullDownContent =
      <div style={{
        background: 'rgb(213, 233, 247)',
        color: 'rgb(42, 144, 215)',
        textAlign: 'center',
        fontSize: 14,
        lineHeight: '32px',
      }}>{t('pull_down_to_refresh')}</div>
    ;
    const ReleaseContent =
      <div style={{
        background: 'rgb(213, 233, 247)',
        color: 'rgb(42, 144, 215)',
        textAlign: 'center',
        fontSize: 14,
        lineHeight: '32px',
      }}>{t('pull_down_to_refresh')}</div>
    ;
    const RefreshContent = ''
    ;

    const getResult = (item) => {
      let aw = item.result.result;
      let res;

      if (item.playId === 'QQC') {
        aw = aw.substring(aw.length - 3, aw.length);
        res = aw.split('');
      } else {
        res = aw.split(',');
      }
      return res;
    };

    const getResult2 = (item) => {
      let aw = this.props.isZongHe && (this.props.playId == 'V6Z' || this.props.playId == 'V5Z') ? item.result_set : item.result;
      let res;

      if(this.props.playId == 'QQC'){
        aw = aw.substring(aw.length-3,aw.length);
        res = aw.split('');
      }else{
        res = aw.split(',');
      }
      return res;
    };

    let dataAttribute = {
      splayid: this.props.splayId,
      playid: this.props.playId,
      id: this.props.id,
    };

    let optionTemplate = [{ value: '', label: t('全部') }];
    this.state.values.map(v => (
      optionTemplate.push({
        value:v.value,
        label:t(v.name)})
      )
    );

    return (
      <>
        <div className="awardcenterpage">
          {this.state.loading === true ?
            <ReactLoading type={'spin'} className={'loadingSpinner'} style={{ width: '49px', height: '49px' }}/>
            : false}
          <AwardRecordHeader {...this.props}/>
          {/*<CardHeader>{t('awardresult')}</CardHeader>*/}
          <Row>
          <Col lg={12} md={12} sm={12} xs={12}>
            <div style={{width:'100%',flexDirection:'row',display:'flex',backgroundColor:'white'}}>
              <div className={this.state.tabLine === "left" ? "award-result-tab active" : "award-result-tab"} onClick={()=>this.handleChangeIndex(0)}>
                <div color="link" outline value={"0"} style={{color:this.state.tabLine === "left" ?'var(--theme-color)':'#000'}}>{t('最新开奖')} </div>
              </div>
              <div className={this.state.tabLine === "right" ? "award-result-tab active" : "award-result-tab"} onClick={()=>this.handleChangeIndex(1)}>
                <div color="link" outline value={"1"}
                        style={{ color: this.state.tabLine === "right" ? 'var(--theme-color)' : '#000' }}>{t('选择彩种')}</div>
              </div>
            </div>
          {this.state.tabLine === "left"?
            <div id="allAward" style={{ overflow: 'auto' }}>
              <PullToRefresh
                pullDownContent={PullDownContent}
                releaseContent={ReleaseContent}
                refreshContent={RefreshContent}
                pullDownThreshold={0}
                onRefresh={this.refresh}
                triggerHeight={this.state.loading ? 0 : 'auto'}
                startInvisible={true}
              >
              <Row style={{ overflow: 'auto', height: `calc(${modalHeight}px - var(--safe-bottom))`, paddingBottom:'80px' }}>
                {try2.map((item, index) => {
                  if (!item.result) {
                    return null;
                  } else {
                    return (
                      <Col lg="4" md="12" sm="12" xs="12" key={index}>
                        <Button disabled={this.state.loading} outline color="link" onClick={() => this.onPress(item, this.state.top)}
                                style={{ padding: 0, border: 0, width: '100%' }}>
                          <React.Fragment>
                            <AwardPage
                              title={t(item.gameName)}
                              description={getResult(item)}
                              issue={i18next.language !== 'zh' ? t('drawno') + item.result.issue : '第' + item.result.issue + '期'}
                              playid={item.playId}
                              gameid={item.gameId}
                              total={getResult(item).reduce((a, b) => parseInt(a) + parseInt(b))}
                              getGameImage={this.getGameImage.bind(this)}
							                gamePictureMod={item.gamePictureMod}
                              gamecategory={this.props.memberInfo.MemberInfo.gamecategory}
                              gamemode={null}
                            />
                          </React.Fragment>
                        </Button>
                      </Col>
                    );
                  }
                })}
              </Row>
              </PullToRefresh>
            </div>
            :
            <div style={{ overflow: 'auto'}}>
              <div style={{display:this.state.onToggle?'block':'none'}}>
                <div className="my-collapsible" style={{
                  position: 'fixed',
                  left: '0px',
                  backgroundColor: 'white',
                  width: '100%',
                  zIndex: 1055,
                }}>
                  <div className="my-collapsible__content" >
                    <div className="my-collapsible__content-inner"
                         style={{ height: `calc(${changeGameHeight}px - var(--safe-bottom))`, overflow: 'auto' }}>
                      {this.props.home.gameList.map((item, index) => {
                          if (index != 0) {
                            let imageUrl;
                            try {
                              if(this.props.memberInfo.MemberInfo.gamecategory === undefined || this.props.memberInfo.MemberInfo.gamecategory === 1 || this.props.memberInfo.MemberInfo.gamecategory === null){
                                imageUrl = images(`./safe_${item.id}.png`)
                              }else{
                                imageUrl = images(`./en_safe_${item.id}.png`);
                              }
                            } catch (ex) {
                              imageUrl = null;
                            }
                            return (
                              <div key={index}>
                                <h3 style={{position:'relative'}} onClick={this.selectGameCategory.bind(this)} type={'changeGameCat'} catid={item.id}>
                                  <img src={imageUrl} type={'changeGameCat'} catid={item.id}/>
                                  <label type={'changeGameCat'} catid={item.id}>{i18next.language != 'zh' ? t(item.id) : item.gameName}</label>
                                  <MdKeyboardArrowDown type={'changeGameCat'} catid={item.id} size={15} className={((this.state.activeCat.includes(item.id)) || (this.state.activeCat === null && index === 1)) ? 'rotate180' : 'rotate0'} style={{position:'absolute', right: '20px'}}/>
                                </h3>
                                <div className="change-game-list">
                                  {item.gameList.map((game, childIndex) =>{
                                      return(
                                        <React.Fragment key={childIndex}>
                                          { this.state.activeCat.includes(item.id) &&
                                            <a style={{ width: i18next.language != 'zh' ? 'calc(50% - 5px)' : '' }}
                                               key={childIndex} {...dataAttribute}
                                               className={this.props.game.mainGameObj.gameName === game.gameName ? 'active' : ''}
                                               id={game.id}
                                               onClick={() => this.onPress2(game, this.state.top)}
                                            >
                                                    <span className="change-game-text" buttontype={'selectGame'}>
                                                      {i18next.language != 'zh' ? t(game.gameName) : game.gameName}
                                                    </span>
                                            </a>
                                          }
                                        </React.Fragment>
                                      )
                                    }
                                  )}
                                </div>
                              </div>
                            );
                          } else {
                            return null;
                          }
                        },
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className="cart-modal" style={{display:!this.state.awardModal2?'none':'block',width:'100%',position:'fixed'}}>
                <div>
                  <Navbar light expand style={{ padding: 0}} className="awardNavbar-theme-white">
                    <Nav navbar className={bem.e('nav-left')} style={{ position: 'absolute' }}>
                      <NavItem className="mr-2" style={{ flexDirection: 'row' }}>
                        <Button outline style={{ outline: 'none', border: 0 }} onClick={this.AwardDetailToggle2.bind(this)}>
                          <MdArrowBack size={24} style={{color:'var(--theme-color)'}}/>
                        </Button>
                      </NavItem>
                    </Nav>

                    <Nav navbar className={bem.e('nav-left')} style={{ margin: 'auto' }}>
                      <NavItem>
                        <p className={'selected-depOptName'} style={{ marginBottom: 0,fontSize: 18, lineHeight: '44px',color:'var(--theme-color)!important' }}>{this.state.zhType ==='VN'?t('越南6D'):this.state.zhType ==='VN_5D'?t('越南5D'):t(this.state.gameName)}</p>
                      </NavItem>
                    </Nav>
                    {this.state.zhType ==='VN_5D' || this.state.zhType ==='VN' ?
                      null
                      :
                      <Nav navbar className={bem.e('nav-left')} style={{position:'absolute',right: 15}}>
                        <NavItem >
                          <UncontrolledButtonDropdown >
                            <DropdownToggle style={{ background:'transparent', border:'transparent',padding: 0}}>
                              <span style={{ paddingLeft: '8px', lineHeight: '20px', fontSize: '12px' }}></span><MdMoreVert size={23} style={{color:'var(--theme-color)'}}/>
                            </DropdownToggle>
                            <DropdownMenu className={'award-dropdown'}>
                              <DropdownItem onClick={() => {this.onPress3(this.state.gameId,this.state.playId,this.state.gameName,20)}} >{i18next.language != 'zh' ? t("20 Record(s)") : "近20期"}</DropdownItem>
                              <DropdownItem onClick={() => {this.onPress3(this.state.gameId,this.state.playId,this.state.gameName,30)}} >{i18next.language != 'zh' ? t("30 Record(s)") : "近30期"}</DropdownItem>
                              <DropdownItem onClick={() => {this.onPress3(this.state.gameId,this.state.playId,this.state.gameName,50)}} >{i18next.language != 'zh' ? t("50 Record(s)") : "近50期"}</DropdownItem>
                              <DropdownItem onClick={() => {this.onPress3(this.state.gameId,this.state.playId,this.state.gameName,70)}} >{i18next.language != 'zh' ? t("70 Record(s)") : "近70期"}</DropdownItem>
                              <DropdownItem onClick={() => {this.onPress3(this.state.gameId,this.state.playId,this.state.gameName,100)}} >{i18next.language != 'zh' ? t("100 Record(s)") : "近100期"}</DropdownItem>
                            </DropdownMenu>
                          </UncontrolledButtonDropdown>
                        </NavItem>
                      </Nav>
                    }
                  </Navbar>
                </div>

                {
                  this.state.zhType === 'VN_5D' || this.state.zhType === 'VN'?
                    <div id="zhongHe" style={{ overflow: 'auto', height:'100%'}}>
                      <div style={{
                        height: '48px',
                        background: 'white',
                        width: '100%',
                        padding: '0 15px',
                        marginBottom: '1px',
                        textAlign: 'center'
                      }}>
                        <div style={{
                          borderRadius: '4px',
                          display: 'inline-block',
                          marginTop: '12px',
                          border: '1px solid #D7D7D7',
                          marginRight: 3,
                        }}>
                          <input type="text" className="input-field" style={{width:'100px'}} placeholder={t('drawno')} value={this.state.issueDate} onChange={this.handleIssueDateChange.bind(this)}/>
                        </div>
                        <div style={{
                          borderRadius: '4px',
                          display: 'inline-block',
                          marginRight: 3,
                          width: 'calc(90% / 3)'
                        }}>
                          <Select className="basic-single" classNamePrefix="select"
                                  defaultValue={optionTemplate[0]}
                                  selected={this.state.selectedValue}
                                  isDisabled={this.state.isDisabled}
                                  isLoading={this.state.isLoading}
                                  isClearable={this.state.isClearable}
                                  isRtl={this.state.isRtl}
                                  isSearchable={this.state.isSearchable}
                                  name="color"
                                  options={optionTemplate}
                                  styles={customStyles}
                                  onChange={this.handleSelectChange}
                          />
                        </div>
                        <div style={{ display: 'inline-flex', padding: '12px 5px 5px 0', paddingLeft: 0 }}>
                          <a style={{
                            padding: '0 15px',
                            color: 'white',
                            background: 'var(--theme-color)',
                            lineHeight: '26px',
                            borderRadius: '4px',
                          }}>
                            <div style={{ fontSize: '12px'}}>
                              <button style={{color:'#fff'}} onClick={this.handleSearch}>
                                <MdSearch size={20}/>{t('lowerreportdetaillistpage.search')}
                              </button>
                            </div>
                          </a>
                        </div>
                      </div>
                      {this.state.localizeAwards.length > 0 ?
                        <Row style={{ overflow: 'auto', height: `calc(${modalHeight6D}px - var(--safe-bottom))`, paddingBottom:'40px' }}>
                          {this.state.localizeAwards.map((item, index) => {
                            return (
                              <>
                                <Col lg="4" md="12" sm="12" xs="12">
                                  <Button disabled={this.state.loading} outline color="link"
                                          style={{ padding: 0, border: 0, width: '100%' }}>
                                    <React.Fragment>
                                      <AwardZhongHePage
                                        title={i18next.language !== 'zh' ? t(item.name) : item.name}
                                        issue={i18next.language !== 'zh' ? t('drawno') + item.issue : '第' + item.issue + '期'}
                                        grand={item.grand}
                                        first={item.first}
                                        second={item.second}
                                        third={item.third}
                                        four={item.four}
                                        five={item.five}
                                        six={item.six}
                                        seven={item.seven}
                                        eight={item.eight}
                                        zongheType={item.zongheType}
                                      />
                                    </React.Fragment>
                                  </Button>
                                </Col>
                              </>
                            );
                          })}
                        </Row>
                        : this.state.loading === false ? (
                          <div style={{textAlign:'center',paddingTop: 50,paddingBottom: 50,lineHeight:1}}>
                            <MdPhotoFilter size={110} style={{color:'#ccc'}}/>
                            <div style={{lineHeight:1,color:'#ccc',fontSize:20}}>{t('no_record_found')}</div>
                          </div>
                        ):''}
                    </div>
                    :
                    <Row style={{overflow:'auto',height:`calc(${modalHeight2}px - var(--safe-bottom))`,position:'absolute',width:'100%',margin:0}}>
                      <Col lg={12} md={12} sm={12} xs={12} style={{padding:0}}>
                        <ListGroup>
                          {this.state.awardetails?
                            this.state.awardetails.award.map((item, parentIndex) => {
                              return (
                                <React.Fragment key={parentIndex}>
                                  <ListGroupItem style={{ border: 0, padding: 2, paddingLeft: 15, paddingTop: 14, paddingBottom: 0,borderRadius:0 }}>
                                    <AwardDetailPage
                                      title={i18next.language != 'zh' ? t('drawno') + item.issue : "第" + item.issue + "期"}
                                      description={getResult2(item)}
                                      right={convertByTimeZone(item.resultDate, 'MM-DD HH:mm')}
                                      playid={this.state.playId} parentindex={parentIndex}
                                      total={getResult2(item).reduce((a, b) => parseInt(a) + parseInt(b))}
                                    />
                                  </ListGroupItem>
                                </React.Fragment>
                              )
                            })
                            : null
                          }
                        </ListGroup>
                      </Col>
                    </Row>
                }

              </div>
            </div>

          }
          </Col>
          </Row>
        </div>
        <AwardDetailModal AwardDetailToggle={this.AwardDetailToggle} onPress={this.onPress}
                          awardetails={this.state.awardetails}
                          gameName={t(this.state.gameName)}
                          gameId={this.state.gameId} playId={this.state.playId}
                          awardModal={this.state.awardModal} {...this.props}/>
                          
      </>
    );
  }

}

const DashboardPage2 = connect(mapStateToProps)(DashboardPage);
export default withLastLocation(withTranslation('common')(DashboardPage2));
