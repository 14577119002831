import { STATE_LOGIN, STATE_SIGNUP } from 'components/AuthForm';
import { EmptyLayout, LayoutRoute, MainLayout } from 'components/Layout';
import PageSpinner from 'components/PageSpinner';
import React, { useEffect, Suspense, lazy } from 'react';
import componentQueries from 'react-component-queries';
import { HashRouter, Redirect, Route, Switch, useHistory } from 'react-router-dom';
import './styles/reduction.scss';
import { options } from 'utils/options';
import { store } from './index';
import Fetcher from './components/Fetcher';
import GameFetcher from './components/GameFetcher';
import { LastLocationProvider } from 'react-router-last-location';
import * as actionType from './actions/ActionType';
import CryptoJS from 'crypto-js';
import i18next from 'i18next';
import storage from 'redux-persist/lib/storage';
import { isMobile } from 'react-device-detect';
import LinesDisplayModal from './components/Modal/LinesDisplayModal';
import { getCustomColor } from './utils/colors.js';
import { saveIndexInit, SetAccountConfigsDefault,saveGameListPage,SaveMemberInfo } from './actions/HomePageAction';
import { saveNewAccConfigsService, checkThridLogin, getLang, getSiteCurrency, getGaiaSiteCurrency, fbAPI, sha256 } from './services/HomeService';
// import abc from '../src/assets/BGM.mp3'
import countdownbgm from '../src/assets/countdown.mp3'
import fetchIntercept from 'fetch-intercept';
import { useLocation } from 'react-router-dom'
import { alert } from './services/AlertService';
import { TimeoutPage, BlockPage, MaintenancePage, LoginExpiredPage, Error404Page, StopSitePage} from 'pages/Stopuser';
import NoticeModal from './components/Modal/NoticeModal';
import VoucherNoticeModal from './components/Modal/VoucherNoticeModal';
import AlertModal from './components/Modal/AlertModal';
import LoginModal from './components/Modal/LoginModal';
import RegisterPage from './components/Modal/RegisterPage';
import ForgotPasswordPage from './components/Modal/ForgotPasswordPage';
import SecurityResetPage from './components/Modal/SecurityResetPage';
import SafePasswordPage from './components/Modal/SafePasswordPage';
import DashboardMainPage from './pages/DashboardMainPage';
import AwardCenterPage from 'pages/AwardCenterPage';
import LiveresultPage from 'pages/LiveAwardPage';
import BettingRecordPage from 'pages/BettingRecordPage';
import LotteryListPage from 'pages/LotteryListPage';
import Stopuser from 'pages/Stopuser';
import CashFlowPage from 'pages/CashFlowPage';
import { SplashScreen } from '@capacitor/splash-screen';
import { Dialog } from '@capacitor/dialog';
import { App } from '@capacitor/app';
import { CapacitorUpdater } from 'capacitor-updater';
import { Http } from '@capacitor-community/http';
import { Device } from '@capacitor/device';
import i18n from 'i18next';
import { Plugins } from '@capacitor/core';
const { ExitAppIosPlugin } = Plugins;

let lastRequestTime = null;
let isLiveResultDisplay = window.location.hash?(window.location.hash == '#/liveresult'):false;
let isMiniGameDisplay = window.location.hash?(window.location.hash.indexOf('/minigame?gameName')!=-1):false;
let hashStr = window.location.hash?window.location.hash:"";

if (window.$mode === 1) {
  const windowURL = window.location.href;
  // console.log('live windowURL:'+(windowURL.includes('livemode=true'))+':hash:'+isLiveResultDisplay+':');
  if (windowURL.includes('livemode=true') && isLiveResultDisplay) { // for push award result
  } else {
    fetchIntercept.register({
      request: async function (url, config) {
        if (!url.includes('/rest/member/myinfo') && (Date.now() - lastRequestTime > 60000)) {
          lastRequestTime = Date.now();
          let memberInfoResult = await GameFetcher(window.$gameUrl + "/rest/member/myinfo", {
            method: "Get",
            headers: {
              'Accept': 'application/json, text/plain, */*',
              'Content-Type': 'application/x-www-form-urlencoded',
            },
            credentials: 'include'
          });
          memberInfoResult = await memberInfoResult.json();
          if (memberInfoResult && memberInfoResult.status) {
            if (memberInfoResult.result.useStatus !== 0) {
              window.location = "/stopuser";
            }
          }
        }
        return [url, config];
      },

      response: async function (response) {
        const responseResult = await response.clone().json();
        if (responseResult && (responseResult.code === 401)) {
          window.location.href = window.location.href.split('#')[0] + '#/timeoutPage';
        } else if (responseResult && (responseResult.code === 500)) {
          window.location.href = window.location.href.split('#')[0] + '#/blockPage';
        } else {
          return response;
        }
      },
    });
  }
}

if (window.$mode === 2) {
  fetchIntercept.register({
    response: async (response) => {
      const responseResult = await response.clone().text();
      if (responseResult && !response.url.includes('.js') && (responseResult.includes('/static/img/maintenance.gif')||responseResult.includes('/static/404/img/maintenance_2.png'))) {
        let firstNo = responseResult.search('<span class="time">')+19;
        let length = responseResult.search('</span>') - firstNo;
        const time = responseResult.substr(firstNo, length);
        store.dispatch({
          type: 'SET_MAINTAIN_TIME',
          input: time,
        });
        window.location.href = window.location.href.split('#')[0] + '#/maintenancePage';
        return { json: () => { return 'MAINTAIN'; } };
      }
      else if (responseResult && !response.url.includes('.js') && responseResult.includes('not_ok')) {
        window.location.href = window.location.href.split('#')[0] + '#/blockPage';
        return "not_ok";
      }
      else if (responseResult && !response.url.includes('.js') && responseResult.includes('无法显示此网页')) {
        window.location.href = window.location.href.split('#')[0] + '#/error404Page';
        return { json: () => { return 'MAINTAIN'; } };
      }
      else if (responseResult && !response.url.includes('.js') && responseResult.includes('站点停用')) {
        window.location.href = window.location.href.split('#')[0] + '#/stopSitePage';
        return { json: () => { return 'MAINTAIN'; } };
      }
      else {
        return response;
      }
    },
  });
}

// GameUI
const AuthModalPage = lazy(() => import('pages/AuthModalPage'));
const ClearCachePage = lazy(() => import('pages/ClearCachePage'));
const RefreshPage = lazy(() => import('pages/RefreshPage'));
const GamePage = lazy(() => import('pages/GamePage'));
const UnityGamePage = lazy(() => import('pages/UnityGamePage'));
const MiniGameLandingPage = lazy(() => import('pages/MiniGameLandingPage'));

// added
const GameRulesPage = lazy(() => import('pages/GameRulesPage'));
const AwardDescriptionsPage = lazy(() => import('pages/AwardDescriptionsPage'));
const LongQueenPage = lazy(() => import('pages/LongQueenPage'));
const DescriptionPage = lazy(() => import('pages/LQDescription'));
const GameLanguageSwitcher = lazy(() => import('components/GameLanguageSwitcher'));
// end added

// GaiaUI added
const LoginPage = lazy(() => import('pages/LoginPage'));
const AuthPage = lazy(() => import('pages/AuthPage')); // sharing
const ThirdLoginPage = lazy(() => import('pages/ThirdLoginPage'));

const GameListPage = lazy(() => import('pages/GameListPage'));
const GameHallPage = lazy(() => import('pages/GameHallPage')); // sharing

const GaiaMainPage = lazy(() => import('pages/GaiaMainPage'));
const NewApiHomePage = lazy(() => import('pages/NewApiHomePage'));
const GreenMainPage = lazy(() => import('pages/GreenMainPage'));
const VietnamLotteryMainPage = lazy(() => import('pages/VietnamLotteryMainPage'));
const GoldMainPage = lazy(() => import('pages/GoldMainPage'));
const SportMainPage = lazy(() => import('pages/SportMainPage'));
const BlueMainPage = lazy(() => import('pages/BlueMainPage'));
const BlueAllGames = lazy(() => import('pages/BlueAllGames'));
const CPMainPage = lazy(() => import('pages/CPMainPage'));
const OldYBMainPage = lazy(() => import('pages/OldYBMainPage'));
const RoyalMainPage = lazy(() => import('pages/RoyalMainPage'));
const SodoMainPage = lazy(() => import('pages/SodoMainPage'));
const MinigameMainPage = lazy(() => import('pages/MinigameMainPage'));
const CP98MainPage = lazy(() => import('pages/CP98MainPage'));
const Bet365MainPage = lazy(() => import('pages/Bet365MainPage'));

const GaiaAwardCenterPage = lazy(() => import('pages/GaiaAwardCenterPage'));
const PromotionPage = lazy(() => import('pages/PromotionPage'));
const KetQuayPage = lazy(() => import('pages/KetQuayPage'));
const CustomerServicePage = lazy(() => import('pages/CustomerServicePage'));
const UserCentrePage = lazy(() => import('pages/UserCentrePage'));
const ZhongheUserCentrePage = lazy(() => import('pages/ZhongheUserCentrePage'));
const DownloadPage = lazy(() => import('pages/DownloadPage'));
const DownloadWebclipPage = lazy(() => import('pages/DownloadWebclipPage'));
const UpdatePage = lazy(() => import('pages/UpdatePage'));

const GaiaBetPurseRecordsPage = lazy(() => import('pages/GaiaBetPurseRecordsPage'));
const LongQueenPageGaia = lazy(() => import('pages/LongQueenPageGaia'));
const OfflineDrawPage = lazy(() => import('pages/MemberCenter/BankTrade/OfflineDrawPage'));
const OfflinePayInfoPage = lazy(() => import('pages/MemberCenter/BankTrade/OfflinePayInfoPage'));
const RechargeIndexPage = lazy(() => import('pages/MemberCenter/BankTrade/RechargeIndexPage'));
const OnlinePayInfoPage = lazy(() => import('pages/MemberCenter/BankTrade/OnlinePayInfoPage'));
const DepositResultPage = lazy(() => import('pages/MemberCenter/BankTrade/DepositResultPage'));
const CheckRecordPage = lazy(() => import('pages/MemberCenter/BankTrade/CheckRecordPage'));

const RankingPage = lazy(() => import('pages/RankingPage'));
const MessagePage = lazy(() => import('pages/MemberCenter/MessagePage'));
const LeaveMessagePage = lazy(() => import('pages/MemberCenter/LeaveMessagePage'));
const DescriptionGaia = lazy(() => import('pages/LQDescriptionGaia'));
const MoneyConversionPage = lazy(() => import('pages/MemberCenter/MoneyConversionPage'));
const CheckRebatePage = lazy(() => import('pages/CheckRebatePage'));
const SignInRecordPage = lazy(() => import('pages/MemberCenter/SignInRecordPage'));
const SignInRulePage = lazy(() => import('pages/MemberCenter/SignInRulePage'));
const DetailEditPage = lazy(() => import('pages/MemberCenter/DetailEditPage'));
const MyServicePage = lazy(() => import('pages/MemberCenter/MyServicePage'));

const MyBankPage = lazy(() => import('pages/MemberCenter/MyBankPage'));
const MyBankAddPage = lazy(() => import('pages/MemberCenter/MyBankAddPage'));
const MyCryptocurrencyAddPage = lazy(() => import('pages/MemberCenter/MyCryptocurrencyAddPage'));
const MyWalletAddPage = lazy(() => import('./pages/MemberCenter/MyWalletAddPage'));
const CashVoucher = lazy(() => import('pages/MemberCenter/CashVoucherPage'));
const TransactionReportPage = lazy(() => import('pages/TransactionRecordPage'));
const ProxyCenterPage = lazy(() => import('pages/MemberCenter/ProxyCenterPage'));
const WelfarePage = lazy(() => import('pages/MemberCenter/WelfarePage'));
const AgentRebatePage = lazy(() => import('pages/MemberCenter/AgentRebatePage'));
const GameRebateReportPage = lazy(() => import('pages/MemberCenter/GameRebateReportPage'));
const GameRebateDetailListPage = lazy(() => import('pages/MemberCenter/GameRebateDetailListPage'));
const AgentTeamReportPage = lazy(() => import('pages/MemberCenter/AgentTeamReportPage'));
const TeamIndexPage = lazy(() => import('pages/MemberCenter/TeamIndexPage'));
const LowerReportPage = lazy(() => import('pages/MemberCenter/LowerReportPage'));
const LowerReportDetailListPage = lazy(() => import('pages/MemberCenter/LowerReportDetailListPage'));
const SubAccountPage = lazy(() => import('pages/MemberCenter/SubAccountPage'));
const AgentPromotionPage = lazy(() => import('pages/MemberCenter/AgentPromotion/AgentPromotionPage'));
const PersonalReportPage = lazy(() => import('pages/PersonalReportPage'));
const MyAccountInfoPage = lazy(() => import('pages/MemberCenter/MyAccountInfoPage'));
const PsdConterPage = lazy(() => import('pages/MemberCenter/PsdConterPage'));
const PsdManagePage = lazy(() => import('pages/MemberCenter/PsdManagePage'));
const LockPsdManagePage = lazy(() => import('pages/MemberCenter/LockPsdManagePage'));
const LockPage = lazy(() => import('pages/MemberCenter/LockPage'));
const PsdProtectedPage = lazy(() => import('pages/MemberCenter/PsdProtectedPage'));
const LevelPage = lazy(() => import('pages/MemberCenter/LevelPage'));
const CustomColor = lazy(() => import('components/CustomColor'));
const CustomBetValuesPage = lazy(() => import('pages/MemberCenter/CustomBetValuesPage'));
const CustomBetCountDown = lazy(() => import('components/CustomBetCountDown'));
const GameCustomSettingPage = lazy(() => import('pages/GameCustomSettingPage')); // sharing
const QuestionPage = lazy(() => import('pages/MemberCenter/QuestionPage'));
const ProxyDetailPage = lazy(() => import('pages/MemberCenter/ProxyDetailPage'));
const DocDetailPage = lazy(() => import('pages/MemberCenter/DocDetailPage'));
const GamesRulePage = lazy(() => import('pages/MemberCenter/GamesRulePage'));
const GaiaAwardDescriptionPage = lazy(() => import('pages/GaiaAwardDescriptionPage'));
const SettingPage = lazy(() => import('pages/SettingPage')); // sharing
const GaiaLanguageSwitcher = lazy(() => import('components/GaiaLanguageSwitcher'));
const ResponsiblePage = lazy(() => import('pages/ResponsiblePage'));
const GameBetPage = lazy(() => import('pages/GamePage')); // sharing
const GaiaBettingRecordPage = lazy(() => import('pages/GaiaBettingRecordPage'));
const Trend = lazy(() => import('components/Trend')); // sharing
const IMPage = lazy(() => import('pages/IMPage')); // sharing
const BonusPage = lazy(() => import('pages/BonusPage'));
const RisePage = lazy(() => import('pages/RisePage'));
const YhDetailPage = lazy(() => import('pages/YhDetailPage'));
const EventPage = lazy(() => import('pages/EventPage'));
const OthersEventPage = lazy(() => import('pages/OthersEventPage'));
const PointConvertEventPage = lazy(() => import('pages/PointConvertEventPage'));
const ExternalPage = lazy(() => import('pages/ExternalPage'));
const RegisterSuccessPage = lazy(() => import('components/Layout/RegisterSuccessPage'));

const DoubleVerify = lazy(() => import('pages/MemberCenter/DoubleVerify'));

const IdentityConfirmation = lazy(() => import('pages/MemberCenter/IdentityConfirmation'));
const Content1Page = lazy(() => import('pages/Content1Page'));
const Content2Page = lazy(() => import('pages/Content2Page'));

// end GaiaUI added

// live
const LiveMainPage = lazy(() => import('pages/LiveMainPage'));

const HelpViewPage = lazy(() => import('pages/HelpViewPage'));

const getBasename = () => {
  return `/${process.env.PUBLIC_URL.split('/').pop()}`;
};

const getWgtDetails =  async function (lang) {
  let updateDomainKey = window.$wgtDomain.replace("http://", "").replace("https://", "");
  const options = {
    url: 'https://www.yunbo22.com/'+updateDomainKey+'/lottery_ionic.json?'+Date.now(),
  };

  const response: HttpResponse = await Http.get(options);
  console.log("response:"+response.data.version)

  return response;
}

let hidden = null;
let visibilityChange = null;
if (typeof document.hidden !== 'undefined') { // Opera 12.10 and Firefox 18 and later support
  hidden = 'hidden';
  visibilityChange = 'visibilitychange';
} else if (typeof document.msHidden !== 'undefined') {
  hidden = 'msHidden';
  visibilityChange = 'msvisibilitychange';
} else if (typeof document.webkitHidden !== 'undefined') {
  hidden = 'webkitHidden';
  visibilityChange = 'webkitvisibilitychange';
}

const RouterInterceptor = (props) => {
  const currentLocation = useLocation();
  const history = useHistory();

  useEffect(() => {
    window.$history = history;
    window.$curLocation = currentLocation
  })
  return (
    props.children
  )
}

class LottoApp extends React.Component {
  constructor(props) {
    if (!isMobile && process.env.NODE_ENV === 'production') {
      let memberInfo = store.getState().memberInfo;
      if(memberInfo.token!==undefined && store.getState().memberInfo.token!==null && window.$mode ===2){
        // window.location.href = window.location.protocol + "//" + window.location.host + '/index?gaiatoken='+memberInfo.token;
        window.location.href = window.location.href.split('#')[0]+"#/?gaiatoken="+memberInfo.token;
      }
    }
    if (window.$mode === 2) {
      if (process.env.NODE_ENV === 'development') {
        window.$isApp = false;
         //window.$wgtDomain = 'https://www.ybapi8888.com'; // temporary url
        window.$gameUrl = window.$wgtDomain + "/opengame";
      } else if (process.env.NODE_ENV === 'production' && !window.$isApp) {
        window.$wgtDomain = window.location.protocol + "//" + window.location.host;
        window.$gameUrl = window.$wgtDomain + "/opengame";
      }
    }
    super(props);
    this.state = {
      lang: options[0],
      loggined: false,
      url: window.location.href,
      isReady: false,
      publicChatStatus: 0,
      windowHeight: window.innerHeight,
      windowWidth: window.innerWidth,
      wapThemePath:'',
      isWaitForSocialLogin: false,
    };
    // default to chinese if no cache detected
    if (!['en', 'zh', 'vi', 'th', 'in', 'id', 'pt', 'es'].includes(i18next.language)) {
      console.log("No Cache default to zh");
      i18next.changeLanguage('zh', (err) => {
        if (err) console.log(err);
      })
    }
  }

  componentDidCatch(error, errorInfo) {
    let errorObj = { error: error.toString() + error.stack, errorInfo: errorInfo }
    store.dispatch({ type: 'PUSH_ERROR', input: errorObj })
    store.dispatch({ type: 'SUCCESS_LOAD' });
  }

  componentDidMount() {
    if (window.$isIonic) {
      let content = "width=device-width, initial-scale=1, shrink-to-fit=no, maximum-scale=1, minimum-scale=1, user-scalable=0, minimal-ui, viewport-fit=contain"
      document.querySelector('meta[name="viewport"]').setAttribute("content", content);
    }
    document.addEventListener(visibilityChange, this.handleVisibilityChange, false);
    //console.log("windowURL1::",windowURL);
    const windowURLHash = window.location.hash;
    // console.log('isLiveResultDisplay:'+isLiveResultDisplay+':mode:'+window.$mode+':');
    if (windowURLHash) {
      if (isLiveResultDisplay) {
      } else {
        var urlParams = new URLSearchParams(windowURLHash.replace("#", "?"));
        var at = urlParams.get('access_token');
        // console.log("at", at);
        if (at) {
          this.setState({ isWaitForSocialLogin: true });
          let fbAPI_URL = "https://graph.facebook.com/v13.0/me?fields=id,name,email&access_token=" + at;
          (async () => {
            let fbAPIResult = await fetch(fbAPI_URL, {
              method: 'post',
              headers: new Headers({
                'Accept': 'application/json, text/plain, */*',
                'Content-Type': 'application/x-www-form-urlencoded',
              }),
              responseType: 'json',
              // credentials: 'include',
              referrer: 'h5'
            })
            fbAPIResult = await fbAPIResult.json();
            console.log("fbAPIResult response:",fbAPIResult);
            if (fbAPIResult){
                let fbAPIResult2 = await fbAPI(fbAPIResult);
                console.log("fbAPIResult2 response:",fbAPIResult2);
            }
            this.setState({ isWaitForSocialLogin: false });
          })()
        }
      }
    }

    if (window.location.href.split('#')[1] !== '/'  && window.location.href.split('#')[1]!="/download") { // prevent user to directly browse with route path
      if (!isLiveResultDisplay && !isMiniGameDisplay) {
        window.location.href = window.location.href.split('#')[0] + '#/';
      }
    }

    if (window.$mode === 2) {
      if (window.$isApp && window.$isIonic) {
        SplashScreen.hide();
        let lang = 'zh';

        (async () => {
          const info = await Device.getInfo();
          
          window.$platform = info.platform;
          window.$platformVersion = info.osVersion;
          lang = await getLang();
          console.log("lang:" + lang);

          let msg01 = "数据加载中,请稍候...";
          if (lang == "en") {
            msg01 = "Loading, please wait...";
          } else if (lang == "vi") {
            msg01 = "Đang tải, Vui lòng đợi...";
          } else if (lang == "th") {
            msg01 = "กำลังโหลด, กรุณารอ...";
          } else if (lang == "in") {
            msg01 = "Loading, please wait...";
          } else if (lang == "id") {
            msg01 = "Loading, please wait...";
          } else if (lang == "pt") {
            msg01 = "Loading, please wait...";
          } else if (lang == "es") {
            msg01 = "Loading, please wait...";
          } else if (lang == "fr") {
            msg01 = "Loading, please wait...";
          } else if (lang == "ru") {
            msg01 = "Loading, please wait...";
          }
          this.setState({
            msg01: msg01
          })

          let response = await getWgtDetails(lang);
          console.log(JSON.stringify(response));
          if (response.data) {
            //this.indexInit();
            //this.gaiaUiInit();
            let currentVersionName = await CapacitorUpdater.versionName();
            window.$app_ver = currentVersionName.versionName;
            console.log("currentVersionName:" + currentVersionName.versionName);
            console.log(JSON.stringify(response));
            if (currentVersionName.versionName !== response.data.version) {
              let msg01 = "应用现在的版本可供更新";
              let msg02 = "文件大小";
              let msg03 = "请更新后使用";
              let msg04 = "更新提示";
              let msg05 = "更新";
              let msg06 = "取消";
              let msg07 = "下载失败";
              let msg08 = "确定";
              if (lang == "en") {
                msg01 = "New version is available for update. ";
                msg02 = "File size";
                msg03 = "";
                msg04 = "Update Notice";
                msg05 = "Update";
                msg06 = "Cancel";
                msg07 = "Download failed";
                msg08 = "Confirm";
              }
              else if (lang == "vi") {
                msg01 = "Một phiên bản mới đã sẵn sàng để cập nhật";
                msg02 = "Kích thước tập tin";
                msg03 = "";
                msg04 = "Chú Ý";
                msg05 = "Cập nhật";
                msg06 = "Hủy bỏ";
                msg07 = "Tải xuống thất bại";
                msg08 = "Quyết định";
              }
              else if (lang == "th") {
                msg01 = "เวอร์ชันใหม่พร้อมให้อัปเดต";
                msg02 = "ขนาดไฟล์";
                msg03 = "";
                msg04 = "ประกาศ";
                msg05 = "อัปเดต";
                msg06 = "ยกเลิก";
                msg07 = "ดาวน์โหลดล้มเหลว";
                msg08 = "ยืนยัน";
              }
              else if (lang == "in") {
                msg01 = "New version is available for update. ";
                msg02 = "File size";
                msg03 = "";
                msg04 = "Update Notice";
                msg05 = "Update";
                msg06 = "Cancel";
                msg07 = "Download failed";
                msg08 = "Confirm";
              }
              else if (lang == "id") {
                msg01 = "New version is available for update. ";
                msg02 = "File size";
                msg03 = "";
                msg04 = "Update Notice";
                msg05 = "Update";
                msg06 = "Cancel";
                msg07 = "Download failed";
                msg08 = "Confirm";
              }
              else if (lang == "pt") {
                msg01 = "New version is available for update. ";
                msg02 = "File size";
                msg03 = "";
                msg04 = "Update Notice";
                msg05 = "Update";
                msg06 = "Cancel";
                msg07 = "Download failed";
                msg08 = "Confirm";
              }
              else if (lang == "es") {
                msg01 = "New version is available for update. ";
                msg02 = "File size";
                msg03 = "";
                msg04 = "Update Notice";
                msg05 = "Update";
                msg06 = "Cancel";
                msg07 = "Download failed";
                msg08 = "Confirm";
              }
              else if (lang == "fr") {
                msg01 = "New version is available for update. ";
                msg02 = "File size";
                msg03 = "";
                msg04 = "Update Notice";
                msg05 = "Update";
                msg06 = "Cancel";
                msg07 = "Download failed";
                msg08 = "Confirm";
              }
              else if (lang == "ru") {
                msg01 = "New version is available for update. ";
                msg02 = "File size";
                msg03 = "";
                msg04 = "Update Notice";
                msg05 = "Update";
                msg06 = "Cancel";
                msg07 = "Download failed";
                msg08 = "Confirm";
              }

              const { value } = await Dialog.confirm({
                title: msg04,
                message: msg01 + "(" + msg02 + ": " + response.data.size + "), " + msg03,
                okButtonTitle: msg05,
                cancelButtonTitle: msg06
              });

              // console.log('Confirmed:', value);
              if (value) {
                //SplashScreen.show();
                // console.log("Start Download:"+response.data.version);
                try {
                  var container = document.getElementById('root');
                  container.insertAdjacentHTML('beforeend', '<div class="mybarBackground"><div id="myBar" class="mybar" style="width:0%;"></div><div style="height: 100%;width: 100%;position: fixed;z-index: 150001;"></div></div>');
                  CapacitorUpdater.addListener('download', (info) => {
                    var elem = document.getElementById("myBar");

                    var width = elem.style.width;
                    width = width.substring(0, width.length - 1);
                    elem.style.width = info.percent + "%";
                  });
                  let updateDomainKey = window.$wgtDomain.replace("http://", "").replace("https://", "");
                  const version = await CapacitorUpdater.download({
                    url: 'https://www.yunbo22.com/' + updateDomainKey + '/ionic' + response.data.version + '.wgt',
                  })
                  // console.log("version:"+version);
                  await CapacitorUpdater.set({
                    version: version.version,
                    versionName: response.data.version
                  });

                } catch (e) {
                  console.log("Download Error:" + e);
                  /*const showAlert = await Dialog.alert({
                      title: msg04,
                      message: msg07,
                      buttonTitle:msg08
                    });*/
                  // SplashScreen.hide();
                  if (info.platform === 'android') {
                    App.exitApp();
                  }
                  else {
                    ExitAppIosPlugin.killApp().then();
                  }
                }
                // App.exitApp();
              }
              else {
                // SplashScreen.show();
                if (info.platform === 'android') {
                  App.exitApp();
                }
                else {
                  ExitAppIosPlugin.killApp().then();
                }
              }
            }
            this.indexInit();
            this.gaiaUiInit();
          }
        })()
      }
      else {
        (async () => {
          await this.indexInit();
          this.gaiaUiInit();
        })()
      }
    } else if (window.$mode === 1) {
      this.gameUiInit();
    }
    this.setState({ mode: window.$mode });
  }

  componentWillUnmount() {
    document.removeEventListener(visibilityChange, this.handleVisibilityChange);
  }

  handleVisibilityChange = () => {
    const countdownEl = document.getElementsByClassName("countdown-element")[0];
    const audioEl = document.getElementsByClassName("audio-element")[0];
    let memberInfo = store.getState().memberInfo;
    let checkBackMusic = false;
    let checkCountDown = false;
    if (memberInfo?.UserInfo?.userInfo && memberInfo?.AccountConfigs["accountConfigs" + memberInfo.UserInfo.userInfo]) {
      let userData = "accountConfigs" + memberInfo.UserInfo.userInfo;
      let accountConfigs = memberInfo.AccountConfigs[userData];
      if (accountConfigs.isBackMusic) {
        checkBackMusic = true;
      }
      if (accountConfigs.isCountDownSound) {
        checkCountDown = true;
      }
    }
    if (document[hidden]) {
      if (checkCountDown) if (countdownEl) countdownEl.muted = true;
      if (checkBackMusic) if (audioEl) audioEl.pause();
    } else {
      if (checkCountDown) if (countdownEl) countdownEl.muted = false;
      if (checkBackMusic) if (audioEl) audioEl.play();
    }
  }

  render() {
    // console.log('render:'+(this.state.isReady)+':'+(this.state.isWaitForSocialLogin)+':'+isLiveResultDisplay+':');
    if (isLiveResultDisplay) {
      return this.gameUi();
    } else {
      if (!this.state.isReady || this.state.isWaitForSocialLogin) {
        return <div id="div2" className="load_screen">
          <div className="loading">
            <div className="lds-css ng-scope">
              <div style={{ width: '100%', height: '100%',top:'50px' }} className="lds-double-ring">
                {/* <div style={{ left: '20px', width: '70px', height: '70px' }}></div> */}
                <div style={{ left: '32px', width: '48px', height: '48px' }}></div>
              </div>
            </div>
          </div>
        </div>;
      } else
      if (this.state.mode === 1) {
        return this.gameUi();
      } else if (this.state.mode === 2) {
        return this.gaiaUi();
      }
    }
  }
   indexInit = async () => {
    //indexInit() {
    fetch(window.$wgtDomain + "/checkip", {
      method: 'get',
      headers: new Headers({
        'Accept': 'text/html,application/xhtml+xml,application/xml;q=0.9,image/webp,image/apng,*/*;q=0.8,application/signed-exchange;v=b3;q=0.9'
      }),
      responseType: 'json',
      // credentials: 'include',
      referrer: 'h5'
    })
      .then(
        (result) => {
          if (result === 'not_ok') {
            this.setState({ isReady: true });
          }
        },
        (error) => {
          console.error(error)
        }
      )
    let logoutStatus = false;
    let indexInit = store.getState().indexInit.indexInit;
    // await getLang();
    getLang();
    let urlParameter = this.state.url;
    urlParameter = urlParameter.split('#')[0];
    let vars = {};
    urlParameter.replace(/[?&]+([^=&]+)=([^&]*)/gi, function (m, key, value) {
        vars[key] = value;
    });

    if (typeof vars['gaiatoken'] !== 'undefined') {
      let memberInfo = store.getState().memberInfo;
      memberInfo.token = vars['gaiatoken'];
      store.dispatch(SaveMemberInfo(memberInfo));
    }

    await Fetcher(window.$wgtDomain + "/wap/index-init?ischess=f&theme=lottery", {
      method: 'get',
      headers: {
        'Accept': 'text/html,application/xhtml+xml,application/xml;q=0.9,image/webp,image/apng,*/*;q=0.8,application/signed-exchange;v=b3;q=0.9',
      },
      responseType: 'json',
      // credentials: 'include',
      referrer: 'h5'
    })
      .then(res => res.json())
      .then(
        (result) => {
          if (result === 'MAINTAIN') {
            this.setState({ isReady: true });
          } else {
            document.title = result.wapTitle;
            window.parent.document.title = result.wapTitle;
            this.setState({wapThemePath:result.wapThemePath});
            let gaiaGameList = [];
            result.listResult.map((item) => {
              item.gameList.map((subItem) => gaiaGameList.push(subItem));
            })
            result.gaiaGameList = gaiaGameList;
            store.dispatch(saveIndexInit(result));
            let defaultColor = result.wapThemeColor ? result.wapThemeColor : "red";
            const defaultAccSetting = { defaultColor: defaultColor, defaultBetValues: [1, 5, 10, 15, 20, 50, 100], };
            store.dispatch(SetAccountConfigsDefault(defaultAccSetting));

            this.setState({ isReady: true,publicChatStatus: result.isPublicChatRoomIMOn });
            document.querySelector('meta[name="generator"]').setAttribute("content", result.WEBSITE_TITLE?result.WEBSITE_TITLE:'');
            document.querySelector('meta[name="keywords"]').setAttribute("content", result.WEBSITE_KEYWORDS?result.WEBSITE_KEYWORDS:'');
            document.querySelector('meta[name="description"]').setAttribute("content", result.WEBSITE_DESCRIPTION?result.WEBSITE_DESCRIPTION:'');
            document.querySelector('meta[name="application-name"]').setAttribute("content", result.WEBSITE_TITLE?result.WEBSITE_TITLE:'');
            document.querySelector('meta[property="og:title"]').setAttribute("content", result.WEBSITE_TITLE?result.WEBSITE_TITLE:'');
            document.querySelector('meta[property="og:description"]').setAttribute("content", result.WEBSITE_DESCRIPTION?result.WEBSITE_DESCRIPTION:'');
            if(result.loginUser){
              let memberObj = store.getState().memberInfo.MemberInfo;
              let langid = new Date().getTime();
              let lng = result.loginUser.userLanguage;
              i18n.changeLanguage(lng);
              memberObj.language = lng == "cn" ? "zh" : lng;
              memberObj.langid = langid;
              store.dispatch(SaveMemberInfo(memberObj));
            }

            // if(result?.loginUser === null){
            //     logoutStatus = true;
            //     let memberObj = store.getState().memberInfo.MemberInfo;
            //     memberObj.langid = "";
            //     store.dispatch(SaveMemberInfo(memberObj));
            // }

          }
        },
        (error) => {
          console.error(error)
        }
      )

      let userEnable = indexInit && indexInit.loginUser && indexInit.loginUser.loginName && indexInit.loginUser.email;
      let indexData = store.getState().indexData.indexData;
      let fbEnable = indexData && indexData.socialLoginFacebookAnalyticToken && indexData.socialLoginFacebookPixelId
      if (userEnable && fbEnable) {
        let data = JSON.stringify({
          data: [{
            event_name: "Main",
            event_time: 1668994630,
            action_source: "website",
            user_data: {
              fn: [
                sha256(indexInit.loginUser.loginName)
              ],
              em: [
                sha256(indexInit.loginUser.email)
              ]
            }
          }]
        });
        fetch("https://graph.facebook.com/v15.0/"+indexData.socialLoginFacebookPixelId+"/events?access_token="+indexData.socialLoginFacebookAnalyticToken, {
          method: 'post',
          headers: new Headers({
            'Accept': 'application/json, text/plain, */*',
            'Content-Type': 'application/json',
          }),
          body: data,
          referrer: 'h5'
        }).then(
          (result) => {
            if (result.status === 200) {
              console.log("Successfully Posted Facebook Analytics Report");
            }else{
              console.error("Facebook Analytics Report Error: " + result.status);
            }
          },
          (error) => {
            console.error("Facebook Analytics Report Error: " + error);
          }
        )
      }

    fetch(window.$wgtDomain + '/wap/game-list-page', {
      method: 'get',
      headers: new Headers({
        'Accept': 'text/html,application/xhtml+xml,application/xml;q=0.9,image/webp,image/apng,*/*;q=0.8,application/signed-exchange;v=b3;q=0.9',
      }),
      responseType: 'json',
      // credentials: 'include',
      referrer: 'h5',
    }).then(res => res.json())
      .then(
        (result) => {
          store.dispatch(saveGameListPage(result));
        });

    // console.log('currenttime at indexInit:'+window.$gameUrl);
    fetch(window.$gameUrl + '/rest/game/lottery/currenttime', {
      method: 'get',
      responseType: 'json',
    }).then(res => res.json())
      .then(
        (data) => {
          let memberInfo = store.getState().memberInfo;
          memberInfo.serverTime = data.result.serverTime;
          store.dispatch(SaveMemberInfo(memberInfo));
        });

    if (indexInit && indexInit.loginUser) {
      checkThridLogin();
      getSiteCurrency();

      if (logoutStatus === true) {
      await getLang();
      logoutStatus = false;
      }
    }

    // await getGaiaSiteCurrency();
    getGaiaSiteCurrency();
    // await this.noLoginInit();  //allow no-login to utilise account configs: music,color,language
    this.noLoginInit();
  }

  noLoginInit() {
    let indexInit = store.getState().indexInit.indexInit;
    let memberInfo = store.getState().memberInfo;
    if (!(indexInit?.loginUser?.loginName)) {
      if (!(memberInfo?.UserInfo?.userInfo) || (memberInfo?.UserInfo?.userInfo && !(memberInfo.UserInfo.userInfo.includes("NoLogin")))) {
        var random = Math.floor(100000 + Math.random() * 900000);
        saveNewAccConfigsService("NoLogin-" + random, indexInit.siteId);
      }
    }
  }

  gaiaUiInit() {
    let indexInit = store.getState().indexInit.indexInit;
    if(indexInit.wapThemePath  === 'minigame'){
      document.getElementById('favicon').setAttribute('href', window.$wgtDomain + '/theme/img/favicon.ico');
      window.parent.document.getElementById('favicon').setAttribute('href', window.$wgtDomain + '/theme/img/favicon.ico');
    }
    else{
      document.getElementById('favicon').setAttribute('href', window.$wgtDomain + '/theme/wap/lottery/favicon.ico');
      window.parent.document.getElementById('favicon').setAttribute('href', window.$wgtDomain + '/theme/wap/lottery/favicon.ico');
    }
  }
  setThemeColor() {
    let root = document.getElementById('root');
    let body = root.parentElement;
    let memberInfo = store.getState().memberInfo;
    let indexInit = store.getState().indexInit.indexInit;
    let colorName = indexInit.wapThemeColor ?? "red";
    if(indexInit.wapThemePath === 'green'){
      colorName = "new-skin-green";
      memberInfo.AccountConfigsDefault.defaultColor = "font-color";
    }else if(indexInit.wapThemePath === 'gold'){
      colorName = "new-skin-gold";
      memberInfo.AccountConfigsDefault.defaultColor = "font-color";
    }else if(indexInit.wapThemePath === 'blue'){
      colorName = "new-skin-blue";
      memberInfo.AccountConfigsDefault.defaultColor = "font-color";
    }else if(indexInit.wapThemePath === 'sport'){
      colorName = "sport-blue";
    }else if(indexInit.wapThemePath === 'cp'){
      colorName = "new-skin-cp";
    }else if(indexInit.wapThemePath === 'royal'){
      colorName = "new-skin-royal";
      memberInfo.AccountConfigsDefault.defaultColor = "font-color";
    }else if(indexInit.wapThemePath === 'sodo'){
      colorName = "sodo-blue";
    }else if(indexInit.wapThemePath  === 'minigame'){
      colorName = "minigame-blue";
    }else if(indexInit.wapThemePath  === 'CP98'){
      colorName = "cp98-white";
    }else if(indexInit.wapThemePath  === 'bet365'){
      colorName = "bet365-green";
    }else if (indexInit.wapThemePath === 'vietnamlottery') {
      colorName = "vietnam-lottery";
    }

    let colorHexCode = getCustomColor(colorName);
    if (indexInit?.loginUser?.loginName) {
      if (memberInfo?.UserInfo?.userInfo && memberInfo?.AccountConfigs["accountConfigs" + memberInfo.UserInfo.userInfo]) {
        let userData = "accountConfigs" + memberInfo.UserInfo.userInfo;
        let accountConfigs = memberInfo.AccountConfigs[userData];
        if (accountConfigs.isColorTheme) {
          colorName = accountConfigs.colorThemeValue;
          colorHexCode = getCustomColor(colorName);
        }
      } else {
        saveNewAccConfigsService(indexInit.loginUser.loginName, indexInit.siteId ?? "NaN");
      }
    } else {
      if (memberInfo?.UserInfo?.userInfo && memberInfo.UserInfo.userInfo.includes("NoLogin")) {
        let userData = "accountConfigs" + memberInfo.UserInfo.userInfo;
        let accountConfigs = memberInfo.AccountConfigs[userData];
        if (accountConfigs.isColorTheme) {
          colorName = accountConfigs.colorThemeValue;
          if(indexInit.wapThemePath === 'green' || indexInit.wapThemePath === 'gold'|| indexInit.wapThemePath === 'blue'|| indexInit.wapThemePath === 'cp' || indexInit.wapThemePath === 'royal'){
            colorHexCode = getCustomColor("font-color");
          }else{
            colorHexCode = getCustomColor(colorName);
          }
        }
      }
    }
    if(indexInit.wapThemePath === 'green' || indexInit.wapThemePath === 'gold' || indexInit.wapThemePath === 'royal'){
      body.classList.add("theme-" + "font-color");
    }else{
      body.classList.add("theme-" + colorName);
    }
    body.style.setProperty("--theme-color", colorHexCode);
    body.style.setProperty("overflow-y", "hidden");
    let vh = window.innerHeight;
    body.style.setProperty('--vh', `${vh}px`);
    if (CSS.supports('padding-bottom: env(safe-area-inset-bottom)')) {
      body.style.setProperty('--safe-bottom', "env(safe-area-inset-bottom)");
    }else{
      body.style.setProperty('--safe-bottom', "0px");
    }
  }
  gaiaUi() {
    this.setThemeColor();

    if (!this.state.isReady) {
      return (<AuthPage />);
    }
    // if(this.state?.wapThemePath === 'newapi' || this.state?.wapThemePath === 'green'){
    //   let indexInit = store.getState().indexInit.indexInit;
    //   if(!indexInit?.loginUser){
    //     window.location.href = window.location.href.split('#')[0] + '#/loginpage';
    //   }
    // }
    function gaiaMainPage(props,state){
      if(state.wapThemePath === 'newapi'){
        return(
          <NewApiHomePage publicChatStatus={state.publicChatStatus} {...props}/>
        )
      }else if(state.wapThemePath === 'green'){
        return(
          <GreenMainPage publicChatStatus={state.publicChatStatus} {...props}/>
        )
      }else if(state.wapThemePath === 'gold'){
        return(
          <GoldMainPage publicChatStatus={state.publicChatStatus} {...props}/>
        )
      }else if(state.wapThemePath === 'blue'){
        return(
          <BlueMainPage publicChatStatus={state.publicChatStatus} {...props}/>
        )
      }else if(state.wapThemePath === 'sport'){
        return(
          <SportMainPage publicChatStatus={state.publicChatStatus} {...props}/>
        )
      }else if(state.wapThemePath === 'cp'){
        return(
          <CPMainPage publicChatStatus={state.publicChatStatus} {...props}/>
        )
      }else if(state.wapThemePath === 'oldyb'){
        return(
          <OldYBMainPage publicChatStatus={state.publicChatStatus} {...props}/>
        )
      }else if(state.wapThemePath === 'royal'){
        return(
          <RoyalMainPage publicChatStatus={state.publicChatStatus} {...props}/>
        )
      }else if(state.wapThemePath === 'sodo'){
        return(
          <SodoMainPage publicChatStatus={state.publicChatStatus} {...props}/>
        )
      }else if(state.wapThemePath === 'minigame'){
        return(
          <MinigameMainPage publicChatStatus={state.publicChatStatus} {...props}/>
        )
      }else if(state.wapThemePath === 'CP98'){
        return(
          <CP98MainPage publicChatStatus={state.publicChatStatus} {...props}/>
        )
      }else if(state.wapThemePath === 'bet365'){
        return(
          <Bet365MainPage publicChatStatus={state.publicChatStatus} {...props}/>
        )
      }else if(state.wapThemePath === 'vietnamlottery') {
        return (
          <VietnamLotteryMainPage publicChatStatus={state.publicChatStatus} {...props} />
        )
      }else{
        return(
          <GaiaMainPage publicChatStatus={state.publicChatStatus} {...props}/>
        )
      }
    }

    function userCentrePage(props, state) {
    	var themePath = store.getState()?.indexInit?.indexInit?.wapThemePath;
      if (themePath === 'blue') {
        return (<ZhongheUserCentrePage {...props} />)
      }
      else {
        return (<UserCentrePage {...props} />)
      }
    }

    return (
      <HashRouter basename={getBasename()}>
        <LastLocationProvider>
              <RouterInterceptor {...this.props}>
                <Suspense fallback={<PageSpinner />}>
                <Switch>
                  <LayoutRoute exact path="/" layout={EmptyLayout} component={props => gaiaMainPage(props,this.state)} />
                  <LayoutRoute exact path="/dash-board" layout={EmptyLayout} component={props => <DashboardMainPage {...props} />} />
                  <LayoutRoute exact path="/game-list" layout={EmptyLayout} component={props => <GameListPage {...props} />} />
                  <LayoutRoute exact path="/award-center" layout={EmptyLayout} component={props => <KetQuayPage {...props} />} />
                  <LayoutRoute exact path="/usercentre" layout={EmptyLayout} component={props => userCentrePage(props, this.state)} />
                  <LayoutRoute exact path="/customerservice" layout={EmptyLayout} component={props => <CustomerServicePage {...props} />} />
                  <LayoutRoute exact path="/proxycenter" layout={EmptyLayout} component={props => <ProxyCenterPage {...props} />} />
                  <LayoutRoute exact path="/welfare" layout={EmptyLayout} component={props => <WelfarePage {...props} />} />
                  <LayoutRoute exact path="/cashvoucher" layout={EmptyLayout} component={props => <CashVoucher {...props} />} />
                  <LayoutRoute exact path="/gamerebatereport" layout={EmptyLayout} component={props => <GameRebateReportPage {...props} />} />
                  <LayoutRoute exact path="/gamerebatedetail" layout={EmptyLayout} component={props => <GameRebateDetailListPage {...props} />} />
                  <LayoutRoute exact path="/teamindex" layout={EmptyLayout} component={props => <TeamIndexPage {...props} />} />
                  <LayoutRoute exact path="/lowerreport" layout={EmptyLayout} component={props => <LowerReportPage {...props} />} />
                  <LayoutRoute exact path="/lowerreportdetail" layout={EmptyLayout} component={props => <LowerReportDetailListPage {...props} />} />
                  <LayoutRoute exact path="/agentrebate" layout={EmptyLayout} component={props => <AgentRebatePage {...props} />} />
                  <LayoutRoute exact path="/games" layout={EmptyLayout} component={props => <GameBetPage {...props} />} />
                  <LayoutRoute exact path="/loginpage" layout={EmptyLayout} component={props => <LoginPage {...props} />} />
                  <LayoutRoute exact path="/personal-report-page" layout={EmptyLayout} component={props => <PersonalReportPage {...props} />} />
                  <LayoutRoute exact path="/my-account-info" layout={EmptyLayout} component={props => <MyAccountInfoPage {...props} />} />
                  <LayoutRoute exact path="/my-account-info-level" layout={EmptyLayout} component={props => <LevelPage {...props} />} />
                  <LayoutRoute exact path="/my-account-info-password" layout={EmptyLayout} component={props => <PsdConterPage {...props} />} />
                  <LayoutRoute exact path="/my-account-info-password-manage" layout={EmptyLayout} component={props => <PsdManagePage {...props} />} />
                  <LayoutRoute exact path="/my-account-info-lock-password-manage" layout={EmptyLayout} component={props => <LockPsdManagePage {...props} />} />
                  <LayoutRoute exact path="/my-account-info-lock" layout={EmptyLayout} component={props => <LockPage {...props} />} />
                  <LayoutRoute exact path="/my-account-info-password-protected" layout={EmptyLayout} component={props => <PsdProtectedPage {...props} />} />
                  <LayoutRoute exact path="/my-account-info-detail" layout={EmptyLayout} component={props => <DetailEditPage {...props} />} />
                  <LayoutRoute exact path="/my-account-info-service" layout={EmptyLayout} component={props => <MyServicePage {...props} />} />
                  <LayoutRoute exact path="/money-conversion" layout={EmptyLayout} component={props => <MoneyConversionPage {...props} />} />
                  <LayoutRoute exact path="/signin-record" layout={EmptyLayout} component={props => <SignInRecordPage {...props} />} />
                  <LayoutRoute exact path="/signin-rule" layout={EmptyLayout} component={props => <SignInRulePage {...props} />} />
                  <LayoutRoute exact path="/my-bank" layout={EmptyLayout} component={props => (<MyBankPage {...props} />)} />
                  <LayoutRoute exact path="/promotion" layout={EmptyLayout} component={props => <PromotionPage {...props} />} />
                  <LayoutRoute exact path="/bonus" layout={EmptyLayout} component={props => <BonusPage {...props} />} />
                  <LayoutRoute exact path="/rise" layout={EmptyLayout} component={props => <RisePage {...props} />} />
                  <LayoutRoute exact path="/yhdetails" layout={EmptyLayout} component={props => <YhDetailPage {...props} />} />
                  <LayoutRoute exact path="/message" layout={EmptyLayout} component={props => <MessagePage {...props} />} />
                  <LayoutRoute exact path="/proxyDetail" layout={EmptyLayout} component={props => <ProxyDetailPage {...props} />} />
                  <LayoutRoute exact path="/docDetail" layout={EmptyLayout} component={props => <DocDetailPage {...props} />} />
                  <LayoutRoute exact path="/transaction-record-page" layout={EmptyLayout} component={props => <TransactionReportPage {...props} />} />
                  <LayoutRoute exact path="/third-login" layout={EmptyLayout} component={props => <ThirdLoginPage {...props} />} />
                  <LayoutRoute exact path="/IM-page" layout={EmptyLayout} component={props => <IMPage {...props} />} />
                  <LayoutRoute exact path="/betting-record-page" layout={EmptyLayout} component={props => <GaiaBettingRecordPage {...props} />} />
                  <LayoutRoute exact path="/recharge-index" layout={EmptyLayout} component={props => (<RechargeIndexPage {...props} />)} />
                  <LayoutRoute exact path="/ranking-page" layout={EmptyLayout} component={props => (<RankingPage {...props} />)} />
                  <LayoutRoute exact path="/offline-pay-info" layout={EmptyLayout} component={props => (<OfflinePayInfoPage {...props} />)} />
                  <LayoutRoute exact path="/offline-draw" layout={EmptyLayout} component={props => (<OfflineDrawPage {...props} />)} />
                  <LayoutRoute exact path="/check-record" layout={EmptyLayout} component={props => (<CheckRecordPage {...props} />)} />
                  <LayoutRoute exact path="/online-pay-info" layout={EmptyLayout} component={props => (<OnlinePayInfoPage {...props} />)} />
                  <LayoutRoute exact path="/deposit-result" layout={EmptyLayout} component={props => (<DepositResultPage {...props} />)} />
                  <LayoutRoute exact path="/sub-account-page" layout={EmptyLayout} component={props => (<SubAccountPage {...props} />)} />
                  <LayoutRoute exact path="/question" layout={EmptyLayout} component={props => <QuestionPage {...props} />} />
                  <LayoutRoute exact path="/leaveMessage" layout={EmptyLayout} component={props => <LeaveMessagePage {...props} />} />
                  <LayoutRoute exact path="/agent-promotion-page" layout={EmptyLayout} component={props => <AgentPromotionPage {...props} />} />
                  <LayoutRoute exact path="/gameRules" layout={EmptyLayout} component={props => <GamesRulePage {...props} />} />
                  <LayoutRoute exact path="/minigamelanding" layout={EmptyLayout} component={props => <MiniGameLandingPage {...props} />} />
                  <LayoutRoute exact path="/minigame" layout={EmptyLayout} component={props => <UnityGamePage {...props} />} />
                  <LayoutRoute exact path="/gaiaAwardDescriptionPage" layout={EmptyLayout} component={props => <GaiaAwardDescriptionPage {...props} />} />
                  <LayoutRoute exact path="/download" layout={EmptyLayout} component={props => <DownloadPage {...props} />} />
                  <LayoutRoute exact path="/downloadWebclip" layout={EmptyLayout} component={props => <DownloadWebclipPage {...props} />} />
                  <LayoutRoute exact path="/update" layout={EmptyLayout} component={props => <UpdatePage {...props} />} />
                  <LayoutRoute exact path="/agent-team-report-page" layout={EmptyLayout} component={props => <AgentTeamReportPage {...props} />} />
                  <LayoutRoute exact path="/game-rules-page" layout={EmptyLayout} component={props => (<GameRulesPage {...props} />)} />
                  <LayoutRoute exact path="/award-description-page" layout={EmptyLayout} component={props => (<AwardDescriptionsPage {...props} />)} />
                  <LayoutRoute exact path="/trend" layout={EmptyLayout} component={props => (<Trend data={this.props.awardTrend} {...props} />)} />
                  <LayoutRoute exact path="/check-rebate" layout={EmptyLayout} component={props => <CheckRebatePage {...props} />} />
                  <LayoutRoute exact path="/longQueen" layout={EmptyLayout} component={props => (<LongQueenPageGaia {...props} />)} />
                  <LayoutRoute exact path="/description" layout={EmptyLayout} component={props => (<DescriptionGaia {...props} />)} />
                  <LayoutRoute exact path="/custom-bet-values" layout={EmptyLayout} component={props => <CustomBetValuesPage {...props} />} />
                  <LayoutRoute exact path="/customBetCountDown" layout={EmptyLayout} component={props => <CustomBetCountDown {...props} />} />
                  <LayoutRoute exact path="/customColor" layout={EmptyLayout} component={props => <CustomColor {...props} />} />
                  <LayoutRoute exact path="/gameHall" layout={EmptyLayout} component={props => <GameHallPage {...props} />} />
                  <LayoutRoute exact path="/my-bank-add" layout={EmptyLayout} component={props => (<MyBankAddPage {...props} />)} />
                  <LayoutRoute exact path="/my-cryptocurrency-add" layout={EmptyLayout} component={props => (<MyCryptocurrencyAddPage {...props} />)} />
                  <LayoutRoute exact path="/my-wallet-add" layout={EmptyLayout} component={props => (<MyWalletAddPage {...props} />)} />
                  <LayoutRoute exact path="/bettingrecord" layout={EmptyLayout} component={props => <GaiaBettingRecordPage {...props} />} />
                  <LayoutRoute exact path="/language-switch" layout={EmptyLayout} component={props => <GaiaLanguageSwitcher {...props} />} />
                  <LayoutRoute exact path="/maintenancePage" layout={EmptyLayout} component={props => (<MaintenancePage {...props} />)} />
                  <LayoutRoute exact path="/settings" layout={EmptyLayout} component={props => (<SettingPage {...props} />)} />
                  <LayoutRoute exact path="/gameCustomSetting" layout={EmptyLayout} component={props => (<GameCustomSettingPage {...props} />)} />
                  <LayoutRoute exact path="/blockPage" layout={EmptyLayout} component={props => (<BlockPage {...props} />)} />
                  <LayoutRoute exact path="/loginExpiredPage" layout={EmptyLayout} component={props => (<LoginExpiredPage {...props} />)} />
                  <LayoutRoute exact path="/error404Page" layout={EmptyLayout} component={props => (<Error404Page {...props} />)} />
                  <LayoutRoute exact path="/stopSitePage" layout={EmptyLayout} component={props => (<StopSitePage {...props} />)} />
                  <LayoutRoute exact path="/event" layout={EmptyLayout} component={props => <EventPage {...props} />} />
                  <LayoutRoute exact path="/othersEvent" layout={EmptyLayout} component={props => <OthersEventPage {...props} />} />
                  <LayoutRoute exact path="/pointConvertEvent" layout={EmptyLayout} component={props => <PointConvertEventPage {...props} />} />
                  <LayoutRoute exact path="/responsible" layout={EmptyLayout} component={props => (<ResponsiblePage {...props} />)} />
                  <LayoutRoute exact path="/autobets-page" layout={EmptyLayout} component={props => <GaiaBetPurseRecordsPage {...props} />} />
                  <LayoutRoute exact path="/blue-allGames" layout={EmptyLayout} component={props => <BlueAllGames {...props} />} />
                  <LayoutRoute exact path="/my-account-verify" layout={EmptyLayout} component={props => <DoubleVerify {...props} />} />
                  <LayoutRoute exact path="/cashflowpage" layout={EmptyLayout} component={props => <CashFlowPage {...props} />} />
                  <LayoutRoute exact path="/live" layout={EmptyLayout} component={props => <LiveMainPage {...props} />} />
                  <LayoutRoute exact path="/identity-confirmation" layout={EmptyLayout} component={props => <IdentityConfirmation {...props} />} />
                  <LayoutRoute exact path="/externalPage" layout={EmptyLayout} component={props => <ExternalPage {...props} />} />
                  <LayoutRoute exact path="/RegisterSuccessPage" layout={EmptyLayout} component={props => <RegisterSuccessPage {...props} />} />
                  <LayoutRoute exact path="/help-view" layout={EmptyLayout} component={props => (<HelpViewPage {...props} />)} />
                  <LayoutRoute exact path="/content1" layout={EmptyLayout} component={props => (<Content1Page {...props} />)} />
                  <LayoutRoute exact path="/content2" layout={EmptyLayout} component={props => (<Content2Page {...props} />)} />

                  {this.state.wapThemePath === 'lottery' && <Redirect to="/" />}
                </Switch>
                </Suspense>
              </RouterInterceptor>
              {!isMiniGameDisplay?
              <>
              <NoticeModal {...this.props} />
              <VoucherNoticeModal {...this.props} />
              <LoginModal {...this.props} />
              <RegisterPage {...this.props} />
              <ForgotPasswordPage {...this.props} />
              <SecurityResetPage {...this.props} />
              <SafePasswordPage {...this.props} />
              <AlertModal {...this.props} />
              </>
              :null}
              {/* <audio className="audio-element">
                <source type="audio/mp3" src={abc} />
              </audio> */}
              <audio className="countdown-element">
                <source type="audio/mp3" src={countdownbgm} />
              </audio>
              <LinesDisplayModal {...this.props} />
              {/* <Loading /> */}
        </LastLocationProvider>
      </HashRouter>
    );
  }

  gameUiInit() {
    // console.log('processing gameUiInit');
    if (!isLiveResultDisplay) {
      this.setThemeColor();
    }
    var lang = 'zh';
    let memberInfo = store.getState().memberInfo;
    let inputReferrer = document.referrer.split('?')[0];
    if (inputReferrer && (inputReferrer.indexOf(window.location.protocol + "//" + window.location.host) === -1)) {
      storage.setItem('myReferrer', document.referrer);
    }
    // fetch(window.$gameUrl + '/rest/game/lottery/currenttime', {
    //   method: 'get',
    //   responseType: 'json',
    // }).then(res => res.json())
    //   .then(
    //     (data) => {
    //       let memberInfo = store.getState().memberInfo;
    //       memberInfo.serverTime = data.result.serverTime;
    //       store.dispatch(SaveMemberInfo(memberInfo));
    //     });
    if (process.env.NODE_ENV === 'development') {
      //window.$gameUrl = 'http://lottoapi.ybapi8888.com:60005';
      memberInfo.gameUrl = window.$gameUrl;
      store.dispatch({
        type: actionType.GET_BALANCE,
        input: memberInfo,
      });

      let urlParameter = this.state.url;
      urlParameter = urlParameter.split('#')[0];
      let vars = {};
      urlParameter.replace(/[?&]+([^=&]+)=([^&]*)/gi, function (m, key, value) {
        vars[key] = value;
      });
      if (typeof vars['gametoken'] !== 'undefined') {
        let memberInfo = store.getState().memberInfo;
        memberInfo.gametoken = vars['gametoken'];
        store.dispatch(SaveMemberInfo(memberInfo));
      }
      this.setState({ isReady: true });
    } else {
      var localLang = localStorage.getItem("i18nextLng");
      if(localLang!==null){
          lang = localLang;
      }
      let urlParameter = this.state.url;
      urlParameter = urlParameter.split('#')[0];
      let vars = {};
      urlParameter.replace(/[?&]+([^=&]+)=([^&]*)/gi, function (m, key, value) {
        vars[key] = value;
      });
      if (!isLiveResultDisplay) {
      if (typeof vars['gametoken'] !== 'undefined') {
        let memberInfo = store.getState().memberInfo;
        memberInfo.gametoken = vars['gametoken'];
        store.dispatch(SaveMemberInfo(memberInfo));
      }
      if (!isMobile && process.env.NODE_ENV === 'production') {
        if(memberInfo.gametoken!==undefined && store.getState().memberInfo.gametoken!==null && window.$mode ===1){
          window.location.href = window.location.protocol + "//" + window.location.host + '/index?gametoken='+memberInfo.gametoken;
        }
        else{
          window.location.href = window.location.protocol + "//" + window.location.host + '/index';
        }
      }
      } else {
        this.setState({ isReady: true });
      }
      if (typeof vars['key'] !== 'undefined') {
        var encryptedBase64Key = 'bXVzdGJlMTZieXRlc2tleQ==';//<encoded base64 key from Java>;
        var parsedBase64Key = CryptoJS.enc.Base64.parse(encryptedBase64Key);
        var encryptedCipherText = vars['key'];
        var decryptedData = CryptoJS.AES.decrypt(encryptedCipherText, parsedBase64Key, {
          mode: CryptoJS.mode.ECB,
          padding: CryptoJS.pad.Pkcs7
        });
        var decryptedText = decryptedData.toString(CryptoJS.enc.Utf8);
        let inputKey = decryptedText.split('|');
        //console.log('inputKey:'+inputKey);
        window.$gameUrl = '//' + inputKey[0];
        if (inputKey[1] !== null && inputKey[1] !== 'null') {
          store.dispatch({
            type: 'SET_JUMP_GAME',
            input: inputKey[1],
          })
        }
        if (inputKey[2]) {
          store.dispatch({
            type: 'SET_SITE_NAME',
            input: decodeURI(inputKey[2]),
          })
        }
        memberInfo.gameUrl = window.$gameUrl;
        store.dispatch({
          type: actionType.GET_BALANCE,
          input: memberInfo,
        });
        this.setState({ isReady: true });
      } else if (memberInfo.gameUrl) {
        window.$gameUrl = memberInfo.gameUrl;
        this.setState({ isReady: true });
      } else if (isLiveResultDisplay) {

      } else {
        var msg01 = "请重新登入";
        if(lang==="en"){
          msg01 = "please login again";
        }
        else if(lang==="vi"){
          msg01 = "xin vui lòng đăng nhập lại";
        }
        else if(lang==="th"){
          msg01 = "กรุณาเข้าสู่ระบบอีกครั้ง";
        }
        else if(lang==="in"){
          msg01 = "please login again";
        }
        else if(lang==="id"){
          msg01 = "please login again";
        }
        else if(lang==="pt"){
          msg01 = "please login again";
        }
        else if(lang==="es"){
          msg01 = "please login again";
        }
        alert(msg01);
      }
      if (typeof vars['lang'] !== 'undefined') {
        console.log("Init Lang:" + vars['lang']);
        i18next.changeLanguage(vars['lang'], (err) => {
          if (err) console.log(err);
        })
      }
    }
    // console.log('currenttime at gameUiInit: '+window.$gameUrl+':');
    fetch(window.$gameUrl + '/rest/game/lottery/currenttime', {
      method: 'get',
      responseType: 'json',
    }).then(res => res.json())
      .then(
        (data) => {
          let memberInfo = store.getState().memberInfo;
          memberInfo.serverTime = data.result.serverTime;
          store.dispatch(SaveMemberInfo(memberInfo));
      });
    let defaultColor = "red";
    const defaultAccSetting = { defaultColor: defaultColor, defaultBetValues: [1, 5, 10, 15, 20, 50, 100], };
    store.dispatch(SetAccountConfigsDefault(defaultAccSetting));

    let root = document.getElementById('root');
    let body = root.parentElement;

    let colorName = "red";

    let colorHexCode = getCustomColor(colorName);
    if (memberInfo.AccountConfigs && memberInfo.UserInfo) {
      let userData = memberInfo.UserInfo.userInfo ? "accountConfigs" + memberInfo.UserInfo.userInfo : "";

      if (userData) {
        if (memberInfo.AccountConfigs[userData]) {
          let accountConfigs = memberInfo.AccountConfigs[userData];
          if (accountConfigs.isColorTheme) {
            colorName = accountConfigs.colorThemeValue;
            colorHexCode = getCustomColor(colorName);
          }
        }
      }
    }
    body.setAttribute("class", "");
    body.classList.add("theme-" + colorName);
    body.style.setProperty("--theme-color", colorHexCode);
    let vh = window.innerHeight;
    body.style.setProperty('--vh', `${vh}px`);
    if (CSS.supports('padding-bottom: env(safe-area-inset-bottom)')) {
      body.style.setProperty('--safe-bottom', "env(safe-area-inset-bottom)");
    }else{
      body.style.setProperty('--safe-bottom', "0px");
    }
    const windowURL = window.location.href;
    // console.log('isLiveResultDisplay checking1:'+isLiveResultDisplay+':'+windowURL+':');
    if (windowURL.includes('livemode=true')) {
      if (!isLiveResultDisplay) {
        window.location.href = windowURL.replace('#/','#/live')
      }
    }
  }

  gameUi() {
    // console.log('isReady mode:'+(this.state.isReady));
    if (!this.state.isReady) {
      return null;
    }
    if (!isLiveResultDisplay) {
      let urlParameter = this.state.url;
      var vars = {};
      var parts = urlParameter.replace(/[?&]+([^=&]+)=([^&]*)/gi, function (m, key, value) {
        vars[key] = value;
      });

      /*
      let loggined = false;
      if ((cookie.load('login') === 'true')) {
        loggined = true;
      }
      else if ((cookie.load('key') === undefined) || (cookie.load('key') === '')) {
        if (urlParameter.includes("?")) {
          let urlParameter = this.state.url;
          vars = {};
          urlParameter.replace(/[?&]+([^=&]+)=([^&]*)/gi, function (m, key, value) {
            vars[key] = value;
          });
          cookie.save('login', true, { path: '/' })
          loggined = true;
        }
      }
      else if (!urlParameter.includes("/login") && cookie.load('login') === undefined) {
        loggined = false;
        cookie.save('login', false, { path: '/' });
        window.location = "/login"
      }
      */

      let loggined = false;
      if (storage.getItem('login') === 'true') {
        loggined = true;
      }
      else if ((storage.getItem('key') === undefined) || (storage.getItem('key') === '')) {
        if (urlParameter.includes("?")) {
          let urlParameter = this.state.url;
          vars = {};
          urlParameter.replace(/[?&]+([^=&]+)=([^&]*)/gi, function (m, key, value) {
            vars[key] = value;
          });
          storage.setItem('login', true, { path: '/' })
          loggined = true;
        }
      }
      else if (!urlParameter.includes("/login") && storage.getItem('login') === undefined) {
        loggined = false;
        storage.setItem('login', false, { path: '/' });
        window.location = "/login"
      }
    }

    return (
      <HashRouter basename={getBasename()}>
        <LastLocationProvider>
          <RouterInterceptor {...this.props}>
            <Suspense fallback={<PageSpinner />}>
              <Switch>
                <LayoutRoute exact path="/login" layout={EmptyLayout} component={props => (<AuthPage {...props} authState={STATE_LOGIN} />)} />
                <LayoutRoute exact path="/signup" layout={EmptyLayout} component={props => (<AuthPage {...props} authState={STATE_SIGNUP} />)} />
                <LayoutRoute exact path="/games" layout={EmptyLayout} component={props => (<GameBetPage {...props} />)} />
                <LayoutRoute exact path="/bettingrecord" layout={EmptyLayout} component={props => (<BettingRecordPage {...props} />)} />
                <LayoutRoute exact path="/awardcenters" layout={EmptyLayout} component={props => (<AwardCenterPage {...props} />)} />
                <LayoutRoute exact path="/liveresult" layout={EmptyLayout} component={props => (<LiveresultPage {...props} />)} />
                <LayoutRoute exact path="/game-rules-page" layout={EmptyLayout} component={props => (<GameRulesPage {...props} />)} />
                <LayoutRoute exact path="/award-description-page" layout={EmptyLayout} component={props => (<AwardDescriptionsPage {...props} />)} />
                <LayoutRoute exact path="/trend" layout={EmptyLayout} component={props => (<Trend data={this.props.awardTrend} {...props} />)} />
                <LayoutRoute exact path="/lotterylist" layout={EmptyLayout} component={props => (<LotteryListPage {...props} />)} />
                <LayoutRoute exact path="/longQueen" layout={EmptyLayout} component={props => (<LongQueenPage {...props} />)} />
                <LayoutRoute exact path="/description" layout={EmptyLayout} component={props => (<DescriptionPage {...props} />)} />
                <LayoutRoute exact path="/settings" layout={EmptyLayout} component={props => (<SettingPage {...props} />)} />
                <LayoutRoute exact path="/gameCustomSetting" layout={EmptyLayout} component={props => (<GameCustomSettingPage {...props} />)} />
                <LayoutRoute exact path="/stopuser" layout={EmptyLayout} component={props => (<Stopuser {...props} />)} />
                <LayoutRoute exact path="/timeoutPage" layout={EmptyLayout} component={props => (<TimeoutPage {...props} />)} />
                <LayoutRoute exact path="/blockPage" layout={EmptyLayout} component={props => (<BlockPage {...props} />)} />
                <LayoutRoute exact path="/gameHall" layout={EmptyLayout} component={props => (<GameHallPage {...props} />)} />
                <LayoutRoute exact path="/IM-page" layout={EmptyLayout} component={props => <IMPage {...props} />} />
                <LayoutRoute exact path="/language-switch" layout={EmptyLayout} component={props => <GameLanguageSwitcher {...props} />} />
                <LayoutRoute exact path="/loginExpiredPage" layout={EmptyLayout} component={props => (<LoginExpiredPage {...props} />)} />
                <LayoutRoute exact path="/error404Page" layout={EmptyLayout} component={props => (<Error404Page {...props} />)} />
                <LayoutRoute exact path="/stopSitePage" layout={EmptyLayout} component={props => (<StopSitePage {...props} />)} />
                <LayoutRoute exact path="/responsible" layout={EmptyLayout} component={props => (<ResponsiblePage {...props} />)} />
                <LayoutRoute exact path="/cashflowpage" layout={EmptyLayout} component={props => (<CashFlowPage {...props} />)} />
                <LayoutRoute exact path="/live" layout={EmptyLayout} component={props => <LiveMainPage {...props} />} />
                <LayoutRoute exact path="/RegisterSuccessPage" layout={EmptyLayout} component={props => <RegisterSuccessPage {...props} />} />
                <MainLayout breakpoint={this.props.breakpoint}>
                  {/* <Suspense fallback={<PageSpinner />}> */}
                    <Route exact path="/" component={DashboardMainPage} />
                    <Route exact path="/login-modal" component={AuthModalPage} />
                    <Route exact path="/awardcenters" component={AwardCenterPage} />
                    <Route exact path="/liveresult" component={LiveresultPage} />
                    <Route exact path="/game-rules-page" component={GameRulesPage} />
                    <Route exact path="/award-description-page" component={AwardDescriptionsPage} />
                    <Route exact path="/bettingrecords" component={BettingRecordPage} />
                    <Route exact path="/clearcaches" component={ClearCachePage} />
                    <Route exact path="/refreshs" component={RefreshPage} />
                    <Route exact path="/games" component={GamePage} />
                    <Route exact path="/unitygames" component={UnityGamePage} />
                    <Route exact path="/lotterylists" component={LotteryListPage} />
                    <Route exact path="/longQueen" component={LongQueenPage} />
                    <Route exact path="/description" component={DescriptionPage} />
                    <Route exact path="/stopuser" component={Stopuser} />
                    <Route exact path="/cashflowpage" component={CashFlowPage} />
                    <Route render={() => <Redirect to="/" />} />
                  {/* </Suspense> */}
                </MainLayout>
                <Redirect to="/" />
              </Switch>
            </Suspense>
          </RouterInterceptor>
          <AlertModal {...this.props} />
          {/* <audio className="audio-element">
            <source type="audio/mp3" src={abc} allow="autoplay" />
          </audio> */}
          <audio className="countdown-element">
            <source type="audio/mp3" src={countdownbgm} allow="autoplay" />
          </audio>
        </LastLocationProvider>
      </HashRouter>
    );
  }
}

const query = ({ width }) => {
  if (width < 575) {
    return { breakpoint: 'xs' };
  }

  if (576 < width && width < 767) {
    return { breakpoint: 'sm' };
  }

  if (768 < width && width < 991) {
    return { breakpoint: 'md' };
  }

  if (992 < width && width < 1199) {
    return { breakpoint: 'lg' };
  }

  if (width > 1200) {
    return { breakpoint: 'xl' };
  }

  return { breakpoint: 'xs' };
};

export default componentQueries(query)(LottoApp);