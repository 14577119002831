import AwardDetailsHeader from '../Layout/AwardDetailHeader';
import { Button, Col, ListGroup, ListGroupItem, Modal, Row } from 'reactstrap';
import React from 'react';
import AwardDetailPage from '../AwardDetailPage';
import { withTranslation } from 'react-i18next';
import i18next from 'i18next';
import AwardZhongHePage from '../AwardZhongHePage';
import { MdSearch, MdPhotoFilter } from 'react-icons/md';
import Select from "react-select";
import ReactLoading from 'react-loading';
import { convertByTimeZone } from '../../services/HomeService';

const customStyles = {
  control: (provided, state) => ({
    ...provided,
    background: '#fff',
    borderColor: '#9e9e9e',
    minHeight: '26px',
    boxShadow: state.isFocused ? null : null,
  }),

  valueContainer: (provided, state) => ({
    ...provided,
    height: '26px',
    padding: '0 6px'
  }),
  menu: (provided, state) => ({
    ...provided,
    textAlign: 'left'
  }),
  input: (provided, state) => ({
    ...provided,
    margin: '0px',
    paddingTop: '0px'
  }),
  indicatorSeparator: state => ({
    display: 'none',
  }),
  indicatorsContainer: (provided, state) => ({
    ...provided,
    height: '26px',
  }),
};

class AwardDetailModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      gameName: '',
      gameId: '',
      top :20,
      localizeAwards: [],
      values: [],
      issueDate: '',
      selectedValue: '',
      zhType: (this.props.zongHeType!='' && this.props.zongHeType!=null)?this.props.zongHeType:'',
      sClearable: false,
      isDisabled: false,
      isLoading: false,
      isRtl: false,
      isSearchable: false,
      loading: false
    };
    this.handleSearch = this.handleSearch.bind(this);
    this.handleIssueDateChange = this.handleIssueDateChange.bind(this);
    this.handleSelectChange = this.handleSelectChange.bind(this);
  }

  componentDidMount() {
    if(this.props.playId == 'V6Z' || this.props.playId == 'V5Z'){
      this.getLocalizeGame(this.state.zhType);
      if(this.props.isCatch){
        this.getLocalizeResult(this.props.game.mainGameObj.selectId,this.state.zhType, '','zh',10);
        this.setState({ selectedValue: this.props.game.mainGameObj.selectId,issueDate: ''});
      }else{
        this.getLocalizeResult(this.state.gameId,this.state.zhType,'','zh',10);
        this.setState({issueDate: ''});
      }
    }
  }

  getLocalizeGame(zongheType) {
    if(this.props.home.gameList.length > 0) {
      let gameVOList = this.props.home.gameList[0].gameList;
      let games = gameVOList.filter(g => g.isCatch && g.isZongHe && g.zongHeType.toUpperCase() === zongheType.toUpperCase()).map(game => {
        return { value: game.id, name: game.gameName };
      });
      this.setState({
        values: games,
      });
    }
  };

  getLocalizeResult(gameId, zongheType, issue, lang, top) {
    this.setState({
      loading: true
    });
    let initialAwads = [];
    fetch(window.$gameUrl + '/page/chart/localizeresult?gameId=' + gameId + '&zongheType=' + zongheType + '&issue=' + issue + '&lang=' + lang + '&last=' + top, {
      method: 'POST',
      headers: new Headers({
        'Accept': 'application/json, text/plain, */*',
        'Content-Type': 'application/x-www-form-urlencoded',
      }),
      // credentials: 'include',
      referrer: 'h5'
    }).then(response => {
      return response.json();
    }).then(data => {
        initialAwads = data.result.list.map(award => {
          return {
            name: award.gameName,
            issue: award.gameIssue,
            grand: award.resultAward[0].value,
            first: award.resultAward[1].value,
            second: award.resultAward[2].value,
            third: award.resultAward[3].value,
            four: award.resultAward[4].value,
            five: award.resultAward[5].value,
            six: award.resultAward[6].value,
            seven: award.resultAward[7].value,
            eight: award.resultAward[8] == undefined ? '' : award.resultAward[8].value,
            zongheType: zongheType
          };
        });
        this.setState({
          localizeAwards: initialAwads,
        });
    }).catch(error => {
      console.log(error);
    });
    this.setState({
      loading: false
    });
  };

  handleSearch() {
    // const formattedIssueDate = moment(this.state.issueDate).format("YYYYMMDD");
    this.getLocalizeResult(this.state.selectedValue, this.state.zhType, this.state.issueDate, 'zh', 10);
  };

  handleIssueDateChange(d) {
    this.setState({ issueDate: d.target.value });
  };


  handleSelectChange(e) {
    this.setState({ selectedValue: e.value });
  };

  render() {

    const { t } = this.props;
    const winHeight = window.innerHeight;
    const modalHeight = winHeight - 44;
    const modalHeight6D = winHeight - 136;
    const getResult = (item) => {
      let aw = this.props.isZongHe && (this.props.playId == 'V6Z' || this.props.playId == 'V5Z') ? item.result_set : item.result;
      let res;

      if(this.props.playId == 'QQC'){
        aw = aw.substring(aw.length-3,aw.length);
        res = aw.split('');
      }else{
        res = aw.split(',');
      }
      return res;
    };

    let optionTemplate = [{ value: '', label: t('全部') }];
      this.state.values.map(v => (
        optionTemplate.push({
          value:v.value,
          label:t(v.name)})
        )
    );

    return(
      <Modal isOpen={this.props.awardModal} toggle={this.AwardDetailToggle} className="cart-modal">
        {this.state.loading === true ?
            <ReactLoading type={'spin'} className={'loadingSpinner'} style={{ width: '49px', height: '49px' }}/>
            : false}
        <AwardDetailsHeader onPress={this.props.onPress.bind(this)} AwardDetailToggle={this.props.AwardDetailToggle.bind(this)} gameName={this.props.gameName}
                            gameId ={this.props.gameId} playId ={this.props.playId} isZongHe={this.props.isZongHe} zongHeType={this.props.zongHeType} isCatch={this.props.isCatch} {...this.props} />

              {this.props.isZongHe && (this.props.playId == 'V6Z' || this.props.playId == 'V5Z')?
                  <>
                      <div style={{ overflow: 'auto',height:'100%' }} className="awardcenterpage">
                          <div style={{
                            height: '48px',
                            background: 'white',
                            width: '100%',
                            padding: '0 15px',
                            marginBottom: '1px',
                            textAlign: 'center'
                          }}>
                          <div style={{
                            borderRadius: '4px',
                            display: 'inline-block',
                            marginTop: '12px',
                            border: '1px solid #D7D7D7',
                            marginRight: 3,
                          }}>
                            {/*<DatePicker*/}
                            {/*  className="input-field"*/}
                            {/*  selected={this.state.issueDate}*/}
                            {/*  onChange={this.handleIssueDateChange.bind(this)}*/}
                            {/*  dateFormat="yyyyMMdd"*/}
                            {/*  locale={i18next.language}*/}
                            {/*  style={"width:100px"}*/}
                            {/*  minDate={subMonths(new Date(), 2)}*/}
                            {/*/>*/}
                            <input type="text" className="input-field" style={{width:'100px'}} placeholder={t('drawno')} value={this.state.issueDate || ''} onChange={this.handleIssueDateChange.bind(this)}/>
                          </div>
                          <div style={{
                            borderRadius: '4px',
                            display: 'inline-block',
                            marginRight: 3,
                            width: 'calc(90% / 3)'
                          }}>
                            <Select className="basic-single" classNamePrefix="select"
                            defaultValue={!this.props.isCatch?optionTemplate[0]:{ value: this.props.game.mainGameObj.selectId, label: t(this.props.game.mainGameObj.gameName) }}
                            isDisabled={this.state.isDisabled}
                            isLoading={this.state.isLoading}
                            isClearable={this.state.isClearable}
                            isRtl={this.state.isRtl}
                            isSearchable={this.state.isSearchable}
                            name="color"
                            options={optionTemplate}
                            styles={customStyles}
                            onChange={this.handleSelectChange}
                          />
                          </div>
                          <div style={{ display: 'inline-flex', padding: '12px 5px 5px 0', paddingLeft: 0 }}>
                            <a style={{
                              padding: '0 15px',
                              color: 'white',
                              background: 'var(--theme-color)',
                              lineHeight: '26px',
                              borderRadius: '4px',
                            }}>
                              <div style={{ fontSize: '12px'}}>
                                <button style={{color:'#fff'}} onClick={this.handleSearch}>
                                  <MdSearch size={20}/>{t('lowerreportdetaillistpage.search')}
                                </button>
                              </div>
                            </a>
                          </div>
                        </div>
                        {this.state.localizeAwards.length > 0 ?
                        <Row style={{ overflow: 'auto'}}>
                            {this.state.localizeAwards.map((item, index) => {
                              return (
                                <>
                                  <Col lg="4" md="12" sm="12" xs="12">
                                    <Button disabled={this.state.loading} outline color="link"
                                            style={{ padding: 0, border: 0, width: '100%' }}>
                                      <React.Fragment>
                                        <AwardZhongHePage
                                          title={i18next.language != 'zh' ? t(item.name) : item.name}
                                          issue={i18next.language != 'zh' ? t('drawno') + item.issue : '第' + item.issue + '期'}
                                          grand={item.grand}
                                          first={item.first}
                                          second={item.second}
                                          third={item.third}
                                          four={item.four}
                                          five={item.five}
                                          six={item.six}
                                          seven={item.seven}
                                          eight={item.eight}
                                          zongheType={item.zongheType}
                                        />
                                      </React.Fragment>
                                    </Button>
                                  </Col>
                                </>
                              );
                            })}
                        </Row>
                        :
                        <div style={{textAlign:'center',paddingTop: 50,paddingBottom: 50,lineHeight:1}}>
                          <MdPhotoFilter size={110} style={{color:'#ccc'}}/>
                          <div style={{lineHeight:1,color:'#ccc',fontSize:20}}>{t('no_record_found')}</div>
                        </div>
                        }
                      </div>
                    </>
              :
                <Row style={{overflow:'auto',height:`calc(${modalHeight}px - var(--safe-bottom))`,position:'absolute',width:'100%',margin:0,top:44}}>
                  <Col lg={12} md={12} sm={12} xs={12} style={{padding:0}}>
                    <ListGroup>
                      {this.props.awardetails?
                        this.props.awardetails.award.map((item, parentIndex) => {
                          return (
                            <React.Fragment key={parentIndex}>
                              <ListGroupItem style={{ border: 0, padding: 5, paddingLeft: 5, paddingTop: 5, paddingBottom: 5,borderRadius:0 }}>
                                <>
                                  <AwardDetailPage
                                    title={i18next.language != 'zh' ? t('drawno') + item.issue : "第" + item.issue + "期"}
                                    description={getResult(item)}
                                    right={convertByTimeZone(item.openTime, 'MM-DD HH:mm')}
                                    playid={this.props.playId} parentindex={parentIndex}
                                    total={getResult(item).reduce((a, b) => parseInt(a) + parseInt(b))}
                                  />
                                </>
                              </ListGroupItem>
                            </React.Fragment>
                          )
                        })
                        : null
                      }
                    </ListGroup>
                  </Col>
                </Row>
              }
      </Modal>
    )
  }
}

export default withTranslation('common')(AwardDetailModal);
