import React from 'react';
import { withTranslation } from "react-i18next";
import bn from '../../../utils/bemnames';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import Slider from '@material-ui/core/Slider';
import { UpdateAlert } from '../../../actions/HomePageAction';
import qs from 'qs';
import SimpleNumberPickerModal from '../../../components/Modal/SimpleNumberPickerModal';
import PickerModal from '../../../components/Modal/PickerModal';
import { Col } from 'reactstrap';
import i18next from 'i18next';
import { alert as alertBasic } from '../../../services/AlertService';
import Fetcher from '../../../components/Fetcher';

const bem = bn.create('header');

const mapStateToProps = (state) => {
    return {
        alertObj: state.alert,
        indexInit: state.indexInit.indexInit,
    };
};

const mapDispatchToProps = dispatch => (
    bindActionCreators({
        UpdateAlert,
    }, dispatch)
);

const subAccountClose = (closeFunc) => {
    //navigator.clipboard.writeText(document.getElementById('info').innerText);
    let textField = document.createElement('textarea');
    textField.innerText = document.getElementById('info').innerText;
    document.body.appendChild(textField);
    textField.select();
    document.execCommand('copy');
    textField.remove();
    closeFunc();
}

let triggerSave = [];

class AgentPromotionTab1 extends React.Component {
    constructor(props) {
        super(props);
        this.rebateChange = this.rebateChange.bind(this);
        this.typechange = this.typechange.bind(this);
        this.gamerebateboxChange = this.gamerebateboxChange.bind(this);
        this.save = this.save.bind(this);
        this.selectPickNum = this.selectPickNum.bind(this);
        this.deletePickNum = this.deletePickNum.bind(this);
        this.triggerPickNum = this.triggerPickNum.bind(this);
        this.triggerPickNumClose = this.triggerPickNumClose.bind(this);
        this.confirmSave = this.confirmSave.bind(this);
        this.triggerEdit = this.triggerEdit.bind(this);
        this.triggerPickSelectChange = this.triggerPickSelectChange.bind(this);
        this.triggerPickSelectOpen = this.triggerPickSelectOpen.bind(this);
        this.triggerPickSelectClose = this.triggerPickSelectClose.bind(this);
        triggerSave.push(this.save)
    }

    state = {
        init: false,
        landinPages: {},
        landKeys: [],
        landValues: [],
        childType: 1,
        newpromotion: {
            accountType: "0",
            id: this.props.selectedId
        },
        edittitle: "",
        promotionData: {
            promotionId: 0
            , promotionacctype: 0
        },
        acc: {},
        platforms: {},
        gameRebateStatus: "",
        gamePlayRebateList: {},
        keypad: {},
        smsg: false,
        selectPromotion: "0",
        gamerebateboxRangeValue: 0.0,
        rebateRangeValue: 0.0,
        rangeInput1: 0.0,
        save: false,
        numberPickerShow: false,
        numberPickerValue: "0",
        selectedPickerFieldName: "",
        selectedPickerCategory: "",
        selectPickerShow: false,
        selectPickerOptionName: {},
        selectPickerOptionValue: {},
        selectedPickerOption: "",
    };

    callLoadDataApi() {
        const { t } = this.props;
        let loginUser = this.props.indexInit.loginUser;
        const param = new URLSearchParams();
        param.set('dayType', '');
        if (typeof (loginUser.loginName) != 'undefined') {
            Fetcher(window.$wgtDomain + '/wap/member-center/promotion/newdata' + (this.props.selectedId ? ("?id=" + this.props.selectedId) : ""), {
                method: 'POST',
                headers: {
                    'Accept': 'application/json, text/plain, */*',
                    'Content-Type': 'application/x-www-form-urlencoded',
                },
                // credentials: 'include',
                body: param,
                referrer: 'h5'
            })
                .then((response) => {
                    if (!response.ok) {
                    } else {
                        response.json()
                            .then((data) => {
                                if (data.status) {
                                    let currentState = this.state;
                                    if (!data.promotion) {
                                        currentState.childType = data.acc.childType;
                                        currentState.newpromotion = { pchainedType: 0, accountType: data.acc.childType, landinPage: 'memreg/register', rebateRateMap: {}, gameRebateRateMap: {} }
                                    } else
                                        currentState.newpromotion = data.promotion;
                                    currentState.newpromotion.accountType += "";
                                    currentState.edittitle = !currentState.newpromotion.id ? t('subaccountpage.new') : t('subaccountpage.edit');
                                    if (!currentState.newpromotion.rebateRateMap)
                                        currentState.newpromotion.rebateRateMap = {};
                                    if (!currentState.newpromotion.gameRebateRateMap)
                                        currentState.newpromotion.gameRebateRateMap = {};
                                    currentState.acc = data.acc;
                                    currentState.acc.statusName = data.acc.userStatus == 0 ? t("subaccountpage.normal") : data.acc.userStatus == 1 ? t("subaccountpage.stop") : t("subaccountpage.locked");
                                    currentState.platforms = data.platforms;
                                    currentState.landinPages = data.pagelink;
                                    let tempArray = [];
                                    Object.entries(currentState.landinPages).map((item, index) => {
                                        const key = item[0], value = item[1];
                                        tempArray.push(t(value));
                                    });
                                    currentState.selectPickerOptionName = { select: tempArray };
                                    currentState.selectPickerOptionValue = { select: currentState.landinPages[currentState.newpromotion.landinPage] };
                                    currentState.selectedPickerOption = currentState.landinPages[currentState.newpromotion.landinPage];
                                    currentState.landKeys = data.landKeys;
                                    currentState.landValues = data.landValues;
                                    currentState.gameRebateStatus = data.gameRebateStatus;
                                    currentState.gamePlayRebateList = data.gamePlayRebateList;
                                    for (let k in currentState.platforms) {
                                        if (typeof currentState.newpromotion.rebateRateMap[k] == "undefined")
                                            currentState.newpromotion.rebateRateMap[k] = 0;
                                    }

                                    if (currentState.gameRebateStatus == "1") {
                                        for (let g in currentState.gamePlayRebateList) {
                                            if (typeof currentState.newpromotion.gameRebateRateMap[g] == "undefined")
                                                currentState.newpromotion.gameRebateRateMap[g] = 0;
                                        }
                                    }
                                    currentState.landinPage = currentState.landinPages[currentState.newpromotion.landinPage];
                                    currentState.accountType = currentState.newpromotion.accountType == "1" ? t("usercentrepage.member") : t("usercentrepage.agent");
                                    this.setState(currentState);

                                    Fetcher(window.$wgtDomain + '/wap/member-center/promotion/getPromotionCode', {
                                        method: 'POST',
                                        headers: {
                                            'Accept': 'application/json, text/plain, */*',
                                            'Content-Type': 'application/x-www-form-urlencoded',
                                        },
                                        // credentials: 'include',
                                        body: param,
                                        referrer: 'h5'
                                    }).then((response) => {
                                        if (!response.ok) {
                                        } else {
                                            response.json().then((data) => {
                                                if (data.promotionCode) {
                                                    const promotionArray = JSON.parse(data.promotionCode);
                                                    this.setState({ promotionArray: promotionArray });
                                                }
                                            })
                                        }
                                    });

                                } else {
                                    if (this.state.smsg) {
                                        this.normalAlert(data.msg);
                                        // if (data.status === false && (data.msg === "您的后台尚未设置返佣比，请联系客服。谢谢" || data.msg === "您的后台尚未设置返点比，请联系客服。谢谢"))
                                        //     chaos.fw7.curView.router.load({ url: "tpl/myIndex.html" });
                                    }

                                }
                            })
                    }
                })

        } else {
            this.normalAlert(t('subaccountpage.pleaselogin'));
        }
    }

    selectPickNum = (input,event) => {
        let iosVersion = 100;
        if(window.$isIonic){
            if(window.$platform == 'IOS') {
                iosVersion = window.$platformVersion.split('.')[0];
            }
        }
        else if (window.$isApp){
            let plus = window.$plus;
            if(plus.os.name.toUpperCase() == 'IOS') {
                iosVersion = plus.os.version.split('.')[0];
            }
        }

        if(event.pointerType=='touch' || parseInt(iosVersion) < 15 || (!window.$isApp && event.pointerType=='mouse') || event.pointerType==undefined){
            if(input =='{bksp}'){
                this.deletePickNum();
            }
            else{
                let currState = this.state;
                currState.numberPickerValue = currState.numberPickerValue + input;
                if (currState.selectedPickerCategory == 'rebateRateMap') {
                    currState.newpromotion.rebateRateMap[currState.selectedPickerFieldName] = parseFloat(currState.numberPickerValue).toFixed(1)
                } else if (currState.selectedPickerCategory == 'gameRebateRateMap') {
                    currState.newpromotion.gameRebateRateMap[currState.selectedPickerFieldName] = parseFloat(currState.numberPickerValue).toFixed(1)
                } else {
                    return;
                }
                this.setState(currState);
                }
        }
    }

    deletePickNum() {
        let currState = this.state;
        currState.numberPickerValue = currState.numberPickerValue.toString().slice(0, -1);
        if (currState.selectedPickerCategory == 'rebateRateMap') {
            currState.newpromotion.rebateRateMap[currState.selectedPickerFieldName] = parseFloat(currState.numberPickerValue).toFixed(1)
        } else if (currState.selectedPickerCategory == 'gameRebateRateMap') {
            currState.newpromotion.gameRebateRateMap[currState.selectedPickerFieldName] = parseFloat(currState.numberPickerValue).toFixed(1)
        } else {
            return;
        }
        this.setState(currState);
    }

    triggerPickNum(event) {
        let currState = this.state;
        currState.numberPickerShow = true;
        currState.selectedPickerFieldName = event.target.getAttribute("name");
        currState.selectedPickerCategory = event.target.getAttribute("category");
        if (currState.selectedPickerCategory == 'rebateRateMap') {
            currState.numberPickerValue = currState.newpromotion.rebateRateMap[currState.selectedPickerFieldName]!=undefined?currState.newpromotion.rebateRateMap[currState.selectedPickerFieldName]:0.0;
        } else if (currState.selectedPickerCategory == 'gameRebateRateMap') {
            currState.numberPickerValue = currState.newpromotion.gameRebateRateMap[currState.selectedPickerFieldName]
        } else {
            return;
        }
        this.setState(currState);
    }

    triggerPickNumClose(event) {
        if (!event.target.getAttribute('category')) {
            let currState = this.state;
            if ((!currState.numberPickerValue) || isNaN(currState.numberPickerValue)) {
                if (currState.selectedPickerCategory == 'rebateRateMap') {
                    currState.newpromotion.rebateRateMap[currState.selectedPickerFieldName] = 0;
                } else if (currState.selectedPickerCategory == 'gameRebateRateMap') {
                    currState.newpromotion.gameRebateRateMap[currState.selectedPickerFieldName] = 0;
                }

            }
            currState.numberPickerShow = false;
            currState.numberPickerValue = 0;
            currState.selectedPickerFieldName = "";
            currState.selectedPickerCategory = "";
            this.setState(currState);
        }
    }

    triggerPickSelectOpen() {
        let currState = this.state;
        currState.selectPickerShow = true;
        this.setState(currState);
    }

    triggerPickSelectClose(event) {
        let currState = this.state;
        currState.selectPickerShow = false;
        this.setState(currState);
    }

    triggerPickSelectChange(name, value) {
        console.log(name, value);
        this.setState({
            selectPickerOptionValue: { select: value },
            selectedPickerOption: value,
        })
    }

    typechange(e) {
        let currState = this.state;
        const type = e.target.value;
        currState.newpromotion.accountType = type;
        currState.promotionData.promotionacctype = type;
        currState.selectPromotion = "0";
        // $(".toggleEmpty, #rangeInput1, #rangeInput2").val(0.0);
        if(!currState.newpromotion.id){//if edit, currState.newpromotion.id is exist, dont reset.
            currState.gamerebateboxRangeValue = 0.0;
            currState.rebateRangeValue = 0.0;

            for (let i = 0; i < Object.keys(currState.gamePlayRebateList).length; i++) {
                currState.newpromotion.gameRebateRateMap[Object.keys(currState.gamePlayRebateList)[i]] = 0;
            }
            for (let i = 0; i < Object.keys(currState.newpromotion.rebateRateMap).length; i++) {
                currState.newpromotion.rebateRateMap[Object.keys(currState.platforms)[i]] = 0;
            }
        }
        this.setState(currState);
    }

    rebateChange(e, newValue) {
        let currState = this.state;
        currState.rebateRangeValue = newValue;
        for (let i in Object.keys(currState.platforms)) {
            if (!Number.isNaN(parseInt(i))) {
                currState.newpromotion.rebateRateMap[Object.keys(currState.platforms)[i]] = currState.rebateRangeValue >= 0.0 && currState.rebateRangeValue <= currState.acc.rebateRateMap[Object.keys(currState.platforms)[i]] ?
                    currState.rebateRangeValue : currState.acc.rebateRateMap[Object.keys(currState.platforms)[i]];
                // currState.keypad[Object.keys(currState.platforms)[i]].setValue(currState.newpromotion.rebateRateMap[Object.keys(currState.platforms)[i]].toFixed(1));
            }
        }
        this.setState(currState);
    }

    gamerebateboxChange(e, newValue) {
        let currState = this.state;
        currState.gamerebateboxRangeValue = newValue;
        for (var i in Object.keys(currState.gamePlayRebateList)) {
            if (!Number.isNaN(parseInt(i))) {
                currState.newpromotion.gameRebateRateMap[Object.keys(currState.gamePlayRebateList)[i]] = currState.gamerebateboxRangeValue >= 0.0 && currState.gamerebateboxRangeValue <= currState.acc.gameRebateRateMap[Object.keys(currState.gamePlayRebateList)[i]] ?
                    currState.gamerebateboxRangeValue : currState.acc.gameRebateRateMap[Object.keys(currState.gamePlayRebateList)[i]];
                // currState.keypad[Object.keys(currState.gamePlayRebateList)[i]].setValue(currState.newpromotion.gameRebateRateMap[Object.keys(currState.gamePlayRebateList)[i]].toFixed(1));
            }
        }
        this.setState(currState);
    };

    normalAlert(content) {
        const { alertObj, UpdateAlert } = this.props;
        alertObj.title = this.props.t('subaccountpage.hitsmsg');
        alertObj.alertType = 'normalAlert';
        alertObj.showAlert = true;
        alertObj.content = content;
        alertObj.functionName = false;
        UpdateAlert(alertObj);
    }

    customizeAlert(data) {
        const { alertObj, UpdateAlert } = this.props;
        alertObj.title = this.props.t('subaccountpage.hitsmsg');
        alertObj.alertType = 'subAccountAlert';
        alertObj.showAlert = true;
        alertObj.data = data;
        alertObj.functionName = 'subAccountClose';
        UpdateAlert(alertObj);
    }

    triggerEdit() {
    }

    save(param) {
        const {t} = this.props;
        let currState = this.state;
        if(param.newpromotion)
            currState.newpromotion = param.newpromotion;
        if(param.selectedPickerOption)
            currState.selectedPickerOption = param.selectedPickerOption;
        currState.save = true;

        if (currState.newpromotion.accountType == "0") {
            for (var k in currState.newpromotion.rebateRateMap) {
                if (currState.newpromotion.rebateRateMap[k] < 0 || currState.newpromotion.rebateRateMap[k] === "") {
                    alert(this.props.t(currState.platforms[k]) + " " + t("subaccountpage.msg.commissionratecannotlesszero"));
                    return;
                }
                if (currState.newpromotion.rebateRateMap[k] > currState.acc.rebateRateMap[k]) {
                    alert(this.props.t(currState.platforms[k]) + " " + t("subaccountpage.msg.commissionratecannotmore") + currState.acc.rebateRateMap[k]);
                    return;
                }
            }
        }

        if (currState.gameRebateStatus == "1") {
            for (var k in currState.newpromotion.gameRebateRateMap) {
                if (currState.newpromotion.gameRebateRateMap[k] < 0 || currState.newpromotion.gameRebateRateMap[k] === "") {
                    alert(this.props.t(currState.gamePlayRebateList[k]) + " " + t("subaccountpage.msg.rebateratecannotlesszero"));
                    return;
                }
                if (currState.newpromotion.gameRebateRateMap[k] > currState.acc.gameRebateRateMap[k]) {
                    alert(this.props.t(currState.gamePlayRebateList[k]) + " " + t("subaccountpage.msg.rebateratecannotmore") + currState.acc.gameRebateRateMap[k]);
                    return;
                }
            }
        }
        currState.newpromotion.landinPage = Object.keys(currState.landinPages).find(key => currState.landinPages[key] === currState.selectedPickerOption);
        this.setState(currState);
        Fetcher(window.$wgtDomain + '/wap/member-center/promotion/save', {
            method: 'POST',
            headers: {
                'Accept': 'application/json, text/plain, */*',
                'Content-Type': 'application/x-www-form-urlencoded',
            },
            // credentials: 'include',
            body: qs.stringify(currState.newpromotion),
            referrer: 'h5'
        }).then((response) => response.json())
            .then((data) => {
                let currState = this.state;
                currState.save = false;
                alertBasic(data.msg);
                if (data.status) {
                    for (var i in Object.keys(currState.platforms)) {
                        if (!Number.isNaN(parseInt(i))) {
                            currState.newpromotion.rebateRateMap[Object.keys(currState.platforms)[i]] = currState.rebateRangeValue >= 0.0 && currState.rebateRangeValue <= currState.acc.rebateRateMap[Object.keys(currState.platforms)[i]] ?
                                currState.rebateRangeValue : currState.acc.rebateRateMap[Object.keys(currState.platforms)[i]];
                            // keypad[Object.keys($scope.platforms)[i]].setValue($scope.newpromotion.rebateRateMap[Object.keys($scope.platforms)[i]].toFixed(1));
                        }
                    }

                    for (var i in Object.keys(currState.gamePlayRebateList)) {
                        if (!Number.isNaN(parseInt(i))) {
                            currState.newpromotion.gameRebateRateMap[Object.keys(currState.gamePlayRebateList)[i]] = currState.gamerebateboxRangeValue >= 0.0 && currState.gamerebateboxRangeValue <= currState.acc.gameRebateRateMap[Object.keys(currState.gamePlayRebateList)[i]] ?
                                currState.gamerebateboxRangeValue : currState.acc.gameRebateRateMap[Object.keys(currState.gamePlayRebateList)[i]];
                            // keypad[Object.keys($scope.gamePlayRebateList)[i]].setValue($scope.newpromotion.gameRebateRateMap[Object.keys($scope.gamePlayRebateList)[i]].toFixed(1));
                        }
                    }
                    currState.promotionData.promotionId = 0;
                    currState.selectPromotion = "0";
                    this.props.handleChangeIndex(1, true);
                }
                this.setState(currState);
            }).catch((err) => { console.log(err) });
    }

    componentDidMount() {
        this.callLoadDataApi();
    }

    confirmSave() {
        const { alertObj, UpdateAlert } = this.props;
        alertObj.title = this.props.t('subaccountpage.hitsmsg');
        alertObj.alertType = 'confirmAlertWithFunction';
        alertObj.showAlert = true;
        alertObj.content = this.props.selectedId ? this.props.t('agentpromotiontab1.confirmmodify') : this.props.t('agentpromotiontab1.confirmadd');
        alertObj.isShowCancelBtn = true;
        alertObj.functionName = 'AgentPromotionTab1Save';
        alertObj.newpromotion = this.state.newpromotion??"";
        alertObj.selectedPickerOption = this.state.selectedPickerOption??"";
        UpdateAlert(alertObj);
    }

    render() {
        const currState = this.state;
        const {t} = this.props;

        let safeRebateRateValue = currState.acc.rebateRateMap != undefined ? (currState.acc.rebateRateMap['safe'] != undefined ? currState.acc.rebateRateMap['safe'] : 0) : 0;
        let iosVersion = 0;
        if(window.$isIonic){
            if(window.$platform == 'IOS') {
                iosVersion = window.$platformVersion.split('.')[0];
            }
        }
        else if (window.$isApp){
            let plus = window.$plus;
            if(plus.os.name.toUpperCase() == 'IOS') {
                iosVersion = plus.os.version.split('.')[0];
            }
        }
        return (
            <div>
                <div onClick={this.triggerPickNumClose} className="sub-account page-content-44">
                    <div className="list-block ptb12">
                        <ul>
                            {/* <li style={currState.childType != 0 ? { opacity: '.55', pointerEvents: 'none' } : {}}> bug 11361, comment because able to edit type*/}
                            <li>
                                <div className="item-content" style={parseInt(iosVersion) < 16?{}:{position:'-webkit-sticky'}}>
                                    <Col className="item-media">{t('subaccountpage.acctype')}</Col>
                                    <Col xs="9">
                                        <RadioGroup style={{ paddingTop: 10}} row value={this.state.newpromotion.accountType}>
                                        <FormControlLabel classes={{ label: 'font14px' }} value={"0"} control={<Radio style={{color:'var(--theme-color)'}} />} labelPlacement="end" label={t("subaccountpage.agenttype")} onClick={this.typechange} />
                                        <FormControlLabel classes={{ label: 'font14px' }} value={"1"} control={<Radio style={{color:'var(--theme-color)'}} />} labelPlacement="end" label={t("agentpromotiontab1.membertype")} onClick={this.typechange} />
                                        </RadioGroup>
                                    </Col>
                                </div>
                            </li>
                        </ul>
                    </div>
                    {currState.newpromotion.accountType == "0" && safeRebateRateValue != 0 ?
                        <div className="list-block ptb12">
                            <ul>
                                    <div className="item-content">
                                        <span>
                                            <label className="font14px">{t('subaccountpage.commitsetting')}</label><label style={{color: '#D3D3D3' }} className="font14px">&nbsp;&nbsp;{t('subaccountpage.msg.plssetcommsetting')} </label>
                                        </span>
                                    </div>
                                    <div className="item-content" style={{ textAlign: "center" }}>
                                        <div className="sub-account-slider" style={parseInt(iosVersion) < 16?{}:{position:'-webkit-sticky'}} data-label="true">
                                            <div>
                                                <div style={{ width: "80%", paddingLeft: '5px', height: "auto", float: "left" }}>
                                                    <Slider
                                                        value={currState.rebateRangeValue}
                                                        onChange={this.rebateChange} step={0.1} min={0} max={currState.acc.maxRebateRate ? currState.acc.maxRebateRate : 0}
                                                        aria-labelledby="input-slider" style={{ width: '100%' }}
                                                    />
                                                </div>
                                                <div style={{ width: "20%", height: "auto", float: "left",display: "inline", textAlign: "left", paddingTop: "10px", paddingLeft: "10px" }} className="rangeVal">
                                                    {currState.rebateRangeValue + '%'}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {Object.entries(currState.platforms).map((item, index) => {
                                        const key = item[0], value = item[1];
                                        return (
                                            <li key={index} className="rebatebox">
                                                <div className="item-content">
                                                    <Col xs="6" className="item-media" style={i18next.language == 'cn' ? {}:{display:'block'}}>{t('subaccountpage.subcordinatecommrate')}[<span >{t(value)}</span>]</Col>
                                                    <Col xs="4" style={parseInt(iosVersion) < 16?{}:{position:'-webkit-sticky'}}>
                                                        {!currState.acc.rebateRateMap[key] ?
                                                            <span>
                                                                <input style = {{backgroundColor: '#D3D3D3', borderRadius: 5, paddingLeft: 10 }} type="text" readOnly onClick={this.triggerPickNum} category="rebateRateMap" name={key} className={'rebateRateMap-' + key + ' toggleEmpty'} max={currState.acc.rebateRateMap[key]} value={currState.newpromotion.rebateRateMap[key]} placeholder={t("subaccountpage.range") + "[0.0-0.0]%"} />
                                                            </span> :
                                                            <span>
                                                                <input style = {{backgroundColor: '#D3D3D3', borderRadius: 5, paddingLeft: 10 }} type="text" readOnly onClick={this.triggerPickNum} category="rebateRateMap" name={key} className={'rebateRateMap-' + key + ' toggleEmpty'} max={currState.acc.rebateRateMap[key]} value={currState.newpromotion.rebateRateMap[key]} placeholder={t("subaccountpage.range") + '[0.0-' + currState.acc.rebateRateMap[key] + ']%'} />
                                                            </span>
                                                        }
                                                    </Col>
                                                    <Col>%</Col>
                                                </div>
                                            </li>
                                        )
                                    })}
                            </ul>
                        </div> : null
                    }
                    <div className="list-block ptb12">
                        <ul>
                            {currState.gameRebateStatus == "1" ?
                                <>
                                    <div className="item-content">
                                        <span>
                                            <label className="font14px">{t('subaccountpage.rebatesetting')}</label><label style={{color: '#D3D3D3' }} className="font14px">&nbsp;&nbsp;{t('subaccountpage.msg.plssetrebatesetting')}</label>
                                        </span>
                                    </div>
                                    <div className="item-content" style={parseInt(iosVersion) < 16?{textAlign: "center"}:{textAlign: "center",position:'-webkit-sticky'}}>
                                        <div className="sub-account-slider" data-label="true">
                                            <div>
                                                <div style={{ width: "80%", paddingLeft: '5px', height: "auto", float: "left", display: "inline" }}>
                                                    <Slider
                                                        value={currState.gamerebateboxRangeValue}
                                                        onChange={this.gamerebateboxChange} step={0.1} min={0} max={currState.acc.gameMaxRebateRate ? currState.acc.gameMaxRebateRate : 0}
                                                        aria-labelledby="input-slider" style={{ width: '100%' }}
                                                    />
                                                </div>
                                                <div style={{ width: "20%", height: "auto", float: "left", display: "inline", textAlign: "left", paddingTop: "10px", paddingLeft: "10px" }} className="rangeVal">
                                                    {currState.gamerebateboxRangeValue + '%'}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {Object.entries(currState.gamePlayRebateList).map((item, index) => {
                                        const key = item[0], value = item[1];
                                        return (
                                            <li key={index} className="gamerebatebox">
                                                <div className="item-content">
                                                    <Col xs="6" className="item-media" style={i18next.language == 'zh' ? {}:{display:'block'}}>{t('subaccountpage.rebaterate')}[<span >{t(value)}</span>]</Col>
                                                    <Col xs="4" style={parseInt(iosVersion) < 16?{}:{position:'-webkit-sticky'}}>
                                                        {!currState.acc.rebateRateMap[key] ?
                                                            <span>
                                                                <input style = {{backgroundColor: '#D3D3D3', borderRadius: 5, paddingLeft: 10 }} type="text" readOnly onClick={this.triggerPickNum} category="gameRebateRateMap" name={key} classame={'gameRebateRateMap-' + key + ' toggleEmpty'} max={currState.acc.gameRebateRateMap[key]} value={currState.newpromotion.gameRebateRateMap[key]} placeholder={t("subaccountpage.range") + "[0.0-0.0]%"} />
                                                            </span> :
                                                            <span>
                                                                <input style = {{backgroundColor: '#D3D3D3', borderRadius: 5, paddingLeft: 10 }} type="text" readOnly onClick={this.triggerPickNum} category="gameRebateRateMap" name={key} className={'gameRebateRateMap-' + key + ' toggleEmpty'} max={currState.acc.gameRebateRateMap[key]} value={currState.newpromotion.gameRebateRateMap[key]} placeholder={t("subaccountpage.range") + '[0.0-' + currState.acc.gameRebateRateMap[key] + ']%'} />
                                                            </span>
                                                        }
                                                    </Col>
                                                    <Col>%</Col>
                                                </div>
                                            </li>
                                        )
                                    })}
                                </> : null
                            }
                            <li>
                                <div className="item-content">
                                    <Col className="item-media">{t('agentpromotiontab1.landingpage')}</Col>
                                    <Col xs="9">
                                        <input className="input-inline" type="text" readOnly id="landinPagebk" onClick={this.triggerPickSelectOpen} value={t(currState.selectedPickerOption)}/>
                                    </Col>
                                </div>
                            </li>
                        </ul>
                    </div>
                    <div className="content-block"><a onClick={this.confirmSave} className="f7-button button-fill" style={{ color: 'white' }}>{t('common.confirm')}</a></div>
                </div>
                <SimpleNumberPickerModal isDecimalPlace={true} show={currState.numberPickerShow} value={currState.numberPickerValue} selectPickNum={this.selectPickNum} deletePickNum={this.deletePickNum} complete={this.triggerPickNumClose} />
                <PickerModal
                    pickerShow={currState.selectPickerShow}
                    selectComplete={this.triggerPickSelectClose}
                    optionGroups={currState.selectPickerOptionName}
                    valueGroups={currState.selectPickerOptionValue}
                    pickerChange={this.triggerPickSelectChange}
                ></PickerModal>
            </div>
        );
    }
}


export { subAccountClose, triggerSave }
export default withTranslation('common')(connect(mapStateToProps, mapDispatchToProps)(AgentPromotionTab1));
