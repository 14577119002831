import React from 'react';
//import "core-js/stable";
import "regenerator-runtime/runtime";
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { I18nextProvider } from 'react-i18next';
import { configureStore, persistor } from './utils/redux';
import configureI18n from './utils/i18n';
import App from './App';
import { PersistGate } from 'redux-persist/integration/react';
import IdleCheck from './utils/IdleCheck';
// import { registerLocale, setDefaultLocale } from  "react-datepicker";
import { registerLocale } from  "react-datepicker";
import zhCN from 'date-fns/locale/zh-CN';
import viVN from 'date-fns/locale/vi';
import hiIN from 'date-fns/locale/hi';
import thTH from 'date-fns/locale/th';
import engEN from 'date-fns/locale/en-US';
import idID from "date-fns/locale/id";
import ptPT from "date-fns/locale/pt";
import esES from "date-fns/locale/es";

registerLocale('cn', zhCN);
registerLocale('en', engEN);
registerLocale('th', thTH);
registerLocale('vi', viVN);
registerLocale('in', hiIN);
registerLocale('id', idID);
registerLocale('pt', ptPT);
registerLocale('es', esES);

const store = configureStore();

const i18n = configureI18n();

const initedPersistor = persistor(store);

const root = document.getElementById('root');
ReactDOM.render(
  <Provider store={store}>
    <React.Suspense fallback="">
    <PersistGate loading={null} persistor={initedPersistor}>
      <I18nextProvider i18n={i18n}>
        <IdleCheck>
          <App />
        </IdleCheck>
      </I18nextProvider>
    </PersistGate>
    </React.Suspense>
  </Provider>,
  root
);

export { store, initedPersistor }
