import React from 'react';
import { Row, Col, ListGroup, ListGroupItem, Button } from 'reactstrap';
import CashFlowRecord from '../components/CashFlowRecord';
import CashFlowHeader from '../components/Layout/CashFlowHeader';
import { MdPhotoFilter, MdAutorenew } from 'react-icons/md';
import { withTranslation } from 'react-i18next';
import moment from 'moment';
import { withLastLocation } from 'react-router-last-location';
import { connect } from 'react-redux';
import LocalizeAwardResult from '../components/GameLayout/Popup/LocalizeAwardResult';
import { showAwardLocalizeResult } from '../services/AwardService';
import { store } from '../index';
import InfiniteScroll from "react-infinite-scroll-component";
import { intToString } from '../services/HomeService';
import GameFetcher from '../components/GameFetcher';


const mapStateToProps = (state) => {
  return {
    indexInit: state.indexInit.indexInit,
    memberInfo: state.memberInfo,
    game: store.getState().game,
    home: state.home
  };
};

class CashFlowPage extends React.Component {

  constructor(props) {
    super(props);
    this.onClickDateChange = this.onClickDateChange.bind(this);
    this.callApi = this.callApi.bind(this);
    this.onToggle = this.onToggle.bind(this);
    //this.selectGame = this.selectGame.bind(this);
    this.myRef = React.createRef();
    let result1 = {};

    this.state = {
      result: true,
      dataSource: [],
      currPageNo: 1,
      hasMore: true,
      title: "today",
      tabLine: "left",
      loading: false,
      status: true,
      winStatus: "-1",
      dayType: "0",
      toggleEvent: Date.now(),
      toggleEvent2: Date.now(),
      condition: false,
      selected: "0",
      tdb: "#f85032",
      tdw: "#f85032",
      ydb: "#212121",
      ydw: "#e1e1e1",
      twb: "#212121",
      tww: "#e1e1e1",
      lwb: "#212121",
      lww: "#e1e1e1",
      tmb: "#212121",
      tmw: "#e1e1e1",
      lmb: "#212121",
      lmw: "#e1e1e1",

      selDayType: "",
      selApiPlatKey: Object.keys(result1)[0],
      selApiPlatName: Object.values(result1)[0],
      apiList: result1,
      activeIndex: 0,
      onToggle: false,
      value: -1,
      gameId: "",
      gameName: ""
    };
  }

  onClickRefresh() {

    this.setState({
      loading: true,
      currPageNo: 1,
      dataSource: [],
    }, this.callApi);

    setTimeout(() => {
      this.setState({ loading: false });
    }, 400)
    this.myRef.current.scrollTo(0, 0);
  }

  callApi() {
    this.setState({ loading: true });
    const param = new URLSearchParams();
    param.set('pageNo', this.state.currPageNo);
    param.set('dayType', this.state.dayType);
    param.set('status', this.state.status);
    if (this.state.gameId != "") {
      param.set('gameId', this.state.gameId);
    }
    GameFetcher(window.$gameUrl + "/rest/records/capitalflow", {
      method: "Post",
      headers: {
        'Accept': 'application/json, text/plain, */*',
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      // credentials: 'include',
      body: param
    })
      .then((response) => response.json().then((data) => {
        if (data.status) {
          let dataSource = this.state.dataSource;
          if (data.result.page.page === this.state.currPageNo) {
            if (data.result.page.pages === this.state.currPageNo) {
              this.setState({ hasMore: false })
            }
            let resultList = data.result.page.records;
            if (resultList) {
              if (this.state.value != -1)
                resultList = resultList.filter(item => item.winStatus == this.state.value);
              dataSource = dataSource.concat(resultList);
              this.setState({
                currPageNo: this.state.currPageNo + 1,
                dataSource: dataSource,
                loading: false
              });
            }
          }
          
        }
      }))
      .catch((error) => {
        console.error(error);
      });
  }

  componentDidMount() {
    if (this.props.lastLocation == null) {
      this.props.history.push('/');
    }
    this.callApi()
  }

  onClickDateChange = e => {
    if (e.target.value === "0") {
      this.setState({
        dayType: e.target.value,
        title: "today",
        currPageNo: 1,
        dataSource: [],
        selected: '0',
        tdb: "#f85032",
        tdw: "#f85032",
        ydb: "#212121",
        ydw: "#e1e1e1",
        twb: "#212121",
        tww: "#e1e1e1",
        lwb: "#212121",
        lww: "#e1e1e1",
        tmb: "#212121",
        tmw: "#e1e1e1",
        lmb: "#212121",
        lmw: "#e1e1e1"
      }, this.callApi);

    } else if (e.target.value === "1") {
      this.setState({
        dayType: e.target.value,
        title: "yesterday",
        currPageNo: 1,
        dataSource: [],
        selected: '1',
        tdb: "#212121",
        tdw: "#e1e1e1",
        ydb: "#f85032",
        ydw: "#f85032",
        twb: "#212121",
        tww: "#e1e1e1",
        lwb: "#212121",
        lww: "#e1e1e1",
        tmb: "#212121",
        tmw: "#e1e1e1",
        lmb: "#212121",
        lmw: "#e1e1e1"
      }, this.callApi);

    } else if (e.target.value === "2") {
      this.setState({
        dayType: e.target.value,
        title: "thisweek",
        currPageNo: 1,
        dataSource: [],
        selected: '2',
        tdb: "#212121",
        tdw: "#e1e1e1",
        ydb: "#212121",
        ydw: "#e1e1e1",
        twb: "#f85032",
        tww: "#f85032",
        lwb: "#212121",
        lww: "#e1e1e1",
        tmb: "#212121",
        tmw: "#e1e1e1",
        lmb: "#212121",
        lmw: "#e1e1e1"
      }, this.callApi);

    } else if (e.target.value === "3") {
      this.setState({
        dayType: e.target.value,
        title: "lastweek",
        currPageNo: 1,
        dataSource: [],
        selected: '3',
        tdb: "#212121",
        tdw: "#e1e1e1",
        ydb: "#212121",
        ydw: "#e1e1e1",
        twb: "#212121",
        tww: "#e1e1e1",
        lwb: "#f85032",
        lww: "#f85032",
        tmb: "#212121",
        tmw: "#e1e1e1",
        lmb: "#212121",
        lmw: "#e1e1e1"
      }, this.callApi);

    } else if (e.target.value === "4") {
      this.setState({
        dayType: e.target.value,
        title: "thismonth",
        currPageNo: 1,
        dataSource: [],
        selected: '4',
        tdb: "#212121",
        tdw: "#e1e1e1",
        ydb: "#212121",
        ydw: "#e1e1e1",
        twb: "#212121",
        tww: "#e1e1e1",
        lwb: "#212121",
        lww: "#e1e1e1",
        tmb: "#f85032",
        tmw: "#f85032",
        lmb: "#212121",
        lmw: "#e1e1e1"
      }, this.callApi);

    } else if (e.target.value === "5") {
      this.setState({
        dayType: e.target.value,
        title: "lastmonth",
        currPageNo: 1,
        dataSource: [],
        selected: '5',
        tdb: "#212121",
        tdw: "#e1e1e1",
        ydb: "#212121",
        ydw: "#e1e1e1",
        twb: "#212121",
        tww: "#e1e1e1",
        lwb: "#212121",
        lww: "#e1e1e1",
        tmb: "#212121",
        tmw: "#e1e1e1",
        lmb: "#f85032",
        lmw: "#f85032"
      }, this.callApi);
    }
    this.onToggle2();
  }


  onToggle = () => {
    this.setState({
      toggleEvent: Date.now(),
      //condition: !this.state.condition
    });
  };

  onToggle2 = () => {
    this.setState({
      toggleEvent2: Date.now(),
      condition: !this.state.condition
    });
  };


  render() {
    const { t } = this.props;
    const { activeIndex } = this.state;
    const winHeight = window.innerHeight;
    const pageHeight = winHeight - 47;
    let divs = ['parentDiv1', 'parentDiv2'];

    let changeGameHeight = window.innerHeight - 92;
    let dataAttribute = {
      splayid: this.props.splayId,
      playid: this.props.playId,
      id: this.props.id,
    };
    return (
      <div>
        {
          this.props.game.popupObj != null && this.props.game.popupObj.showAwardLocalizeResult ?
            <LocalizeAwardResult onModalClose={this.onModalClose} {...this.props} />
            :
            null
        }
        <CashFlowHeader {...this.props} dataSource={this.state.dataSource} apiList={this.state.apiList}
          onClickDateChange={this.onClickDateChange}
          selected={this.state.selected} title={this.state.title}
          toggleEvent2={this.state.toggleEvent2} onToggle2={this.onToggle2} condition={this.state.condition}
          tdb={this.state.tdb} tdw={this.state.tdw} ydb={this.state.ydb} ydw={this.state.ydw}
          twb={this.state.twb} tww={this.state.tww} lwb={this.state.lwb} lww={this.state.lww}
          tmb={this.state.tmb} tmw={this.state.tmw} lmb={this.state.lmb} lmw={this.state.lmw} />
        
        {this.state.dataSource.length > 1?
              <div id="parentDiv" ref={this.myRef} style={{ height: pageHeight, overflow: 'auto' }}>
                <InfiniteScroll
                  dataLength={this.state.dataSource.length}
                  next={this.callApi}
                  hasMore={this.state.hasMore}
                  scrollableTarget="parentDiv"
                >
                  {this.state.dataSource.map((item) => {
                    const getDate = () => {
                      let date = moment(item.createDate);
                      return date.format("YYYY/MM/DD");
                    };

                    return (
                      <ListGroup key={item.tradeNo}>
                        <React.Fragment >
                          <ListGroupItem style={{ border: 0, padding: 2, paddingLeft: 15, paddingTop: 14, paddingBottom: 0, content: "" }}>
                            <div className="betTable">
                              <CashFlowRecord
                                title={t(item.tradeTypeName)}
                                subtitle={t(item.betContent)}
                                subtitle2={item.playname}
                                defaultCurr={item.defaultCurr}
                                tradeMoney={item.tradeMoney}
                                date={getDate()}
                                color='dark'
                                item={item}
                                tradeNo={item.tradeNo}
                              />
                            </div>
                          </ListGroupItem>
                        </React.Fragment>
                        <Button className="betting-settlement" style={{
                          borderRadius: 100, width: "2.5rem", height: "2.5rem", borderStyle: 'none', position: 'fixed', bottom: '15px',
                          right: '15px', padding: '0'
                        }} onClick={() => { this.onClickRefresh() }}>
                          <MdAutorenew size={24} style={{ color: '#fff' }} />
                        </Button>
                      </ListGroup>
                    )
                  })}
                </InfiniteScroll>
              </div>

              : (
                <div style={{ textAlign: 'center', paddingTop: 50, paddingBottom: 50, lineHeight: 1 }}>
                  <MdPhotoFilter size={110} style={{ color: '#ccc' }} />
                  <div style={{ lineHeight: 1, color: '#ccc', fontSize: 20 }}>{t('no_record_found')}</div>
                </div>
              )}
      </div>
      
    );
  }
}

const ConnectedCashFlowPage = connect(mapStateToProps)(CashFlowPage);
export default withLastLocation(withTranslation('common')(ConnectedCashFlowPage));