import React from 'react';
import { Button, Modal, Nav, Navbar, NavItem } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import { MdArrowBack } from 'react-icons/md';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { ShowLoginModal, saveIndexInit } from '../../actions/HomePageAction';
import { closeSafePasswordModal } from '../../services/HomeService';
import { alert } from '../../services/AlertService';
import MaterialIcon from 'material-icons-react';
import { addBankAlert } from '../../services/AlertService';
import bn from 'utils/bemnames';
import { sendcode, isEmpty } from '../../services/RegisterService';
import i18next from 'i18next';
import Fetcher from '../../components/Fetcher';

const bem = bn.create('header');

const tran = (input) => {
  return i18next.t(input);
}

const mapStateToProps = (state) => {
  return {
    modal: state.modal,
    indexInit: state.indexInit.indexInit,
  };
};

const mapDispatchToProps = dispatch => (
  bindActionCreators({
    saveIndexInit,
    ShowLoginModal,
  }, dispatch)
);

class SafePasswordPage extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      isdetail: 0,
      phoneSendVerify: 1,
      emailSendVerify: 1,
      username: "",
      sendCode: "",
      safePasswordSetting: '',
      safePasswordConfirmSetting: '',
      windowWidth:window.innerWidth,
      windowHeight:window.innerHeight,
    };
  }

  componentDidMount() {
    window.addEventListener('resize', this.updateDimensions.bind(this));
    if (this.props.indexInit?.loginUser?.loginName) {
      Fetcher(window.$wgtDomain + '/wap/member-center/safe-psd-data', {
        method: 'GET',
        headers: {
          'Accept': 'application/json, text/plain, */*',
          'Content-Type': 'application/x-www-form-urlencoded'
        },
        // credentials: 'include',
        referrer: 'h5'
      })
        .then((response) => response.json())
        .then(
          (response) => {
            this.setState({
              isdetail: 0,
              phoneSendVerify: response.phoneSendVerify,
              emailSendVerify: response.emailSendVerify,
            });
          })
        .catch((error) => {
          console.error(error);
      });
    }
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateDimensions.bind(this));
  }

  updateDimensions() {
    let update_width = window.innerWidth;
    let update_height = window.innerHeight;
    this.setState({ windowWidth: update_width, windowHeight: update_height });
  }

  changeSafePassword(e) {
    e.preventDefault();

    // if(this.state.isdetail==0){
    //     alert("validate phone/email");
    //     return;
    // }
    if (!/^[\w]{6,16}$/.test(this.state.safePasswordSetting)) {
      alert(tran("安全密码必须由6～16位英文字母或数字组成"), tran("提示"));
      return;
    } else if (!this.state.safePasswordSetting) {
      alert(tran("请填写安全密码!"), tran("提示"));
      return;
    } else if (!this.state.safePasswordConfirmSetting) {
      alert(tran("请填写确认安全密码!"), tran("提示"));
      return;
    } else if (this.state.safePasswordSetting !== this.state.safePasswordConfirmSetting) {
      alert(tran("确认安全密码错误！请重新输入！"), tran("提示"));
      return;
    }

    const param = new URLSearchParams();
    param.set('username', this.state.username);
    param.set('sendCode', this.state.sendCode);
    param.set('safePwd', this.state.safePasswordSetting);
    param.set('isdetail', this.state.isdetail);

    //reset those input value
    // this.setState({
    //   username: "",
    //   sendCode: "",
    //   safePasswordSetting: '',
    //   safePasswordConfirmSetting: '',
    // });
    
    Fetcher(window.$wgtDomain + '/wap/member-center/safepwd', {
      method: 'POST',
      headers: {
        'Accept': 'application/json, text/plain, */*',
        'Content-Type': 'application/x-www-form-urlencoded'
      },
      body: param,
      // credentials: 'include',
      referrer: 'h5'
    })
      .then((response) => response.json())
      .then(
        (response) => {
          if(response.status){
            this.setState({
              username: "",
              sendCode: "",
              safePasswordSetting: '',
              safePasswordConfirmSetting: '',
            });
            let indexInit = this.props.indexInit;
            indexInit.loginUser.security = response.security;
            this.props.saveIndexInit(indexInit);
            if (response.security) {
              closeSafePasswordModal();
              addBankAlert();
            }
          }else{
            alert(tran(response.msg),tran(tran("提示")));
          }

        })
      .catch((error) => {
        console.error(error);
        alert(tran('网络问题, 请稍后重试'), '');
        closeSafePasswordModal();
      });
  };

  safeUsername(event){
    let inputValue = event.target.value;
    if(/^1\d{10}$/.test(inputValue)){ // 如果是手机号
      if(this.state.phoneSendVerify===1 && inputValue.length===11) {
        document.getElementById('showcode').style.display = 'block';
      }else{
        document.getElementById('showcode').style.display = 'none';
      }
    }else if(/^\w+([-+.]\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/.test(inputValue)){  // 如果是邮箱
      if(this.state.emailSendVerify===1 && inputValue.length>=6) {
        document.getElementById('showcode').style.display = 'block';
      }else{
        document.getElementById('showcode').style.display = 'none';
      }
    }else{
      document.getElementById('showcode').style.display = 'none';
    }
  };

  passValidcode(event){
    let type = event.target;
    let loginName = this.state.username;
    if(isEmpty(loginName)) {
      alert(tran("请输入手机/邮箱"),tran("提示"));
      return;
    }
    if(/^1\d{10}$/.test(loginName)){ // 如果是手机号
      if(document.getElementById("getValidCode").textContent === tran(" 重新发送 ")){
          sendcode(1,loginName,'',true,'pass','getValidCode');
      }else{
        sendcode(1,loginName,'',false,'pass','getValidCode');
      }
    }
    else if(/^\w+([-+.]\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/.test(loginName)){  // 如果是邮箱
      if(document.getElementById("getValidCode").textContent === tran(" 重新发送 ")){
          sendcode(0,loginName,'',true,'pass','getValidCode');
      }else{
        sendcode(0,loginName,'',false,'pass','getValidCode');
      }
    }else{
      alert(tran("请输入正确的手机/邮箱"),tran("格式错误"));
      return;
    }
  };

  closeModal() {
    closeSafePasswordModal();
  }

  handleChange(event) {
    this.setState({ [event.target.name]: event.target.value });
  }

  render() {
    const { t } = this.props;
    return (
      <Modal isOpen={this.props.modal.safePasswordModal.show} className="safe-password-modal" style={{ maxWidth: 'none', margin: 0 }} >
        <div className="popup my-popup popup-safepwd" style={{ height: this.state.windowHeight, overflow: 'hidden' }}>
          <Navbar className="theme-background-color" light expand style={{ paddingLeft: 0, paddingRight: 0 }}>
            <Nav navbar className={bem.e('nav-left')} style={{ position: 'absolute' }}>
              <NavItem className="mr-2" style={{ flexDirection: 'row' }}>
                <Button outline style={{ outline: 'none', border: 0 }} onClick={this.closeModal}>
                  <MdArrowBack size={24} />
                </Button>
              </NavItem>
            </Nav>
            {/* <Nav navbar className={bem.e('nav-left')} style={{ margin: 'auto' }}>
              <NavItem>
                <p className="p text-white" style={{ marginBottom: 0, fontSize: 18 }}>{t('psdconterpage.setsavecode')}</p>
              </NavItem>
            </Nav> */}
          </Navbar>
          <div className="page" ng-controller="SafePsdController">
            <div className="page-content" >
              <div className="list-block pt0">
                <ul>
                  <li className="item-content" style={{ display: this.state.isdetail !== 0 ? '' : 'none' }}>
                    <div className="item-media" style={{ minWidth: '20px' }}>
                      <MaterialIcon icon="person_outline" size={24} />
                    </div>
                    <div className="item-inner">
                      <div className="item-title label" style={{ width: '0%' }}></div>
                      <div className="item-input">
                        <input type="text" name="username" placeholder={t('注册的手机/邮箱')} onKeyUp={this.safeUsername.bind(this)} onChange={this.handleChange.bind(this)} value={this.state.username}></input>
                      </div>
                    </div>
                  </li>
                  <li className="showcode" id="showcode" style={{ display: 'none' }}>
                    <div className="item-content" style={{ display: (this.state.isdetail === 1 && this.state.phoneSendVerify === 1) || (this.state.isdetail === 2 && this.state.emailSendVerify === 1) || (this.state.isdetail === 3 && (this.state.phoneSendVerify === 1 || this.state.emailSendVerify === 1)) ? '' : 'none' }}>
                      <div className="item-media" style={{ minWidth: '20px' }}>
                        <i className="icon material-icons">lock_outline</i>
                      </div>
                      <div className="item-inner">
                        <div className="item-input item-code">
                          <input type="text" placeholder={t('loginpage.NameLoginContent.verifycode')} name="sendCode" maxLength="8" onChange={this.handleChange.bind(this)}/> <span className="phone-email-validcode-span">| </span>
                          <a href="#" className="phone-email-validcode-a" id="getValidCode" onClick={this.passValidcode.bind(this)}>{t('loginpage.NameLoginContent.getverifycode')}</a>
                          <img src="" alt="" style={{display:'none'}} id="validCodeImg"  onClick={this.passValidcode.bind(this)}/>
                        </div>
                        <div className="item-title label" style={{ color: 'red', display: 'none' }}></div>
                      </div>
                    </div>
                  </li>
                  <li>
                    <div className="item-content">
                      <div className="item-media" style={{ minWidth: '20px' }}>
                        <i className="icon material-icons">lock_outline</i>
                      </div>
                      <div className="item-inner">
                        <div className="item-input item-input-field">
                          <input type="password" value={this.state.safePasswordSetting} name="safePasswordSetting" onChange={this.handleChange.bind(this)} placeholder={t('6-16位数字的安全密码')} id="safepassword-setting" maxLength="16" minLength="6" />
                        </div>
                      </div>
                    </div>
                  </li>
                  <li>
                    <div className="item-content">
                      <div className="item-media" style={{ minWidth: '20px' }}><i className="icon material-icons">lock_outline</i></div>
                      <div className="item-inner">
                        <div className="item-input item-input-field">
                          <input type="password" value={this.state.safePasswordConfirmSetting} name="safePasswordConfirmSetting" onChange={this.handleChange.bind(this)} placeholder={t('再次输入安全密码')} id="safepasswordconfirm-setting" maxLength="16" minLength="6" />
                        </div>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
              <div className="content-block"><a href="#" onClick={this.changeSafePassword.bind(this)} className="f7-button button-fill" style={{ color: '#fff' }}>{t('确定')}</a></div>
              <div style={{fontSize:'15px', textAlign:'center'}}>{t('温馨提醒：出于安全性考量不建议登录密码和安全密码一致')}</div>
            </div>
          </div>
        </div>
      </Modal>
    );
  };
}

const ConnectedSafePasswordPage = connect(mapStateToProps, mapDispatchToProps)(SafePasswordPage);
export default withTranslation('common')(ConnectedSafePasswordPage);