import React from 'react';
import stopimage from 'assets/image/403.png';
import timeoutImage from 'assets/image/Session-timeout-app.png';
import blockImage from 'assets/image/whitelist.png';
import maintainImage from 'assets/image/maintenance.png';
import loginImage from 'assets/image/login.png';
import reloadImage from 'assets/image/reload.png';
import backImage from 'assets/image/back.png';
import eror404image from 'assets/image/404.png';

import { useSelector } from 'react-redux'
import i18next from 'i18next';

class Stopuser extends React.Component {

  render() {
    return(
      <div className='error-page'>
        <table>
          <tr>
            <td>
              <p><img src={stopimage} style={{width:'220px'}} /></p>
              <p>用户权限不足</p>
              <p>Deny due to insufficient access right</p>
              <p>Từ chối do không đủ quyền truy cập</p>
              <p>ปฏิเสธเนื่องจากสิทธิ์ในการเข้าถึงไม่เพียงพอ</p>
            </td>
          </tr>
        </table>
      </div>
    );
  }

}

function TimeoutPage(props) {
  return (
    <img src={timeoutImage} style={{width:'100%',height:'100%'}}/>
  )
}

function BlockPage(props) {
  return (
    <div className='error-page'>
      <table>
        <tr>
          <td>
            <p><img src={blockImage} style={{width:'220px'}} /></p>
            <p>抱歉，您的IP地址不在允许范围内，无法访问本站</p>
            <p>Sorry, your IP address is not under our service coverage, unable to visit.</p>
            <p>Xin lỗi, địa chỉ IP của bạn không thuộc phạm vi dịch vụ của chúng tôi, không thể truy cập.</p>
            <p>ขออภัยที่อยู่ IP ของคุณไม่อยู่ภายใต้การให้บริการของเราไม่สามารถเยี่ยมชมได้</p>
          </td>
        </tr>
      </table>
    </div>
  )
}

function MaintenancePage(props) {
  const utils = useSelector(state => state.utils);
  return (
    <div className='error-page'>
      <table>
        <tr>
          <td>
            <p style={{fontSize:'12px'}}><img src={maintainImage} style={{width:'220px'}} /></p>
            <p style={{fontSize:'12px'}}>{i18next.t('maintainance.msg1')}<span className="time">{utils.maintainTime}</span>{i18next.t('maintainance.msg2')}</p>
          </td>
        </tr>
      </table>
    </div>
  )
}

function LoginExpiredPage(props) {
  return (
    <div className='error-page'>
      <table>
        <tr>
          <td>
            <p><img src={loginImage} style={{width:'220px'}} /></p>
            <p>{i18next.t('login.expired.msg')}</p>
          </td>
        </tr>
      </table>
    </div>
  )
}

function Error404Page(props) {
  return (
    <div className='error-404-page'>
      <table>
        <tr>
          <td>
            <p><img src={eror404image}/></p>
            <p>无法显示此网页</p>
            <p>Unable to display this site</p>
            <p>Không thể hiển thị trang web này</p>
            <p>ไม่สามารถแสดงไซต์นี้</p>
            <p><a href="#"><img src={reloadImage} className="icon" /></a> <a href="#"><img src={backImage} className="icon" /></a></p>
          </td>
        </tr>
      </table>
    </div>
  )
}

function StopSitePage(props) {
  return (
    <div className='error-page'>
      <table>
        <tr>
          <td>
            <p><img src={eror404image}/></p>
            <p>站点停用</p>
            <p>Site disabled</p>
            <p>Trang web bị vô hiệu hóa</p>
            <p>ไซต์ถูกปิดใช้งาน</p>
          </td>
        </tr>
      </table>
    </div>
  )
}

export { TimeoutPage, BlockPage, MaintenancePage, LoginExpiredPage, Error404Page, StopSitePage };
export default Stopuser;