import React from 'react';
import { Button, Nav, Navbar, NavItem} from 'reactstrap';
import { withTranslation } from 'react-i18next';
import bn from 'utils/bemnames';
import { MdCancel } from 'react-icons/md';

const bem = bn.create('header');

class Header extends React.Component {
    goBack = () => {
        this.props.history.goBack();
      };
    render() {
        
        return (
            <div style={{ marginTop: 0, }}>
                <Navbar light expand style={{ backgroundColor: '#ff5722', paddingLeft: 0, paddingRight: 0, backgroundColor: '#F74F0A' }}>

                    <Nav navbar className={bem.e('nav-left')} style={{ margin: 'auto' }}>
                        <NavItem>
                            <p className="p text-white" style={{ marginBottom: 0 }}>极速时时彩</p>
                        </NavItem>
                    </Nav>

                    <div style={{ marginLeft: -45, marginTop: -1, marginRight: 2 }}>
                        <Button outline style={{ outline: 'none', border: 0 }} onClick={this.goBack}>
                            <MdCancel color={'white'} size={28} />
                        </Button>
                    </div>
                </Navbar>


            </div>
        );
    }
}

export default withTranslation('common')(Header);
