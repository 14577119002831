import sidebarBgImage from 'assets/img/sidebar/sidebar-4.jpg';
import React from 'react';
import {
  MdSend,
  MdKeyboardArrowRight,
  MdSettings,
} from 'react-icons/md';
import { NavLink, withRouter } from 'react-router-dom';
import {
  Nav,
  Navbar,
  NavItem,
  NavLink as BSNavLink,
} from 'reactstrap';
import bn from 'utils/bemnames';
import { withTranslation, Trans } from "react-i18next";
import { store } from '../../index';
import { bindActionCreators } from 'redux';
import { UpdateAlert } from '../../actions/HomePageAction';
import { connect } from 'react-redux';
import LanguageSwitcher from 'components/LanguageSwitcher';

const mapStateToProps = (state) => {
  return {
    alert: state.alert,
    memberInfo: state.memberInfo,
  };
};

const mapDispatchToProps = dispatch => (
  bindActionCreators({
    UpdateAlert,
  }, dispatch)
);

const sidebarBackground = {
  backgroundColor:'#f4f4f4'
};

const navItems = [
  // { to: '/', name: 'dashboard', exact: false, Icon: MdSend },
  { to: '/bettingrecord', name: 'bettingrecord', exact: false, Icon: MdSend },
  { to: '/cashflowpage', name: 'cashflowrecord', exact: false, Icon: MdSend },
  { to: '/awardcenters', name: 'openaward', exact: false, Icon: MdSend },  
  { to: '/settings', name: 'settings', exact: false, Icon: MdSettings },
 // { to: '/responsible', name: '理性博弈设置', exact: false, Icon: MdSettings },
];

const bem = bn.create('sidebar');

class Sidebar extends React.Component {
  state = {
    isOpenComponents: true,
    isOpenContents: true,
    isOpenPages: true,
  };

  handleClick = name => () => {
    this.setState(prevState => {
      const isOpen = prevState[`isOpen${name}`];

      return {
        [`isOpen${name}`]: !isOpen,
      };
    });
  };

  refresh = () => {
    window.location.href = window.location.href.split('#')[0];
  }

  clearCache = () => {
    const { t } = this.props;
    this.props.alert.content = t('clear_cache_alert_msg');
    this.props.alert.title = t('notice');
    this.props.alert.alertType = 'clearCache';
    this.props.alert.showAlert = true;
    this.props.UpdateAlert(this.props.alert);
  }

  render() {
    const { t } = this.props;
    return (
      <aside className={bem.b()} data-image={sidebarBgImage}>
        <div className={bem.e('background')} style={sidebarBackground} />
        <div className={bem.e('content')}>
          <Navbar style={{padding:0}}>
            <div style={{lineHeight:'44px',width:'100%',textAlign:'center'}}>
                <Trans className="text-white" i18nKey="more">{t('more')}</Trans>
            </div>
          </Navbar>

          <Nav vertical>
            {navItems.map(({ to, name, exact, Icon }, index) => (
              <NavItem key={index} className={bem.e('nav-item')}>
                <BSNavLink
                  id={`navItem-${name}-${index}`}
                  //className="text-uppercase"
                  tag={NavLink}
                  to={to}
                  activeClassName="active"
                  exact={exact}
                >
                  <span ><Trans i18nKey={name}/></span>
                  <MdKeyboardArrowRight className="side-nav-icon" />
                </BSNavLink>
              </NavItem>
            ))}
            {(this.props.memberInfo.MemberInfo.restWarning===1||this.props.memberInfo.MemberInfo.pauseWarning===1 || this.props.memberInfo.MemberInfo.profitWarning===1 || this.props.memberInfo.MemberInfo.sleepWarning===1)?
                  <NavItem key='3' className={bem.e('nav-item')}>
                  <BSNavLink
                    id={`navItem-responsible-3`}
                    //className="text-uppercase"
                    tag={NavLink}
                    to='/responsible'
                    activeClassName="active"
                    exact='false'
                  >
                    <span ><Trans i18nKey='理性博弈设置'/></span>
                    <MdKeyboardArrowRight className="side-nav-icon" />
                  </BSNavLink>
                </NavItem>
                  :null}
            <NavItem className={bem.e('nav-item')}>
              <span className="nav-link">
                <span style={{fontWeight: 400}}onClick={this.clearCache}><Trans i18nKey='Clear Cache'/></span>
              </span>
            </NavItem>
            <NavItem className={bem.e('nav-item')}>
              <span className="nav-link" onClick={this.refresh} style={{fontWeight:400}}><Trans i18nKey='refresh'/></span>
            </NavItem>
            <LanguageSwitcher />
          </Nav>
        </div>
      </aside>
    );
  }
}

const ConnectedSidebar = connect(mapStateToProps, mapDispatchToProps)(Sidebar);
export default withRouter(withTranslation('common')(ConnectedSidebar));
