import React from 'react';
import { Button, Nav, Navbar, NavItem, DropdownItem, DropdownMenu, DropdownToggle, UncontrolledButtonDropdown } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import bn from 'utils/bemnames';
import { MdArrowBack, MdMoreVert } from 'react-icons/md';
import i18next from 'i18next';

const bem = bn.create('header');

class Header extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      toggleEvent: Date.now(),
      condition: false
    };
  }

  goBack = () => {
    this.props.history.goBack();
  };

  render() {
    const { t } = this.props;
    return (
      <div>
        <Navbar light expand style={{ padding: 0}}>
          <Nav navbar className={bem.e('nav-left')} style={{ position: 'absolute' }}>
            <NavItem className="mr-2" style={{ flexDirection: 'row' }}>
              <Button outline style={{ outline: 'none', border: 0 }} onClick={this.props.AwardDetailToggle}>
                <MdArrowBack size={24} />
              </Button>
            </NavItem>
          </Nav>

          <Nav navbar className={bem.e('nav-left')} style={{ margin: 'auto' }}>
            <NavItem>
              <p className={'text-white'} style={{ marginBottom: 0,fontSize: 18, lineHeight: '44px' }}>{this.props.gameName}</p>
            </NavItem>
          </Nav>
          {(this.props?.game?.gameBetObject?.game?.playId == 'V6Z' || this.props?.game?.gameBetObject?.game?.playId == 'V5Z') && this.props.isZongHe ?
            null
          :
          <Nav navbar className={bem.e('nav-left')} style={{position:'absolute',right: 15}}>
            <NavItem >
              <UncontrolledButtonDropdown >
                <DropdownToggle style={{ background:'transparent', border:'transparent',padding: 0}}>
                  <span style={{ paddingLeft: '8px', lineHeight: '20px', fontSize: '12px' }}></span><MdMoreVert size={23} />
                </DropdownToggle>
                <DropdownMenu>
                  <DropdownItem onClick={() => {this.props.onPress(this.props,20)}} >{i18next.language != 'zh' ? t("20 Record(s)") : "近20期"}</DropdownItem>
                  <DropdownItem onClick={() => {this.props.onPress(this.props,30)}} >{i18next.language != 'zh' ? t("30 Record(s)") : "近30期"}</DropdownItem>
                  <DropdownItem onClick={() => {this.props.onPress(this.props,50)}} >{i18next.language != 'zh' ? t("50 Record(s)") : "近50期"}</DropdownItem>
                  <DropdownItem onClick={() => {this.props.onPress(this.props,70)}} >{i18next.language != 'zh' ? t("70 Record(s)") : "近70期"}</DropdownItem>
                  <DropdownItem onClick={() => {this.props.onPress(this.props,100)}} >{i18next.language != 'zh' ? t("100 Record(s)") : "近100期"}</DropdownItem>
                </DropdownMenu>
              </UncontrolledButtonDropdown>
            </NavItem>
          </Nav>
          }
        </Navbar>
      </div>
    );
  }
}

export default withTranslation('common')(Header);
