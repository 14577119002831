import React from 'react';
import { withTranslation } from "react-i18next";
import bn from 'utils/bemnames';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import HeaderWithBack from '../../components/Layout/HeaderWithBack';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import Slider from '@material-ui/core/Slider';
import { UpdateAlert } from '../../actions/HomePageAction';
import qs from 'qs';
import SimpleNumberPickerModal from '../../components/Modal/SimpleNumberPickerModal';
import { Col } from 'reactstrap';
import i18next from 'i18next';
import Fetcher from '../../components/Fetcher';

const images = require.context('../../assets/image/', true);

const bem = bn.create('header');

const mapStateToProps = (state) => {
    return {
        alertObj: state.alert,
        indexInit: state.indexInit.indexInit,
    };
};

const mapDispatchToProps = dispatch => (
    bindActionCreators({
        UpdateAlert,
    }, dispatch)
);

const initLoadingAction = () => {
    return { type: 'RESET_LOAD' }
}

const closeLoadingAction = () => {
    return { type: 'SUCCESS_LOAD' }
}

const subAccountClose = (closeFunc) => {
    //navigator.clipboard.writeText(document.getElementById('info').innerText);
    let textField = document.createElement('textarea');
    textField.innerText = document.getElementById('info').innerText;
    document.body.appendChild(textField);
    textField.select();
    document.execCommand('copy');
    textField.remove();
    closeFunc();
}

const getImage = (imagewithtype) => {
    try{
      return images('./'+imagewithtype);
    } catch (err) {
      console.error(err);
      return window.$wgtDomain+"/imagenotfound.png"
    }
}

class SubAccountPage extends React.Component {
    constructor(props) {
        super(props);
        this.rebateChange = this.rebateChange.bind(this);
        this.typechange = this.typechange.bind(this);
        this.gamerebateboxChange = this.gamerebateboxChange.bind(this);
        this.save = this.save.bind(this);
        this.selectPickNum = this.selectPickNum.bind(this);
        this.deletePickNum = this.deletePickNum.bind(this);
        this.triggerPickNum = this.triggerPickNum.bind(this);
        this.triggerPickNumClose = this.triggerPickNumClose.bind(this);
    }

    state = {
        init: false,
        landinPages: {},
        landKeys: [],
        landValues: [],
        childType: 1,
        newpromotion: {
            accountType: "0",
        },
        edittitle: "",
        promotionData: {
            promotionId: 0
            , promotionacctype: 0
        },
        acc: {},
        platforms: {},
        gameRebateStatus: "",
        gamePlayRebateList: {},
        keypad: {},
        smsg: false,
        selectPromotion: "0",
        gamerebateboxRangeValue: 0.0,
        rebateRangeValue: 0.0,
        rangeInput1: 0.0,
        save: false,
        numberPickerShow: false,
        numberPickerValue: "0",
        selectedPickerFieldName: "",
        selectedPickerCategory: "",
        isShowPassword: false,
        isShowPassword2: false,
    };

    goToOtherPage(pageName){
        if(this.props.indexInit.loginUser==null){
          this.loginClick();
        }else{
          if(!this.state.signinRecordClick)//prevent trigger redirect here when userCheckin onclick
            window.location.href = window.location.href.split('#')[0]+ '#'+pageName;
        }
      }

    callLoadDataApi() {
        const { t } = this.props;
        let loginUser = this.props.indexInit.loginUser;
        const param = new URLSearchParams();
        param.set('dayType', '');
        if (typeof (loginUser.loginName) != 'undefined') {
            Fetcher(window.$wgtDomain + '/wap/member-center/promotion/newdata' + (this.state.promotionData.promotionId > 0 ? ("?id=" + this.state.promotionData.promotionId) : ""), {
                method: 'POST',
                headers: {
                    'Accept': 'application/json, text/plain, */*',
                    'Content-Type': 'application/x-www-form-urlencoded',
                },
                // credentials: 'include',
                body: param,
                referrer: 'h5'
            })
                .then((response) => {
                    if (!response.ok) {
                    } else {
                        response.json()
                            .then((data) => {
                                if (data.status) {
                                    let currentState = this.state;
                                    if (!data.promotion) {
                                        currentState.childType = data.acc.childType;
                                        currentState.newpromotion = { pchainedType: 0, accountType: data.acc.childType, landinPage: 'memreg/register', rebateRateMap: {}, gameRebateRateMap: {} }
                                    } else
                                        currentState.newpromotion = data.promotion;
                                    currentState.newpromotion.accountType += "";
                                    currentState.edittitle = !currentState.newpromotion.id ? t('subaccountpage.new') : t('subaccountpage.edit');
                                    if (!currentState.newpromotion.rebateRateMap)
                                        currentState.newpromotion.rebateRateMap = {};
                                    if (!currentState.newpromotion.gameRebateRateMap)
                                        currentState.newpromotion.gameRebateRateMap = {};
                                    currentState.acc = data.acc;
                                    currentState.acc.statusName = data.acc.userStatus == 0 ? t("subaccountpage.normal") : data.acc.userStatus == 1 ? t("subaccountpage.stop") : t("subaccountpage.locked");
                                    currentState.platforms = data.platforms;
                                    currentState.landinPages = data.pagelink;
                                    currentState.landKeys = data.landKeys;
                                    currentState.landValues = data.landValues;
                                    currentState.gameRebateStatus = data.gameRebateStatus;
                                    currentState.gamePlayRebateList = data.gamePlayRebateList;
                                    for (let k in currentState.platforms) {
                                        if (typeof currentState.newpromotion.rebateRateMap[k] == "undefined")
                                            currentState.newpromotion.rebateRateMap[k] = 0;
                                    }

                                    if (currentState.gameRebateStatus == "1") {
                                        for (let g in currentState.gamePlayRebateList) {
                                            if (typeof currentState.newpromotion.gameRebateRateMap[g] == "undefined")
                                                currentState.newpromotion.gameRebateRateMap[g] = 0;
                                        }
                                    }
                                    currentState.landinPage = currentState.landinPages[currentState.newpromotion.landinPage];
                                    currentState.accountType = currentState.newpromotion.accountType == "1" ? t("teamindexpage.member") : t("teamindexpage.agent");
                                    this.setState(currentState);

                                    Fetcher(window.$wgtDomain + '/wap/member-center/promotion/getPromotionCode', {
                                        method: 'POST',
                                        headers: {
                                            'Accept': 'application/json, text/plain, */*',
                                            'Content-Type': 'application/x-www-form-urlencoded',
                                        },
                                        // credentials: 'include',
                                        body: param,
                                        referrer: 'h5'
                                    }).then((response) => {
                                        if (!response.ok) {
                                        } else {
                                            response.json().then((data) => {
                                                if (data.promotionCode) {
                                                    const promotionArray = JSON.parse(data.promotionCode);
                                                    this.setState({ promotionArray: promotionArray });
                                                }
                                            })
                                        }
                                    });

                                } else {
                                    if (this.state.smsg) {
                                        this.normalAlert(data.msg);
                                        // if (data.status === false && (data.msg === "您的后台尚未设置返佣比，请联系客服。谢谢" || data.msg === "您的后台尚未设置返点比，请联系客服。谢谢"))
                                        //     chaos.fw7.curView.router.load({ url: "tpl/myIndex.html" });
                                    }

                                }
                            })
                    }
                })

        } else {
            this.normalAlert(t('subaccountpage.pleaselogin'));
        }
    }

    selectPickNum = (input,event) => {
        let iosVersion = 100;
        if(window.$isIonic){
            if(window.$platform == 'IOS') {
                iosVersion = window.$platformVersion.split('.')[0];
            }
        }
        else if (window.$isApp){
            let plus = window.$plus;
            if(plus.os.name.toUpperCase() == 'IOS') {
                iosVersion = plus.os.version.split('.')[0];
            }
        }

        if(event.pointerType=='touch' || parseInt(iosVersion) < 15 || (!window.$isApp && event.pointerType=='mouse') || event.pointerType==undefined){
            if(input =='{bksp}'){
                this.deletePickNum();
            }
            else{
                let currState = this.state;
                currState.numberPickerValue = currState.numberPickerValue + input;
                if (currState.selectedPickerCategory == 'rebateRateMap') {
                    currState.newpromotion.rebateRateMap[currState.selectedPickerFieldName] = parseFloat(currState.numberPickerValue).toFixed(1)
                } else if (currState.selectedPickerCategory == 'gameRebateRateMap') {
                    currState.newpromotion.gameRebateRateMap[currState.selectedPickerFieldName] = parseFloat(currState.numberPickerValue).toFixed(1)
                } else {
                    return;
                }
                this.setState(currState);
            }
        }
    }

    deletePickNum() {
        let currState = this.state;
        currState.numberPickerValue = currState.numberPickerValue.toString().slice(0, -1);
        if (currState.selectedPickerCategory == 'rebateRateMap') {
            if(currState.numberPickerValue==''){
                currState.newpromotion.rebateRateMap[currState.selectedPickerFieldName] = parseFloat(0).toFixed(1)
            }else{
            currState.newpromotion.rebateRateMap[currState.selectedPickerFieldName] = parseFloat(currState.numberPickerValue).toFixed(1)
            }
        } else if (currState.selectedPickerCategory == 'gameRebateRateMap') {
            if(currState.numberPickerValue==''){
                currState.newpromotion.gameRebateRateMap[currState.selectedPickerFieldName] = parseFloat(0).toFixed(1)
            }else{
            currState.newpromotion.gameRebateRateMap[currState.selectedPickerFieldName] = parseFloat(currState.numberPickerValue).toFixed(1)
            }
        } else {
            return;
        }
        this.setState(currState);
    }

    triggerPickNum(event) {
        let currState = this.state;
        currState.numberPickerShow = true;
        currState.selectedPickerFieldName = event.target.getAttribute("name");
        currState.selectedPickerCategory = event.target.getAttribute("category");
        if (currState.selectedPickerCategory == 'rebateRateMap') {
            if(currState.newpromotion.rebateRateMap[currState.selectedPickerFieldName]){
            currState.numberPickerValue = currState.newpromotion.rebateRateMap[currState.selectedPickerFieldName]
            }
            else{
                currState.numberPickerValue = 0;
            }
        } else if (currState.selectedPickerCategory == 'gameRebateRateMap') {
            currState.numberPickerValue = currState.newpromotion.gameRebateRateMap[currState.selectedPickerFieldName]
        } else {
            return;
        }
        this.setState(currState);
    }

    triggerPickNumClose(event) {
        if (!event.target.getAttribute('category')) {
            let currState = this.state;
            if ((!currState.numberPickerValue) || isNaN(currState.numberPickerValue)) {
                if (currState.selectedPickerCategory == 'rebateRateMap') {
                    currState.newpromotion.rebateRateMap[currState.selectedPickerFieldName] = 0;
                } else if (currState.selectedPickerCategory == 'gameRebateRateMap') {
                    currState.newpromotion.gameRebateRateMap[currState.selectedPickerFieldName] = 0;
                }

            }
            currState.numberPickerShow = false;
            currState.numberPickerValue = 0;
            currState.selectedPickerFieldName = "";
            currState.selectedPickerCategory = "";
            this.setState(currState);
        }
    }

    typechange(e) {
        let currState = this.state;
        const type = e.target.value;
        currState.newpromotion.accountType = type;
        currState.promotionData.promotionacctype = type;
        currState.selectPromotion = "0";
        // $(".toggleEmpty, #rangeInput1, #rangeInput2").val(0.0);
        currState.gamerebateboxRangeValue = 0.0;
        currState.rebateRangeValue = 0.0;

        for (var i in Object.keys(currState.gamePlayRebateList)) {
            currState.newpromotion.gameRebateRateMap[Object.keys(currState.gamePlayRebateList)[i]] = 0;
        }

        for (var i in Object.keys(currState.newpromotion.rebateRateMap)) {
            currState.newpromotion.rebateRateMap[Object.keys(currState.platforms)[i]] = 0;
        }

        this.setState(currState);
    }

    rebateChange(e, newValue) {
        let currState = this.state;
        currState.rebateRangeValue = newValue;
        for (let i in Object.keys(currState.platforms)) {
            if (!Number.isNaN(parseInt(i))) {
                currState.newpromotion.rebateRateMap[Object.keys(currState.platforms)[i]] = currState.rebateRangeValue >= 0.0 && currState.rebateRangeValue <= currState.acc.rebateRateMap[Object.keys(currState.platforms)[i]] ?
                    currState.rebateRangeValue : currState.acc.rebateRateMap[Object.keys(currState.platforms)[i]];
                // currState.keypad[Object.keys(currState.platforms)[i]].setValue(currState.newpromotion.rebateRateMap[Object.keys(currState.platforms)[i]].toFixed(1));
            }
        }
        this.setState(currState);
    }

    gamerebateboxChange(e, newValue) {
        let currState = this.state;
        currState.gamerebateboxRangeValue = newValue;
        for (var i in Object.keys(currState.gamePlayRebateList)) {
            if (!Number.isNaN(parseInt(i))) {
                currState.newpromotion.gameRebateRateMap[Object.keys(currState.gamePlayRebateList)[i]] = currState.gamerebateboxRangeValue >= 0.0 && currState.gamerebateboxRangeValue <= currState.acc.gameRebateRateMap[Object.keys(currState.gamePlayRebateList)[i]] ?
                    currState.gamerebateboxRangeValue : currState.acc.gameRebateRateMap[Object.keys(currState.gamePlayRebateList)[i]];
                // currState.keypad[Object.keys(currState.gamePlayRebateList)[i]].setValue(currState.newpromotion.gameRebateRateMap[Object.keys(currState.gamePlayRebateList)[i]].toFixed(1));
            }
        }
        this.setState(currState);
    };

    async validateLoginName(currState) {
        let loginNameValue = currState.newpromotion.account;
        let validateResult = false;
        if (!loginNameValue || loginNameValue === 'undefined' || loginNameValue == "") {
            this.normalAlert(this.props.t("subaccountpage.msg.acclength"));
            validateResult = false;
        } else if (loginNameValue != '' && loginNameValue.length >= 6 && loginNameValue.length <= 14 && !/[^a-z0-9]/.test(loginNameValue)) {
            const param = new URLSearchParams();
            param.set('loginName', loginNameValue);
            try {
                let response = await Fetcher(window.$wgtDomain + "/memreg/validonly-loginname", {
                    method: "POST",
                    headers: {
                        'Accept': 'application/json, text/plain, */*',
                        'Content-Type': 'application/x-www-form-urlencoded',
                    },
                    // credentials: 'include',
                    body: param,
                    responseType: "json",
                    referrer: 'h5'
                });
                let result = await response.json();
                validateResult = result.status;
                if (validateResult == false) {
                    validateResult = false;
                    this.normalAlert(result.content);
                } else {
                    validateResult = true;
                }
            } catch (err) {
                validateResult = false;
            }
        } else {
            this.normalAlert(this.props.t("subaccountpage.msg.accinvalid"));
            validateResult = false;
        }

        //console.log(validateResult);
        return validateResult;
    }

    validateLoginPwd(currState) {
        const { t } = this.props;
        let loginPwdValue = currState.newpromotion.password;
        if (loginPwdValue != '') {
            if ((loginPwdValue ? loginPwdValue.trim() : "") == (currState.newpromotion.account ? currState.newpromotion.account.trim() : "")) {
                this.normalAlert(t("subaccountpage.msg.pwdnotsameacc"));
                return false;
            } else if (!/^(?![0-9]+$)(?![a-z]+$)(?![A-Z]+$)[0-9A-Za-z]+$/.test(loginPwdValue)) {
                this.normalAlert(t("subaccountpage.msg.pwdineng"));
                return false;
            } else if (loginPwdValue.length >= 6 && loginPwdValue.length <= 16 && /^(?![0-9]+$)(?![a-z]+$)(?![A-Z]+$)[0-9A-Za-z]{6,16}$/.test(loginPwdValue)) {
                return true;
            } else {
                this.normalAlert(t("subaccountpage.msg.pwdinvalid"));
                return false;
            }
        } else {
            this.normalAlert(t("subaccountpage.msg.pwdlength"));
            return false;
        }
    }

    normalAlert(content) {
        const { alertObj, UpdateAlert, t } = this.props;
        alertObj.title = t('subaccountpage.hitsmsg');
        alertObj.alertType = 'normalAlert';
        alertObj.showAlert = true;
        alertObj.content = content;
        alertObj.functionName = false;
        UpdateAlert(alertObj);
    }

    customizeAlert(data) {
        const { alertObj, UpdateAlert, t } = this.props;
        alertObj.title = t('subaccountpage.hitsmsg');
        alertObj.alertType = 'subAccountAlert';
        alertObj.showAlert = true;
        alertObj.data = data;
        alertObj.functionName = 'subAccountClose';
        UpdateAlert(alertObj);
    }

    save() {
        let currState = this.state;
        let loginPwdValue = currState.newpromotion.password = document.getElementById('password').value;
        let verifyPwd = currState.newpromotion.passworderor = document.getElementById('passworderor').value;
        currState.newpromotion.account = document.getElementById('account').value;
        // if (isTestUser) return toJionThrid(1);
        if (currState.save) return;
        if (!this.validateLoginName(currState))
            return;
        if (loginPwdValue != verifyPwd) {
            this.normalAlert(this.props.t('subaccountpage.msg.pwdnotsame'));
            return;
        }
        if (!this.validateLoginPwd(currState))
            return;

        currState.save = true;

        if (currState.newpromotion.accountType == "0") {
            for (var k in currState.newpromotion.rebateRateMap) {
                if (currState.newpromotion.rebateRateMap[k] < 0 || currState.newpromotion.rebateRateMap[k] === "") {
                    alert(this.props.t(currState.platforms[k]) + " " + this.props.t("subaccountpage.msg.commissionratecannotlesszero"));
                    return;
                }
                if (currState.newpromotion.rebateRateMap[k] > currState.acc.rebateRateMap[k]) {
                    alert(this.props.t(currState.platforms[k]) + " " + this.props.t("subaccountpage.msg.commissionratecannotmore") + currState.acc.rebateRateMap[k]);
                    return;
                }
            }
        }

        if (currState.gameRebateStatus == "1") {
            for (var k in currState.newpromotion.gameRebateRateMap) {
                if (currState.newpromotion.gameRebateRateMap[k] < 0 || currState.newpromotion.gameRebateRateMap[k] === "") {
                    alert(this.props.t(currState.gamePlayRebateList[k]) + " " + this.props.t("subaccountpage.msg.rebateratecannotlesszero"));
                    return;
                }
                if (currState.newpromotion.gameRebateRateMap[k] > currState.acc.gameRebateRateMap[k]) {
                    alert(this.props.t(currState.gamePlayRebateList[k]) + " " + this.props.t("subaccountpage.msg.rebateratecannotmore") + currState.acc.gameRebateRateMap[k]);
                    return;
                }
            }
        }
        //console.log("pass through all checking");
        this.setState(currState);

        Fetcher(window.$wgtDomain + '/wap/member-center/promotion/saveSubAccount', {
            method: 'POST',
            headers: {
                'Accept': 'application/json, text/plain, */*',
                'Content-Type': 'application/x-www-form-urlencoded',
            },
            // credentials: 'include',
            body: qs.stringify(currState.newpromotion),
            referrer: 'h5'
        }).then((response) => response.json())
            .then((data) => {
                //console.log(JSON.stringify(data));
                let currState = this.state;
                currState.save = false;
                if (data.status) {
                    this.customizeAlert(data);
                    // new Clipboard('#copyInfo');

                    for (var i in Object.keys(currState.platforms)) {
                        if (!Number.isNaN(parseInt(i))) {
                            currState.newpromotion.rebateRateMap[Object.keys(currState.platforms)[i]] = currState.rebateRangeValue >= 0.0 && currState.rebateRangeValue <= currState.acc.rebateRateMap[Object.keys(currState.platforms)[i]] ?
                                currState.rebateRangeValue : currState.acc.rebateRateMap[Object.keys(currState.platforms)[i]];
                            // keypad[Object.keys($scope.platforms)[i]].setValue($scope.newpromotion.rebateRateMap[Object.keys($scope.platforms)[i]].toFixed(1));
                        }
                    }

                    for (var i in Object.keys(currState.gamePlayRebateList)) {
                        if (!Number.isNaN(parseInt(i))) {
                            currState.newpromotion.gameRebateRateMap[Object.keys(currState.gamePlayRebateList)[i]] = currState.gamerebateboxRangeValue >= 0.0 && currState.gamerebateboxRangeValue <= currState.acc.gameRebateRateMap[Object.keys(currState.gamePlayRebateList)[i]] ?
                                currState.gamerebateboxRangeValue : currState.acc.gameRebateRateMap[Object.keys(currState.gamePlayRebateList)[i]];
                            // keypad[Object.keys($scope.gamePlayRebateList)[i]].setValue($scope.newpromotion.gameRebateRateMap[Object.keys($scope.gamePlayRebateList)[i]].toFixed(1));
                        }
                    }

                    document.getElementById('password').value = "";
                    document.getElementById('passworderor').value = "";
                    document.getElementById('account').value = "";
                    currState.newpromotion.account = "";
                    currState.newpromotion.password = "";
                    currState.newpromotion.passworderor = "";
                    currState.promotionData.promotionId = 0;
                    currState.selectPromotion = "0";
                } else {
                    this.normalAlert(data.msg);
                    return false;
                }
                this.setState(currState);
            }).catch((err) => {console.log(err)});

        // $.ajax({
        //     url: domainUrl + '/wap/member-center/promotion/saveSubAccount',
        //     data: $.param(currState.newpromotion),
        //     type: 'POST',
        //     async: false,
        //     dataType: 'json',
        //     complete: function () {
        //         chaos.fw7.hideIndicator();
        //     }
        // }).done(function (data) {
        //     save = false;
        //     if (data.status) {
        //         chaos.fw7.alert('<div style="text-align:center;"><img src="img/ico-success.png" width="64px" height="64px"><br>开户成功, <a href="javascript:void(0);" id="copyInfo" data-clipboard-target="#info">点击复制并关闭</a><br><span id="info" style="text-align:left;"><p>平台帐号：' + data.account +
        //             '</p><p>密码：' + data.password + '</p><p>登入网址：http://' + data.url + '</p></span></div>', '提示信息');
        //         new Clipboard('#copyInfo');

        //         for (var i in Object.keys(currState.platforms)) {
        //             if (!Number.isNaN(parseInt(i))) {
        //                 currState.newpromotion.rebateRateMap[Object.keys(currState.platforms)[i]] = currState.rebateRangeValue >= 0.0 && currState.rebateRangeValue <= currState.acc.rebateRateMap[Object.keys(currState.platforms)[i]] ?
        //                     currState.rebateRangeValue : currState.acc.rebateRateMap[Object.keys(currState.platforms)[i]];
        //                 // keypad[Object.keys($scope.platforms)[i]].setValue($scope.newpromotion.rebateRateMap[Object.keys($scope.platforms)[i]].toFixed(1));
        //             }
        //         }

        //         for (var i in Object.keys(currState.gamePlayRebateList)) {
        //             if (!Number.isNaN(parseInt(i))) {
        //                 currState.newpromotion.gameRebateRateMap[Object.keys(currState.gamePlayRebateList)[i]] = currState.gamerebateboxRangeValue >= 0.0 && currState.gamerebateboxRangeValue <= currState.acc.gameRebateRateMap[Object.keys(currState.gamePlayRebateList)[i]] ?
        //                     currState.gamerebateboxRangeValue : currState.acc.gameRebateRateMap[Object.keys(currState.gamePlayRebateList)[i]];
        //                 // keypad[Object.keys($scope.gamePlayRebateList)[i]].setValue($scope.newpromotion.gameRebateRateMap[Object.keys($scope.gamePlayRebateList)[i]].toFixed(1));
        //             }
        //         }

        //         currState.newpromotion.account = "";
        //         currState.newpromotion.password = "";
        //         currState.newpromotion.passworderor = "";
        //         currState.promotionData.promotionId = 0;
        //         currState.selectPromotion = "0";
        //     }
        // });

    }

    isShowPasswordSwitch = () => {
        const currState = this.state;
        currState.isShowPassword = !currState.isShowPassword;
        this.setState(currState);
    }

    isShowPasswordSwitch2 = () => {
        const currState = this.state;
        currState.isShowPassword2 = !currState.isShowPassword2;
        this.setState(currState);
    }

    componentDidMount() {
        this.callLoadDataApi();
    }

    render() {
        const { t } = this.props;
        const currState = this.state;
        let safeRebateRateValue = currState.acc.rebateRateMap != undefined ? (currState.acc.rebateRateMap['safe'] != undefined ? currState.acc.rebateRateMap['safe'] : 0) : 0;
        
        let isShowPassType = this.state.isShowPassword ? 'text' : 'password'; 
        let isShowPassImg = this.state.isShowPassword ? 'open' : 'close'; 
        
        let isShowPassType2 = this.state.isShowPassword2 ? 'text' : 'password'; 
        let isShowPassImg2 = this.state.isShowPassword2 ? 'open' : 'close';
        return (
            <div>
                <HeaderWithBack style={{ backgroundColor: '#f44336' }} headerName={t('下级开户')} {...this.props}></HeaderWithBack>
                <div onClick={this.triggerPickNumClose} className="sub-account page-content-44">
                    <div className="list-block ptb12">
                        <ul>
                            <li style={ currState.childType != 0 ? {opacity: '.55', pointerEvents: 'none'} : {} }>
                                <div className="item-content">
                                    <Col className="item-media">{t('subaccountpage.acctype')}</Col>
                                    <Col xs="8">
                                        <RadioGroup style={{ paddingTop: 10}} row value={this.state.newpromotion.accountType}>
                                        <FormControlLabel classes={{ label: 'font14px' }} value={"0"} control={<Radio style={{color:'var(--theme-color)'}} />} labelPlacement="end" label={t("subaccountpage.agenttype")} onClick={this.typechange} />
                                        <FormControlLabel classes={{ label: 'font14px' }} value={"1"} control={<Radio style={{color:'var(--theme-color)'}} />} labelPlacement="end" label={t("subaccountpage.membertype")} onClick={this.typechange} />
                                        </RadioGroup>
                                    </Col>
                                    <Col xs="1"></Col>
                                </div>
                            </li>
                            <li>
                                <div className="item-content">
                                    <Col className="item-media">{t('teamreportpage.account')}</Col>
                                    <Col xs="8">
                                        <input className="input-inline" id="account" type="text" />
                                    </Col>
                                    <Col xs="1"></Col>
                                </div>
                            </li>
                            <li>
                                <div className="item-content">
                                    <Col className="item-media">{t('loginpage.NameLoginContent.password')}</Col>
                                    <Col xs="8" style={{ position: 'relative', display: 'inline-block' }}>
                                        <img alt="" src={getImage(`eye-${isShowPassImg}.png`)} className="icon-eye" onClick={this.isShowPasswordSwitch} style={{ right: '10px' }}/>
                                        <input className="input-inline" id="password" type={isShowPassType}/>
                                    </Col>
                                    <Col xs="1"></Col>
                                </div>
                            </li>
                            <li>
                                <div className="item-content">
                                    <Col className="item-media">{t('psdmanagepage.confirmpwd')}</Col>
                                    <Col xs="8" style={{ position: 'relative', display: 'inline-block' }}>
                                        <img alt="" src={getImage(`eye-${isShowPassImg2}.png`)} className="icon-eye" onClick={this.isShowPasswordSwitch2} style={{ right: '10px' }}/>
                                        <input className="input-inline" id="passworderor" type={isShowPassType2}/>
                                    </Col>
                                    <Col xs="1"></Col>
                                </div>
                            </li>
                        </ul>
                    </div>
                    {currState.newpromotion.accountType == "0" && safeRebateRateValue != 0 ?
                      <>
                        <div className="list-block ptb12">
                            <ul>
                                <div className="item-content">
                                    <span>
                                        <label className="font14px">{t('subaccountpage.commitsetting')}</label><label style={{color: '#D3D3D3' }} className="font14px">&nbsp;&nbsp;{t('subaccountpage.msg.plssetcommsetting')}</label>
                                    </span>
                                </div>
                                <div className="item-content" style={{ textAlign: "center" }}>
                                    <div className="sub-account-slider" data-label="true">
                                        <div>
                                            <div style={{ width: "80%", paddingLeft: '5px', height: "auto", float: "left" }}>
                                                {/* <input id="rangeInput1" type="range" min="0" max="{{acc.maxRebateRate}}" step="0.1" value="0" ng-model="rebateRangeValue" ng-change="rebateChange()" /> */}
                                                <Slider
                                                    value={currState.rebateRangeValue}
                                                    onChange={this.rebateChange} step={0.1} min={0} max={currState.acc.maxRebateRate ? currState.acc.maxRebateRate : 0}
                                                    aria-labelledby="input-slider" style={{ width: '100%' }}
                                                />
                                            </div>
                                            <div style={{ width: "20%", height: "auto", float: "left", display: "inline", textAlign: "left", paddingTop: "10px", paddingLeft: "10px" }} className="rangeVal">
                                                {currState.rebateRangeValue + '%'}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {Object.entries(currState.platforms).map((item, index) => {
                                    const key = item[0], value = item[1];
                                    return (
                                        <li key={index} className="rebatebox">
                                            <div className="item-content">
                                                <Col xs="6" className="item-media"  style={i18next.language == 'cn' ? {}:{display:'block'}}>{t('subaccountpage.subcordinatecommrate')}[<span >{t(value)}</span>]</Col>
                                                <Col xs="4">
                                                    {!currState.acc.rebateRateMap[key] ?
                                                        <span>
                                                            <input style = {{backgroundColor: '#D3D3D3', borderRadius: 5, paddingLeft: 10 }} type="text" readOnly onClick={this.triggerPickNum} category="rebateRateMap" name={key} className={'rebateRateMap-' + key + ' toggleEmpty'} max={currState.acc.rebateRateMap[key]} value={currState.newpromotion.rebateRateMap[key]?parseFloat(currState.newpromotion.rebateRateMap[key]).toFixed(1):parseFloat(0).toFixed(1)} placeholder={t("subaccountpage.range") + "[0.0-0.0]%"} />
                                                        </span> :
                                                        <span>
                                                            <input style = {{backgroundColor: '#D3D3D3', borderRadius: 5, paddingLeft: 10 }} type="text" readOnly onClick={this.triggerPickNum} category="rebateRateMap" name={key} className={'rebateRateMap-' + key + ' toggleEmpty'} max={currState.acc.rebateRateMap[key]} value={parseFloat(currState.newpromotion.rebateRateMap[key]).toFixed(1)} placeholder={t("subaccountpage.range") + '[0.0-' + currState.acc.rebateRateMap[key] + ']%'} />
                                                        </span>
                                                    }
                                                </Col>
                                                <Col>%</Col>
                                            </div>
                                        </li>
                                    )
                                })}
                            </ul>
                        </div>
                      </> : null
                    }
                    <div className="list-block ptb12">
                        <ul>
                            {currState.gameRebateStatus == "1" ?
                                <>
                                    <div className="item-content">
                                        <span>
                                            <label className="font14px">{t('subaccountpage.rebatesetting')}</label><label style={{color: '#D3D3D3' }} className="font14px">&nbsp;&nbsp;{t('subaccountpage.msg.plssetrebatesetting')}</label>
                                            &nbsp;&nbsp;<label onClick={() => this.goToOtherPage('check-rebate')}  className="font14px" style={{color: 'red'}}>{t('subaccountpage.viewrebaterate')}</label>
                                        </span>
                                    </div>
                                    <div className="item-content" style={{ textAlign: "center" }}>
                                        <div className="sub-account-slider" data-label="true">
                                            <div>
                                                <div style={{ width: "80%", paddingLeft: '5px', height: "auto", float: "left", display: "inline" }}>
                                                    {/* <input id="rangeInput2" type="range" min="0" max="{{acc.gameMaxRebateRate}}" step="0.1" value="0" ng-model="gamerebateboxRangeValue" ng-change="gamerebateboxChange()" /> */}
                                                    <Slider
                                                        value={currState.gamerebateboxRangeValue}
                                                        onChange={this.gamerebateboxChange} step={0.1} min={0} max={currState.acc.gameMaxRebateRate ? currState.acc.gameMaxRebateRate : 0}
                                                        aria-labelledby="input-slider" style={{ width: '100%' }}
                                                    />
                                                </div>
                                                <div style={{ width: "20%", height: "auto", float: "left", display: "inline", textAlign: "left", paddingTop: "10px", paddingLeft: "10px" }} className="rangeVal">
                                                    {currState.gamerebateboxRangeValue + '%'}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {Object.entries(currState.gamePlayRebateList).map((item, index) => {
                                        const key = item[0], value = item[1];
                                        return (
                                            <li key={index} className="gamerebatebox">
                                                <div className="item-content">
                                                    <Col xs="6" className="item-media" style={i18next.language == 'zh' ? {}:{display:'block'}}>{t('subaccountpage.rebaterate')}[<span >{t(value)}</span>]</Col>
                                                    <Col xs="4">
                                                    {!currState.acc.rebateRateMap[key] ?
                                                        <span>
                                                            <input style = {{backgroundColor: '#D3D3D3', borderRadius: 5, paddingLeft: 10 }} type="text" readOnly onClick={this.triggerPickNum} category="gameRebateRateMap" name={key} className={'gameRebateRateMap-' + key + ' toggleEmpty'} max={currState.acc.gameRebateRateMap[key]} value={parseFloat(currState.newpromotion.gameRebateRateMap[key]).toFixed(1)} placeholder={t("subaccountpage.range") + "[0.0-0.0]%"} />
                                                        </span> :
                                                        <span>
                                                            <input style = {{backgroundColor: '#D3D3D3', borderRadius: 5, paddingLeft: 10 }} type="text" readOnly onClick={this.triggerPickNum} category="gameRebateRateMap" name={key} className={'gameRebateRateMap-' + key + ' toggleEmpty'} max={currState.acc.gameRebateRateMap[key]} value={parseFloat(currState.newpromotion.gameRebateRateMap[key]).toFixed(1)} placeholder={t("subaccountpage.range") + '[0.0-' + currState.acc.gameRebateRateMap[key] + ']%'} />
                                                        </span>
                                                    }
                                                    </Col>
                                                    <Col>%</Col>
                                                </div>
                                            </li>
                                        )
                                    })}
                                </> : null
                            }
                        </ul>
                    </div>
                    <div className="content-block"><a onClick={this.save} className="f7-button button-fill" style={{color: 'white'}}>{t('common.confirm')}</a></div>
                </div>
                <SimpleNumberPickerModal isDecimalPlace={true} show={currState.numberPickerShow} value={currState.numberPickerValue} selectPickNum={this.selectPickNum} deletePickNum={this.deletePickNum} complete={this.triggerPickNumClose}/>
            </div>
        );
    }
}

export { subAccountClose }
export default withTranslation('common')(connect(mapStateToProps, mapDispatchToProps)(SubAccountPage));
