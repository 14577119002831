import i18n from 'i18next';
import { initReactI18next } from "react-i18next";
import LanguageDetector from 'i18next-browser-languagedetector';
// import en from '../gameData/locales/en/common.json';
// import vi from '../gameData/locales/vi/common.json';
// import zh from '../gameData/locales/zh/common.json';
// import th from '../gameData/locales/th/common.json';
// import hi from '../gameData/locales/in/common.json';
// import id from '../gameData/locales/id/common.json';
// import pt from '../gameData/locales/pt/common.json';
// import es from '../gameData/locales/es/common.json';

// import HttpApi from "i18next-http-backend"; // common.json fetch
import resourcesToBackend from 'i18next-resources-to-backend';


const Detector = new LanguageDetector();

const i18nextConfig = {
  // whitelist: ['en', 'zh', 'vi', 'th', 'in', 'id', 'pt', 'es'],
  ns: ['common'],
  defaultNS: 'common',
  fallbackLng: 'zh'
};

// const resources = {
//   en: { common: en },
//   zh: { common: zh },
//   vi: { common: vi },
//   th: { common: th },
//   in: { common: hi },
//   id: { common: id },
//   pt: { common: pt },
//   es: { common: es },
// }

function configureI18n() {
  i18n
    // .use(HttpApi)
    .use(Detector)
    .use(initReactI18next)
    .use(resourcesToBackend((language, namespace, callback) => {
      import(`../gameData/locales/${language}/${namespace}.json`)
        .then((resources) => {
          callback(null, resources)
        })
        .catch((error) => {
          callback(error, null)
        })
    }))  
    .init({
      // resources: {
      //   en: { common: en },
      //   zh: { common: zh },
		  //   vi: { common: vi },
      //   th: { common: th },
      //   in: { common: hi },
      //   id: { common: id },
      //   pt: { common: pt },
      //   es: { common: es },
      // },
      // resources,
      cache: {
        enable: true,
        expirationTime: Infinity,
      },
      detection: {
        lookupLocalStorage: 'i18nextLng',
        caches: ['localStorage'],
        // order: ["path", "localStorage", "htmlTag", "cookie"],
        // caches: ["localStorage", "cookie"], // cache user language on
        excludeCacheFor: ['cimode']
      },
      fallbackLng: i18nextConfig.fallbackLng,
      ns: i18nextConfig.ns,
      defaultNS: i18nextConfig.defaultNS,
      keySeparator: false,
      interpolation: {
        escapeValue: false,
        formatSeparator: ',',
      },
      react: {
        wait: true,
        useSuspense: false
      },
      nonExplicitWhitelist: true,
      load: 'currentOnly',
      // load: 'languageOnly',
      // debug: true,
      // backend: {
        // loadPath: '/locales/{{lng}}/{{ns}}.json',
        // addPath: '/locales/zh/{{ns}}.json',
        // allowMultiLoading: false,
        // reloadInterval: typeof window !== 'undefined' ? false : 60 * 60 * 1000,
        // allowedAddOrUpdateHosts: [''],
        // translatedPercentageThreshold: 1,
      // },
      // partialBundledLanguages: true,
      // lazy: true,
    });
}

export default configureI18n;
