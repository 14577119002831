import React from 'react';
import { Button, Nav, Navbar, NavItem } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import bn from 'utils/bemnames';
import { MdArrowBack } from 'react-icons/md';

const bem = bn.create('header');
const images = require.context('../../assets/image/', true);

class HeaderWithBack extends React.Component {

  constructor(props){
    super(props);
    this.state = {
      toggleEvent: Date.now(),
      condition: false
    };
  }

  goBack = () => {
    this.props.history.goBack();
  };

  getImage(imagewithtype){
    try{
      return images('./'+imagewithtype);
    } catch (err) {
      console.error(err);
      return window.$wgtDomain+"/imagenotfound.png"
    }
  }

  render() {
    let iosVersion = 0;
    if(window.$isIonic){
      if(window.$platform === 'IOS') {
        iosVersion = window.$platformVersion.split('.')[0];
      }
    }
    else if (window.$isApp){
      let plus = window.$plus;
      if(plus.os.name.toUpperCase() == 'IOS') {
        iosVersion = plus.os.version.split('.')[0];
      }
    }
    return (
      <div>
      <Navbar className="theme-background-color" light expand style={parseInt(iosVersion) < 16?{paddingLeft:0,paddingRight:0}:{paddingLeft:0,paddingRight:0,position:'-webkit-sticky'}}>
        <Nav navbar className={bem.e('nav-left')} style={{margin:'auto',position:'absolute',width:'100%',top:0,textAlign:'center',padding:0,left:0}}>
          <NavItem className="mr-2" style={{flexDirection:'row'}}>
            <Button outline style={{outline:'none',border:0,color: '#fff'}} onClick={this.goBack}>
              <MdArrowBack size={24} />
            </Button>
            {this.props.backText ? <span style={{top:3,position:'relative',color:'white'}}>{this.props.backText}</span> : null}
            <div className="text-white top-title" style={{fontSize:'14px', display: 'inline'}}>{this.props.headerName}</div>
          </NavItem>
        </Nav>
        <Nav navbar className={bem.e('nav-right')} style={{position:'absolute',right:'10px',lineHeight:'44px',padding:0}}>
          <NavItem>
            <img src={this.getImage(`vl-white.png`)} alt=""  style={{position:'relative',float:'right',width:'30px'}}/>
          </NavItem>
          </Nav>
      </Navbar>
      </div>
    );
  }
}

export default withTranslation('common')(HeaderWithBack);
