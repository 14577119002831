import React from 'react';
import PropTypes from 'utils/propTypes';
import { Card, CardText } from 'reactstrap';
import Typography from 'components/Typography';
import { MdKeyboardArrowRight } from 'react-icons/md';
import {lustcitycolor, lustcityfun, ysccolor,eurolotto, yxxpic} from '../services/AwardService';

const yxxImages = require.context('../assets/image/YXX', true);
const osdImages = require.context('../assets/image/OSD', true);

const AwardPage = ({ title, description, issue, playid, gameid, gamePictureMod, total, getGameImage, gamecategory, gamemode, ...restProps }) => {
  let fun,color,path;
  if(playid === "LCT"){
    fun = lustcityfun(description[0]);
    color = lustcitycolor(description[0]);
    path = "LUSTCITY";
  } else if (playid === "YSC") {
    color = ysccolor(description[0]);
    path = "YSC";
  }

  return (
    <Card body {...restProps} style={{ width: '100%',padding: '14px 16px',borderRadius: 0 , borderTop: 0,borderBottomWidth: '0.4px'}}>
      <table>
        <tbody>
        <tr>
          <td style={{width:'50px',textAlign:'left',verticalAlign:'top'}}>
          {gamePictureMod!=null?<img className="rules-img" alt="" src={window.$wgtDomain + gamePictureMod} />:
              gamecategory === null ?
          <>
            {
              gamemode != null ?
                gamemode != 1 ?
                <img className="rules-img" alt="" src={getGameImage('en_safe_'+gameid+'.png')} />
                : <img className="rules-img" alt="" src={getGameImage('safe_'+gameid+'.png')} />
              : <img className="rules-img" alt="" src={getGameImage('safe_'+gameid+'.png')} />
            }
          </>
          : gamecategory === 1 ?
            <img className="rules-img" alt="" src={getGameImage('safe_'+gameid+'.png')} />
            : <img className="rules-img" alt="" src={getGameImage('en_safe_'+gameid+'.png')} />
          }
          </td>
          <td style={{textAlign:'left', width:'100%'}}>
          <div className="d-flex justify-content-between">
          <CardText tag="div">
            <Typography className="mb-0" style={{lineHeight:'24px'}}>
                {title}
            </Typography>
            <div style={{marginLeft:'auto',fontSize:10,color:'#757575',lineHeight:'24px'}}>{issue}</div>
          </CardText>
          <MdKeyboardArrowRight size={24} style={{color:'#cacacf'}}/>
          </div>
          <CardText tag="div" className="d-flex justify-content-between" style={{marginTop: 5}}>
            <Typography tag="span" className="text-left text-muted small">
              {
                playid !== "STM" ?
                  (playid !== "LCT" && playid !== "YSC" && playid !== "YXX" && playid !== "OSD") ?
                    description.map((item,index)=>{
                      const itemValue = item + "";
                      return (
                        <>
                          <div key={index} className={playid === "KW3" ? "dice dice"+itemValue : playid === "BSC" ? "racing r"+itemValue : (playid === "LHC" || playid === "LOT" || playid === "JHC") ? "LHC-ball-small-full lhc-b-"+itemValue : eurolotto(playid) ?"LHC-ball-small-full lhc-b-01": "red-ball" }>
                            {item}
                          </div>
                          {/*LHC*/}
                          {index === description.length-2 && (playid === "LHC" || playid === "LOT" || eurolotto(playid) || playid === "JHC") ?
                            <div style={{display:'inline-block',marginRight:5,color:'black'}}>+</div>
                          :
                            null
                          }
                          {
                            (playid === "DDC" || playid==="QQC") && index === 2 ?
                              <>
                                <div style={{display:'inline-block',marginRight:5,color:'black'}}>
                                  =
                                </div>
                                <div className={"red-ball"} >
                                  {total}
                                </div>
                              </>
                              :
                              (playid === "DDC" || playid==="QQC") && index !== 2 ?
                                <div style={{display:'inline-block',marginRight:5,color:'black'}}>
                                  +
                                </div>
                              :
                                null
                          }
                        </>
                        )
                    })
                    :
                    (playid === "YXX") ?
                      description.map((item,index)=>{
                        const itemValue = item + "";
                        return (
                        <>
                          {<img style={{ width: '35px', height: '34px', borderRadius: '10px', marginBottom: '5px' }} alt="" src={yxxImages('./'+yxxpic(item)+'.png')}/>}
                        </>
                        )
                      })
                    :
                    (playid === "OSD") ?
                      description.map((item,index)=>{
                        const itemValue = item + "";
                        return (
                        <>
                          {<img style={{ width: '28px', height: '28px',marginRight: '3px'}} alt="" src={osdImages('./'+item+'.png')}/>}
                        </>
                        )
                      })
                    :
                    <>
                      {playid === "LCT" &&
                      <img className="lct-award-num" alt="" src={require(`../assets/image/${path}/${fun}.png`)}
                           style={{ marginBottom: 5, marginRight: 3 }}/>
                      }
                      <div className="lct-award-num">{description[0]}</div>
                      <img className="lct-award-num" alt="" src={require(`../assets/image/${path}/${color}.png`)} style={{marginBottom:5, marginLeft:3}}/>
                    </>
                  :
                  (parseFloat(description[1])-parseFloat(description[0])).toFixed(2)>0?
                    <>
                    <span className="stm-trending-up" >{description[1]}</span>
                    <img src={require(`../assets/image/trending_up.png`)} alt="" style={{width:20, height:20, marginBottom:5, marginLeft:3}}/>
                    </>
                    :
                    <>
                    <span className="stm-trending-down" >{description[1]}</span>
                    <img src={require(`../assets/image/trending_down.png`)} alt="" style={{width:20, height:20, marginBottom:5, marginLeft:3}}/>
                    </>
              }
            </Typography>
          </CardText>
          </td>
        </tr>
        </tbody>
      </table>

    </Card>
  );
};

AwardPage.propTypes = {
  title: PropTypes.string.isRequired,
  right: PropTypes.node,
};

export default AwardPage;
